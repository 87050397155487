import { EntityChange } from '@laingorourke/core-web-components';
import { History } from 'domain/models/api-models';
import { usePeopleByEmails } from 'domain/services/dataService/usePeopleByEmails';

const isEmail = (value?: string) => value && /\S+@\S+\.\S+/.test(value);

export const useEntityChangesWithAuthors = (
    history: History
): [EntityChange[] | undefined, boolean] => {
    const emails = history?.entityChanges?.filter((e) => isEmail(e.author))?.map((e) => e.author!);
    const people = usePeopleByEmails(emails);
    const entityChanges = history?.entityChanges?.map((e) => {
        const person = people.data?.find(
            (p) => p.contact.email.toLocaleLowerCase() === e.author?.toLocaleLowerCase()
        );
        const displayName =
            person?.displayName ?? (!!e.author && !isEmail(e.author) ? e.author : undefined);
        return {
            ...e,
            author: {
                displayName: displayName,
                photo: person?.photo,
            },
        };
    });

    const isLoading = !history?.entityChanges || people.isLoading;
    return [entityChanges, isLoading];
};
