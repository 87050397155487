import { generatePath } from '@laingorourke/core-web-components';
import { InspectionType } from 'domain/models/api-models';
import { useInspectionType } from 'domain/store/reducers/inspectionType';
import { useItemTypeMetadata } from 'domain/store/reducers/itemType';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { AdminRouteParams } from '../../AdminRouteParams';
import { UpsertForm } from './UpsertForm';

interface InspectionTypeParams extends AdminRouteParams {
    inspectionTypeId: string;
}

export const UpsertInspectionType: React.FC = () => {
    const history = useHistory();
    const { inspectionTypeId, itemTypeCode } = useParams<InspectionTypeParams>();
    const inspectionType: InspectionType | undefined = useInspectionType(inspectionTypeId);
    const metadata = useItemTypeMetadata();

    const categories = metadata?.categories;
    const isEditMode = inspectionTypeId !== undefined;

    const goToInspectionTypes = () =>
        history.push(generatePath(routes.admin.routes!.inspectionTypes.path, { itemTypeCode }));

    return (
        <>
            {categories && (inspectionTypeId === undefined || inspectionType !== undefined) && (
                <Modal show={true} onHide={goToInspectionTypes} animation={false} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditMode ? 'Edit' : 'New'} Inspection</Modal.Title>
                    </Modal.Header>
                    <SecuredContent
                        permissions={Permission.ManageComplianceSettingsWrite}
                        ignoreSecurable={true}>
                        <UpsertForm
                            categories={categories}
                            isEditMode={isEditMode}
                            inspectionType={inspectionType}
                            goToInspectionTypes={goToInspectionTypes}
                        />
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
