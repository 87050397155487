import { useCurrentExternalUserGroups } from 'domain/services/dataService/useCurrentExternalUserGroups';
import { fetchProjectItemDetails, useItemDetails } from 'domain/store/reducers/projects';
import { useIsExternalUser } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectItemDetailsPage } from '../ProjectItemDetailsPage';
import { ProjectItemDetailsParams } from '../ProjectItemDetailsParams';
import { GroupsDataTable } from './GroupsDataTable';

const getGroupIds = (isExternal: boolean, itemGroupIds?: string[], externalGroupIds?: string[]) => {
    //externals can only see members of groups they belong
    return itemGroupIds?.filter((id) => !isExternal || externalGroupIds?.includes(id));
};

export const ItemGroups: React.FC = () => {
    const dispatch = useAppDispatch();
    const { projectItemId, projectNumber } = useParams<ProjectItemDetailsParams>();
    const itemDetails = useItemDetails();
    const isExternal = useIsExternalUser();
    const externalUserGroups = useCurrentExternalUserGroups();
    const dataLoaded = !!itemDetails && (!isExternal || !externalUserGroups.isFetching);

    useEffect(() => {
        dispatch(fetchProjectItemDetails({ projectItemId, projectNumber }));
    }, [projectNumber, projectItemId, dataLoaded, dispatch]);

    return (
        <ProjectItemDetailsPage title={itemDetails?.name}>
            <GroupsDataTable
                groupIds={getGroupIds(
                    isExternal,
                    itemDetails?.groupIds,
                    externalUserGroups.data?.map((eg) => eg.id)
                )}
                projectNumber={projectNumber}
            />
        </ProjectItemDetailsPage>
    );
};
