import { Button } from '@laingorourke/core-web-components';
import cn from 'classnames';
import React, { useState } from 'react';
import { ItemNumber } from '../../../../../../components/itemNumber/ItemNumber';
import styles from './QrCode.module.scss';

interface QRCodeProps {
    itemNumber: string;
    projectNumber: string;
    itemTitle: string;
    qrCodeAsBase64: string;
}

export const QRCode: React.FC<QRCodeProps> = ({ itemNumber, projectNumber, qrCodeAsBase64, itemTitle }) => {
    const [qrcodeLoaded, setQrcodeLoaded] = useState(false);
    const printQrCode = () => {
        const content = document.getElementById('qrcode') as any;
        const contentPrint = (document.getElementById('ifmcontentstoprint') as any).contentWindow;
        contentPrint.document.open();
        contentPrint.document.write(content.innerHTML);

        setTimeout(function () {
            contentPrint.document.close();
            contentPrint.focus();
            contentPrint.print();
            // https://stackoverflow.com/questions/31725373/google-chrome-not-showing-image-in-print-preview
            // we need allow some time for load
        }, 250);
    };

    return (
        <>
            <div id="qrcode" className={styles.container}>
                <style>
                    {` @media print {
                        .qrcode{
                            width: 90%;
                            display: block;
                            margin:auto;
                        }
                        .itemNumber{
                            font-size: 70px;
                            display: inline-block;
                            text-align: center;
                            width: 100%;
                            font-family: "Century Gothic", Futura, Helvetica Neue, Helvetica, Arial, sans-serif;
                        }
                        .itemTitle{
                            font-size: 28px;
                            display: inline-block;
                            text-align: center;
                            width: 100%;
                            font-family: "Century Gothic", Futura, Helvetica Neue, Helvetica, Arial, sans-serif;
                        }
                        .instruction {
                            font-size: 18px;
                            font-family: "Century Gothic", Futura, Helvetica Neue, Helvetica, Arial, sans-serif;
                            margin-bottom: 0;
                        }
                        .first{
                            margin-top: 10px;
                        }
                    }`}
                </style>
                <img
                    className="qrcode"
                    alt="QR Code"
                    src={`data:image/png;base64,${qrCodeAsBase64!}`}
                    onLoad={() => setQrcodeLoaded(true)}
                />
                <span className="itemNumber">
                    <ItemNumber itemNumber={itemNumber} projectNumber={projectNumber} copyTextEnabled={false} />
                </span>
                <span className={cn(styles.hidden, 'itemTitle')}>{itemTitle}</span>
                <p className={cn(styles.hidden, 'instruction', 'first')}>
                    To scan a QR Code: point your smartphone’s camera at a QR Code and click on the
                    notification URL that pops up. This will direct you to the Laing O’Rourke Comply
                    application. Alternatively, open Comply app and select "Scan QR Code" from app
                    menu.
                </p>
                <p className={cn(styles.hidden, 'instruction')}>
                    This works with both Android phones running on Android 8 and higher and iPhones
                    (iOS 11 or higher).
                </p>
            </div>
            {qrcodeLoaded && (
                <Button variant="link" onClick={printQrCode}>
                    Print QR Code
                </Button>
            )}
        </>
    );
};
