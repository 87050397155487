import { LorImagesCdn3rdPartyLogos } from 'consts';
import { ApplicationLogo, DateTableCell, TooltipWrapper } from 'views/components';
import styles from './DateInService.module.scss';

interface DateInServiceProps {
    id: string;
    dateInService: Date;
    isDateInServiceFromP6: boolean;
}

export const DateInService: React.FC<DateInServiceProps> = ({
    id,
    dateInService,
    isDateInServiceFromP6,
}) => {
    return (
        <div className={styles.container}>
            <DateTableCell date={dateInService} />
            {isDateInServiceFromP6 && (
                <TooltipWrapper id={`p6-${id}`} tooltipContent="This Date In Service is from P6">
                    <ApplicationLogo
                        className={styles.logo}
                        alt="P6"
                        src={`${LorImagesCdn3rdPartyLogos}/p6.png`}
                    />
                </TooltipWrapper>
            )}
        </div>
    );
};
