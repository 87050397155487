import {
    TelemetryProvider,
    ToastPosition,
    ToastProvider,
    TourContextProvider,
} from '@laingorourke/core-web-components';
import { PersonProvider } from '@laingorourke/core-web-components-personselector';
import { HttpClientProvider } from '@laingorourke/core-web-httpclient';
import { MyTasksProvider } from '@laingorourke/core-web-mytasksreactsdk';
import { QueryClient } from "@tanstack/react-query";
import store from 'domain/store/rootStore';
import { History, createBrowserHistory as createHistory } from 'history';
import React from 'react';
import { Provider } from 'react-redux';
import { Router as ReactRouter } from 'react-router-dom';
import {
    getApplicationInsightsConfiguration,
    getDataServiceConfiguration,
    getMyTasksConfiguration,
} from './helpers/applicationConfig';
import './index.scss';
import App from './views/App';
import Auth0ProviderWithHistory from './views/Auth0ProviderWithHistory';

const ComplyApp: React.FC = () => {
    const history = createHistory();
    // this consolog log is put here for analysys purposes - investigation of history rendender issue
    console.info('Rerender of Comply App');
    return (
        <ToastProvider position={ToastPosition.TopRight}>
            <Provider store={store}>
                <Shell history={history} />
            </Provider>
        </ToastProvider>
    );
};

export default ComplyApp;

const Shell: React.FC<{ history: History }> = ({ history }) => {
    const myTasksConfig = getMyTasksConfiguration();
    const dataServiceConfig = getDataServiceConfiguration();

    const client = new QueryClient();

    return (
        <TelemetryProvider
            history={history}
            instrumentationKey={getApplicationInsightsConfiguration()?.instrumentationKey!}>
            <Auth0ProviderWithHistory history={history}>
                {myTasksConfig && (
                    <HttpClientProvider>
                        <PersonProvider dataServiceEndpoint={dataServiceConfig?.endpoint!} client={client} userServicesHttpClientKey="COMPLY">
                            <MyTasksProvider
                                baseUrl={myTasksConfig?.api!}
                                audience={myTasksConfig?.audience}>
                                <ReactRouter history={history}>
                                    <TourContextProvider>
                                        <App />
                                    </TourContextProvider>
                                </ReactRouter>
                            </MyTasksProvider>
                        </PersonProvider>
                    </HttpClientProvider>
                )}
            </Auth0ProviderWithHistory>
        </TelemetryProvider>
    );
};
