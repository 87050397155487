import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

interface State {
    applicationMessage: ApplicationMessageState;
}

export interface ApplicationMessageState {
    type?: 'danger' | 'warning';
    isError: boolean;
    message: string;
}

export const initialErrorState: ApplicationMessageState = {
    type: 'danger',
    isError: false,
    message: '',
};

export const ApplicationMessageSlice = createSlice({
    name: 'ApplicationMessage',
    initialState: initialErrorState,
    reducers: {
        setError: (
            state: ApplicationMessageState,
            action: PayloadAction<ApplicationMessageState>
        ) => {
            return {
                ...state,
                type: action.payload.type || 'danger',
                isError: action.payload.isError,
                message: action.payload.message,
            };
        },
        clearError: (state: ApplicationMessageState) => {
            return { ...state, isError: false, message: '' };
        },
    },
});

export const useErrorState = () => useSelector((state: State) => state.applicationMessage);

export const { setError, clearError } = ApplicationMessageSlice.actions;
export default ApplicationMessageSlice.reducer;
