import { gql, GraphQLClient, Variables } from 'graphql-request';
import { GraphQLResponse } from 'graphql-request/build/esm/types';
import { getDataServiceConfiguration } from 'helpers/applicationConfig';
import { auth0Token } from '../auth0Service';
import { LogLevel, LogService } from '../logService';
import { GraphQLNotFoundExceptionName } from './constants';

const allowedStages = ['bidding', 'won'];
const minGateway = 3;

export const getGraphQlClient = async () => {
    const dataServiceConfig = getDataServiceConfiguration();
    if (!dataServiceConfig) return;

    const token = await auth0Token.getAccessToken();

    return new GraphQLClient(dataServiceConfig?.endpoint!, {
        headers: {
            authorization: `bearer ${token}`,
        },
    });
};

export const getProjects = async () => {
    const query = gql`
        {
            projects {
                id
                name
                projectNumber
                stage
                stageDescription
                discipline
                logoUrl
                sector
                gateway
                addresses {
                    id
                    name
                    streetAddress
                    postcode
                    city
                    state
                }
            }
        }
    `;

    let result = await getQueryResult(query);
    return result.projects
        .filter(
            (p: any) =>
                allowedStages.indexOf(p.stageDescription.toLowerCase()) > -1 &&
                p.gateway >= minGateway
        )
        .map((p: any) => ({
            ...p,
            sites: p.addresses.map((a: any) => ({
                id: a.id,
                name: a.name,
                address: `${a.streetAddress}, ${a.postcode} ${a.city}, ${a.state}`,
            })),
        }));
};

const catchError = async (error: any) => {
    const logService = new LogService();
    const response = error.response as GraphQLResponse;

    const allNotFound = response.errors?.every((e) =>
        (e.extensions['codes'] as string[]).every((code) => code === GraphQLNotFoundExceptionName)
    );

    logService.logError(
        `An error occurred. name: '${error.name}' message: '${error.message}'`,
        error.stack,
        allNotFound ? LogLevel.Warning : LogLevel.Error
    );
    throw error;
};

export const getQueryResult = async <T = any, V = Variables>(
    query: string,
    variables?: V,
    dispatchError: (error: any) => boolean = (_) => true
): Promise<T> => {
    const client = await getGraphQlClient();
    if (!client) throw new Error('Cannot create an instance of graphQl client');
    return (await client.request(query, variables as Variables | undefined).catch((e) => {
        if (dispatchError(e)) catchError(e);
    })) as Promise<T>;
};
