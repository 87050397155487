import { Form, useForm } from '@laingorourke/core-web-components';
import {
    Classification,
    ComplianceTemplate,
    ControlMeasureSummary,
    InspectionTypeSummary,
    ItemTypeCategory,
} from 'domain/models/api-models';
import {
    createComplianceTemplate,
    updateComplianceTemplate,
} from 'domain/store/reducers/complianceTemplates';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Permission } from 'security/Permission';
import { ModalFooter } from 'views/components';
import { ComplianceTemplateFormProps, ComplianceTemplateSchema } from '../../../schemas';
import { UpsertComplianceTemplateFormFields } from './UpsertComplianceTemplateFormFields';

interface AddOrEditFormProps {
    complianceTemplate: ComplianceTemplate | undefined;
    projectNumber: string | undefined;
    categories: ItemTypeCategory[];
    inspectionTypes: InspectionTypeSummary[];
    controlMeasures: ControlMeasureSummary[];
    classifications: Classification[];
    isEditMode: boolean;
    isProjectMode: boolean;
    onCancel: () => void;
}

export const AddOrEditForm: React.FC<AddOrEditFormProps> = ({
    complianceTemplate,
    projectNumber,
    categories,
    inspectionTypes,
    controlMeasures,
    classifications,
    isEditMode,
    isProjectMode,
    onCancel,
}) => {
    const dispatch = useAppDispatch();

    const userHasPermissionsToAllProjects = useHasPermissions(
        Permission.ManageComplianceSettingsWrite,
        ''
    );

    const itemTypeId = useSelectedItemTypeId();

    const form = useForm<ComplianceTemplateFormProps>({
        initialValues: {
            allProjects:
                !userHasPermissionsToAllProjects || isProjectMode
                    ? false
                    : isEditMode
                    ? complianceTemplate?.projectNumber === null
                    : true,
            projectNumber: complianceTemplate?.projectNumber ?? projectNumber ?? null,
            categoryId: complianceTemplate?.categoryId ?? null,
            example: isEditMode ? complianceTemplate!.example! : '',
            inspections: isEditMode
                ? complianceTemplate!.inspectionTypes!.map((c) => ({
                      inspectionTypeId: c.id!,
                      frequency: c.frequency!,
                  }))
                : ([] as { inspectionTypeId: string; frequency: number }[]),
            controlMeasuresIds: isEditMode
                ? complianceTemplate?.controlMeasuresIds!
                : ([] as string[]),
            classificationId: complianceTemplate?.classificationId as string,
        },
        validationSchema: ComplianceTemplateSchema,
        onSubmit: async (values) => {
            let result;
            if (isEditMode) {
                const { allProjects, classificationId, categoryId, ...updateValues } = values;
                result = await dispatch(
                    updateComplianceTemplate({
                        ...updateValues,
                        itemTypeId,
                        id: complianceTemplate!.id,
                    })
                );
            } else {
                const { allProjects, ...createValues } = values;
                result = await dispatch(
                    createComplianceTemplate({
                        ...createValues,
                        itemTypeId,
                    })
                );
            }
            if ((result as any).payload) onCancel();
        },
    });

    const { isSubmitting } = form;
    return (
        <Form form={form}>
            <Modal.Body>
                <UpsertComplianceTemplateFormFields
                    form={form}
                    isEditMode={isEditMode}
                    isProjectMode={isProjectMode}
                    complianceTemplate={complianceTemplate}
                    categories={categories!}
                    inspectionTypes={inspectionTypes}
                    classifications={classifications!}
                    controlMeasures={controlMeasures}
                    writePermission={Permission.ManageComplianceSettingsWrite}
                />
            </Modal.Body>
            <ModalFooter
                submitText={isEditMode ? 'Update' : 'Create'}
                isSubmitting={isSubmitting}
                onCancel={onCancel}
            />
        </Form>
    );
};
