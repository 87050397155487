import { youAreOfflineDataNotAvailableMessage } from '@laingorourke/core-web-components';
import { useIsOffline } from 'hooks/useIsOffline';
import React, { PropsWithChildren } from 'react';
import { InfoPanel } from '..';

export const OnlineContentLoader: React.FC<PropsWithChildren<{ loading: boolean }>> = ({
    loading,
    children,
}) => {
    const isOffline = useIsOffline();
    return isOffline && loading ? (
        <InfoPanel message={youAreOfflineDataNotAvailableMessage} />
    ) : (
        <>{children}</>
    );
};
