import { describeRecurrence, Table } from '@laingorourke/core-web-components';
import { SCHEDULEKEY, useSchedules } from '@laingorourke/core-web-mytasksreactsdk';
import { Recurrence } from '@laingorourke/core-web-types';
import { useQueryClient } from '@tanstack/react-query';
import { ProjectItemDetailsSummary } from 'domain/models/api-models';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useWithAssignee } from 'hooks/useWithPerson';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Permission } from 'security/Permission';
import { getDefaultSortedByColummKey, getFilterOptions } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { getTaskName } from 'views/routes/project/myTasksHelpers';
import { ProjectItemDetailsParams } from '../ProjectItemDetailsParams';
import { getTableColumns } from './tableColumns';

interface SchedulesDataTableProps {
    itemDetails: ProjectItemDetailsSummary;
    projectItemId: string;
    itemTypeId: string;
    currentUserHasManageItemsWritePermission: boolean;
    currentUserHasManageItemsDeletePermission: boolean;
    nonEditableStatusesMessage: string;
    projectId?: string;
}

const scheduleNonEditableMessage = (nonEditableStatusesMessage: string) =>
    `Inspection Schedule can not be edited or deleted as the Item is of status ${nonEditableStatusesMessage}`;

const scheduleInactiveMessage = 'This Inspection Schedule has been deleted';

const editBlockedMessage = (
    isScheduleActive: boolean,
    itemDetails: ProjectItemDetailsSummary,
    nonEditableStatusesMessage: string
) =>
    !isScheduleActive
        ? scheduleInactiveMessage
        : itemDetails?.canBeUpdated
            ? ''
            : scheduleNonEditableMessage(nonEditableStatusesMessage);

const actionsBlockedMessage = (
    isScheduleActive: boolean,
    itemDetails: ProjectItemDetailsSummary,
    nonEditableStatusesMessage: string,
    currentUserHasManageInspectionsDeletePermission: boolean,
) => {
    if (!isScheduleActive) {
        return scheduleInactiveMessage;
    }
    if (!itemDetails?.canBeUpdated) {
        return scheduleNonEditableMessage(nonEditableStatusesMessage);
    }

    if (!currentUserHasManageInspectionsDeletePermission) {
        return 'You are not authorised to Delete a Schedule created from Compliance Template.';
    }
    return '';
};

export const SchedulesDataTable = ({
    itemDetails,
    projectItemId,
    itemTypeId,
    currentUserHasManageItemsWritePermission,
    currentUserHasManageItemsDeletePermission,
    nonEditableStatusesMessage,
    projectId,
}: SchedulesDataTableProps) => {

    const { projectNumber } = useParams<ProjectItemDetailsParams>();

    const currentUserHasManageInspectionsDeletePermission = useHasPermissions(
        Permission.ManageInspectionsDelete,
        projectNumber
    );
    const query = {
        filter: {
            and: [
                {
                    meta: {
                        any: { label: 'ProjectItemId', value: projectItemId },
                    },
                },
                {
                    meta: {
                        any: { label: 'ItemTypeId', value: itemTypeId },
                    },
                },
            ],
        },
    };

    const queryClient = useQueryClient();
    const schedules = useSchedules(query, undefined, false);

    const schedulesData = schedules.data?.map((s) => {
        const isManuallyCreatedSchedule = !!s.meta.find(
            (m) => m.label === 'Source' && m.value.toLowerCase() === 'manual'
        );
        return {
            id: s.id,
            name: getTaskName(s.name),
            assignedUserId: s.template.assignedUserId,
            description: describeRecurrence(s.recurrence as Recurrence),
            canBeEdited: s.isActive && itemDetails?.canBeUpdated,
            editBlockedMessage: editBlockedMessage(
                s.isActive,
                itemDetails,
                nonEditableStatusesMessage
            ),
            canBeDeleted: s.isActive && (isManuallyCreatedSchedule || currentUserHasManageInspectionsDeletePermission) && !!itemDetails?.canBeUpdated,
            actionsBlockedMessage: actionsBlockedMessage(
                s.isActive,
                itemDetails,
                nonEditableStatusesMessage,
                currentUserHasManageInspectionsDeletePermission
            ),
            startDate: new Date(s.startDate),
            endDate: !!s.endDate ? new Date(s.endDate) : undefined,
            offset: s.offset.toString().replace('-', ''),
            isActive: s.isActive,
        };
    });
    const data = useWithAssignee(schedulesData);
    const dataLoaded = !!schedulesData;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Schedules found');

    const names = getFilterOptions(data, 'name');
    const columns = getTableColumns(
        names,
        currentUserHasManageItemsWritePermission,
        currentUserHasManageItemsDeletePermission,
        projectId
    );
    // invalidate query (and get tasks again when the page is opened again)
    useEffect(() => {
        return () => {
            queryClient.invalidateQueries(SCHEDULEKEY());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Table
            {...getDefaultSortedByColummKey(columns)}
            {...tableLoadingProps}
            data={data ?? []}
            keySelector={(d) => d.id}
            columns={columns}
            hover
        />
    );
};
