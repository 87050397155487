import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    CreateDesignerRequest,
    Designer,
    DesignerSummary,
    ProjectDesigner,
    UpdateDesignerRequest,
} from 'domain/models/api-models';
import { get, patch, post, remove } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

interface FetchDesignersParams {
    itemTypeId: string;
}

export const fetchDesigners = createAsyncThunk<
    Designer[],
    FetchDesignersParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/designers/get',
    async (args) => {
        return (await get(`itemtypes/${args.itemTypeId}/designers`))!;
    },
    {
        condition: (args) => !!args.itemTypeId,
    }
);

interface FetchProjectDesignersParams {
    projectNumber: string;
    itemTypeId: string;
}

export const fetchDesignersSummaries = createAsyncThunk<
    DesignerSummary[],
    FetchProjectDesignersParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/designers/summaries/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/designers/summaries`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

export const fetchProjectDesigners = createAsyncThunk<
    ProjectDesigner[],
    FetchProjectDesignersParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/designers/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/designers`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface DisableDesigner extends FetchProjectDesignersParams {
    designerId: string;
    designerName: string;
}

export const disableDesigner = createAsyncThunk<string, DisableDesigner, { state: RootState }>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/designers/{designerId}/disable/post',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await post(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/designers/${args.designerId}/disable`
            );
            dispatch(
                addNotification({
                    message: `Designer ${args.designerName} has been disabled succesfully.`,
                    isSuccess: true,
                })
            );
            return args.designerId;
        }, dispatch);
    }
);

export const enableDesigner = createAsyncThunk<string, DisableDesigner, { state: RootState }>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/designers/{designerId}/enable/post',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await post(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/designers/${args.designerId}/enable`
            );
            dispatch(
                addNotification({
                    message: `Designer ${args.designerName} has been enabled succesfully.`,
                    isSuccess: true,
                })
            );
            return args.designerId;
        }, dispatch);
    }
);

interface CreateDesigner extends CreateDesignerRequest {
    itemTypeId: string;
}

export const createDesigner = createAsyncThunk<Designer, CreateDesigner, { state: RootState }>(
    'itemtypes/{itemTypeId}/designers/post',
    async (designer, { dispatch }) => {
        return await runSafe(async () => {
            const createdDesigner = await post(
                `itemtypes/${designer.itemTypeId}/designers`,
                designer
            );
            dispatch(
                addNotification({
                    message: 'Designer has been created successfully',
                    isSuccess: true,
                })
            );
            return createdDesigner;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId,
    }
);

interface UpdateDesigner extends UpdateDesignerRequest {
    itemTypeId: string;
    id: string;
}

export const updateDesigner = createAsyncThunk<Designer, UpdateDesigner, { state: RootState }>(
    'itemtypes/{itemTypeId}/designers/patch',
    async (designer, { dispatch }) => {
        return await runSafe(async () => {
            const updatedDesigner = await patch(
                `itemtypes/${designer.itemTypeId}/designers/${designer.id}`,
                designer
            );

            dispatch(
                addNotification({
                    message: 'Designer has been updated successfully',
                    isSuccess: true,
                })
            );
            return updatedDesigner;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId,
    }
);

interface DeleteParams {
    itemTypeId: string;
    id: string;
}

export const deleteDesigner = createAsyncThunk<string, DeleteParams, { state: RootState }>(
    'itemtypes/{itemTypeId}/designers/delete',
    async (designer, { dispatch }) => {
        return await runSafe(async () => {
            await remove(`itemtypes/${designer.itemTypeId}/designers/${designer.id}`);
            dispatch(
                addNotification({
                    message: 'Designer has been deleted successfully',
                    isSuccess: true,
                })
            );
            return designer.id;
        }, dispatch);
    },
    {
        condition: (designer) => !!designer.itemTypeId && !!designer.id,
    }
);

export const reactivateDesigner = createAsyncThunk<
    Designer,
    { id: string; itemTypeId: string },
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/designers/patch/reactivate',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const updatedDesigner = await patch(
                `itemtypes/${args.itemTypeId}/designers/${args.id}/reactivate`,
                args
            );

            dispatch(
                addNotification({
                    message: 'Designer has been activated successfully',
                    isSuccess: true,
                })
            );
            return updatedDesigner;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId,
    }
);
