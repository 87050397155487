import cn from 'classnames';
import React, { PropsWithChildren } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { OverlayDelay } from 'react-bootstrap/esm/OverlayTrigger';
import { Placement } from 'react-bootstrap/esm/types';
import styles from './TooltipWrapper.module.scss';

interface TooltipProps {
    tooltipContent: string | React.ReactNode;
    id: string;
    placement?: Placement;
    delay?: OverlayDelay;
    children: React.ReactNode;
    show?: boolean;
    className?: string;
}

export const TooltipWrapper: React.FC<PropsWithChildren<TooltipProps>> = ({
    tooltipContent,
    id,
    show,
    children,
    placement,
    delay,
    className,
}) => {
    const showTooltip = show && !!tooltipContent;
    const tooltip = (
        <Popover id={`popover-${id}`}>
            <Popover.Body className={styles.popover}>{tooltipContent}</Popover.Body>
        </Popover>
    );
    return (
        <>
            {!showTooltip && children}
            {showTooltip && (
                <OverlayTrigger placement={placement} overlay={tooltip} delay={delay}>
                    <div className={cn(styles.buttonWrapper, className)}>{children}</div>
                </OverlayTrigger>
            )}
        </>
    );
};

TooltipWrapper.defaultProps = {
    show: true,
    delay: { show: 250, hide: 400 },
    placement: 'bottom',
};
