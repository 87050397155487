import { Checkbox, Form } from '@laingorourke/core-web-components';
import cn from 'classnames';
import { ControlMeasureSummary } from 'domain/models/api-models';
import { useSelectedItemTypeCode } from 'domain/store/reducers/itemType';
import { FieldArrayRenderProps } from 'formik';
import { fieldClassnames } from 'views/components/form';
import { RouteLink } from 'views/components/links';
import { routes } from 'views/routes/Routes';
import { ComplianceTemplateFormikProps } from './ComplianceTemplateFormikProps';
import styles from './ControlMeasuresFieldArray.module.scss';

interface ControlMeasuresFieldArrayProps {
    values: ComplianceTemplateFormikProps;
    errors: any;
    touched: any;
    arrayHelpers: FieldArrayRenderProps;
    controlMeasures: ControlMeasureSummary[];
}

export const ControlMeasuresFieldArray: React.FC<ControlMeasuresFieldArrayProps> = ({
    controlMeasures,
    values,
    errors,
    touched,
    arrayHelpers,
}) => {
    const itemTypeCode = useSelectedItemTypeCode();
    const getControlMeasuresToDisplay = (projectNumber: string | null) =>
        controlMeasures
            ?.filter((c) => c.projectNumber === projectNumber || c.projectNumber === null)
            .map((c) => c.id);

    const getControlMeasuresOfProject = (projectNumber: string) =>
        controlMeasures?.filter((c) => c.projectNumber === projectNumber).map((c) => c.id);

    const onChange = (checked: boolean, controlMeasureId: string) => {
        if (checked) arrayHelpers.push(controlMeasureId);
        else {
            const idx = values.controlMeasuresIds.indexOf(controlMeasureId);
            arrayHelpers.remove(idx);
        }
    };

    const controlMeasuresToDisplay = getControlMeasuresToDisplay(values.projectNumber);

    return (
        <>
            <label className="field-label form-label">Control Measures</label>

            {values.projectNumber && !getControlMeasuresOfProject(values.projectNumber).length && (
                <>
                    <p>No Control Measures for this project have been created yet.</p>

                    <RouteLink
                        className={styles.link}
                        route={routes.admin.routes!.controlMeasures.routes?.newControlMeasure!}
                        params={{ itemTypeCode }}>
                        Create A Control Measure
                    </RouteLink>
                </>
            )}
            <ul
                className={cn(
                    fieldClassnames('controlMeasuresIds', touched, errors, false),
                    styles.list
                )}>
                {controlMeasuresToDisplay.map((controlMeasureId, i) => (
                    <li key={controlMeasureId}>
                        <Form.Field name={controlMeasureId} label="" className={styles.label}>
                            <Checkbox
                                name={`controlMeasures[${i}]`}
                                onChange={(e) => onChange(e, controlMeasureId)}
                                isInvalid={!!errors.allProjects}
                                checked={values.controlMeasuresIds?.includes(controlMeasureId)}>
                                {controlMeasures.find((c) => c.id === controlMeasureId)!.name}
                            </Checkbox>
                        </Form.Field>
                    </li>
                ))}
            </ul>
        </>
    );
};
