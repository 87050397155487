import { ApplicationProject } from 'domain/models/ApplicationProject';
import { gql } from 'graphql-request';
import { getQueryResult } from './graphQlClient';
import { Page } from './models';

export const getApplicationProjects = async (applicationName: string) => {
    const query = gql`
        query getApplicationProjects($applicationName: String!) {
            applicationProjects(applicationName: $applicationName) {
                data {
                    application
                    applicationProjectId
                    applicationProjectName
                }
            }
        }
    `;
    const result = await getQueryResult<{
        applicationProjects: Page<ApplicationProject>;
    }>(query, { applicationName });

    return result?.applicationProjects?.data;
};
