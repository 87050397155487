import { useControlMeasureSummaries } from 'domain/store/reducers/complianceTemplates';
import { useHasPermissions } from 'domain/store/reducers/users';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ActionButtonsPanel, usePageActions } from 'views/components';
import { TooltipButton } from 'views/components/tooltipButton';
import { ComplianceTemplateProps } from '../ComplianceTemplateProps';
import { useGenerateComplianceTemplatePath } from '../hooks/useGenerateComplianceTemplatePath';

interface NewComplianceTemplateButtonProps extends ComplianceTemplateProps {}

export const NewComplianceTemplateButton: React.FC<NewComplianceTemplateButtonProps> = ({
    itemTypeCode,
    projectNumber,
    isProjectMode,
}) => {
    const history = useHistory();
    const generateComplianceTemplatePath = useGenerateComplianceTemplatePath();
    const controlMeasuresLength = useControlMeasureSummaries()?.length;

    const hasWritePermissions = useHasPermissions(
        Permission.ManageComplianceSettingsWrite,
        projectNumber,
        !isProjectMode
    );

    const goToNewComplianceTemplate = () =>
        history.push(
            generateComplianceTemplatePath(
                (baseRoute) => baseRoute.routes!.newComplianceTemplate.path
            )
        );

    usePageActions(
        hasWritePermissions && (
            <ActionButtonsPanel>
                <TooltipButton
                    id="new-compliance-template"
                    onClick={goToNewComplianceTemplate}
                    disabled={!controlMeasuresLength}
                    tooltipContent="Please add at least one Control Measure to be applied across all projects.">
                    New Compliance Template
                </TooltipButton>
            </ActionButtonsPanel>
        ),
        [controlMeasuresLength, itemTypeCode]
    );
    return <> </>;
};
