import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { isGuid } from 'helpers/guidHelper';
import { getQueryResult } from './graphQlClient';
import { Page, Person } from './models';

const query = gql`
    query getPeopleByIds($ids: [ID!]!) {
        peopleByIds(ids: $ids) {
            data {
                id
                displayName
                photo
            }
        }
    }
`;

export const usePeople = (ids?: string[]) => {
    ids = ids?.filter((id) => isGuid(id));
    return useQuery(
        [ids, ids],
        async () => {
            const result = await getQueryResult<{
                peopleByIds: Page<Person>;
            }>(query, {
                ids,
            });
            return result.peopleByIds.data;
        },
        {
            enabled: !!ids?.length,
        }
    );
};
