import { ColumnDefinition, Skeleton, Table } from '@laingorourke/core-web-components';
import { format } from 'date-fns';
import { MediaResponse } from 'domain/models/api-models';
import {
    fetchAllBuiltViewTeamsByIds,
    fetchBuiltViewMediaByItem,
    useBuiltViewMediaByItem,
    useBuiltViewTeamsByIds,
} from 'domain/store/reducers/builtViewIntegration';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { fetchProjectItemDetails, useItemDetails } from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import {
    getDefaultPaginationOptions,
    getDefaultSortedByColummKey,
    SecuredContent,
} from 'views/components';
import Image from 'views/components/image/Image';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { ProjectItemDetailsPage } from '../ProjectItemDetailsPage';
import { ProjectItemDetailsParams } from '../ProjectItemDetailsParams';

export const ItemBuiltViewMedia: React.FC = () => {
    const dispatch = useAppDispatch();
    const { projectItemId, projectNumber } = useParams<ProjectItemDetailsParams>();
    const itemDetails = useItemDetails();
    const builtViewMedia = useBuiltViewMediaByItem();
    const teamIds = builtViewMedia?.map((m) => m.teamId) ?? [];
    const itemTypeId = useSelectedItemTypeId();
    const teamNames = useBuiltViewTeamsByIds(teamIds);
    const itemsDetailsLoaded = !!itemDetails;
    const builtViewMediaLoaded = !!builtViewMedia;

    useEffect(() => {
        dispatch(fetchProjectItemDetails({ projectItemId, projectNumber }));
    }, [projectNumber, projectItemId]);

    useEffect(() => {
        if (itemsDetailsLoaded) {
            dispatch(
                fetchBuiltViewMediaByItem({
                    projectNumber,
                    itemTypeId,
                    itemNumber: itemDetails.number!,
                })
            );
        }
    }, [itemsDetailsLoaded, projectNumber, itemTypeId]);

    useEffect(() => {
        dispatch(fetchAllBuiltViewTeamsByIds({ projectNumber, itemTypeId, ids: teamIds }));
    }, [builtViewMediaLoaded]);

    const dataLoaded = itemsDetailsLoaded && builtViewMediaLoaded;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Media found');

    const imageFormatter = (row: MediaResponse) => (
        <Image src={row.thumbnail} alt={row.id} width={60} />
    );

    const columns: ColumnDefinition<MediaResponse>[] = [
        {
            text: 'Thumbnail',
            field: (result) => imageFormatter(result),
            size: 25,
        },
        {
            text: 'Team',
            sortable: true,
            field: (result) => (
                <>
                    {!!teamNames ? (
                        teamNames?.find((t) => t.id === result.teamId)?.title
                    ) : (
                        <Skeleton.Box height={21} width={300} />
                    )}
                </>
            ),
            customSort: (a, b, order) => {
                const rowATeamName = teamNames?.find((t) => t.id === a.teamId)?.title;
                const rowBTeamName = teamNames?.find((t) => t.id === b.teamId)?.title;
                const sortOrder = !!rowATeamName
                    ? !!rowBTeamName
                        ? rowATeamName?.localeCompare(rowBTeamName)
                        : 1
                    : -1;
                if (order === 'asc') {
                    return sortOrder;
                }
                return sortOrder * -1;
            },
            size: 40,
        },
        {
            text: 'Date taken',
            key: nameof<MediaResponse>('timestamp'),
            sortable: true,
            field: (result) => format(new Date(result.timestamp), 'dd MMM yyyy hh:mm:ss a'),
            size: 35,
            sortField: (result) => new Date(result.timestamp),
        },
    ];

    return (
        <ProjectItemDetailsPage title={itemDetails?.name}>
            <SecuredContent permissions={Permission.ManageItemsRead}>
                <Table
                    {...getDefaultPaginationOptions()}
                    {...getDefaultSortedByColummKey(columns, nameof<MediaResponse>('timestamp'))}
                    {...tableLoadingProps}
                    data={dataLoaded ? builtViewMedia : []}
                    clickable={true}
                    onRowClick={(data) => window.open(data.builtViewLink, '_blank')}
                    keySelector={(data) => data.thumbnail}
                    cacheKey="itemBuiltViewMedia"
                    columns={columns}
                />
            </SecuredContent>
        </ProjectItemDetailsPage>
    );
};
