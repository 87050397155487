import { generatePath } from '@laingorourke/core-web-components';
import { Designer } from 'domain/models/api-models';
import { useDesigner } from 'domain/store/reducers/designers';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { AdminRouteParams } from '../../AdminRouteParams';
import { UpsertForm } from './UpsertForm';

interface DesignerParams extends AdminRouteParams {
    designerId: string;
}

export const UpsertDesigner: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { designerId, itemTypeCode } = useParams<DesignerParams>();

    const designer: Designer | undefined = useDesigner(designerId);

    const isEditMode = designer !== undefined;

    const goToDesigners = () =>
        history.push(generatePath(routes.admin.routes!.designers.path, { itemTypeCode }));

    return (
        <>
            {(designerId === undefined || designer !== undefined) && (
                <Modal show={true} onHide={goToDesigners} animation={false} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditMode ? 'Edit' : 'New'} Designer</Modal.Title>
                    </Modal.Header>
                    <SecuredContent
                        permissions={Permission.ManageDesignerSettingsWrite}
                        ignoreSecurable={true}>
                        <UpsertForm
                            designer={designer}
                            isEditMode={isEditMode}
                            goToDesigners={goToDesigners}
                        />
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
