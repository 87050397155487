import { createAsyncThunk } from '@reduxjs/toolkit';
import { ComplianceTemplate } from 'domain/models/api-models';
import { get, patch, post, remove } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

interface ComplianceTemplateParams {
    projectNumber: string | null | undefined;
    itemTypeId: string;
}

const getBaseRoute = (params: ComplianceTemplateParams): string => {
    const projectsSegment = !!params.projectNumber ? `projects/${params.projectNumber}/` : '';
    return `itemtypes/${params.itemTypeId}/${projectsSegment}complianceTemplates`;
};

export const fetchComplianceTemplates = createAsyncThunk<
    ComplianceTemplate[],
    ComplianceTemplateParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/complianceTemplates/get',
    async (args) => (await get(getBaseRoute(args)))!,
    {
        condition: (args) => !!args.itemTypeId,
    }
);

export const fetchControlMeasureSummaries = createAsyncThunk<
    ComplianceTemplate[],
    void,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/controlMeasures/get/summary',
    async (_, { getState }) => {
        const { itemTypes } = getState();
        const itemTypeId = itemTypes.selectedItemType.id;
        return (await get(`itemtypes/${itemTypeId}/controlMeasures/summary`))!;
    },
    {
        condition: (_, { getState }) => getState().itemTypes.selectedItemType.id !== '',
    }
);

export const fetchInspectionTypesSummaries = createAsyncThunk<
    ComplianceTemplate[],
    void,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/inspectionTypes/get/summary',
    async (_, { getState }) => {
        const { itemTypes } = getState();
        const itemTypeId = itemTypes.selectedItemType.id;
        return (await get(`itemtypes/${itemTypeId}/inspectionTypes/summary`))!;
    },
    {
        condition: (_, { getState }) => getState().itemTypes.selectedItemType.id !== '',
    }
);

interface InspectionFrequency {
    inspectionTypeId: string;
    frequency: number;
}

interface CreateComplianceTemplate extends ComplianceTemplateParams {
    categoryId?: string | null;
    classificationId: string;
    example: string;
    inspections: InspectionFrequency[];
    controlMeasuresIds: string[];
}

export const createComplianceTemplate = createAsyncThunk<
    ComplianceTemplate,
    CreateComplianceTemplate,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/complianceTemplates/post',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const createdComplianceTemplate = await post(getBaseRoute(args), args);
            dispatch(
                addNotification({
                    message: 'Compliance Template has been created successfully',
                    isSuccess: true,
                })
            );
            return createdComplianceTemplate;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId,
    }
);

interface UpdateComplianceTemplate extends ComplianceTemplateParams {
    id: string;
    example: string;
    inspections: InspectionFrequency[];
    controlMeasuresIds: string[];
}

export const updateComplianceTemplate = createAsyncThunk<
    ComplianceTemplate,
    UpdateComplianceTemplate,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/complianceTemplates/patch',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const updatedComplianceTemplate = await patch(`${getBaseRoute(args)}/${args.id}`, args);

            dispatch(
                addNotification({
                    message: 'Compliance Template has been updated successfully',
                    isSuccess: true,
                })
            );

            return updatedComplianceTemplate;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.id,
    }
);

interface DeleteComplianceTemplate extends ComplianceTemplateParams {
    id: string;
}

export const deleteComplianceTemplate = createAsyncThunk<
    string,
    DeleteComplianceTemplate,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/complianceTemplates/delete',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await remove(`${getBaseRoute(args)}/${args.id}`);
            dispatch(
                addNotification({
                    message: 'Compliance Template has been deleted successfully',
                    isSuccess: true,
                })
            );
            return args.id;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.id,
    }
);
