import { Table } from '@laingorourke/core-web-components';
import { clearDesigners, fetchDesigners, useDesigners } from 'domain/store/reducers/designers';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { hasPermission, useCurrentUser } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { PropsWithChildren, useEffect } from 'react';
import { Permission } from 'security/Permission';
import { SecuredContent, getProjectDisplayName } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { DesignerTableRow } from './DesignerTableRow';
import { NewDesignerButton } from './components/NewDesignerButton';
import { getTableColumns } from './tableColumns';

export const Designers: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ManageDesignerSettingsRead} ignoreSecurable={false}>
        <DesignersContent>{children}</DesignersContent>
    </SecuredContent>
);

const DesignersContent: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const currentUser = useCurrentUser();

    const designers = useDesigners();
    const itemTypeId = useSelectedItemTypeId();

    const dataLoaded = !!designers;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Designers found');
    const designersTableData: DesignerTableRow[] | undefined = designers?.map((d) => ({
        ...d,
        projectName: getProjectDisplayName(undefined, []),
        projectNumber: undefined,
        canEdit: hasPermission(currentUser, Permission.ManageDesignerSettingsWrite, undefined),
        canDelete: hasPermission(currentUser, Permission.ManageDesignerSettingsDelete, undefined),
    }));

    const columns = getTableColumns(
        !!designersTableData?.find((c) => c.canEdit || c.canDelete),
        itemTypeId
    );

    useEffect(() => {
        dispatch(fetchDesigners({ itemTypeId }));
        return () => {
            dispatch(clearDesigners());
        };
    }, [itemTypeId, dispatch]);

    return (
        <>
            <NewDesignerButton disabled={!dataLoaded} />
            {children}
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(columns)}
                {...tableLoadingProps}
                columns={columns}
                data={dataLoaded ? designersTableData! : []}
                keySelector={(d) => d.id}
                saveSelectedPageSize
                cacheKey="designers"
                hover
            />
        </>
    );
};
