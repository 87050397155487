import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TooltipWrapper } from '../tooltipWrapper';
import styles from './MissingIndicator.module.scss';

export const MissingIndicator: React.FC<{ id: string; message: string | React.ReactNode }> = ({
    id,
    message,
}) => {
    return (
        <TooltipWrapper id={id} placement="top" tooltipContent={message}>
            <FontAwesomeIcon icon={faCircleExclamation} size="lg" className={styles.icon} />
        </TooltipWrapper>
    );
};
