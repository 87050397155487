import { fetchAreaOwnersIds, useAreaOwnersIds } from 'domain/store/reducers/users';
import { AppDispatch } from 'domain/store/rootStore';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useAreaOwners = (projectNumber: string, currentOwnerId?: string) => {
    const dispatch = useDispatch<AppDispatch>();
    let ownersIds = useAreaOwnersIds();
    if (currentOwnerId) {
        ownersIds = ownersIds.concat([currentOwnerId]);
    }

    useEffect(() => {
        dispatch(fetchAreaOwnersIds({ projectNumber }));
    }, [projectNumber]);

    return ownersIds;
};
