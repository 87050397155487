import { Button, Page, Skeleton } from '@laingorourke/core-web-components';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useQrCodeExample } from 'domain/store/reducers/projects';
import { fetchQrCodeExample } from 'domain/store/reducers/projects/actions/qrCode';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ActionButtonsPanel, SecuredContent, usePageActions } from 'views/components';
import { routes } from 'views/routes/Routes';
import { ProjectParams } from '../../Project';
import styles from './QrCode.module.scss';
import { QrCodeImage } from './QrCodeImage';

export const QrCode: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ProjectQrCodeRead} ignoreSecurable={false}>
        <QrCodeContent>{children}</QrCodeContent>
    </SecuredContent>
);

export const QrCodeContent: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();

    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();
    const history = useHistory();
    const exampleQrCode = useQrCodeExample();
    const itemTypeId = useSelectedItemTypeId();
    const [isLoading, setIsLoading] = useState(false);

    const currentUserHasProjectQrCodeWritePermission = useHasPermissions(
        Permission.ProjectQrCodeWrite,
        projectNumber
    );

    const currentUserHasProjectQrCodeDeletePermission = useHasPermissions(
        Permission.ProjectQrCodeDelete,
        projectNumber
    );

    const qrCodeParams = { projectNumber, itemTypeCode };
    const qrCodeRoutes =
        routes.projects.routes!.project.routes!.projectSetup.routes!.qrCode.routes!;

    const goToUploadLogo = () =>
        history.push(generatePath(qrCodeRoutes.uploadLogo.path, { ...qrCodeParams }));

    const goToDeleteLogo = () =>
        history.push(generatePath(qrCodeRoutes.deleteLogo.path, { ...qrCodeParams }));

    useEffect(() => {
        if (exampleQrCode === undefined && !isLoading && !!itemTypeId) {
            setIsLoading(true);
            dispatch(fetchQrCodeExample({ projectNumber, itemTypeId }));
        }
    }, [projectNumber, itemTypeId, exampleQrCode === undefined]);

    useEffect(() => {
        if (!!exampleQrCode) setIsLoading(false);
    }, [exampleQrCode]);

    usePageActions(
        <ActionButtonsPanel>
            {currentUserHasProjectQrCodeDeletePermission &&
                exampleQrCode !== undefined &&
                !exampleQrCode.hasDefaultLogo && (
                    <Button onClick={goToDeleteLogo}>Delete Logo</Button>
                )}
            {currentUserHasProjectQrCodeWritePermission && (
                <Button variant="primary" onClick={goToUploadLogo} className={styles.uploadBtn}>
                    Upload Logo
                </Button>
            )}
        </ActionButtonsPanel>,
        [exampleQrCode?.hasDefaultLogo]
    );

    return (
        <Page.Section>
            {children}
            <div className={styles.container}>
                <h3>Preview of Item QR Code</h3>
                {!!exampleQrCode && (
                    <QrCodeImage
                        base64Image={exampleQrCode?.base64Image}
                        className={styles.qrCode}
                    />
                )}
                {!exampleQrCode && <Skeleton.Box width={400} height={400} />}
            </div>
        </Page.Section>
    );
};
