import React, { PropsWithChildren } from 'react';
import styles from './SelectedItems.module.scss';
import { useTableSelectedRowsSummary } from './useTableSelectedRowsSummary';

interface SelectedItem {
    id: string;
}

interface SelectedItemsProps<TData extends SelectedItem> {
    entities: TData[];
    selectedEntityName: string;
    entityRenderer: (entity: TData) => React.ReactNode;
}

export const SelectedItems = <TData extends SelectedItem>(props: SelectedItemsProps<TData>) => {
    const { firstItems, lastItems } = useTableSelectedRowsSummary(props.entities.map((e) => e.id));

    const ids = props.entities.map((e) => e.id);
    return (
        <>
            <p>
                The following <span className={styles.number}>{ids.length}</span>{' '}
                {props.selectedEntityName}
                {ids.length > 1 && 's'} will be updated:
            </p>
            <ul className={styles.list}>
                {firstItems.map((id) => (
                    <SelectedItem
                        key={id}
                        id={id}
                        entities={props.entities}
                        entityRenderer={props.entityRenderer}
                    />
                ))}
                {!!lastItems.length && <li>...</li>}
                {lastItems.map((id) => (
                    <SelectedItem
                        key={id}
                        id={id}
                        entities={props.entities}
                        entityRenderer={props.entityRenderer}
                    />
                ))}
            </ul>
        </>
    );
};

interface SelectedItemProps<TData> extends PropsWithChildren {
    id: string;
    entities: TData[];
    entityRenderer: (entity: TData) => React.ReactNode;
}

export const SelectedItem = <TData extends SelectedItem>(props: SelectedItemProps<TData>) => (
    <li>{props.entityRenderer(props.entities.find((i) => i.id === props.id)!)}</li>
);
