import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WeatherEvent, WeatherEventItem, WeatherInspectionType } from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import {
    createWeatherInspectionType,
    deleteWeatherInspectionType,
    fetchWeatherEventItems,
    fetchWeatherEvents,
    fetchWeatherInspectionTypes,
    updateWeatherInspectionType,
} from './actions';

const initialState = {
    weatherInspectionTypes: undefined as unknown as WeatherInspectionType[],
    weatherEvents: undefined as unknown as WeatherEvent[],
    weatherEventItems: undefined as unknown as WeatherEventItem[],
};

const weatherSlice = createSlice({
    name: 'weather',
    initialState: initialState,
    reducers: {
        clearWeatherInspectionTypes() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchWeatherInspectionTypes.fulfilled.type,
            (state, action: PayloadAction<WeatherInspectionType[]>) => {
                state.weatherInspectionTypes = action.payload;
            }
        );
        builder.addCase(
            fetchWeatherEvents.fulfilled.type,
            (state, action: PayloadAction<WeatherEvent[]>) => {
                state.weatherEvents = action.payload;
            }
        );
        builder.addCase(
            fetchWeatherEventItems.fulfilled.type,
            (state, action: PayloadAction<WeatherEventItem[]>) => {
                state.weatherEventItems = action.payload;
            }
        );
        builder.addCase(
            createWeatherInspectionType.fulfilled.type,
            (state, action: PayloadAction<WeatherInspectionType>) => {
                if (action.payload === undefined) return;
                state.weatherInspectionTypes.push(action.payload);
            }
        );
        builder.addCase(
            updateWeatherInspectionType.fulfilled.type,
            (state, action: PayloadAction<WeatherInspectionType>) => {
                if (action.payload === undefined) return;
                state.weatherInspectionTypes = state.weatherInspectionTypes.map((w) =>
                    w.id === action.payload.id ? action.payload : w
                );
            }
        );
        builder.addCase(
            deleteWeatherInspectionType.fulfilled.type,
            (state, action: PayloadAction<string>) => {
                if (action.payload === undefined) return;
                state.weatherInspectionTypes = state.weatherInspectionTypes.map((w) =>
                    w.id === action.payload ? { ...w, isActive: false } : w
                );
            }
        );
    },
});
export const useWeatherInspectionTypes = () =>
    useSelector((state: RootState) => state.weather.weatherInspectionTypes);

export const useWeatherInspectionType = (id: string) =>
    useSelector((state: RootState) =>
        state.weather.weatherInspectionTypes?.find((w) => w.id === id)
    );

export const useWeatherEvents = () =>
    useSelector((state: RootState) => state.weather.weatherEvents);

export const useWeatherEventItems = () =>
    useSelector((state: RootState) => state.weather.weatherEventItems);

export const { clearWeatherInspectionTypes } = weatherSlice.actions;
export default weatherSlice.reducer;
