import { Avatar, Skeleton } from '@laingorourke/core-web-components';
import { usePerson } from 'domain/services/dataService';
import { defaultStringWhenEmtpy, detailFieldHeight } from '../readOnlyDetailsList';
import { UserNotFoundLabel } from '../table';
import styles from './PersonDisplayName.module.scss';

export const PersonDisplayName: React.FC<{ personId?: string }> = ({ personId }) => {
    const person = usePerson(personId);

    return !personId ? (
        <>{defaultStringWhenEmtpy}</>
    ) : (
        <Skeleton.Box height={detailFieldHeight} loading={person.isLoading}>
            <span>
                {!person.data ? (
                    <i>{UserNotFoundLabel}</i>
                ) : (
                    <>
                        <Avatar
                            className={styles.photo}
                            displayName={person.data?.displayName}
                            src={person.data?.photo}
                        />
                        {person.data?.displayName}
                    </>
                )}
            </span>
        </Skeleton.Box>
    );
};
