import { Table } from '@laingorourke/core-web-components';
import {
    fetchProjectClassifications,
    useProjectClassifications,
} from 'domain/store/reducers/classifications';
import { useItemStatuses, useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { fetchProjectLeadDays, useProjectLeadDays } from 'domain/store/reducers/projectLeadDays';
import { useProjects } from 'domain/store/reducers/projects/reducer';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import React, { PropsWithChildren, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent, getProjectDisplayName } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { ProjectParams } from '../../Project';
import { LeadDayTableRow } from './LeadDayTableRow';
import { getTableColumns } from './tableColumns';

export const LeadDays: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ProjectLeadDaysRead}>
        <LeadDaysContent>{children}</LeadDaysContent>
    </SecuredContent>
);

const LeadDaysContent: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const classifications = useProjectClassifications();
    const { projectNumber } = useParams<ProjectParams>();
    const currentUserHasManageLeadDaysWritePermission = useHasPermissions(
        Permission.ProjectLeadDaysWrite,
        projectNumber
    );

    const itemStatuses = useItemStatuses();
    const projects = useProjects();
    const itemTypeId = useSelectedItemTypeId();
    const leadDays = useProjectLeadDays();
    const leadDaysData = leadDays?.map((l) => ({
        ...l,
        projectName: getProjectDisplayName(l.projectNumber, projects),
        status: itemStatuses?.find((s) => l.itemStatusWhenControlMeasureCompleted === s.id)?.name,
    }));
    const dataLoaded = !!leadDays && !!itemStatuses && !!classifications;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Lead Days found');
    const columns = getTableColumns(
        currentUserHasManageLeadDaysWritePermission,
        classifications,
        leadDaysData,
        itemStatuses
    );

    useEffect(() => {
        dispatch(fetchProjectLeadDays({ projectNumber, itemTypeId }));
        dispatch(fetchProjectClassifications({ itemTypeId, projectNumber }));
    }, [projectNumber, itemTypeId, dispatch]);

    return (
        <>
            {children}
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(columns, nameof<LeadDayTableRow>('status'))}
                {...tableLoadingProps}
                // this is far from ideal but this is really the only way to "enfore re-sort" once all categories are loaded
                // this is obviously not enforcing sort, but rerendering an entire component
                // Something like (table.current as any).sortContext.handleSort(columns[0]); cannot be used
                // as this just simulates "click" and doesn't consider sort order (asc / desc) and might lead to bugs
                // bootstrap react table doesn't support it in any other way
                key={itemStatuses?.length ?? 'none'}
                data={dataLoaded ? leadDaysData! : []}
                keySelector={(r) => r.controlMeasureId}
                columns={columns}
                saveSelectedPageSize
                cacheKey="lead-days"
                hover
            />
        </>
    );
};
