import { ThunkDispatch } from '@reduxjs/toolkit';
import { LogService } from 'domain/services/logService';
import { addNotification } from '../reducers/notificationsReducer';
import { RootState } from '../rootStore';

export const logGlobalError = (error: Error) => {
    const logService = new LogService();
    logService.logError(
        `An error occurred. name: '${error.name}' message: '${error.message}'`,
        error.stack
    );
};

export const runSafe = async (action: any, dispatch: ThunkDispatch<RootState, unknown, any>) => {
    try {
        return await action();
    } catch (e: any) {
        dispatch(
            addNotification({
                message: e.message,
                isSuccess: false,
            })
        );
    }
};
