import { fetchIntegrations } from 'domain/store/reducers/integrations/actions';
import { useIntegrations } from 'domain/store/reducers/integrations/selectors';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType/selectors';
import { useAppDispatch } from 'domain/store/rootStore';
import { PropsWithChildren, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components/securedContent';
import { SharedRouteParams } from '../SharedRouteParams';
import { IntegrationTable } from './components/integrationTable/IntegrationTable';
import { NewIntegrationButton } from './components/NewIntegrationButton';
import { ProjectApplicationIntegrationsTable } from './components/projectApplicationIntegration/ProjectApplicationIntegrationTables';

export const Integrations: React.FC<PropsWithChildren> = ({ children }) => {
    const { projectNumber } = useParams<SharedRouteParams>();
    const isProjectMode = !!projectNumber;

    return (
        <SecuredContent
            permissions={Permission.ManageIntegrationSettingsRead}
            projectNumber={projectNumber}
            ignoreSecurable={!isProjectMode}>
            <IntegrationsContent>{children}</IntegrationsContent>
        </SecuredContent>
    );
};

export const IntegrationsContent: React.FC<PropsWithChildren> = ({ children }) => {
    const { projectNumber } = useParams<SharedRouteParams>();
    const isProjectMode = !!projectNumber;
    const dispatch = useAppDispatch();
    const integrations = useIntegrations();
    const itemTypeId = useSelectedItemTypeId();

    useEffect(() => {
        dispatch(
            fetchIntegrations({
                itemTypeId,
                projectNumber,
                includeProjectApplicationIntegrations: !isProjectMode,
            })
        );
    }, [itemTypeId, dispatch]);

    return (
        <>
            <NewIntegrationButton />
            {children}
            <IntegrationTable integrations={integrations} />
            {!isProjectMode && (
                <ProjectApplicationIntegrationsTable
                    projectApplicationIntegrationList={
                        integrations?.projectApplicationIntegrationList
                    }
                />
            )}
        </>
    );
};
