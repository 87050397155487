import {
    Button,
    ButtonProps,
    youAreOfflineFeatureNotAvailableMessage,
} from '@laingorourke/core-web-components';
import { useIsOffline } from 'hooks/useIsOffline';
import React, { PropsWithChildren } from 'react';
import { OverlayDelay } from 'react-bootstrap/esm/OverlayTrigger';
import { Placement } from 'react-bootstrap/esm/types';
import { TooltipWrapper } from '..';

interface TooltipButtonProps extends ButtonProps {
    tooltipContent?: string | React.ReactNode;
    id: string;
    placement?: Placement;
    delay?: OverlayDelay;
}

export const TooltipButton: React.FC<PropsWithChildren<TooltipButtonProps>> = ({
    tooltipContent,
    id,
    style,
    disabled,
    children,
    placement,
    delay,
    variant,
    ...props
}) => {
    const isOffline = useIsOffline();

    return (
        <TooltipWrapper
            id={`${id}-tooltip`}
            tooltipContent={isOffline ? youAreOfflineFeatureNotAvailableMessage : tooltipContent}
            show={isOffline || disabled}
            placement={placement}
            delay={delay}>
            <Button
                id={`${id}-btn`}
                {...props}
                variant={variant || 'primary'}
                disabled={isOffline || disabled}
                style={isOffline || disabled ? { ...style, pointerEvents: 'none' } : style}>
                {children}
            </Button>
        </TooltipWrapper>
    );
};

TooltipButton.defaultProps = {
    disabled: false,
};
