import { Switch } from '@laingorourke/core-web-components';
import { ProjectDesigner } from 'domain/models/api-models';
import { disableDesigner, enableDesigner } from 'domain/store/reducers/designers';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ProjectParams } from 'views/routes/project/Project';
import styles from './DisabledDesignerSwitch.module.scss';

const DisabledDesignerSwitch: React.FC<{ designer: ProjectDesigner }> = ({
    designer: designer,
}) => {
    const { projectNumber } = useParams<ProjectParams>();
    const itemTypeId = useSelectedItemTypeId();
    const dispatch = useAppDispatch();
    const currentUserHasProjectDesignerWritePermission = useHasPermissions(
        Permission.ProjectDesignerWrite,
        projectNumber
    );

    const disabledDesignerActionParams = {
        projectNumber: projectNumber,
        itemTypeId: itemTypeId,
        designerId: designer.id,
        designerName: designer.name!,
    };
    return (
        <Switch
            onChange={() =>
                dispatch(
                    designer.isDisabled
                        ? enableDesigner(disabledDesignerActionParams)
                        : disableDesigner(disabledDesignerActionParams)
                )
            }
            value={!designer.isDisabled}
            className={styles.switch}
            disabled={!currentUserHasProjectDesignerWritePermission}
        />
    );
};

export default DisabledDesignerSwitch;
