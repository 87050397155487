import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';

export const useItemTypes = () => useSelector((state: RootState) => state.itemTypes.itemTypes);
export const useSelectedItemTypeId = () =>
    useSelector((state: RootState) => state.itemTypes.selectedItemType.id);
export const useSelectedItemTypeOwnerLabel = () =>
    useSelector((state: RootState) => state.itemTypes.selectedItemType.ownerLabel);
export const useSelectedItemTypeMyTasksWorkflowId = () =>
    useSelector((state: RootState) => state.itemTypes.selectedItemType.workflowId);
export const useSelectedItemTypeName = () =>
    useSelector(
        (state: RootState) =>
            state.itemTypes.itemTypes.find((i) => i.id === state.itemTypes.selectedItemType.id)!
                .name
    );
export const useItemTypeId = (itemTypeCode: string) =>
    useSelector(
        (state: RootState) => state.itemTypes.itemTypes.find((i) => i.code === itemTypeCode)?.id
    );
export const useSelectedItemTypeCode = () =>
    useSelector((state: RootState) => state.itemTypes.selectedItemType.code ?? '');
export const useSelectedItemTypeCodeIsSet = () =>
    useSelector((state: RootState) => !!state.itemTypes.selectedItemType.code);
export const useItemTypeMetadata = () =>
    useSelector((state: RootState) => state.itemTypes.selectedItemType.metadata);
export const useItemTypeCategories = () =>
    useSelector((state: RootState) => state.itemTypes.selectedItemType.metadata?.categories);
export const useDesigners = () =>
    useSelector((state: RootState) => state.itemTypes.selectedItemType.designers);
export const useItemStatuses = () =>
    useSelector(
        (state: RootState) => state.itemTypes.selectedItemType.projectStatuses?.itemStatuses
    );
