import { Drawer, useDevice } from '@laingorourke/core-web-components';
import { Area } from 'domain/models/api-models';
import { Applications } from 'domain/models/ApplicationProject';
import { useApplicationProjectById } from 'domain/services/dataService/useApplicationProjectById';
import { useArea } from 'domain/store/reducers/areas';
import { useAreaCodeInItemNumber } from 'domain/store/reducers/projectItemNumberConfiguration';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { MissingIndicator, ReadOnlyDetailsList } from 'views/components';
import { PersonDisplayName } from 'views/components/personDisplayName';
import { routes } from 'views/routes/Routes';
import { AreaRouteParams } from '../AreaRouteParams';
import { missingItemNumberCommencementMessage, missingItemNumberPrefixMessage } from '../messages';

const notSet = '-';

export const Details: React.FC = () => {
    const params = useParams<AreaRouteParams>();
    const area: Area | undefined = useArea(params.areaId);
    const isMobile = useDevice().isMobile;
    const history = useHistory();
    const areaCodeInItemNumber = useAreaCodeInItemNumber();

    const asiteProject = useApplicationProjectById(area?.asiteWorkspaceId, Applications.Asite);

    const fieldViewproject = useApplicationProjectById(
        area?.fieldViewProjectId,
        Applications.FieldView
    );

    const goToAreas = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.areas.path, { ...params })
        );

    const dataLoaded =
        !!area && areaCodeInItemNumber !== undefined && !!asiteProject && !!fieldViewproject;

    return (
        <>
            {dataLoaded && (
                <Drawer
                    push={true}
                    closeOnClickAway={false}
                    open={true}
                    onChange={() => goToAreas()}
                    title="Area"
                    width={isMobile ? '100vw' : '20vw'}>
                    <ReadOnlyDetailsList
                        items={[
                            { label: 'Area', value: area.name },
                            ...(!!area.subAreaName
                                ? [{ label: 'Sub Area', value: area.subAreaName }]
                                : []),
                            {
                                label: 'Appointed Person',
                                value: <PersonDisplayName personId={area?.ownerId} />,
                            },
                            {
                                label: 'Description',
                                value: !!area.description ? area.description : notSet,
                            },
                            {
                                label: 'Field View Project',
                                value: !!area?.fieldViewProjectId
                                    ? fieldViewproject.data?.applicationProjectName
                                    : notSet,
                            },
                            {
                                label: 'Asite Workspace',
                                value: !!area?.asiteWorkspaceId
                                    ? asiteProject.data?.applicationProjectName
                                    : notSet,
                            },
                            ...(areaCodeInItemNumber
                                ? [
                                      {
                                          label: (
                                              <>
                                                  Item Number Prefix
                                                  {!area.itemNumberPrefix && (
                                                      <MissingIndicator
                                                          id={`${area.id}-missing-prefix`}
                                                          message={missingItemNumberPrefixMessage}
                                                      />
                                                  )}
                                              </>
                                          ),
                                          value: !!area.itemNumberPrefix
                                              ? area.itemNumberPrefix
                                              : notSet,
                                      },
                                      {
                                          label: (
                                              <>
                                                  Item Number Commencement
                                                  {!area.itemNumberPrefix && (
                                                      <MissingIndicator
                                                          id={`${area.id}-missing-commencement`}
                                                          message={
                                                              missingItemNumberCommencementMessage
                                                          }
                                                      />
                                                  )}
                                              </>
                                          ),
                                          value: area.itemNumberCommencement ?? notSet,
                                      },
                                  ]
                                : []),
                        ]}
                    />
                </Drawer>
            )}
        </>
    );
};
