import { Table } from '@laingorourke/core-web-components';
import {
    clearProjectClassifications,
    fetchProjectClassifications,
    useProjectClassifications,
} from 'domain/store/reducers/classifications';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useProjects } from 'domain/store/reducers/projects/reducer';
import { hasPermission, useCurrentUser, useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import React, { PropsWithChildren, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { ProjectParams } from '../../Project';
import { ClassificationTableRow } from './ClassificationTableRow';
import { NewClassificationButton } from './components/NewClassificationButton';
import { useTableColumns } from './tableColumns';

const classificationsCountLimit = 12;

export const Classifications: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ProjectClassificationsRead} ignoreSecurable={false}>
        <ClassificationsContent>{children}</ClassificationsContent>
    </SecuredContent>
);

const ClassificationsContent: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const currentUser = useCurrentUser();

    const { projectNumber } = useParams<ProjectParams>();
    const itemTypeId = useSelectedItemTypeId();
    const projects = useProjects();
    const classifications = useProjectClassifications(false);
    const currentUserHasProjectClassificationsWritePermission = useHasPermissions(
        Permission.ProjectClassificationsWrite,
        projectNumber
    );
    const dataLoaded = !!classifications;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Classifications found');
    const classificationsTableData: ClassificationTableRow[] | undefined = classifications?.map(
        (c) => ({
            ...c,
            canEdit: hasPermission(
                currentUser,
                Permission.ProjectClassificationsWrite,
                c.projectNumber
            ),
            canDelete: hasPermission(
                currentUser,
                Permission.ProjectClassificationsDelete,
                c.projectNumber
            ),
        })
    );

    const columns = useTableColumns(
        !!classificationsTableData?.find((c) => c.canEdit || c.canDelete),
        itemTypeId,
        projectNumber
    );

    useEffect(() => {
        dispatch(fetchProjectClassifications({ itemTypeId, projectNumber }));
        return () => {
            dispatch(clearProjectClassifications());
        };
    }, [itemTypeId, dispatch]);

    const classificationsLimitReached =
        dataLoaded &&
        classifications?.filter((c) => c.isActive)?.length >= classificationsCountLimit;
    const disabledMessage = classificationsLimitReached
        ? 'This project has reached the maximum amount of Classifications. You can create a new classification if an active classification is deleted.'
        : !dataLoaded
            ? 'Loading...'
            : '';

    return (
        <>
            {currentUserHasProjectClassificationsWritePermission && (
                <NewClassificationButton
                    disabled={!dataLoaded || classificationsLimitReached}
                    disabledMessage={disabledMessage}
                />
            )}
            {children}
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(columns, nameof<ClassificationTableRow>('hub'))}
                {...tableLoadingProps}
                data={dataLoaded ? classificationsTableData! : []}
                keySelector={(d) => d.id}
                columns={columns}
                saveSelectedPageSize
                cacheKey="classifications"
                hover
            />
        </>
    );
};
