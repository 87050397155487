import { Form, generatePath, Upload, useForm } from '@laingorourke/core-web-components';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { fetchQrCodeExample, uploadLogo } from 'domain/store/reducers/projects/actions/qrCode';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ModalFooter, SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { ProjectParams } from '../../Project';
import { QrCodeImage } from './QrCodeImage';
import { UploadLogoSchema } from './schemas';
import styles from './UploadLogo.module.scss';

export const UploadLogo: React.FC = () => {
    const dispatch = useAppDispatch();

    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();
    const history = useHistory();
    const itemTypeId = useSelectedItemTypeId();
    const [exampleQrCode, setExampleQrCode] = useState<string>();
    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>();

    const form = useForm({
        initialValues: {
            documents: [] as Upload[],
        },
        validationSchema: UploadLogoSchema,
        onSubmit: async (values) => {
            const result = await dispatch(
                uploadLogo({
                    projectNumber: projectNumber,
                    file: values.documents[0].file!,
                    itemTypeId: itemTypeId,
                })
            );
            if ((result as any).payload) {
                goToQrCode();
            }
        },
    });

    const { isSubmitting, values } = form;

    const goToQrCode = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.projectSetup.routes!.qrCode.path, {
                projectNumber,
                itemTypeCode,
            })
        );

    return (
        <Modal show={true} onHide={goToQrCode} animation={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Upload Logo</Modal.Title>
            </Modal.Header>
            <SecuredContent permissions={Permission.ProjectQrCodeWrite}>
                <Form form={form}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.List name="documents">
                                {(helpers) => (
                                    <Upload
                                        accept=".png,.jpg,.jpeg,.svg"
                                        onAdd={async (upload) => {
                                            helpers.remove(0);
                                            upload.file = new File([upload.file!], upload.name);
                                            helpers.push(upload);
                                            setIsLoadingPreview(true);
                                            const result = await dispatch(
                                                fetchQrCodeExample({
                                                    projectNumber: projectNumber,
                                                    itemTypeId: itemTypeId,
                                                    file: upload.file!,
                                                })
                                            );
                                            const example = (result as any).payload.qrCodeExample
                                                .base64Image as string;
                                            setExampleQrCode(example);
                                            setIsLoadingPreview(false);
                                        }}
                                        value={values.documents}
                                        multiple={false}
                                        itemRender={(upload, _, index) => (
                                            <Upload.Item
                                                className={styles.uploadItem}
                                                id={upload.id}
                                                isInvalid={!!upload.error}
                                                error={upload.error}
                                                onRemove={() => {
                                                    helpers.remove(index);
                                                    setExampleQrCode(undefined);
                                                }}>
                                                {upload.name}
                                            </Upload.Item>
                                        )}>
                                        <Upload.Placeholder
                                            title="Upload File"
                                            description={
                                                <p>{`Drag & drop or click here to upload file. The recommened file ratio is 1:2.`}</p>
                                            }
                                        />
                                    </Upload>
                                )}
                            </Form.List>
                        </Form.Row>
                        {!!exampleQrCode && (
                            <Form.Row className={styles.preview}>
                                <h4>Preview</h4>
                                <QrCodeImage base64Image={exampleQrCode} />
                            </Form.Row>
                        )}
                    </Modal.Body>
                    <ModalFooter
                        submitText="Upload"
                        isSubmitting={isSubmitting || isLoadingPreview}
                        onCancel={goToQrCode}
                    />
                </Form>
            </SecuredContent>
        </Modal>
    );
};
