import { Navigation } from '@laingorourke/core-web-components';
import { Routes } from '@laingorourke/core-web-types';
import { useSelectedItemTypeCode } from 'domain/store/reducers/itemType';
import { useFavouriteProjects } from 'domain/store/reducers/projects';
import React from 'react';
import { FavouriteStar } from '../favouriteStar/FavouriteStar';
import styles from './FavouriteProjectsMenu.module.scss';

// Unfortunately, routes have to be passed due to imports mismatch. Direct import of routes result in initialization errors.

interface FavouriteProjectsMenu {
    routes: Routes;
}

export const FavouriteProjectsMenu: React.FC<FavouriteProjectsMenu> = ({ routes }) => {
    const favouriteProjects = useFavouriteProjects();
    const itemTypeCode = useSelectedItemTypeCode();

    return (
        <>
            {!!favouriteProjects?.length ? (
                <Navigation.SubMenu title="Projects">
                    {!!itemTypeCode &&
                        favouriteProjects?.map((p) => (
                            <Navigation.Route
                                key={p.projectNumber}
                                route={routes.projects.routes!.project}
                                params={{
                                    projectNumber: p.projectNumber,
                                    itemTypeCode: itemTypeCode,
                                }}>
                                <div className={styles.title}>
                                    <span>{`${p.name} (${p.projectNumber})`}</span>
                                    <FavouriteStar />
                                </div>
                            </Navigation.Route>
                        ))}
                </Navigation.SubMenu>
            ) : (
                <Navigation.Route route={routes.projects} />
            )}
        </>
    );
};
