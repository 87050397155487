import {
    emojis,
    emojiValidationMessage,
    getIntegerYup,
    invalidCharacters,
} from 'views/components/form/validators';
import * as Yup from 'yup';

const LeadDaysSchema = Yup.object().shape({
    classificationId: Yup.string().required('Classification is required'),
    leadDays: getIntegerYup('Lead Days', -731, 731).nullable(),
    comments: Yup.string()
        .nullable()
        .matches(emojis, emojiValidationMessage)
        .matches(invalidCharacters, 'Comments contain invalid characters')
        .max(500, 'Comments cannot be longer than 500 characters'),
});

export const ControlMeasureSchema = Yup.object().shape({
    name: Yup.string()
        .matches(emojis, emojiValidationMessage)
        .matches(invalidCharacters, 'Name contains invalid characters')
        .max(100, 'Name cannot be longer than 100 characters')
        .required('Name is required'),
    description: Yup.string()
        .matches(emojis, emojiValidationMessage)
        .matches(invalidCharacters, 'Description contains invalid characters')
        .max(250, 'Description cannot be longer than 250 characters'),
    igmsLink: Yup.string().url('IGMS Link must be valid. Make sure it begins with https://'),
    allProjects: Yup.boolean(),
    projectNumber: Yup.string()
        .nullable()
        .when('allProjects', (allProjects: boolean, schema: any) => {
            if (!allProjects) {
                return schema.required('Project is required');
            }
            return schema;
        }),
    updateInspectionScheduleStartDate: Yup.boolean(),
    evidenceRequired: Yup.boolean(),
});

export const ControlMeasureLeadDaysSchema = Yup.object().shape({
    leadDays: Yup.array(LeadDaysSchema),
});
