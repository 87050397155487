import { ColumnDefinition, Table, textFilter } from '@laingorourke/core-web-components';
import { LorImagesCdn3rdPartyLogos } from 'consts';
import { ProjectApplicationIntegration } from 'domain/models/api-models';
import { useProjects } from 'domain/store/reducers/projects/reducer';
import { nameof } from 'helpers/nameOf';
import React from 'react';
import {
    ProjectDisplayName,
    TableCellActive,
    TableCellImage,
    getDefaultPaginationOptions,
    getDefaultSortedByColummKey,
    getProjectDisplayName,
} from 'views/components';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import styles from './ProjectApplicationIntegrationTables.module.scss';
import { ProjectApplicationIntegrationType } from './projectApplicationIntegrationType';

interface ProjectApplicationIntegrationsTableProps {
    projectApplicationIntegrationList?: ProjectApplicationIntegration[];
}

const applicationLogosUrls = () => {
    const urls = new Map();
    urls.set(
        ProjectApplicationIntegrationType.BuiltView,
        `${LorImagesCdn3rdPartyLogos}/builtView.png`
    );
    urls.set(ProjectApplicationIntegrationType.P6, `${LorImagesCdn3rdPartyLogos}/p6.png`);
    return urls;
};

export const ProjectApplicationIntegrationsTable: React.FC<
    ProjectApplicationIntegrationsTableProps
> = ({ projectApplicationIntegrationList }) => {
    const projects = useProjects();
    const dataLoaded = !!projectApplicationIntegrationList;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Application Integrations found');
    const applicationLogosUrlsMap = applicationLogosUrls();
    const columns: ColumnDefinition<ProjectApplicationIntegration>[] = [
        {
            text: 'Project',
            field: (result) => getProjectDisplayName(result.projectNumber, projects),
            size: 40,
            sortable: true,
            filter: textFilter(),
            key: nameof<ProjectApplicationIntegration>('projectNumber'),
            renderer: (_, row: ProjectApplicationIntegration) => (
                <ProjectDisplayName projectNumber={row.projectNumber} />
            ),
        },
        {
            text: 'Application',
            field: (result) => (
                <TableCellImage
                    text={result.application!}
                    src={applicationLogosUrlsMap.get(result.application)}
                />
            ),
        },
        {
            text: 'Status',
            field: (result) => <TableCellActive isActive={result.isActive} />,
            sortable: true,
        },
    ];
    return (
        <>
            <h3 className={styles.header}>Application Integrations</h3>
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(
                    columns,
                    nameof<ProjectApplicationIntegration>('projectNumber')
                )}
                {...tableLoadingProps}
                data={projectApplicationIntegrationList ?? []}
                cacheKey="projectApplicationIntegrations"
                keySelector={(data: ProjectApplicationIntegration) =>
                    `${data.projectNumber}${data.application}`
                }
                columns={columns}
            />
        </>
    );
};
