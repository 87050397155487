import { faCopy } from '@fortawesome/free-regular-svg-icons';
import {
    Button,
    CollapsibleCard,
    Form,
    generatePath,
    Upload,
    useForm,
    WarningPanel,
} from '@laingorourke/core-web-components';
import { useProjectClassifications } from 'domain/store/reducers/classifications';
import { useItemTypeCategories, useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { clearProjectItems } from 'domain/store/reducers/projects';
import {
    fetchCsvTemplate,
    fetchProjectItems,
    uploadCsvTemplate,
} from 'domain/store/reducers/projects/actions';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { CopyText, ModalFooter, SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { ItemRouteParams } from '../../details/ItemRouteParams';
import { UploadCsvSchema } from '../schemas';
import styles from './UploadCsv.module.scss';

export const UploadCsv: React.FC = () => {
    const dispatch = useAppDispatch();

    const { projectNumber, itemTypeCode } = useParams<ItemRouteParams>();
    const itemTypeId = useSelectedItemTypeId();
    const history = useHistory();
    const categories = useItemTypeCategories();
    const classifications = useProjectClassifications();

    const form = useForm({
        initialValues: {
            documents: [] as Upload[],
        },
        validationSchema: UploadCsvSchema,
        onSubmit: async (values) => {
            const result = await dispatch(
                uploadCsvTemplate({
                    projectNumber: projectNumber,
                    file: values.documents[0].file!,
                })
            );
            if ((result as any).payload) {
                dispatch(clearProjectItems());
                dispatch(fetchProjectItems({ projectNumber, itemTypeId }));
                goToItems();
            }
        },
    });

    const { isSubmitting, values } = form;
    const goToItems = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.items.path, {
                projectNumber,
                itemTypeCode,
            })
        );

    return (
        <Modal show={true} onHide={goToItems} animation={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Upload CSV</Modal.Title>
            </Modal.Header>
            <SecuredContent permissions={Permission.ManageItemsWrite} denyExternalUserAccess>
                <Form form={form}>
                    <Modal.Body className={styles.body}>
                        <Button
                            className={styles.downloadBtn}
                            onClick={() =>
                                dispatch(fetchCsvTemplate({ projectNumber, itemTypeId }))
                            }>
                            Download Template
                        </Button>
                        <Form.Row>
                            <Form.List name="documents">
                                {(helpers) => (
                                    <Upload
                                        accept=".csv"
                                        onAdd={(upload) => {
                                            helpers.remove(0);
                                            upload.file = new File([upload.file!], upload.name, {
                                                type: 'text/csv',
                                            });
                                            helpers.push(upload);
                                        }}
                                        value={values.documents}
                                        multiple={false}
                                        itemRender={(upload, { handleDownload }, index) => (
                                            <Upload.Item
                                                className={styles.uploadItem}
                                                id={upload.id}
                                                isInvalid={!!upload.error}
                                                error={upload.error}
                                                onRemove={() => helpers.remove(index)}>
                                                {upload.name}
                                            </Upload.Item>
                                        )}>
                                        <Upload.Placeholder
                                            title="Upload File"
                                            description={
                                                <p>{`Drag & drop or click here to upload file`}</p>
                                            }
                                        />
                                    </Upload>
                                )}
                            </Form.List>
                        </Form.Row>
                        <Form.Row>
                            <WarningPanel className={styles.infoPanel}>
                                <>
                                    To complete your CSV file, you must use the correct Category and
                                    Classification for each Item. Click
                                    <Button
                                        variant="icon"
                                        icon={{
                                            icon: faCopy as any,
                                            size: 'sm',
                                        }}
                                    />
                                    to copy to clipboard and then paste into your CSV.
                                </>
                            </WarningPanel>
                        </Form.Row>
                        <Form.Row>
                            <CollapsibleCard title="Categories" collapsed className={styles.card}>
                                <ul>
                                    {categories?.map((c) => (
                                        <li key={c.id}>
                                            <CopyText text={c.name!} />
                                        </li>
                                    ))}
                                </ul>
                            </CollapsibleCard>
                        </Form.Row>
                        <Form.Row className={styles.classificationsRow}>
                            <CollapsibleCard
                                title="Classifications"
                                collapsed
                                className={styles.card}>
                                <ul>
                                    {classifications?.map((c) => (
                                        <CopyText key={c.id} text={c.name!} />
                                    ))}
                                </ul>
                            </CollapsibleCard>
                        </Form.Row>
                    </Modal.Body>
                    <ModalFooter
                        submitText="Upload"
                        isSubmitting={isSubmitting}
                        onCancel={goToItems}
                    />
                </Form>
            </SecuredContent>
        </Modal>
    );
};
