import { deleteClassification } from 'domain/store/reducers/classifications';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { TableRowActionButtons } from 'views/components/table';
import { ProjectParams } from 'views/routes/project/Project';
import { routes } from 'views/routes/Routes';
import { ClassificationTableRow } from '../ClassificationTableRow';

const classificationInactiveMessage = 'This classification has been deleted';

const ActionButtons: React.FC<{ classification: ClassificationTableRow }> = ({
    classification,
}) => {
    const dispatch = useAppDispatch();
    const [successfullyUpdated, setSuccessfullyUpdated] = useState<boolean | undefined>(undefined);
    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();
    const itemTypeId = useSelectedItemTypeId();

    const currentUserHasProjectClassificationsWritePermission = useHasPermissions(
        Permission.ProjectClassificationsWrite,
        projectNumber
    );
    const currentUserHasProjectClassificationsDeletePermission = useHasPermissions(
        Permission.ProjectClassificationsDelete,
        projectNumber
    );

    const onDelete = async () => {
        const result = await dispatch(
            deleteClassification({
                classificationId: classification.id,
                itemTypeId: itemTypeId,
                projectNumber: projectNumber,
            })
        );
        if ((result as any).payload) setSuccessfullyUpdated(true);
    };

    useEffect(() => {
        // reset successfully updated
        if (successfullyUpdated) setSuccessfullyUpdated(false);
    }, [successfullyUpdated]);

    return (
        <TableRowActionButtons
            editPath={
                routes.projects.routes!.project.routes!.projectSetup.routes!.classifications.routes!
                    .editClassification.path
            }
            routeParams={{ projectNumber, itemTypeCode, classificationId: classification.id }}
            entityId={classification.id}
            deleteBody="Are you sure you want to remove this classification?"
            onDelete={onDelete}
            deleteTitle={`Delete Classification`}
            successfullyUpdated={successfullyUpdated}
            hideEdit={!classification.projectNumber || !classification.canEdit}
            hideDelete={!classification.canDelete}
            isEditDisabled={
                !currentUserHasProjectClassificationsWritePermission || !classification.isActive
            }
            editDisabledMessage={!classification.isActive ? classificationInactiveMessage : ''}
            deleteDisabledMessage={!classification.isActive ? classificationInactiveMessage : ''}
            isDeleteDisabled={
                !currentUserHasProjectClassificationsDeletePermission || !classification.isActive
            }
        />
    );
};

export default ActionButtons;
