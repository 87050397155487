import {
    emojis,
    emojiValidationMessage,
    getIntegerYup,
    invalidCharacters,
} from 'views/components/form/validators';
import * as Yup from 'yup';

export interface InspectionTypeFormProps {
    inspectionTypeId: string;
    frequency: number;
}

const InspectionFrequencySchema = Yup.object().shape({
    inspectionTypeId: Yup.string().required('Inspection Type is required'),
    frequency: getIntegerYup('Frequency', 1, 100).required('Frequency is required'),
});

const hasDuplicates = (array: string[]) => new Set(array).size !== array.length;

export interface ComplianceTemplateFormProps {
    allProjects: boolean;
    projectNumber: string | null;
    categoryId: string | null;
    example: string;
    inspections: {
        inspectionTypeId: string;
        frequency: number;
    }[];
    controlMeasuresIds: string[];
    classificationId: string;
}

export const ComplianceTemplateSchemaBase = {
    allProjects: Yup.boolean(),
    example: Yup.string()
        .required('Classification Examples are required')
        .matches(emojis, emojiValidationMessage)
        .matches(invalidCharacters, 'Classification Examples contain invalid characters')
        .max(1000, 'Classification Examples cannot be longer than 1000 characters'),
    projectNumber: Yup.string()
        .nullable()
        .when('allProjects', (allProjects: boolean, schema: any) => {
            if (!allProjects) {
                return schema.required('Project is required');
            }
            return schema;
        }),
    categoryId: Yup.string()
        .nullable()
        .when('allProjects', (allProjects: boolean, schema: any) => {
            if (allProjects) {
                return schema.required('Category is required');
            }
            return schema;
        }),
    inspections: Yup.array(InspectionFrequencySchema).test(
        'Test duplicates',
        'Inspection Type duplicated',
        (value) => {
            if (!value) return true;
            const ids = value.map((v) => v!.inspectionTypeId!);
            if (!hasDuplicates(ids)) return true;
            return new Yup.ValidationError(
                'Inspection Types cannot be duplicated',
                null,
                'inspections'
            );
        }
    ),
    controlMeasuresIds: Yup.array(Yup.string()).min(1, 'At least 1 Control Measure is required'),
};

export const ComplianceTemplateSchema = Yup.object().shape({
    classificationId: Yup.string().required('Classification is required'),
    ...ComplianceTemplateSchemaBase,
});
