export interface ApplicationProject {
    application: string;
    applicationProjectId: string;
    applicationProjectName: string;
}

//The following enum is a mapping of IDH Application names.
export enum Applications {
    Asite = 'Asite',
    FieldView = 'Fieldview',
}
