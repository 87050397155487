import { ColumnDefinition } from '@laingorourke/core-web-components';
import { useProjectClassifications } from 'domain/store/reducers/classifications';
import { nameof } from 'helpers/nameOf';
import { ClassificationBadge, ItemNumber } from 'views/components/';
import { getPersonColumn } from 'views/components/table/personColumn';
import styles from '../../../../../../components/table/Table.module.scss';
import { WeatherEventAcknowledgementItemTableRow } from './WeatherEventAcknowledgementItemTableRow';

export const getTableColumns = (projectNumber: string): ColumnDefinition<WeatherEventAcknowledgementItemTableRow>[] => {
    // get info related to classifications, it's required in the table
    const classifications = useProjectClassifications(false);
    const classiticationsSortOrder = classifications && classifications.map((p) => p.id!);
    const personColumn = getPersonColumn('owner', false, true);
    return [
        {
            field: (d) => d.name,
            text: 'Title',
            sortable: true,
            headerClass: styles.minWidth170,
        },
        {
            field: (d) => d.number,
            text: 'Number',
            key: nameof<WeatherEventAcknowledgementItemTableRow>('number'),
            sortable: true,
            headerClass: styles.minWidth170,
            renderer: (_: any, row) => <ItemNumber itemNumber={row.number} projectNumber={projectNumber} copyTextEnabled />,
        },
        {
            field: (d) => d.classificationId,
            text: 'Classif.',
            sortable: true,
            customSort: (a, b, order) =>
                order === 'asc'
                    ? classiticationsSortOrder!.indexOf(a.classificationId!) -
                    classiticationsSortOrder!.indexOf(b.classificationId!)
                    : classiticationsSortOrder!.indexOf(b.classificationId!) -
                    classiticationsSortOrder!.indexOf(a.classificationId!),

            renderer: (_: any, row) => <ClassificationBadge id={row.classificationId} />,
        },
        {
            field: (d) => d.name,
            text: 'Area',
            sortable: true,
        },
        {
            field: (d) => d.name,
            text: 'Sub Area',
            sortable: true,
            headerClass: styles.minWidth120,
        },
        {
            field: (d) => d.categoryName,
            text: 'Category',
            sortable: true,
        },
        {
            field: (d) => d.owner,
            text: 'Owner',
            ...personColumn,
        },
    ];
};
