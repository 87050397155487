import { Button, Form, Select, useForm } from '@laingorourke/core-web-components';
import { useProjects } from 'domain/store/reducers/projects';
import { createTransfer, validateTransfer } from 'domain/store/reducers/transfers';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getProjectDisplayName, ModalFooter } from 'views/components';
import styles from './CreateForm.module.scss';
import { TransferSchema } from './schemas';

interface CreateTransferFormProps {
    itemTypeId: string;
    goToTransfers: () => void;
}

export const CreateForm: React.FC<CreateTransferFormProps> = ({ itemTypeId, goToTransfers }) => {
    const dispatch = useAppDispatch();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isValidating, setIsValidating] = useState(false);
    const projects = useProjects();

    const form = useForm({
        initialValues: {
            sourceProjectNumber: '',
            targetProjectNumber: '',
        },
        validationSchema: TransferSchema,
        onSubmit: async (values) => {
            let result = await dispatch(
                createTransfer({
                    ...values,
                    itemTypeId,
                })
            );

            if ((result as any).payload) goToTransfers();
        },
    });

    const { isSubmitting, values, validateForm, setFieldTouched } = form;

    const sourceProjectDisplayName = getProjectDisplayName(values.sourceProjectNumber, projects);
    const targetProjectDisplayName = getProjectDisplayName(values.targetProjectNumber, projects);

    return (
        <Form form={form}>
            <Modal.Body>
                {!showConfirmation && (
                    <>
                        <p>
                            Please select what project you would like to copy administration
                            settings from and to.
                        </p>
                        <p>
                            This will create new administrative settings based on what is in the
                            from project for the following:
                        </p>
                        <TransferedEntitiesList />
                        <Form.Row>
                            <Form.Field name="sourceProjectNumber" label="From Project">
                                <Select
                                    options={projects}
                                    dataShape={{
                                        value: 'projectNumber',
                                        label: 'displayName',
                                    }}
                                />
                            </Form.Field>
                        </Form.Row>
                        <Form.Row>
                            <Form.Field name="targetProjectNumber" label="To Project">
                                <Select
                                    options={projects}
                                    dataShape={{
                                        value: 'projectNumber',
                                        label: 'displayName',
                                    }}
                                />
                            </Form.Field>
                        </Form.Row>
                    </>
                )}
                {showConfirmation && (
                    <>
                        <p>
                            Please confirm you are copying the administration set from{' '}
                            <span className={styles.project}>{sourceProjectDisplayName}</span> to{' '}
                            <span className={styles.project}>{targetProjectDisplayName}</span> and
                            this will recreate in{' '}
                            <span className={styles.project}>{targetProjectDisplayName}</span> the
                            following:
                        </p>
                        <TransferedEntitiesList />
                    </>
                )}
            </Modal.Body>
            {!showConfirmation && (
                <ModalFooter
                    submitText="Next"
                    type="button"
                    isSubmitting={isValidating}
                    onSubmit={async () => {
                        // there is no other way to validate formik form, we have to set "touched" to make validation show mup on the UI, otherwise it will be done after another re-render
                        const errors = await validateForm();
                        setFieldTouched('sourceProjectNumber');
                        setFieldTouched('targetProjectNumber');
                        if (!Object.keys(errors).length) {
                            setIsValidating(true);
                            let result = await dispatch(
                                validateTransfer({
                                    ...values,
                                    itemTypeId,
                                })
                            );
                            setIsValidating(false);
                            if (result.payload) setShowConfirmation(true);
                        }
                    }}
                    onCancel={goToTransfers}></ModalFooter>
            )}
            {showConfirmation && (
                <ModalFooter
                    submitText="Create"
                    isSubmitting={isSubmitting}
                    onCancel={goToTransfers}>
                    <Button variant="secondary" onClick={() => setShowConfirmation(false)}>
                        Back
                    </Button>
                </ModalFooter>
            )}
        </Form>
    );
};

const TransferedEntitiesList: React.FC = () => (
    <ul>
        <li>Compliance Templates</li>
        <li>Control Measures</li>
        <li>Integrations</li>
        <li>Classifications</li>
        <li>Lead Days</li>
        <li>Designers</li>
        <li>Item Number Configuration</li>
    </ul>
);
