import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BuiltViewIntegration, MediaResponse } from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import {
    activateBuiltViewIntegration,
    deactivateBuiltViewIntegration,
    fetchAllBuiltViewTeamsByEmail,
    fetchAllBuiltViewTeamsByIds,
    fetchBuiltViewMediaByItem,
    fetchBuiltViewProjectIntegration,
} from './actions';

export interface Team {
    id: string;
    title: string;
}

const initialState = {
    builtViewProjectIntegration: null as unknown as BuiltViewIntegration,
    allUserTeams: null as unknown as Team[],
    teamsByIds: null as unknown as Team[],
    builtViewItemMedia: null as unknown as MediaResponse[],
};

const builtViewProjectIntegrationSlice = createSlice({
    name: 'builtViewProjectIntegration',
    initialState: initialState,
    reducers: {
        clearBuiltViewProjectIntegrations() {
            return initialState;
        },
        clearBuiltViewItemMedia(state) {
            return { ...state, builtViewItemMedia: initialState.builtViewItemMedia };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchAllBuiltViewTeamsByEmail.fulfilled,
            (state, action: PayloadAction<Team[]>) => {
                state.allUserTeams = action.payload;
            }
        );
        builder.addCase(
            fetchBuiltViewProjectIntegration.fulfilled,
            (state, action: PayloadAction<BuiltViewIntegration>) => {
                state.builtViewProjectIntegration =
                    action.payload ??
                    ({
                        isActive: false,
                    } as BuiltViewIntegration);
            }
        );
        builder.addCase(
            fetchBuiltViewMediaByItem.fulfilled,
            (state, action: PayloadAction<MediaResponse[]>) => {
                state.builtViewItemMedia = action.payload;
            }
        );
        builder.addCase(
            fetchAllBuiltViewTeamsByIds.fulfilled,
            (state, action: PayloadAction<Team[]>) => {
                state.teamsByIds = action.payload;
            }
        );
        builder.addCase(activateBuiltViewIntegration.fulfilled.type, (state) => {
            state.builtViewProjectIntegration.isActive = true;
        });
        builder.addCase(deactivateBuiltViewIntegration.fulfilled.type, (state) => {
            state.builtViewProjectIntegration.isActive = false;
            state.builtViewProjectIntegration.teamIds = [];
        });
    },
});

export const useAllBuiltViewTeams = () =>
    useSelector((state: RootState) => state.builtViewProjectIntegration.allUserTeams);

export const useBuiltViewMediaByItem = () =>
    useSelector((state: RootState) => state.builtViewProjectIntegration.builtViewItemMedia);

export const useBuiltViewTeamsByIds = (teamIds: string[]) =>
    useSelector((state: RootState) =>
        state.builtViewProjectIntegration.teamsByIds?.filter((t) =>
            teamIds.some((id) => t.id === id)
        )
    );

export const useBuiltViewProjectIntegration = () => {
    return useSelector(
        (state: RootState) => state.builtViewProjectIntegration.builtViewProjectIntegration
    );
};

export const { clearBuiltViewProjectIntegrations, clearBuiltViewItemMedia } =
    builtViewProjectIntegrationSlice.actions;
export default builtViewProjectIntegrationSlice.reducer;
