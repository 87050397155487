import { ProjectLeadDays } from 'domain/models/api-models';
import React from 'react';
import { useParams } from 'react-router-dom';
import { TableRowActionButtons } from 'views/components/table';
import { ProjectParams } from 'views/routes/project/Project';
import { routes } from 'views/routes/Routes';

interface ActionButtonsProps {
    projectLeadDays: ProjectLeadDays;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ projectLeadDays }) => {
    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();

    return (
        <TableRowActionButtons
            editPath={
                routes.projects.routes!.project.routes!.projectSetup.routes!.leadDays.routes!
                    .editLeadDays.path
            }
            routeParams={{
                projectNumber,
                itemTypeCode,
                controlMeasureId: projectLeadDays.controlMeasureId,
            }}
            entityId={projectLeadDays.controlMeasureId}
            skipDelete={true}
        />
    );
};

export default ActionButtons;
