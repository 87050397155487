import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { isGuid } from 'helpers/guidHelper';
import { getQueryResult } from './graphQlClient';
import { Group, Page } from './models';

const query = gql`
    query getGroupsByIds($ids: [ID!]!) {
        groupsByIds(ids: $ids) {
            data {
                id
                name
            }
        }
    }
`;

export const useGroupsByIds = (ids?: string[]) => {
    ids = ids?.filter((id) => isGuid(id));
    return useQuery(
        [ids, ids],
        async () => {
            const result = await getQueryResult<{
                groupsByIds: Page<Group>;
            }>(query, {
                ids,
            });
            return result.groupsByIds.data;
        },
        {
            enabled: !!ids?.length,
        }
    );
};
