import { Page } from '@laingorourke/core-web-components';
import {
    activateBuiltViewIntegration,
    clearBuiltViewProjectIntegrations,
    deactivateBuiltViewIntegration,
    fetchAllBuiltViewTeamsByEmail,
    fetchBuiltViewProjectIntegration,
    useBuiltViewProjectIntegration,
} from 'domain/store/reducers/builtViewIntegration';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import {
    activateP6Integration,
    clearP6ProjectIntegrations,
    deactivateP6Integration,
    fetchP6ProjectIntegration,
    useP6ProjectIntegration,
} from 'domain/store/reducers/p6Integration';
import { useCurrentUser, useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { Setting } from 'views/components/setting/Setting';
import { Integrations as SharedIntegration } from '../../../shared/integrations';
import { ProjectParams } from '../../Project';
import { TeamsSettings } from './components/TeamsSettings';
import styles from './Integration.module.scss';

export const noEditPermissionsMessage = 'You can not edit this configuration';

export const Integrations: React.FC<PropsWithChildren> = ({ children }) => {
    const builtViewIntegration = useBuiltViewProjectIntegration();
    const p6Integration = useP6ProjectIntegration();
    const dispatch = useAppDispatch();
    const { projectNumber } = useParams<ProjectParams>();
    const itemTypeId = useSelectedItemTypeId();
    const currentUser = useCurrentUser();
    const currentUserHasIntegrationWritePermission = useHasPermissions(
        Permission.ManageIntegrationSettingsWrite,
        projectNumber
    );
    const currentUserHasIntegratioReadPermission = useHasPermissions(
        Permission.ManageIntegrationSettingsRead,
        projectNumber
    );

    const tooltipMessage = useMemo(() => {
        if (!currentUserHasIntegrationWritePermission) {
            return noEditPermissionsMessage;
        }
    }, [noEditPermissionsMessage]);

    useEffect(() => {
        if (currentUserHasIntegratioReadPermission) {
            dispatch(fetchBuiltViewProjectIntegration({ itemTypeId, projectNumber }));
            dispatch(
                fetchAllBuiltViewTeamsByEmail({
                    itemTypeId,
                    projectNumber,
                    email: currentUser.emailAddress!,
                })
            );
            dispatch(fetchP6ProjectIntegration({ itemTypeId, projectNumber }));
        }
        return () => {
            dispatch(clearBuiltViewProjectIntegrations());
            dispatch(clearP6ProjectIntegrations());
        };
    }, [itemTypeId, projectNumber, currentUser, currentUserHasIntegratioReadPermission]);

    return (
        <SharedIntegration>
            {children}
            <Page.Section className={styles.settingCard}>
                <Setting<boolean>
                    label="BuiltView Sync"
                    description="By enabling BuiltView Sync and selecting Teams, Item Numbers will be sent to BuiltView as Tags."
                    canEdit={currentUserHasIntegrationWritePermission}
                    value={builtViewIntegration?.isActive ?? false}
                    loading={!builtViewIntegration}
                    tooltipMessage={tooltipMessage}
                    onChange={(value) => {
                        if (value) {
                            dispatch(activateBuiltViewIntegration({ projectNumber, itemTypeId }));
                        } else {
                            dispatch(deactivateBuiltViewIntegration({ projectNumber, itemTypeId }));
                        }
                    }}
                />
                {builtViewIntegration?.isActive && (
                    <TeamsSettings
                        teamIds={builtViewIntegration!.teamIds!}
                        canEdit={currentUserHasIntegrationWritePermission}
                        onOperationCompleted={() =>
                            dispatch(
                                fetchBuiltViewProjectIntegration({ itemTypeId, projectNumber })
                            )
                        }
                    />
                )}
                <Setting<boolean>
                    label="P6 Sync for Date In Service"
                    description={`By turning on, the date in service for the item will be updated with the P6 programme User Field 'Temporary Work Code'`}
                    canEdit={currentUserHasIntegrationWritePermission}
                    value={p6Integration?.isActive ?? false}
                    loading={!p6Integration}
                    tooltipMessage={tooltipMessage}
                    onChange={(value) => {
                        if (value) {
                            dispatch(activateP6Integration({ projectNumber, itemTypeId }));
                        } else {
                            dispatch(deactivateP6Integration({ projectNumber, itemTypeId }));
                        }
                    }}
                />
            </Page.Section>
        </SharedIntegration>
    );
};
