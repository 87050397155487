import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../rootStore';

interface Notification {
    message: string;
    isSuccess: boolean | undefined;
    show: boolean;
}

const initState: Notification = {
    message: '',
    isSuccess: undefined,
    show: false,
};
const notificationSlice = createSlice({
    name: 'notification',
    initialState: initState,
    reducers: {
        addNotification(state, action: PayloadAction<{ message: string; isSuccess: boolean }>) {
            state.message = action.payload.message;
            state.isSuccess = action.payload.isSuccess;
            state.show = true;
        },
        removeNotification(state) {
            state.message = '';
            state.isSuccess = undefined;
            state.show = false;
        },
    },
});

export const useNotification = () => useSelector((state: RootState) => state.notification);

export const { addNotification, removeNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
