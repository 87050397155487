import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@laingorourke/core-web-components';
import React, { useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { routes } from 'views/routes/Routes';
import { SearchPageParams } from '../../SearchPage';
import styles from './SearchInput.module.scss';

export const SearchInput: React.FC = () => {
    const history = useHistory();
    const match = useRouteMatch<SearchPageParams>('/search/:term');
    const [searchTerm, setSearchTerm] = useState(match?.params?.term ?? '');

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (searchTerm.trim().length === 0) return;
        history.push(generatePath(routes.search.path, { term: searchTerm }));
    }

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Input
                value={searchTerm}
                placeholder="Search..."
                prepend={<FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
        </form>
    );
};
