import { Input, InputNumber, Select, Skeleton, Switch } from '@laingorourke/core-web-components';
import { useEffect, useState } from 'react';
import { TooltipWrapper } from '../tooltipWrapper';
import styles from './Setting.module.scss';

type SettingType = string | boolean | number;

interface Setting<T extends SettingType> {
    label: string;
    description: string;
    loading?: boolean;
    canEdit: boolean;
    tooltipMessage?: string;
    value: T;
    options?: {
        label: string;
        value: T;
    }[];
    min?: number;
    max?: number;
    onChange: (value: T) => void;
}

export const Setting = <T extends SettingType>(props: Setting<T>) => {
    const {
        label,
        description,
        loading,
        canEdit,
        tooltipMessage,
        value,
        options,
        min,
        max,
        onChange,
    } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isString = typeof value === 'string' || value instanceof String;
    const [numericValue, setNumericValue] = useState(value);

    const onValueChange = (v: T) => {
        setIsSubmitting(true);
        onChange(v);
        setIsSubmitting(false);
    };

    const isNumericValid =
        (max === undefined || numericValue <= max) && (min === undefined || numericValue >= min);

    useEffect(() => {
        setNumericValue(value);
    }, [value]);

    return (
        <div className={styles.setting}>
            <h3>{label}</h3>
            <p>{description}</p>
            {
                //maybe configuration could be children
                !loading && (
                    <TooltipWrapper
                        id={label.replace(' ', '-')}
                        show={!canEdit}
                        tooltipContent={tooltipMessage}>
                        <>
                            {isString && !options && (
                                <Input
                                    value={value as string}
                                    disabled={!canEdit}
                                    onChange={(v) => onValueChange(v.target.value as T)}
                                />
                            )}
                            {!!options && (
                                <Select
                                    value={value as string}
                                    disabled={!canEdit || isSubmitting}
                                    onChange={(v) => onValueChange(v as T)}
                                    options={options}
                                />
                            )}
                            {typeof value === 'boolean' && (
                                <Switch
                                    value={value as boolean}
                                    disabled={!canEdit || isSubmitting}
                                    onChange={(v) => onValueChange(v as T)}
                                />
                            )}
                            {!isNaN(value as number) && typeof value !== 'boolean' && (
                                <div>
                                    <InputNumber
                                        value={numericValue as number}
                                        disabled={!canEdit}
                                        onChange={(v) => setNumericValue(v as T)}
                                        onBlur={(v) => {
                                            if (!isNumericValid) return;
                                            onValueChange(v.target.value as T);
                                        }}
                                        min={min}
                                        max={max}
                                        isInvalid={!isNumericValid}
                                    />
                                    <div className="invalid-feedback">
                                        Max Item Number cannot be less than {min} and greater than{' '}
                                        {max}
                                    </div>
                                </div>
                            )}
                        </>
                    </TooltipWrapper>
                )
            }
            {loading && <Skeleton.Box width={80} height={30} />}
        </div>
    );
};
