import { useIsOffline } from 'hooks/useIsOffline';
import React, { useEffect } from 'react';

interface PageActionsContext {
    content?: React.ReactNode;
    setContent: (content: React.ReactNode) => void;
}

export const PageActionsContext = React.createContext<PageActionsContext | null>(null);

export const usePageActions = (content: React.ReactNode, deps?: ReadonlyArray<any> | undefined) => {
    const context = React.useContext(PageActionsContext)!;
    const isOffline = useIsOffline();

    useEffect(() => {
        context.setContent(content);
        return () => context.setContent(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps ?? []);

    useEffect(() => {
        context.setContent(content);
        return () => context.setContent(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOffline]);
};

export const PageActions: React.FC<{
    children: (content: React.ReactNode) => React.ReactNode;
}> = (props) => {
    const [content, setContent] = React.useState<React.ReactNode>();

    return (
        <PageActionsContext.Provider value={{ content, setContent }}>
            {props.children(content)}
        </PageActionsContext.Provider>
    );
};
