export interface GroupTableRow {
    key: string;
    name: string;
    member: string;
    jobTitle?: string;
    role?: string;
    approvalRequired: StatusOption;
    userId: string;
    memberId: string;
    permission?: string;
}

export enum StatusOption {
    Active = 'Active',
    Inactive = 'Inactive',
    Pending = 'Pending',
}
