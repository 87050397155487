import { Table } from '@laingorourke/core-web-components';
import {
    fetchAllProjectsClassifications,
    useClassifications,
} from 'domain/store/reducers/classifications';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useProjects } from 'domain/store/reducers/projects/reducer';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import React, { PropsWithChildren, useEffect } from 'react';
import { Permission } from 'security/Permission';
import { SecuredContent, getProjectDisplayName } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { ClassificationTableRow } from 'views/routes/project/projectSetup/classifications/ClassificationTableRow';
import { getTableColumns } from './tableColumns';

export const Classifications: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent
        permissions={Permission.ManageClassificationSettingsRead}
        ignoreSecurable={true}>
        <ClassificationsContent>{children}</ClassificationsContent>
    </SecuredContent>
);

const ClassificationsContent: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();

    const classifications = useClassifications();
    const projects = useProjects();
    const itemTypeId = useSelectedItemTypeId();

    const classificationsTableData = classifications.map((i) => ({
        ...i,
        projectName: getProjectDisplayName(i.projectNumber, projects),
    })) as ClassificationTableRow[];

    const dataLoaded = !!classificationsTableData && !!projects;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Classifications found');

    const tableColumns = getTableColumns(classifications);
    useEffect(() => {
        dispatch(fetchAllProjectsClassifications({ itemTypeId }));
    }, [itemTypeId]);

    return (
        <Table
            {...getDefaultPaginationOptions()}
            {...getDefaultSortedByColummKey(
                tableColumns,
                nameof<ClassificationTableRow>('projectName')
            )}
            {...tableLoadingProps}
            data={dataLoaded ? classificationsTableData! : []}
            keySelector={(d) => d.id}
            columns={tableColumns}
            saveSelectedPageSize
            cacheKey="classifications"
            hover
        />
    );
};
