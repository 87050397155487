import { Form, Select, useForm } from '@laingorourke/core-web-components';
import { fetchDesignersSummaries } from 'domain/store/reducers/designers/actions';
import { useDesignersSummaries } from 'domain/store/reducers/designers/reducer';
import { loadingCompleted, loadingStarted } from 'domain/store/reducers/inProgress/reducer';
import { assignDesigner } from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Permission } from 'security/Permission';
import { ModalFooter, SecuredContent } from 'views/components';
import * as Yup from 'yup';
import { SelectedProjectItems } from '../components/SelectedProjectItem';

interface AssignDesignerProps {
    itemTypeId: string;
    projectNumber: string;
    projectItemsIds: string[];
    show: boolean;
    onClose: (success: boolean) => void;
}

export const AssignDesigner: React.FC<AssignDesignerProps> = ({
    itemTypeId,
    projectNumber,
    projectItemsIds,
    onClose,
}) => {
    const dispatch = useAppDispatch();

    const designers = useDesignersSummaries();

    const form = useForm({
        initialValues: {
            designerId: '',
        },
        validationSchema: Yup.object().shape({
            designerId: Yup.string().required('Designer is required'),
        }),
        onSubmit: async (values) => {
            await dispatch(
                assignDesigner({
                    projectItemsIds,
                    designerId: values.designerId,
                    itemTypeId,
                    projectNumber,
                })
            );
            onClose(true);
        },
    });

    const { isSubmitting } = form;

    const dataLoaded = !!designers;

    useEffect(() => {
        dispatch(fetchDesignersSummaries({ projectNumber, itemTypeId }));
    }, [projectNumber, itemTypeId, dispatch]);

    useEffect(() => {
        if (dataLoaded) dispatch(loadingCompleted());
        else dispatch(loadingStarted());
        return () => {
            dispatch(loadingCompleted());
        };
    }, [dataLoaded, dispatch]);

    return (
        <>
            {dataLoaded && designers.length > 0 && (
                <Modal
                    show={true}
                    onHide={() => onClose(false)}
                    animation={false}
                    backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Assign Designer</Modal.Title>
                    </Modal.Header>
                    <SecuredContent permissions={Permission.ManageItemsWrite}>
                        <Form form={form}>
                            <Modal.Body>
                                <Form.Row>
                                    <Form.Field name="designerId" label="Designer">
                                        <Select
                                            options={designers!}
                                            dataShape={{
                                                value: 'id',
                                                label: 'name',
                                            }}
                                        />
                                    </Form.Field>
                                </Form.Row>
                                <SelectedProjectItems projectItemsIds={projectItemsIds} projectNumber={projectNumber} />
                            </Modal.Body>
                            <ModalFooter
                                submitText="Update"
                                isSubmitting={isSubmitting}
                                onCancel={() => onClose(false)}
                            />
                        </Form>
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
