import { deleteArea } from 'domain/store/reducers/areas';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { MissingIndicator } from 'views/components';
import { TableRowActionButtons } from 'views/components/table';
import { routes } from 'views/routes/Routes';
import { ProjectParams } from '../../Project';
import { AreaTableRow } from '../AreaTableRow';
import { missingItemNumberCommencementMessage, missingItemNumberPrefixMessage } from '../messages';
import styles from './ActionButtons.module.scss';

const getAreaCannotBeModifiedMessage = (action: string, subAreaPrefix: string) =>
    `You cannot ${action} this ${subAreaPrefix}Area until background processing is complete.`;

const ActionButtons: React.FC<{ area: AreaTableRow }> = ({ area }) => {
    const dispatch = useAppDispatch();
    const [successfullyUpdated, setSuccessfullyUpdated] = useState<boolean | undefined>(undefined);
    const itemTypeId = useSelectedItemTypeId();
    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();

    const currentUserHasManageAreasWritePermission = useHasPermissions(
        Permission.ManageAreasWrite,
        projectNumber
    );
    const currentUserHasManageAreasDeletePermission = useHasPermissions(
        Permission.ManageAreasDelete,
        projectNumber
    );

    const isSubArea = area.subAreaName!?.length > 0;
    const subAreaPrefix = isSubArea ? 'Sub ' : '';
    const onDelete = async () => {
        const result = await dispatch(
            deleteArea({ projectNumber, itemTypeId, areaId: area.id, isSubArea: isSubArea })
        );
        if ((result as any).payload) setSuccessfullyUpdated(true);
    };
    const message = area.hasActiveInstances
        ? `You cannot delete this ${subAreaPrefix}Area as long as it is in use`
        : area.hasSubAreas
        ? `You cannot delete this ${subAreaPrefix}Area as it has linked sub areas`
        : area.canBeModified
        ? getAreaCannotBeModifiedMessage('delete', subAreaPrefix)
        : '';

    const configurationSetUp = !!area.itemNumberPrefix && area.itemNumberCommencement !== undefined;
    const itemNumberPrefixIsSetUp = !!area.itemNumberPrefix;
    const itemNumberCommencementIsSetUp =
        area.itemNumberCommencement !== undefined && area.itemNumberCommencement !== null;
    const missingMessage = (
        <>
            {itemNumberPrefixIsSetUp ? '' : missingItemNumberPrefixMessage}
            {itemNumberCommencementIsSetUp ? (
                ''
            ) : (
                <span className={styles['commencement-message']}>
                    {missingItemNumberCommencementMessage}
                </span>
            )}
        </>
    );
    return (
        <>
            <TableRowActionButtons
                editPath={routes.projects.routes!.project.routes!.areas.routes!.editArea.path}
                routeParams={{ projectNumber, itemTypeCode, areaId: area.id }}
                entityId={area.id}
                deleteBody={`Are you sure you want to remove '${
                    isSubArea ? area.subAreaName : area.name!
                }'?`}
                isDeleteDisabled={
                    area.hasActiveInstances || area.hasSubAreas || !area.canBeModified
                }
                onDelete={onDelete}
                deleteTitle={`Delete ${subAreaPrefix}Area`}
                deleteDisabledMessage={message}
                successfullyUpdated={successfullyUpdated}
                hideEdit={!currentUserHasManageAreasWritePermission}
                hideDelete={!currentUserHasManageAreasDeletePermission}
                editDisabledMessage={
                    area.canBeModified ? '' : getAreaCannotBeModifiedMessage('edit', subAreaPrefix)
                }
                isEditDisabled={!area.canBeModified}
                prefix={
                    area.areaCodeInItemNumber &&
                    !configurationSetUp &&
                    !area.subAreaName && (
                        <MissingIndicator id={`${area.id}-missing`} message={missingMessage} />
                    )
                }
            />
        </>
    );
};

export default ActionButtons;
