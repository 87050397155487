import { Avatar, Skeleton } from '@laingorourke/core-web-components';
import { PersonInfo } from 'hooks/useWithPerson';
import React from 'react';
import styles from './PersonTableCell.module.scss';

interface PersonTableCellProps extends PersonInfo {}

export const UserNotFoundLabel = 'User not found';

export const PersonTableCell: React.FC<PersonTableCellProps> = ({
    displayName,
    photo,
    isLoading,
    isNotFound,
}) =>
    isLoading ? (
        <Skeleton.Box height={21} width={180} />
    ) : (
        <span className={styles.name}>
            {!!photo && !isNotFound && (
                <Avatar displayName={displayName} src={photo} className={styles.avatar} />
            )}
            {isNotFound ? <i>{UserNotFoundLabel}</i> : <>{displayName}</>}
        </span>
    );
