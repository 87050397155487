import { Checkbox, Form, Select } from '@laingorourke/core-web-components';
import { useProjects } from 'domain/store/reducers/projects/reducer';
import { hasPermission, useCurrentUser, useHasPermissions } from 'domain/store/reducers/users';
import React, { useEffect } from 'react';
import { Permission } from 'security/Permission';

interface ProjectSelectorProps {
    isEditMode: boolean;
    allProjects: boolean;
    permission: Permission;
    isProjectMode?: boolean;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const ProjectSelector: React.FC<ProjectSelectorProps> = ({
    isEditMode,
    allProjects,
    permission,
    setFieldValue,
    isProjectMode,
}) => {
    const user = useCurrentUser();
    const projects = useProjects()?.filter((p) => hasPermission(user, permission, p.projectNumber));

    const userHasPermissionsToAllProjects = useHasPermissions(permission, '');

    useEffect(() => {
        if (allProjects) {
            setFieldValue('projectNumber', null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allProjects, setFieldValue]);
    return (
        <>
            <Form.Row>
                <Form.Field name="allProjects" label="Apply To">
                    <Checkbox
                        disabled={isEditMode || !userHasPermissionsToAllProjects || isProjectMode}>
                        All projects
                    </Checkbox>
                </Form.Field>
            </Form.Row>
            {!allProjects && (
                <Form.Row>
                    <Form.Field name="projectNumber" label="Project">
                        <Select
                            options={projects}
                            dataShape={{
                                value: 'projectNumber',
                                label: 'displayName',
                            }}
                            disabled={isEditMode || isProjectMode}
                        />
                    </Form.Field>
                </Form.Row>
            )}
        </>
    );
};
