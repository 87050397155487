import { deleteControlMeasure } from 'domain/store/reducers/controlMeasure';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableRowActionButtons } from 'views/components/table';
import { routes } from 'views/routes/Routes';
import { ControlMeasureTableRow } from '../ControlMeasureTableRow';

interface ActionButtonsProps {
    controlMeasure: ControlMeasureTableRow;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ controlMeasure }) => {
    const dispatch = useAppDispatch();
    const routeParams = useParams();
    const [successfullyUpdated, setSuccessfullyUpdated] = useState<boolean | undefined>(undefined);
    const onDelete = async () => {
        const result = await dispatch(deleteControlMeasure(controlMeasure.id));
        if ((result as any).payload) setSuccessfullyUpdated(true);
    };

    return (
        <TableRowActionButtons
            editPath={routes.admin.routes!.controlMeasures.path + `/edit/${controlMeasure.id}`}
            routeParams={routeParams}
            entityId={controlMeasure.id}
            deleteBody={`Are you sure you want to remove '${controlMeasure.name!}'?`}
            isDeleteDisabled={controlMeasure.hasActiveInstances}
            onDelete={onDelete}
            deleteTitle="Delete Control Measure"
            deleteDisabledMessage="You cannot delete this Control Measure as it is assigned to a compliance template."
            successfullyUpdated={successfullyUpdated}
            hideEdit={!controlMeasure.canEdit}
            hideDelete={!controlMeasure.canDelete}
        />
    );
};

export default ActionButtons;
