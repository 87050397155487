import { Form, Input, useForm } from '@laingorourke/core-web-components';
import { TourStep } from 'domain/models/api-models';
import { updateTourStep } from 'domain/store/reducers/tourSteps';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalFooter } from 'views/components';
import { ContentEditor } from './ContentEditor';
import { TourStepSchema } from './schemas';

interface UpdateTransferFormProps {
    tourStep: TourStep;
    goToTour: () => void;
}

export const UpdateForm: React.FC<UpdateTransferFormProps> = ({ tourStep, goToTour }) => {
    const dispatch = useAppDispatch();

    const form = useForm({
        initialValues: {
            target: tourStep.target,
            title: tourStep.title,
            content: tourStep.content,
            path: tourStep.path,
            placement: tourStep.placement,
            sortOrder: tourStep.sortOrder,
        },
        validationSchema: TourStepSchema,
        onSubmit: async (values) => {
            let result = await dispatch(
                updateTourStep({
                    id: tourStep.id,
                    title: values.title,
                    content: values.content,
                    sortOrder: values.sortOrder,
                })
            );
            if ((result as any).payload) goToTour();
        },
    });

    const { isSubmitting, values, validateForm, setFieldTouched } = form;

    return (
        <Form form={form}>
            <Modal.Body>
                <Form.Row>
                    <Form.Field name="title" label="Title">
                        <Input />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="content" label="Content">
                        <ContentEditor isEditable />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="target" label="Target">
                        <Input disabled />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="path" label="Path">
                        <Input disabled />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="sortOrder" label="Sort Order">
                        <Input />
                    </Form.Field>
                </Form.Row>
            </Modal.Body>
            <ModalFooter submitText="Update" isSubmitting={isSubmitting} onCancel={goToTour} />
        </Form>
    );
};
