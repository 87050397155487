import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { isDevelopment } from 'appSettings';
import { errorMiddleware } from 'domain/store/middleware/errorMiddleware';
import { useDispatch } from 'react-redux';
import ApplicationMessageReducer from './reducers/applicationMessage/reducer';
import ApplicationProjectReducer from './reducers/applicationProject/reducer';
import AreasReducer from './reducers/areas/reducer';
import BuiltViewProjectIntegrationReducer from './reducers/builtViewIntegration/reducer';
import ClassificationsReducer from './reducers/classifications/reducer';
import ComplianceTemplatesReducer from './reducers/complianceTemplates/reducer';
import ControlMeasuresReducer from './reducers/controlMeasure/reducer';
import DesignersReducer from './reducers/designers/reducer';
import InProgressReducer from './reducers/inProgress/reducer';
import InspectionTypesReducer from './reducers/inspectionType/reducer';
import IntegrationsReducer from './reducers/integrations/reducer';
import ItemTypesReducer from './reducers/itemType/reducer';
import NotificationReducer from './reducers/notificationsReducer';
import P6ProjectIntegrationReducer from './reducers/p6Integration/reducer';
import PeopleReducer from './reducers/people/reducer';
import ProjectItemNumberConfigurationReducer from './reducers/projectItemNumberConfiguration/reducer';
import ProjectLeadDaysReducer from './reducers/projectLeadDays/reducer';
import ProjectsReducer from './reducers/projects/reducer';
import ReviewsReducer from './reducers/reviews/reducer';
import TourStepsReducer from './reducers/tourSteps/reducer';
import TransferReducer from './reducers/transfers/reducer';
import UsersReducer from './reducers/users/reducer';
import WeatherReducer from './reducers/weather/reducer';

const rootReducer = combineReducers({
    // base reducers
    inProgress: InProgressReducer,
    applicationMessage: ApplicationMessageReducer,
    // Comply business data reducers
    projects: ProjectsReducer,
    itemTypes: ItemTypesReducer,
    controlMeasures: ControlMeasuresReducer,
    notification: NotificationReducer,
    inspectionTypes: InspectionTypesReducer,
    complianceTemplates: ComplianceTemplatesReducer,
    areas: AreasReducer,
    integrations: IntegrationsReducer,
    users: UsersReducer,
    reviews: ReviewsReducer,
    projectLeadDays: ProjectLeadDaysReducer,
    classifications: ClassificationsReducer,
    designers: DesignersReducer,
    projectItemNumberConfiguration: ProjectItemNumberConfigurationReducer,
    people: PeopleReducer,
    applicationProjects: ApplicationProjectReducer,
    transfers: TransferReducer,
    weather: WeatherReducer,
    builtViewProjectIntegration: BuiltViewProjectIntegrationReducer,
    tourSteps: TourStepsReducer,
    p6ProjectIntegration: P6ProjectIntegrationReducer,
});

const store = configureStore({
    reducer: rootReducer,
    devTools: isDevelopment,
    middleware: getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(
        errorMiddleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
