import { Table } from '@laingorourke/core-web-components';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { fetchTourSteps, useTourSteps } from 'domain/store/reducers/tourSteps';
import { hasPermission, useCurrentUser } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import React, { PropsWithChildren, useEffect } from 'react';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { TourStepTableRow } from './TourStepTableRow';
import { getTableColumns } from './tableColumns';

export const Tours: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ManageTourSettingsRead} ignoreSecurable={true}>
        <ToursContent>{children}</ToursContent>
    </SecuredContent>
);

const ToursContent: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const currentUser = useCurrentUser();
    const tourSteps = useTourSteps();
    const itemTypeId = useSelectedItemTypeId();
    const canEdit = hasPermission(currentUser, Permission.ManageTourSettingsWrite, undefined, true);
    const canDelete = hasPermission(
        currentUser,
        Permission.ManageTourSettingsDelete,
        undefined,
        true
    );

    const tourStepsTableData = tourSteps?.map((t) => ({
        ...t,
        canEdit: canEdit,
        canDelete: canDelete,
    })) as TourStepTableRow[];

    const dataLoaded = !!tourStepsTableData;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Tour Steps found');

    const tableColumns = getTableColumns(canEdit || canDelete);

    useEffect(() => {
        dispatch(fetchTourSteps());
    }, [itemTypeId, dispatch]);

    return (
        <>
            {children}
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(
                    tableColumns,
                    nameof<TourStepTableRow>('sortOrder')
                )}
                {...tableLoadingProps}
                data={dataLoaded ? tourStepsTableData! : []}
                keySelector={(d) => d.id}
                columns={tableColumns}
                saveSelectedPageSize
                cacheKey="tourSteps"
                hover
            />
        </>
    );
};
