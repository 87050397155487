import { deleteDesigner } from 'domain/store/reducers/designers';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableRowActionButtons } from 'views/components/table';
import { routes } from 'views/routes/Routes';
import { DesignerTableRow } from '../DesignerTableRow';

interface ActionButtonsProps {
    designer: DesignerTableRow;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ designer }) => {
    const dispatch = useAppDispatch();
    const routeParams = useParams();
    const itemTypeId = useSelectedItemTypeId();
    const [successfullyUpdated, setSuccessfullyUpdated] = useState<boolean | undefined>(undefined);
    const onDelete = async () => {
        const result = await dispatch(deleteDesigner({ id: designer.id, itemTypeId: itemTypeId }));
        if ((result as any).payload) setSuccessfullyUpdated(true);
    };

    return (
        <TableRowActionButtons
            editPath={routes.admin.routes!.designers.path + `/edit/${designer.id}`}
            routeParams={routeParams}
            entityId={designer.id}
            deleteBody={`Are you sure you want to remove '${designer.name!}'?`}
            onDelete={onDelete}
            deleteTitle="Delete Designer"
            successfullyUpdated={successfullyUpdated}
            hideEdit={!designer.canEdit}
            hideDelete={!designer.canDelete}
            editDisabledMessage="This Designer has been deleted, it can no longer be edited"
            isEditDisabled={!designer.isActive}
            deleteDisabledMessage="This Designer has been deleted"
            isDeleteDisabled={!designer.isActive}
        />
    );
};

export default ActionButtons;
