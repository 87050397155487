import {
    Editor as RTE,
    EditorProps as RTEProps,
    OnChangeEvent,
} from '@laingorourke/core-web-components-richtexteditor';
import { Hub } from '@laingorourke/core-web-types';
import { useCollaborationToken } from 'domain/services/documentCollaborationService';
import { getDocumentCollaborationConfiguration } from 'helpers/applicationConfig';

export interface EditorProps extends Partial<Pick<RTEProps, 'isEditable' | 'placeholder'>> {
    value?: RTEProps['initialDocument'];
    onChange?: (value: string) => void;
}

export const ContentEditor: React.FC<EditorProps> = ({ value, ...props }) => {
    const accessToken = useCollaborationToken();

    const configuration = getDocumentCollaborationConfiguration();

    const editorProps: RTEProps = {
        enableCollab: false,
        menuConfiguration: {
            hideAttachFile: true,
            hideEmbedReport: true,
            hideOnlineUsers: true,
            hideTwoColumnLayout: true,
            hideEmoji: true,
        },

        serverURL: configuration?.baseUrl!,
        hub: Hub.Australia,
        accessToken: accessToken,
        initialDocument: value,
    };

    if (!configuration?.baseUrl) return null;
    if (!accessToken) {
        return <>{props.placeholder}</>;
    }

    const onChange = (e: OnChangeEvent) => props.onChange && props?.onChange(e.serializedDoc);

    return <RTE {...editorProps} {...props} onChange={onChange} isLoading={true} />;
};
