import { Review } from 'domain/models/api-models';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { deleteReview } from 'domain/store/reducers/reviews';
import { useCurrentUser, useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { TableRowActionButtons } from 'views/components/table';
import { routes } from 'views/routes/Routes';
import { ProjectParams } from '../../Project';

const ActionButtons: React.FC<{ review: Review }> = ({ review }) => {
    const dispatch = useAppDispatch();
    const [successfullyUpdated, setSuccessfullyUpdated] = useState<boolean | undefined>(undefined);
    const itemTypeId = useSelectedItemTypeId();
    const currentUser = useCurrentUser();
    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();

    const currentUserHasManageReviewsWritePermission = useHasPermissions(
        Permission.ProjectGovernanceWrite,
        projectNumber
    );
    const currentUserHasManageReviewsDeletePermission = useHasPermissions(
        Permission.ProjectGovernanceDelete,
        projectNumber
    );

    const allowEdit =
        currentUserHasManageReviewsWritePermission && currentUser.id === review.reviewerId;
    const allowDelete =
        currentUserHasManageReviewsDeletePermission && currentUser.id === review.reviewerId;

    const onDelete = async () => {
        const result = await dispatch(
            deleteReview({
                projectNumber,
                itemTypeId,
                reviewId: review.id,
            })
        );
        if ((result as any).payload) setSuccessfullyUpdated(true);
    };

    return (
        <TableRowActionButtons
            editPath={routes.projects.routes!.project.routes!.reviews.routes!.editReview.path}
            routeParams={{ projectNumber, itemTypeCode, reviewId: review.id }}
            entityId={review.id}
            deleteBody="Are you sure you want to remove this review?"
            onDelete={onDelete}
            deleteTitle={`Delete Review`}
            successfullyUpdated={successfullyUpdated}
            hideEdit={!allowEdit}
            hideDelete={!allowDelete}
        />
    );
};

export default ActionButtons;
