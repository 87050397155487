import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClassificationLeadDays, ControlMeasure } from 'domain/models/api-models';
import { get, patch, post, remove } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

export const fetchControlMeasures = createAsyncThunk<ControlMeasure[], void, { state: RootState }>(
    'itemtypes/{itemTypeId}/controlmeasures/get',
    async (_, { getState }) => {
        const { itemTypes } = getState();
        const itemTypeId = itemTypes.selectedItemType.id;
        return (await get(`itemtypes/${itemTypeId}/controlmeasures`))!;
    },
    {
        condition: (_, { getState }) => getState().itemTypes.selectedItemType.id !== '',
    }
);

interface CreateControlMeasure {
    name: string;
    projectNumber?: string;
    description: string;
    igmsLink: string;
    leadDays?: ClassificationLeadDays[];
    itemStatusWhenControlMeasureCompleted?: string | null;
}

export const createControlMeasure = createAsyncThunk<
    ControlMeasure,
    CreateControlMeasure,
    { state: RootState }
>('itemtypes/{itemTypeId}/controlmeasures/post', async (controlMeasure, { getState, dispatch }) => {
    return await runSafe(async () => {
        const itemTypeId = getState().itemTypes.selectedItemType.id;

        const createdControlMeasure = await post(`itemtypes/${itemTypeId}/controlmeasures`, {
            ...controlMeasure,
            projectNumber: controlMeasure.projectNumber,
        });
        dispatch(
            addNotification({
                message: 'Control Measure has been created successfully',
                isSuccess: true,
            })
        );
        return createdControlMeasure;
    }, dispatch);
});

interface UpdateControlMeasure {
    id: string;
    name: string;
    description: string;
    igmsLink: string;
    leadDays?: ClassificationLeadDays[];
    itemStatusWhenControlMeasureCompleted?: string | null;
}

export const updateControlMeasure = createAsyncThunk<
    ControlMeasure,
    UpdateControlMeasure,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/controlmeasures/patch',
    async (controlMeasure, { getState, dispatch }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            const updatedControlMeasure = await patch(
                `itemtypes/${itemTypeId}/controlmeasures/${controlMeasure.id}`,
                controlMeasure
            );

            dispatch(
                addNotification({
                    message: 'Control Measure has been updated successfully',
                    isSuccess: true,
                })
            );
            return updatedControlMeasure;
        }, dispatch);
    }
);

export const deleteControlMeasure = createAsyncThunk<string, string, { state: RootState }>(
    'itemtypes/{itemTypeId}/controlmeasures/delete',
    async (id, { getState, dispatch }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            await remove(`itemtypes/${itemTypeId}/controlmeasures/${id}`);
            dispatch(
                addNotification({
                    message: 'Control Measure has been deleted successfully',
                    isSuccess: true,
                })
            );
            return id;
        }, dispatch);
    }
);
