import { Button, Skeleton } from '@laingorourke/core-web-components';
import {
    fetchAllBuiltViewTeamsByIds,
    useBuiltViewTeamsByIds,
} from 'domain/store/reducers/builtViewIntegration';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useAppDispatch } from 'domain/store/rootStore';
import { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { TooltipWrapper } from 'views/components';
import { noEditPermissionsMessage, ProjectParams } from 'views/routes/project';
import styles from '../Integration.module.scss';
import { AssignTeams } from './AssignTeams';

interface TeamsSettingsProps {
    teamIds: string[];
    onOperationCompleted: () => void;
    canEdit: boolean;
}

export const TeamsSettings: React.FC<TeamsSettingsProps> = ({
    teamIds,
    onOperationCompleted,
    canEdit,
}) => {
    const { projectNumber } = useParams<ProjectParams>();
    const itemTypeId = useSelectedItemTypeId();
    const dispatch = useAppDispatch();
    const selectedTeamsWithNames = useBuiltViewTeamsByIds(teamIds);
    const [showAssignTeams, setShowAssignTeams] = useState(false);

    useEffect(() => {
        dispatch(
            fetchAllBuiltViewTeamsByIds({
                itemTypeId,
                projectNumber,
                ids: teamIds,
            })
        );
    }, [itemTypeId, projectNumber, teamIds]);

    const sortedSelectedTeamsWithNames = selectedTeamsWithNames?.sort((a, b) =>
        a.title!.localeCompare(b.title)
    );

    const onCloseAssignTeams = (success: boolean) => onClose(success, setShowAssignTeams);

    const onClose = (success: boolean, showAction: (show: boolean) => void) => {
        showAction(false);
        if (success) onOperationCompleted();
    };

    return (
        <>
            <div>
                <h3>BuiltView Teams</h3>
                <p>Please select the BuiltView Teams you want Item Numbers to be available in.</p>
            </div>
            <Skeleton.Box
                height={50}
                loading={!selectedTeamsWithNames && !!teamIds && teamIds!.length > 0}>
                <div className={styles.teamsContainer}>
                    <div className={styles.badgeContainer}>
                        {sortedSelectedTeamsWithNames?.length > 0 ? (
                            sortedSelectedTeamsWithNames?.map((team) => (
                                <Badge text="light" key={team.id}>
                                    {team.title}
                                </Badge>
                            ))
                        ) : (
                            <div className={styles.noContent}>
                                No BuiltView Teams have been selected.
                            </div>
                        )}
                    </div>
                    <div className={styles.actions}>
                        <TooltipWrapper
                            id={`assignTeams-${projectNumber}`}
                            tooltipContent={!canEdit && noEditPermissionsMessage}>
                            <Button onClick={() => setShowAssignTeams(true)} disabled={!canEdit}>
                                Update Teams
                            </Button>
                        </TooltipWrapper>
                    </div>
                    {showAssignTeams && (
                        <AssignTeams
                            itemTypeId={itemTypeId}
                            projectNumber={projectNumber}
                            show={showAssignTeams}
                            onClose={onCloseAssignTeams}
                            selectedTeams={sortedSelectedTeamsWithNames}
                        />
                    )}
                </div>
            </Skeleton.Box>
        </>
    );
};
