import { generatePath } from '@laingorourke/core-web-components';
import { Area } from 'domain/models/api-models';
import {
    fetchAsiteProjects,
    fetchFieldViewProjects,
} from 'domain/store/reducers/applicationProject/actions';
import { useArea, useParentAreas } from 'domain/store/reducers/areas';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useAreaCodeInItemNumber } from 'domain/store/reducers/projectItemNumberConfiguration';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { AreaRouteParams } from '../AreaRouteParams';
import { UpsertForm } from './UpsertForm';

export const UpsertArea: React.FC<{ subArea?: boolean }> = ({ subArea }) => {
    const { projectNumber, itemTypeCode, areaId } = useParams<AreaRouteParams>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const goToArea = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.areas.path, {
                projectNumber,
                itemTypeCode,
            })
        );
    const itemTypeId = useSelectedItemTypeId();

    const areas = useParentAreas();
    const area: Area | undefined = useArea(areaId);
    const isSubArea = subArea ?? (area && area?.subAreaName!.length > 0) ?? false;

    const areaCodeInItemNumber = useAreaCodeInItemNumber();

    const isEditMode = areaId !== undefined;
    const dataLoaded = areas && areaCodeInItemNumber !== undefined;

    useEffect(() => {
        dispatch(fetchAsiteProjects());
        dispatch(fetchFieldViewProjects());
    }, []);

    return (
        <>
            {(areaId === undefined || area !== undefined) && (
                <Modal show={true} onHide={goToArea} animation={false} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {isEditMode ? 'Edit' : 'New'} {isSubArea ? 'Sub' : ''} Area
                        </Modal.Title>
                    </Modal.Header>
                    <SecuredContent permissions={Permission.ManageAreasWrite}>
                        {dataLoaded && (
                            <UpsertForm
                                area={area}
                                isEditMode={isEditMode}
                                areas={areas}
                                isSubArea={isSubArea}
                                projectNumber={projectNumber}
                                itemTypeId={itemTypeId}
                                goToArea={goToArea}
                                areaCodeInItemNumber={areaCodeInItemNumber}
                            />
                        )}
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
