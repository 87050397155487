import { Table } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import {
    useItemTypeCategories,
    useItemTypeMetadata,
    useSelectedItemTypeId,
} from 'domain/store/reducers/itemType';
import { useProjects } from 'domain/store/reducers/projects/reducer';
import { hasPermission, useCurrentUser } from 'domain/store/reducers/users';
import {
    clearWeatherInspectionTypes,
    fetchWeatherInspectionTypes,
    useWeatherInspectionTypes,
} from 'domain/store/reducers/weather';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import React, { PropsWithChildren, useEffect } from 'react';
import { Permission } from 'security/Permission';
import { getProjectDisplayName } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { WeatherWarning } from 'views/routes/shared/weatherEvents/WeatherWarning';
import tableStyles from '../../../../components/table/Table.module.scss';
import { WeatherInspectionTypeTableRow } from './WeatherInspectionTypeTableRow';
import { NewWeatherInspectionTypeButton } from './components/NewWeatherInspectionTypeButton';
import { getTableColumns } from './tableColumns';

export const WeatherInspectionTypes: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const currentUser = useCurrentUser();

    const weatherInspectionTypes = useWeatherInspectionTypes();
    const itemTypeId = useSelectedItemTypeId();
    const projects = useProjects();
    const categories = useItemTypeCategories();
    const metadata = useItemTypeMetadata();

    const dataLoaded = !!weatherInspectionTypes && !!categories && !!projects;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Weather Inspection Types found');

    const getCategoryName = (id: string) => metadata?.categories?.find((p) => p.id === id)?.name!;
    const weatherInspectionTypesTableData: WeatherInspectionTypeTableRow[] | undefined =
        weatherInspectionTypes?.map((w) => ({
            ...w,
            projectName: getProjectDisplayName(w.projectNumber, projects),
            categoryNames: w.categoriesIds!.map((c) => getCategoryName(c)),
            canEdit: hasPermission(
                currentUser,
                Permission.ManageWeatherSettingsWrite,
                w.projectNumber,
                false
            ),
            canDelete: hasPermission(
                currentUser,
                Permission.ManageWeatherSettingsDelete,
                w.projectNumber,
                false
            ),
        }));

    const allCategories = weatherInspectionTypes
        ? weatherInspectionTypes.reduce((acc, cur) => {
            acc.push(...cur.categoriesIds!);
            return acc;
        }, [] as string[])
        : [];

    const categoryOptions = !!metadata?.categories
        ? metadata.categories
            .filter((c) => allCategories.indexOf(c.id) > -1)
            .map((c) => {
                return {
                    label: c.name,
                    value: c.id,
                } as OptionTypeBase;
            })
        : [];

    const columns = getTableColumns(
        weatherInspectionTypesTableData,
        categoryOptions,
        metadata?.categories,
        !!weatherInspectionTypesTableData?.find((c) => c.canEdit || c.canDelete)
    );

    useEffect(() => {
        dispatch(fetchWeatherInspectionTypes());
        return () => {
            dispatch(clearWeatherInspectionTypes());
        };
    }, [itemTypeId, dispatch]);

    return (
        <>
            <WeatherWarning />
            <NewWeatherInspectionTypeButton disabled={!dataLoaded} />
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(
                    columns,
                    nameof<WeatherInspectionTypeTableRow>('projectName')
                )}
                {...tableLoadingProps}
                data={dataLoaded ? weatherInspectionTypesTableData : []}
                columns={columns}
                keySelector={(d) => d.id}
                saveSelectedPageSize
                cacheKey="weather-inspection-types"
                rowClasses={() => tableStyles.preWrappedWitheSpace}
                hover
            />
            {children}
        </>
    );
};
