import { Button, ColumnDefinition, textFilter } from '@laingorourke/core-web-components';
import { reactivateDesigner } from 'domain/store/reducers/designers';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import { ProjectDisplayName, TableCellActive } from 'views/components';
import ActionButtons from './components/ActionButtons';
import styles from './Designers.module.scss';
import { DesignerTableRow } from './DesignerTableRow';

const actionsFormatter = (cell: any, row: DesignerTableRow) => <ActionButtons designer={row} />;

const actionsColumn: ColumnDefinition<DesignerTableRow> = {
    field: (d) => d.id,
    text: '',
    renderer: actionsFormatter,
    headerClass: styles.minWidth100,
};
export const getTableColumns = (
    showActions: boolean,
    itemTypeId?: string
): ColumnDefinition<DesignerTableRow>[] => {
    const dispatch = useAppDispatch();
    return [
        {
            field: (d) => d.name,
            text: 'Designer',
            key: nameof<DesignerTableRow>('name'),
            sortable: true,
            filter: textFilter(),
        },
        {
            field: (d) => d.projectName,
            text: 'Project',
            sortable: true,
            headerClass: styles.minWidth150,
            filter: textFilter(),
            renderer: (_, row: DesignerTableRow) => (
                <ProjectDisplayName projectNumber={row.projectNumber} />
            ),
        },
        {
            field: (d) => d.isActive,
            text: 'Status',
            sortable: true,
            renderer: (_: any, row: DesignerTableRow) => (
                <>
                    <TableCellActive isActive={row.isActive} className={styles.status} />
                    {!row.isActive && itemTypeId && (
                        <Button
                            size="sm"
                            onClick={() => {
                                dispatch(
                                    reactivateDesigner({
                                        id: row.id,
                                        itemTypeId,
                                    })
                                );
                            }}>
                            Activate
                        </Button>
                    )}
                </>
            ),
        },
        {
            ...actionsColumn,
            hidden: !showActions,
        },
    ];
};
