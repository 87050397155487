import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    ProjectClassificationLeadDays,
    ProjectLeadDays,
    UpdateProjectLeadDaysRequest,
} from 'domain/models/api-models';
import { get, patch } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

interface FetchParams {
    projectNumber: string;
    itemTypeId: string;
}

export const fetchProjectLeadDays = createAsyncThunk<
    ProjectLeadDays[],
    FetchParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/projectleaddays/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/projectleaddays`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface UpdateProjectLeadDays extends UpdateProjectLeadDaysRequest {
    itemTypeId: string;
    projectNumber: string;
}

export const updateProjectLeadDays = createAsyncThunk<
    ProjectClassificationLeadDays,
    UpdateProjectLeadDays,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/reviews/patch',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const updatedProjectLeadDays = await patch(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/projectLeadDays/${args.controlMeasureId}`,
                args
            );

            dispatch(
                addNotification({
                    message: 'Project Lead Days have been updated successfully',
                    isSuccess: true,
                })
            );
            return updatedProjectLeadDays;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);
