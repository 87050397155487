import React from 'react';
import { ApplicationLogo } from 'views/components/applicationLogo';
import styles from './TableCellImage.module.scss';

interface TableCellImageProps {
    text: string;
    src?: string;
}

export const TableCellImage: React.FC<TableCellImageProps> = ({ text, src }) => {
    return (
        <>
            {!!src ? (
                <div className={styles.application}>
                    <ApplicationLogo alt={text} src={src} />
                    <span>{text}</span>
                </div>
            ) : (
                text
            )}
        </>
    );
};
