import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons/faStar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@laingorourke/core-web-components';
import cn from 'classnames';
import { Project } from 'domain/models/Project';
import {
    addFavouriteProject,
    removeFavouriteProject,
} from 'domain/store/reducers/projects/actions';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { FavouriteStar } from 'views/components';
import styles from './FavouriteButton.module.scss';

const FavouriteButton: React.FC<{ project: Project }> = ({ project }) => {
    const dispatch = useAppDispatch();
    const favouriteActionParams = {
        projectNumber: project.projectNumber,
        projectName: project.name,
    };
    return (
        <Button
            onClick={(e) => {
                e.stopPropagation();
                dispatch(
                    project.isFavourite
                        ? removeFavouriteProject(favouriteActionParams)
                        : addFavouriteProject(favouriteActionParams)
                );
            }}
            name={`${project.isFavourite ? 'Favourite ' : 'Unfavourite '} ${project.name}`}
            variant="icon"
            className={cn(styles.favourite, project.isFavourite && styles.selected)}>
            {project.isFavourite && <FavouriteStar />}
            {!project.isFavourite && <FontAwesomeIcon icon={faStarRegular} />}
        </Button>
    );
};

export default FavouriteButton;
