import { Button, StickyBar } from '@laingorourke/core-web-components';
import { useSites } from 'domain/services/dataService/useSites';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useProjectId } from 'domain/store/reducers/projects';
import { useIsExternalUser } from 'domain/store/reducers/users';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectParams } from '../../Project';
import { AssignDesigner } from '../assignDesigner/AssignDesigner';
import { AssignOwner } from '../assignOwner/AssignOwner';
import { AssignSite } from '../assignSite/AssignSite';
import { AssignSubcontractor } from '../assignSubcontractor/AssignSubcontractor';
import { ChangeStatus } from '../changeStatus/ChangeStatus';
import styles from './List.module.scss';

export const BulkOperations: React.FC<{
    selectedItems: string[];
    onOperationCompleted: () => void;
}> = ({ selectedItems, onOperationCompleted }) => {
    const { projectNumber } = useParams<ProjectParams>();
    const itemTypeId = useSelectedItemTypeId();

    const [showAssignOwner, setShowAssignOwner] = useState(false);
    const [showAssignDesigner, setShowAssignDesigner] = useState(false);
    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [showAssignSubcontractors, setShowAssignSubcontractors] = useState(false);
    const [showAssignSite, setShowAssignSite] = useState(false);

    const currentUserIsExternal = useIsExternalUser();

    const projectId = useProjectId(projectNumber);
    const sites = useSites(projectId);
    // Assign Sites is available if there is more than 1 site in PC
    const showBulkSiteAssign = (sites?.data?.length ?? 0) > 1;

    const onCloseAssignOwner = (success: boolean) => onClose(success, setShowAssignOwner);

    const onCloseAssignDesigner = (success: boolean) => onClose(success, setShowAssignDesigner);

    const onCloseChangeStatus = (success: boolean) => onClose(success, setShowChangeStatus);

    const onCloseAssignSubcontractors = (success: boolean) =>
        onClose(success, setShowAssignSubcontractors);

    const onCloseAssignSite = (success: boolean) => onClose(success, setShowAssignSite);

    const onClose = (success: boolean, showAction: (show: boolean) => void) => {
        showAction(false);
        if (success) onOperationCompleted();
    };

    return (
        <>
            {showAssignOwner && (
                <AssignOwner
                    projectItemsIds={selectedItems}
                    itemTypeId={itemTypeId}
                    projectNumber={projectNumber}
                    show={showAssignOwner}
                    onClose={onCloseAssignOwner}
                />
            )}
            {showAssignDesigner && (
                <AssignDesigner
                    projectItemsIds={selectedItems}
                    itemTypeId={itemTypeId}
                    projectNumber={projectNumber}
                    show={showAssignDesigner}
                    onClose={onCloseAssignDesigner}
                />
            )}
            {showChangeStatus && (
                <ChangeStatus
                    projectItemsIds={selectedItems}
                    itemTypeId={itemTypeId}
                    projectNumber={projectNumber}
                    show={showChangeStatus}
                    onClose={onCloseChangeStatus}
                />
            )}
            {showAssignSubcontractors && (
                <AssignSubcontractor
                    projectItemsIds={selectedItems}
                    itemTypeId={itemTypeId}
                    projectNumber={projectNumber}
                    show={showAssignSubcontractors}
                    onClose={onCloseAssignSubcontractors}
                />
            )}
            {showAssignSite && sites.data && (
                <AssignSite
                    projectItemsIds={selectedItems}
                    itemTypeId={itemTypeId}
                    projectNumber={projectNumber}
                    show={showAssignSite}
                    sites={sites.data}
                    onClose={onCloseAssignSite}
                />
            )}
            <StickyBar hidden={selectedItems.length === 0} className={styles.stickyBar}>
                <span className={styles.selectedItemsLabel}>
                    {selectedItems.length} item{selectedItems.length !== 1 ? 's' : ''} selected
                </span>
                {showBulkSiteAssign && (
                    <Button variant="secondary" onClick={() => setShowAssignSite(true)}>
                        Assign Site
                    </Button>
                )}
                {!currentUserIsExternal && (
                    <Button variant="secondary" onClick={() => setShowAssignSubcontractors(true)}>
                        Assign Subcontractors
                    </Button>
                )}
                <Button variant="secondary" onClick={() => setShowAssignDesigner(true)}>
                    Assign Designer
                </Button>
                <Button variant="secondary" onClick={() => setShowChangeStatus(true)}>
                    Change Status
                </Button>
                <Button variant="primary" onClick={() => setShowAssignOwner(true)}>
                    Assign Owners
                </Button>
            </StickyBar>
        </>
    );
};
