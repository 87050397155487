import { Layout, Page } from '@laingorourke/core-web-components';
import { AppDisplayName, ComplyLogoSrc } from 'consts';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

export const ErrorPage: React.FC<{ error: Error }> = ({ error }) => {
    return (
        <BrowserRouter basename={'/projects'}>
            <Layout>
                <Layout.Main>
                    <Layout.Header
                        sticky
                        brand={{
                            name: AppDisplayName,
                            logo: ComplyLogoSrc,
                        }}
                    />
                    <Page header={{ title: 'An error occurred' }}>
                        <Page.Section>
                            <div role="alert">
                                <p>
                                    <span dangerouslySetInnerHTML={{ __html: error.message }} />
                                </p>
                            </div>
                        </Page.Section>
                    </Page>
                </Layout.Main>
                <Layout.Footer copyrightYear={new Date().getFullYear()} />
            </Layout>
        </BrowserRouter>
    );
};
