import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { youAreOfflineFeatureNotAvailableMessage } from '@laingorourke/core-web-components';
import { syncIntegration } from 'domain/store/reducers/integrations';
import { useSelectedItemTypeName } from 'domain/store/reducers/itemType';
import { useAppDispatch } from 'domain/store/rootStore';
import { useIsOffline } from 'hooks/useIsOffline';
import React, { useEffect, useState } from 'react';
import { ActionModalButton } from 'views/components';
import { IntegrationTableRow } from './integrationTable/IntegrationTableRow';

export const SyncIntegrationModalButton: React.FC<IntegrationTableRow> = (props) => {
    const dispatch = useAppDispatch();
    const [successfullyUpdated, setSuccessfullyUpdated] = useState<boolean | undefined>(undefined);

    const isOffline = useIsOffline();

    const onSync = async () => {
        const result = await dispatch(
            syncIntegration({ integrationId: props.id, projectNumber: props.projectNumber })
        );
        if ((result as any).payload) setSuccessfullyUpdated(true);
    };

    const itemTypeName = useSelectedItemTypeName();

    // once modal is closed we have to set this prop to false, reset it, so that it could be closed the next time modal is open and sync is run
    useEffect(() => {
        if (successfullyUpdated) {
            setSuccessfullyUpdated(false);
        }
    }, [successfullyUpdated]);

    return (
        <ActionModalButton
            key={`${props.id!}-sync`}
            body={
                <>
                    <p>{`This function recalculates the status of ${itemTypeName} Items based on the Integrations listed, and is only required to be run if you have added a new Integration and would like to force the recalculation of Item statuses.  This may take some time to complete.`}</p>
                    {`Are you sure you wish to recalculate the status of all ${itemTypeName} Items for ${
                        !!props.controlMeasureId ? 'Control Measure' : 'Inspection'
                    } `}
                    <b>{props.name}</b>?
                </>
            }
            title="Confirm Status Recalculation"
            message={isOffline ? youAreOfflineFeatureNotAvailableMessage : ''}
            successfullyUpdated={successfullyUpdated}
            onInvokeAction={onSync}
            disabled={isOffline}
            okButtonText="Confirm"
            buttonText="Sync"
            icon={faSyncAlt}
        />
    );
};
