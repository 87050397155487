import { deleteTourStep } from 'domain/store/reducers/tourSteps';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableRowActionButtons } from 'views/components/table';
import { routes } from 'views/routes/Routes';
import { TourStepTableRow } from '../TourStepTableRow';

interface ActionButtonsProps {
    tourStep: TourStepTableRow;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ tourStep }) => {
    const dispatch = useAppDispatch();
    const routeParams = useParams();
    const [successfullyUpdated, setSuccessfullyUpdated] = useState<boolean | undefined>(undefined);
    const onDelete = async () => {
        const result = await dispatch(deleteTourStep({ tourStepId: tourStep.id }));
        if ((result as any).payload) setSuccessfullyUpdated(true);
    };

    useEffect(() => {
        // reset successfully updated
        if (successfullyUpdated) setSuccessfullyUpdated(false);
    }, [successfullyUpdated]);

    return (
        <TableRowActionButtons
            editPath={routes.admin.routes!.tour.path + `/edit/${tourStep.id}`}
            routeParams={{ ...routeParams }}
            entityId={tourStep.id}
            deleteBody={`Are you sure you want to remove '${tourStep.title!}'?`}
            onDelete={onDelete}
            deleteTitle="Delete Tour Step"
            isDeleteDisabled={!tourStep.isActive}
            deleteDisabledMessage="Tour Step is already inactive"
            successfullyUpdated={successfullyUpdated}
            hideEdit={!tourStep.canEdit}
            hideDelete={!tourStep.canDelete}
        />
    );
};

export default ActionButtons;
