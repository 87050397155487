import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectLeadDays } from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import { fetchProjectLeadDays, updateProjectLeadDays } from './actions';

const initialState = {
    leadDays: null as unknown as ProjectLeadDays[],
};

const leadDaysSlice = createSlice({
    name: 'projectLeadDays',
    initialState: initialState,
    reducers: {
        clearProjectLeadDays() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchProjectLeadDays.fulfilled,
            (state, action: PayloadAction<ProjectLeadDays[]>) => {
                state.leadDays = action.payload;
            }
        );

        builder.addCase(
            updateProjectLeadDays.fulfilled.type,
            (state, action: PayloadAction<ProjectLeadDays>) => {
                if (action.payload === undefined) return;
                state.leadDays = state.leadDays?.map((l) =>
                    l.controlMeasureId === action.payload.controlMeasureId ? action.payload : l
                );
            }
        );
    },
});

export const useProjectLeadDays = () =>
    useSelector((state: RootState) => state.projectLeadDays.leadDays);
export const useProjectControlMeasureLeadDays = (controlMeasureId: string) =>
    useSelector((state: RootState) =>
        state.projectLeadDays.leadDays?.find((l) => l.controlMeasureId === controlMeasureId)
    );

export const { clearProjectLeadDays } = leadDaysSlice.actions;
export default leadDaysSlice.reducer;
