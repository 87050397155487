import { generatePath, Skeleton } from '@laingorourke/core-web-components';
import { Classification } from 'domain/models/api-models';
import {
    fetchAllowedBaseComplianceTemplates,
    useAllowedBaseComplianceTemplates,
    useClassification,
} from 'domain/store/reducers/classifications';
import {
    fetchControlMeasureSummaries,
    fetchInspectionTypesSummaries,
    useControlMeasureSummaries,
    useInspectionTypesSummaries,
} from 'domain/store/reducers/complianceTemplates';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { ProjectParams } from 'views/routes/project/Project';
import { routes } from 'views/routes/Routes';
import { UpsertForm } from './UpsertForm';

interface ClassificationParams extends ProjectParams {
    classificationId: string;
}
export const UpsertClassification: React.FC = () => {
    const dispatch = useAppDispatch();
    const { projectNumber, itemTypeCode, classificationId } = useParams<ClassificationParams>();
    const history = useHistory();
    const goToClassifications = () =>
        history.push(
            generatePath(
                routes.projects.routes!.project.routes!.projectSetup.routes!.classifications.path,
                {
                    projectNumber,
                    itemTypeCode,
                }
            )
        );

    const itemTypeId = useSelectedItemTypeId();
    const controlMeasures = useControlMeasureSummaries();
    const inspectionTypes = useInspectionTypesSummaries();
    const allowedBaseComplianceTemplates = useAllowedBaseComplianceTemplates();

    const isEditMode = classificationId !== undefined;
    const classification: Classification | undefined = useClassification(classificationId);

    const dataLoaded =
        (!isEditMode || !!classification) &&
        !!inspectionTypes &&
        !!controlMeasures &&
        !!allowedBaseComplianceTemplates;

    useEffect(() => {
        dispatch(fetchControlMeasureSummaries());
        dispatch(fetchInspectionTypesSummaries());
        dispatch(fetchAllowedBaseComplianceTemplates({ itemTypeId, projectNumber }));
    }, [itemTypeId, dispatch]);

    return (
        <Modal show={true} onHide={goToClassifications} animation={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? 'Edit' : 'New'} Classification</Modal.Title>
            </Modal.Header>
            <SecuredContent permissions={Permission.ProjectClassificationsWrite}>
                {dataLoaded && (
                    <UpsertForm
                        isEditMode={isEditMode}
                        projectNumber={projectNumber}
                        itemTypeId={itemTypeId}
                        classification={classification}
                        goToClassification={goToClassifications}
                        controlMeasures={controlMeasures}
                        inspectionTypes={inspectionTypes}
                        allowedBaseComplianceTemplates={allowedBaseComplianceTemplates}
                    />
                )}
                {!dataLoaded && (
                    <Modal.Body>
                        <Skeleton.Text />
                    </Modal.Body>
                )}
            </SecuredContent>
        </Modal>
    );
};
