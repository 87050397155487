import { ButtonProps, generatePath } from '@laingorourke/core-web-components';
import { useHasPermissions } from 'domain/store/reducers/users';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ActionButtonsPanel, usePageActions } from 'views/components';
import { TooltipButton } from 'views/components/tooltipButton';
import { routes } from 'views/routes/Routes';
import { AdminRouteParams } from '../AdminRouteParams';

interface NewInspectionTypeButtonProps extends ButtonProps {}

export const NewInspectionTypeButton: React.FC<NewInspectionTypeButtonProps> = ({ disabled }) => {
    const routeParams = useParams<AdminRouteParams>();
    const history = useHistory();

    const currentUserHasAnyManageComplianceSettingsWritePermission = useHasPermissions(
        Permission.ManageComplianceSettingsWrite,
        undefined,
        true
    );

    const goToNewInspection = () =>
        history.push(
            generatePath(routes.admin.routes!.inspectionTypes.routes!.newInspectionType.path, {
                ...routeParams,
            })
        );

    usePageActions(
        currentUserHasAnyManageComplianceSettingsWritePermission && (
            <ActionButtonsPanel>
                <TooltipButton
                    variant="primary"
                    onClick={goToNewInspection}
                    id="new-inspection-type"
                    disabled={disabled}
                    tooltipContent="Loading...">
                    New Inspection
                </TooltipButton>
            </ActionButtonsPanel>
        ),
        [
            currentUserHasAnyManageComplianceSettingsWritePermission,
            routeParams.itemTypeCode,
            disabled,
        ]
    );

    return <> </>;
};
