import { faFileExclamation } from '@fortawesome/pro-solid-svg-icons/faFileExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren } from 'react';
import { TooltipWrapper } from '../tooltipWrapper';
import styles from './EvidenceRequiredCell.module.scss';

interface EvidenceRequiredCellProps {
    id: string;
    children: React.ReactNode;
    evidenceRequired: boolean;
}

export const EvidenceRequiredCell: React.FC<PropsWithChildren<EvidenceRequiredCellProps>> = ({
    id,
    evidenceRequired,
    children,
}) => (
    <div className={styles.cell}>
        <div>{children}</div>
        {evidenceRequired && (
            <TooltipWrapper id={id} placement="right" tooltipContent="Evidence is required">
                <div>
                    <FontAwesomeIcon icon={faFileExclamation} />
                </div>
            </TooltipWrapper>
        )}
    </div>
);
