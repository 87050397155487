import { AuthorisedContent } from '@laingorourke/core-web-components';
import { useHasPermissions } from 'domain/store/reducers/users';
import React, { PropsWithChildren } from 'react';
import { useParams } from 'react-router';
import { Permission } from 'security/Permission';

interface SecuredContentProps {
    permissions: Permission | Permission[];
    ignoreSecurable?: boolean;
    projectNumber?: string;
    denyExternalUserAccess?: boolean;
}

export const SecuredContent: React.FC<PropsWithChildren<SecuredContentProps>> = ({
    permissions,
    ignoreSecurable,
    projectNumber,
    denyExternalUserAccess,
    children,
}) => {
    const params = useParams<{ projectNumber: string }>();
    const projectNumberToVerify = projectNumber || params.projectNumber;
    const currentUserHasPermission = useHasPermissions(
        permissions,
        projectNumberToVerify,
        ignoreSecurable,
        denyExternalUserAccess
    );
    return <AuthorisedContent authorised={currentUserHasPermission}>{children}</AuthorisedContent>;
};
