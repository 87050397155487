import { Skeleton } from '@laingorourke/core-web-components';
import { Classification } from 'domain/models/api-models';
import React from 'react';
import { ClassificationBadge } from 'views/components';
import styles from './LeadDaysTableHeader.module.scss';

interface LeadDaysTableHeaderProps {
    classifications?: Classification[];
}

export const LeadDaysTableHeader: React.FC<LeadDaysTableHeaderProps> = ({ classifications }) => (
    <>
        Lead Days
        <div className={styles.subHeader}>
            {classifications?.map((c) => (
                <ClassificationBadge key={c.id} id={c.id} />
            ))}
            {!classifications && <Skeleton.Box width={253} height={21} />}
        </div>
    </>
);
