import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { ButtonProps } from '@laingorourke/core-web-components';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalFooter } from 'views/components/modal';
import { ActionButton } from './ActionButton';

interface ActionModalButtonProps extends Omit<ButtonProps, 'icon'> {
    message: string;
    key: string;
    title: string;
    body: string | React.ReactNode;
    successfullyUpdated: boolean | undefined;
    okButtonText: string;
    buttonText?: string;
    icon: IconLookup;
    onInvokeAction: () => Promise<void>;
}

export const ActionModalButton: React.FC<ActionModalButtonProps> = (props) => {
    const {
        onClick,
        disabled,
        title,
        body,
        successfullyUpdated,
        onInvokeAction,
        okButtonText,
        icon,
        ...rest
    } = props;

    const dispatch = useAppDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async () => {
        setIsSubmitting(true);
        await onInvokeAction();
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (successfullyUpdated) handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successfullyUpdated]);

    return (
        <>
            <ActionButton
                {...rest}
                disabled={disabled}
                onClick={(e) => {
                    handleShow();
                    if (onClick) onClick(e);
                }}
                icon={icon}
            />

            <Modal show={show} onHide={handleClose} animation={false} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <ModalFooter
                    submitText={okButtonText}
                    onSubmit={onSubmit}
                    isSubmitting={isSubmitting}
                    onCancel={handleClose}
                />
            </Modal>
        </>
    );
};
