import { ColumnDefinition, selectFilter, textFilter } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { WeatherEventType } from 'domain/models/api-models';
import { nameof } from 'helpers/nameOf';
import { ClassificationBadge, ItemNumber, Site } from 'views/components/';
import { getFilterOptionsFromArray, getItemDetailsTableCell } from 'views/components/table';
import styles from 'views/components/table/Table.module.scss';
import { WeatherEventItemTableRow } from './WeatherEventItemTableRow';

export const getTableColumns = (
    onPreviewClicked: (itemId: string) => string,
    classiticationsSortOrder: string[],
    classificationOptions: OptionTypeBase[],
    areaNameOptions: OptionTypeBase[],
    subAreaNameOptions: OptionTypeBase[],
    projectNumber: string,
    hideItemDetailsColumn: boolean
): ColumnDefinition<WeatherEventItemTableRow>[] => [
    {
        ...getItemDetailsTableCell(onPreviewClicked),
        hidden: hideItemDetailsColumn,
    },
    {
        field: (d) => d.weatherEventType,
        text: 'Type',
        sortable: true,
        filter: selectFilter({
            options: getFilterOptionsFromArray(Object.values(WeatherEventType)),
        }),
        renderer: (_: any, row: WeatherEventItemTableRow) =>
            WeatherEventType[row.weatherEventType as keyof typeof WeatherEventType],
    },
    {
        field: (d) => d.siteName,
        text: 'Site',
        sortable: true,
        filter: textFilter(),
        renderer: (_: any, row: WeatherEventItemTableRow) => (
            <Site projectNumber={projectNumber} siteId={row.siteId} />
        ),
    },
    {
        field: (d) => d.name,
        text: 'Title',
        sortable: true,
        filter: textFilter(),
        headerClass: styles.minWidth170,
    },
    {
        field: (d) => d.number,
        text: 'Number',
        key: nameof<WeatherEventItemTableRow>('number'),
        sortable: true,
        filter: textFilter(),
        headerClass: styles.minWidth170,
        renderer: (_: any, row: WeatherEventItemTableRow) => (
            <ItemNumber itemNumber={row.number} projectNumber={projectNumber} copyTextEnabled />
        ),
    },
    {
        field: (d) => d.classificationId,
        text: 'Classif.',
        sortable: true,
        filter: selectFilter({
            options: classificationOptions,
            multi: true,
        }),
        customSort: (a, b, order) =>
            order === 'asc'
                ? classiticationsSortOrder!.indexOf(a.classificationId) -
                  classiticationsSortOrder!.indexOf(b.classificationId)
                : classiticationsSortOrder!.indexOf(b.classificationId) -
                  classiticationsSortOrder!.indexOf(a.classificationId),
        renderer: (cell: any, row: WeatherEventItemTableRow) => (
            <ClassificationBadge id={row.classificationId} />
        ),
    },
    {
        field: (d) => d.area,
        text: 'Area',
        sortable: true,
        filter: selectFilter({
            options: areaNameOptions,
            multi: true,
        }),
    },
    {
        field: (d) => d.subArea,
        text: 'Sub Area',
        sortable: true,
        filter: selectFilter({
            options: subAreaNameOptions,
            multi: true,
        }),
        headerClass: styles.minWidth120,
    },
    {
        field: (d) => d.categoryName,
        text: 'Category',
        sortable: true,
        filter: textFilter(),
    },
];
