import { SecurableType } from 'domain/models/api-models';
import { Group } from 'domain/services/dataService/models';
import { Permission } from 'security/Permission';
import { GroupTableRow, StatusOption } from './GroupTableRow';

export const toFlatData = (projectNumber: string, groups?: Group[]) => {
    const recursionDepth = 5;
    const results =
        groups
            ?.map((g) => {
                return g.members?.data.map((m) => {
                    return m.roleAssignments?.data.map((ra) => {
                        return ra.role.securables
                            ?.filter(
                                (rs) =>
                                    rs.typeId === SecurableType.All ||
                                    (rs.typeId === SecurableType.Project && rs.id === projectNumber)
                            )
                            .map((s) => {
                                const permissionsOrder = [
                                    Permission.ManageItemsRead,
                                    Permission.ManageItemsWrite,
                                    Permission.ManageItemsDelete,
                                ] as string[];

                                return s.users
                                    ?.filter((u) => u.userId === m.id)
                                    .map((u2) => {
                                        const permissions = ra.role.permissions
                                            ?.filter(
                                                (p) =>
                                                    p.identifier === Permission.ManageItemsRead ||
                                                    p.identifier === Permission.ManageItemsWrite ||
                                                    p.identifier === Permission.ManageItemsDelete
                                            )
                                            ?.sort((a, b) =>
                                                permissionsOrder.indexOf(a.identifier) >
                                                permissionsOrder.indexOf(b.identifier)
                                                    ? 1
                                                    : -1
                                            )
                                            .map((t) => t.type)
                                            .join('; ');
                                        return {
                                            key: `${g.name}-${m.id}-${ra.role.id}-${u2.securable?.typeId}-${u2.isActive}${u2.approvalRequired}`,
                                            name: g.name,
                                            member: m.displayName,
                                            jobTitle: m.jobTitle,
                                            role: ra.role.name,
                                            approvalRequired: u2.approvalRequired
                                                ? StatusOption.Pending
                                                : u2.isActive
                                                ? StatusOption.Active
                                                : StatusOption.Inactive,
                                            userId: u2.userId,
                                            memberId: m.id,
                                            permission: permissions,
                                        };
                                    });
                            });
                    });
                });
            })
            .flat(recursionDepth) ?? [];
    return results as GroupTableRow[];
};
