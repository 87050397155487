import React from 'react';
import { InfoIcon } from '../infoIcon';
import { TooltipWrapper } from '../tooltipWrapper';
import styles from './FieldInfoLabel.module.scss';

interface FieldInfoLabelProps {
    label: string;
    showIcon?: boolean;
    tooltipContent: string | React.ReactNode;
}

export const FieldInfoLabel: React.FC<FieldInfoLabelProps> = ({
    label,
    showIcon,
    tooltipContent,
}) => {
    return (
        <div className={styles.applicationFormName}>
            {label}
            {(showIcon === undefined || showIcon) && (
                <TooltipWrapper
                    id={label.toLowerCase().replace(' ', '-')}
                    placement="top"
                    tooltipContent={tooltipContent}>
                    <InfoIcon className={styles.infoIcon} />
                </TooltipWrapper>
            )}
        </div>
    );
};
