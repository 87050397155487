import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { getQueryResult } from './graphQlClient';
import { Person } from './models';

const query = gql`
    query getPerson($id: ID!) {
        person(id: $id) {
            id
            displayName
            photo
        }
    }
`;

export const usePerson = (id?: string) => {
    return useQuery(
        ['person', id],
        async () => {
            const result = await getQueryResult<{
                person: Person;
            }>(query, {
                id,
            });
            return result?.person;
        },
        {
            enabled: !!id,
        }
    );
};
