import { Button, ColumnDefinition } from '@laingorourke/core-web-components';
import { fetchActiveTourSteps, reactivateTourStep } from 'domain/store/reducers/tourSteps';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import { TableCellActive } from 'views/components/table';
import ActionButtons from './components/ActionButtons';
import styles from './TableColumns.module.scss';
import { TourStepTableRow } from './TourStepTableRow';

const actionsFormatter = (value: any, row: TourStepTableRow) => <ActionButtons tourStep={row} />;

const actionsColumn: ColumnDefinition<TourStepTableRow> = {
    field: (d) => d.id,
    text: '',
    renderer: actionsFormatter,
    headerClass: styles.minWidth150,
};

export const getTableColumns = (showActions: boolean): ColumnDefinition<TourStepTableRow>[] => {
    const dispatch = useAppDispatch();
    return [
        {
            field: (d) => d.title,
            text: 'Title',
            sortable: true,
        },
        {
            field: (d) => d.target,
            text: 'Target',
        },
        {
            field: (d) => d.path,
            text: 'Path',
        },
        {
            field: (d) => d.sortOrder,
            text: 'Sort Order',
            sortable: true,
            key: nameof<TourStepTableRow>('sortOrder'),
        },
        {
            field: (d) => d.placement,
            text: 'Placement',
            sortable: true,
        },
        {
            field: (d) => d.isActive,
            text: 'Status',
            renderer: (_: any, row: TourStepTableRow) => (
                <div className={styles['status-cell']}>
                    <TableCellActive isActive={row.isActive} className={styles.status} />
                    {!row.isActive && (
                        <Button
                            size="sm"
                            onClick={() => {
                                dispatch(
                                    reactivateTourStep({
                                        id: row.id,
                                    })
                                );
                                dispatch(fetchActiveTourSteps());
                            }}>
                            Activate
                        </Button>
                    )}
                </div>
            ),
        },
        {
            ...actionsColumn,
            hidden: !showActions,
        },
    ];
};
