import { faMagnifyingGlassPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDefinition } from '@laingorourke/core-web-components';
import styles from './ItemDetailsTableCell.module.scss';

export interface ItemDetailsTableRow {
    detailAccessLocked: boolean;
    id: string;
    projectNumber: string;
}

export const getItemDetailsTableCell = (
    onPreviewClicked: (itemId: string, projectNumber: string) => string
): ColumnDefinition<ItemDetailsTableRow> => ({
    field: (d) => d.id,
    text: '',
    cellClass: styles.cell,
    renderer: (_: any, row: ItemDetailsTableRow) =>
        row.detailAccessLocked ? (
            <></>
        ) : (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    onPreviewClicked(row.id, row.projectNumber);
                }}>
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} size="lg" />
            </div>
        ),
});
