import { createAsyncThunk } from '@reduxjs/toolkit';
import { ItemType, ItemTypeMetadata, ProjectStatuses } from 'domain/models/api-models';
import { get } from 'domain/services/client';
import { RootState } from 'domain/store/rootStore';

export const fetchItemTypes = createAsyncThunk<ItemType[], void, { state: RootState }>(
    'itemtypes/get',
    async () => (await get('itemtypes'))!,
    {
        condition: (_, { getState }) => {
            const { itemTypes } = getState();
            return itemTypes.itemTypes.length === 0;
        },
    }
);

export const fetchMetadata = createAsyncThunk<
    ItemTypeMetadata,
    { itemTypeId: string },
    { state: RootState }
>(
    'itemtypes/{id}/metadata/get',
    async (args) => (await get(`itemtypes/${args.itemTypeId}/metadata`))!,
    {
        condition: (args) => !!args.itemTypeId,
    }
);

export const fetchProjectStatuses = createAsyncThunk<
    ProjectStatuses,
    { itemTypeId: string },
    { state: RootState }
>(
    'itemtypes/{id}/statuses/get',
    async (args) => (await get(`itemtypes/${args.itemTypeId}/statuses`))!,
    {
        condition: (args) => !!args.itemTypeId,
    }
);
