import React, { PropsWithChildren } from 'react';
import { InputGroup } from 'react-bootstrap';
import styles from './daysControl.module.scss';

export const DaysControl: React.FC<PropsWithChildren<{ count?: number; dayPreffix?: string }>> = ({
    count,
    dayPreffix,
    children,
}) => (
    <InputGroup>
        {children}
        <InputGroup.Text className={styles.frequencyText}>
            {count} {dayPreffix}day{count === 1 ? '' : 's'}
        </InputGroup.Text>
    </InputGroup>
);

DaysControl.displayName = 'DaysControl';
