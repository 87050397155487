import { Table } from '@laingorourke/core-web-components';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useProjectId } from 'domain/store/reducers/projects';
import { fetchReviews, useReviews } from 'domain/store/reducers/reviews';
import { useCurrentUser, useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import { useWithPerson } from 'hooks/useWithPerson';
import React, { PropsWithChildren, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { ProjectParams } from '../Project';
import { ReviewTableRow } from './ReviewTableRow';
import AddReview from './components/AddReview';
import { getTableColumns } from './tableColumns';

export const Reviews: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ProjectGovernanceRead}>
        <ReviewsContent>{children}</ReviewsContent>
    </SecuredContent>
);

const ReviewsContent: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const currentUser = useCurrentUser();
    const { projectNumber } = useParams<ProjectParams>();
    const currentUserHasManageReviewsWritePermission = useHasPermissions(
        Permission.ProjectGovernanceWrite,
        projectNumber
    );
    const currentUserHasManageReviewsDeletePermission = useHasPermissions(
        Permission.ProjectGovernanceDelete,
        projectNumber
    );

    const itemTypeId = useSelectedItemTypeId();
    const reviewsData = useReviews();
    const reviews = useWithPerson('reviewer', 'reviewerId', reviewsData) as ReviewTableRow[];
    const projectId = useProjectId(projectNumber);

    const dataLoaded = !!reviews;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Reviews found');
    const columns = getTableColumns(
        currentUserHasManageReviewsWritePermission &&
        !!reviews?.find((r) => r.reviewerId === currentUser.id),
        currentUserHasManageReviewsDeletePermission &&
        !!reviews?.find((r) => r.reviewerId === currentUser.id),
        projectId
    );

    useEffect(() => {
        dispatch(fetchReviews({ projectNumber, itemTypeId }));
    }, [projectNumber, itemTypeId, dispatch]);

    return (
        <>
            {currentUserHasManageReviewsWritePermission && <AddReview />}
            {dataLoaded && children}
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(columns, nameof<ReviewTableRow>('date'), 'desc')}
                {...tableLoadingProps}
                data={dataLoaded ? reviews! : []}
                keySelector={(d) => d.id}
                columns={columns}
                saveSelectedPageSize
                cacheKey="reviews"
                hover
            />
        </>
    );
};
