import {
    emojis,
    emojiValidationMessage,
    invalidCharacters,
} from 'views/components/form/validators';
import * as Yup from 'yup';

export const DesignerSchema = Yup.object().shape({
    name: Yup.string()
        .matches(emojis, emojiValidationMessage)
        .matches(invalidCharacters, 'Name contains invalid characters')
        .max(100, 'Name cannot be longer than 100 characters')
        .required('Name is required'),
});
