import { AuthorisedContent } from '@laingorourke/core-web-components';
import { useCurrentUserHasProjectPermissions } from 'domain/store/reducers/users';
import React, { PropsWithChildren } from 'react';
import { useParams } from 'react-router';

export const ProjectSecuredContent: React.FC<PropsWithChildren> = ({ children }) => {
    const { projectNumber } = useParams<{ projectNumber: string }>();
    const currentUserHasProjectSecurable = useCurrentUserHasProjectPermissions(projectNumber);
    return (
        <AuthorisedContent authorised={currentUserHasProjectSecurable}>
            {children}
        </AuthorisedContent>
    );
};
