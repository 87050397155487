import { ApplicationProject } from 'domain/models/ApplicationProject';

export const getApplicationProjectName = (
    applicationProjectId?: number,
    applicationProjects?: ApplicationProject[]
) => {
    return !!applicationProjectId
        ? applicationProjects?.find(
              (ap) => ap.applicationProjectId === applicationProjectId.toString()
          )?.applicationProjectName
        : '';
};
