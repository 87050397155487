import { Project } from 'domain/models/Project';
import { useProjects } from 'domain/store/reducers/projects';
import React from 'react';
import styles from './ProjectDisplayName.module.scss';

const AllProjects = 'All';

interface ProjectDisplayNameProps {
    projectNumber: string | undefined;
}

export const ProjectDisplayName: React.FC<ProjectDisplayNameProps> = ({ projectNumber }) => {
    const projects = useProjects();

    if (!projectNumber || projectNumber === '') return <>{AllProjects}</>;
    const project = projects?.find((p) => p.projectNumber === projectNumber);

    return (
        <>
            {!!project && project.displayName}
            {!project && (
                <div className={styles['not-found']}>
                    <span>Project not found</span> ({projectNumber})
                </div>
            )}
        </>
    );
};

// Even though we have a nice React Component above, we still need this. This is used to create a table row prop that will help with sorting
// Without it we would be sorting by the cell source, so most likely project number
// Table rows get a new prop and the value returned by this helper is assigned to it, then the sorting and filtering works automagically
export const getProjectDisplayName = (projectNumber: string | undefined, projects: Project[]) => {
    if (!projectNumber || projectNumber === '') return AllProjects;
    const project = projects?.find((p) => p.projectNumber === projectNumber);
    return project?.displayName ?? `Project Not Found (${projectNumber})`;
};
