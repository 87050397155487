import React, { PropsWithChildren } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import styles from './ButtonOverlayTrigger.module.scss';

const delay = { show: 250, hide: 400 };

export const ButtonOverlayTrigger: React.FC<
    PropsWithChildren<{
        message: string | undefined;
        key: string;
        placement?: Placement;
    }>
> = ({ message, key, placement, children }) => {
    return (
        <OverlayTrigger
            placement={placement || 'top'}
            key={key}
            overlay={
                <Popover id={`popover-${key}`}>
                    <Popover.Body className={styles.popover}>{message}</Popover.Body>
                </Popover>
            }
            delay={delay}>
            <div>{children}</div>
        </OverlayTrigger>
    );
};
