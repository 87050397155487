import { ColumnDefinition, textFilter } from '@laingorourke/core-web-components';
import format from 'date-fns/format';
import { nameof } from 'helpers/nameOf';
import { ProjectDisplayName } from 'views/components';
import { getPersonColumn } from 'views/components/table/personColumn';
import { TransferTableRow } from './TransferTableRow';

export const tableColumns: ColumnDefinition<TransferTableRow>[] = [
    {
        field: (d) => d.sourceProjectName,
        text: 'From Project',
        sortable: true,
        filter: textFilter(),
        renderer: (_, row: TransferTableRow) => (
            <ProjectDisplayName projectNumber={row.sourceProjectNumber} />
        ),
    },
    {
        field: (d) => d.targetProjectName,
        text: 'To Project',
        sortable: true,
        filter: textFilter(),
        renderer: (_, row: TransferTableRow) => (
            <ProjectDisplayName projectNumber={row.targetProjectNumber} />
        ),
    },
    {
        field: (d) => d.description,
        text: 'Administration copied',
    },
    {
        field: (d) => d.createdById,
        text: 'Owner',
        ...getPersonColumn('createdBy', true, true),
    },
    {
        field: (d) => d.createdOn,
        text: 'Date Copied',
        sortable: true,
        key: nameof<TransferTableRow>('createdOn'),
        renderer: (_, row) => format(new Date(row.createdOn), 'dd MMM yyyy'),
    },
];
