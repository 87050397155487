import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersonGroups } from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';

const initialState = {
    peopleWithSupplyChainGroups: [] as PersonGroups[],
};

const peopleSlice = createSlice({
    name: 'people',
    initialState: initialState,
    reducers: {
        addPeopleWithSupplyChainGroups(state, action: PayloadAction<{ personGroups: PersonGroups }>) {
            if (!state.peopleWithSupplyChainGroups.some(p => p.id === action.payload.personGroups.id))
                state.peopleWithSupplyChainGroups.push(action.payload.personGroups);
        }
    },
});


export const usePeopleGroups = () => useSelector((state: RootState) => {
    return state.people.peopleWithSupplyChainGroups;
});

export const { addPeopleWithSupplyChainGroups } = peopleSlice.actions;

export default peopleSlice.reducer;
