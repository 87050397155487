import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    AssignedOwner,
    AssignedStatus,
    History,
    PersonGroups,
    ProjectClassificationGuidelines,
    ProjectControlMeasure,
    ProjectItem,
    ProjectItemCategoryInspection,
    ProjectItemDetails,
    ProjectItemDetailsSummary,
    ProjectQrCodeExample,
} from 'domain/models/api-models';
import { Project } from 'domain/models/Project';
import { notAuthorisedMessage } from 'domain/services/client';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootStore';
import {
    addFavouriteProject,
    assignOwner,
    changeStatus,
    cloneItem,
    createItem,
    deleteLogo,
    fetchFavouriteProjects,
    fetchProjectComplianceTemplates,
    fetchProjectControlMeasures,
    fetchProjectItemCategoryInspections,
    fetchProjectItemDetails,
    fetchProjectItemDetailsHistory,
    fetchProjectItemDetailsSummary,
    fetchProjectItems,
    fetchProjects,
    fetchQrCodeExample,
    ownerSelected,
    removeFavouriteProject,
    updateItem,
    uploadLogo,
} from './actions';

const initialState = {
    projects: null as unknown as Project[],
    classificationGuidelines: undefined as unknown as ProjectClassificationGuidelines,
    controlMeasures: null as unknown as ProjectControlMeasure[],
    peopleDetailsWithGroups: [] as PersonGroups[],
    selectedProject: {
        items: undefined as unknown as ProjectItem[],
        selectedItem: null as unknown as ProjectItemDetails,
        selectedItemSummary: null as unknown as ProjectItemDetailsSummary,
        selectedItemHistory: null as unknown as History,
        projectItemCategoryInspections: null as unknown as ProjectItemCategoryInspection[],
        selectedItemLocked: false,
        selectedItemOwnerSummaryWithSupplyChainGroups: undefined as unknown as PersonGroups,
    },
    qrCodeExample: undefined as unknown as ProjectQrCodeExample,
};

const projectsSlice = createSlice({
    name: 'projects',
    initialState: initialState,
    reducers: {
        clearProjectItems(state) {
            return { ...initialState, projects: state.projects };
        },
        addProjects(state, action: PayloadAction<Project[]>) {
            state.projects = action.payload.map((p) => ({
                ...p,
                displayName: `${p.name} (${p.projectNumber})`,
            }));
        },
        clearItemDetails(state) {
            return {
                ...state,
                selectedProject: {
                    ...state.selectedProject,
                    selectedItem: initialState.selectedProject.selectedItem,
                    selectedItemSummary: initialState.selectedProject.selectedItemSummary,
                    selectedItemHistory: initialState.selectedProject.selectedItemHistory,
                    projectItemCategoryInspections:
                        initialState.selectedProject.projectItemCategoryInspections,
                    selectedItemOwnerSummaryWithSupplyChainGroups:
                        initialState.selectedProject.selectedItemOwnerSummaryWithSupplyChainGroups,
                },
            };
        },
        clearItemFormState(state) {
            return {
                ...state,
                selectedProject: {
                    ...state.selectedProject,
                    selectedItemOwnerSummaryWithSupplyChainGroups:
                        initialState.selectedProject.selectedItemOwnerSummaryWithSupplyChainGroups,
                },
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProjects.fulfilled.type, (state, action: PayloadAction<Project[]>) => {
            state.projects.push(...action.payload);
        });
        builder.addCase(
            fetchFavouriteProjects.fulfilled.type,
            (state, action: PayloadAction<string[]>) => {
                state.projects = state.projects.map((p) => ({
                    ...p,
                    isFavourite: action.payload.indexOf(p.projectNumber) > -1,
                }));
            }
        );
        builder.addCase(
            addFavouriteProject.fulfilled.type,
            (state, action: PayloadAction<string>) => {
                state.projects = state.projects.map((p) =>
                    p.projectNumber === action.payload ? { ...p, isFavourite: true } : p
                );
            }
        );
        builder.addCase(
            removeFavouriteProject.fulfilled.type,
            (state, action: PayloadAction<string>) => {
                state.projects = state.projects.map((p) =>
                    p.projectNumber === action.payload ? { ...p, isFavourite: false } : p
                );
            }
        );
        builder.addCase(
            fetchProjectComplianceTemplates.fulfilled.type,
            (state, action: PayloadAction<ProjectClassificationGuidelines>) => {
                state.classificationGuidelines = action.payload;
            }
        );
        builder.addCase(
            fetchProjectControlMeasures.fulfilled.type,
            (state, action: PayloadAction<ProjectControlMeasure[]>) => {
                state.controlMeasures = action.payload;
            }
        );
        builder.addCase(
            fetchProjectItems.fulfilled.type,
            (state, action: PayloadAction<ProjectItem[]>) => {
                state.selectedProject.items = action.payload;
            }
        );
        builder.addCase(createItem.fulfilled.type, (state, action: PayloadAction<ProjectItem>) => {
            if (action.payload === undefined) return;
            state.selectedProject.items.push(action.payload);
        });
        builder.addCase(
            updateItem.fulfilled.type,
            (state, action: PayloadAction<ProjectItemDetails>) => {
                if (action.payload === undefined) return;
                if (state.selectedProject.items)
                    state.selectedProject.items = state.selectedProject.items.map((c) =>
                        c.id === action.payload.id
                            ? {
                                id: action.payload.id,
                                name: action.payload.name,
                                number: action.payload.number,
                                classificationId: action.payload.classificationId,
                                area: action.payload.areaName,
                                subArea: action.payload.subAreaName,
                                dateInService: action.payload.dateInService,
                                isDateInServiceFromP6: action.payload.isDateInServiceFromP6,
                                ownerId: action.payload.ownerId,
                                statusId: action.payload.statusId,
                                canBeModified: action.payload.canBeModified,
                                applyProjectComplianceTemplate:
                                    action.payload.applyProjectComplianceTemplate,
                                myTasksOperationsBlocked: action.payload.myTasksOperationsBlocked,
                                detailAccessLocked: action.payload.detailAccessLocked,
                                siteId: action.payload.siteId,
                            }
                            : c
                    );
                if (state.selectedProject.selectedItem !== null)
                    state.selectedProject.selectedItem = action.payload;
            }
        );
        builder.addCase(
            cloneItem.fulfilled.type,
            (state, action: PayloadAction<ProjectItemDetails>) => {
                if (action.payload === undefined) return;
                state.selectedProject.selectedItem = action.payload;
                state.selectedProject.items.push({
                    id: action.payload.id,
                    name: action.payload.name,
                    number: action.payload.number,
                    classificationId: action.payload.classificationId,
                    area: action.payload.areaName,
                    subArea: action.payload.subAreaName,
                    dateInService: action.payload.dateInService,
                    isDateInServiceFromP6: action.payload.isDateInServiceFromP6,
                    ownerId: action.payload.ownerId,
                    statusId: action.payload.statusId,
                    canBeModified: action.payload.canBeModified,
                    applyProjectComplianceTemplate: action.payload.applyProjectComplianceTemplate,
                    myTasksOperationsBlocked: action.payload.myTasksOperationsBlocked,
                    detailAccessLocked: action.payload.detailAccessLocked,
                    siteId: action.payload.siteId,
                });
            }
        );
        builder.addCase(
            fetchProjectItemDetails.fulfilled.type,
            (state, action: PayloadAction<ProjectItemDetails>) => {
                state.selectedProject.selectedItem = action.payload;
            }
        );
        builder.addCase(fetchProjectItemDetails.rejected.type, (state, action: any) => {
            state.selectedProject.selectedItemLocked =
                action.error?.message === notAuthorisedMessage;
        });
        builder.addCase(
            fetchProjectItemDetailsSummary.fulfilled.type,
            (state, action: PayloadAction<ProjectItemDetailsSummary>) => {
                state.selectedProject.selectedItemSummary = action.payload;
                state.selectedProject.selectedItemLocked = action.payload.detailAccessLocked;
            }
        );
        builder.addCase(
            fetchProjectItemDetailsHistory.fulfilled.type,
            (state, action: PayloadAction<History>) => {
                state.selectedProject.selectedItemHistory = action.payload;
            }
        );
        builder.addCase(fetchProjectItemDetailsHistory.rejected.type, (state, action: any) => {
            state.selectedProject.selectedItemLocked =
                action.error?.message === notAuthorisedMessage;
        });
        builder.addCase(
            assignOwner.fulfilled.type,
            (state, action: PayloadAction<AssignedOwner[]>) => {
                if (action.payload === undefined) return;
                state.selectedProject.items = state.selectedProject.items.map((i) => ({
                    ...i,
                    ownerId: action.payload.find((a) => a.projectItemId === i.id)
                        ? action.payload.find((a) => a.projectItemId === i.id)?.ownerId
                        : i.ownerId,
                }));
            }
        );
        builder.addCase(
            changeStatus.fulfilled.type,
            (state, action: PayloadAction<AssignedStatus[]>) => {
                if (action.payload === undefined) return;
                state.selectedProject.items = state.selectedProject.items.map((i) => ({
                    ...i,
                    statusId: action.payload.find((a) => a.projectItemId === i.id)
                        ? action.payload.find((a) => a.projectItemId === i.id)?.statusId!
                        : i.statusId,
                    canBeModified: (action.payload.find((a) => a.projectItemId === i.id)
                        ? action.payload.find((a) => a.projectItemId === i.id)?.canBeModified
                        : i.canBeModified)!,
                }));
            }
        );
        builder.addCase(
            fetchProjectItemCategoryInspections.fulfilled.type,
            (state, action: PayloadAction<ProjectItemCategoryInspection[]>) => {
                state.selectedProject.projectItemCategoryInspections = action.payload;
            }
        );
        builder.addCase(
            ownerSelected.fulfilled.type,
            (state, action: PayloadAction<PersonGroups | undefined>) => {
                if (action.payload === undefined) return;
                state.selectedProject.selectedItemOwnerSummaryWithSupplyChainGroups =
                    action.payload!;
            }
        );
        builder.addCase(
            fetchQrCodeExample.fulfilled.type,
            (
                state,
                action: PayloadAction<{ qrCodeExample: ProjectQrCodeExample; refresh: boolean }>
            ) => {
                if (action.payload.refresh) state.qrCodeExample = action.payload.qrCodeExample;
            }
        );
        builder.addCase(uploadLogo.fulfilled.type, (state, _: PayloadAction) => {
            state.qrCodeExample = initialState.qrCodeExample;
        });
        builder.addCase(deleteLogo.fulfilled.type, (state, _: PayloadAction) => {
            state.qrCodeExample = initialState.qrCodeExample;
        });
    },
});

const selectProjects = (state: RootState) => state.projects.projects;

const selectFavoriteProjects = createSelector(
    selectProjects,
    (projects) => projects?.filter((p) => p.isFavourite).sort((a, b) => a.name.localeCompare(b.name))
);

export const useFavouriteProjects = () => useSelector(selectFavoriteProjects);

const selectProjectsSorted = createSelector(
    selectProjects,
    projects => projects?.slice().sort((a, b) => a.name.localeCompare(b.name))
);

export const useProjects = () => useSelector(selectProjectsSorted);

export const useProject = (projectNumber?: string) =>
    useSelector((state: RootState) =>
        state.projects.projects?.find((p) => p.projectNumber === projectNumber)
    );
export const useProjectId = (projectNumber: string) =>
    useSelector(
        (state: RootState) =>
            state.projects.projects?.find((p) => p.projectNumber === projectNumber)?.id
    );
export const useProjectClassificationGuidelines = () =>
    useSelector((state: RootState) => state.projects.classificationGuidelines);
export const useProjectItems = () =>
    useSelector((state: RootState) => state.projects.selectedProject.items);
export const useItemDetails = () =>
    useSelector((state: RootState) => state.projects.selectedProject.selectedItem);
export const useItemDetailsSummary = () =>
    useSelector((state: RootState) => state.projects.selectedProject.selectedItemSummary);
export const useItemDetailsHistory = () =>
    useSelector((state: RootState) => state.projects.selectedProject.selectedItemHistory);
export const useItemDetailsIsLocked = () =>
    useSelector((state: RootState) => state.projects.selectedProject.selectedItemLocked);
export const useProjectControlMeasures = () =>
    useSelector((state: RootState) => state.projects.controlMeasures);
export const useProjectItemCategoryInspections = () =>
    useSelector(
        (state: RootState) => state.projects.selectedProject.projectItemCategoryInspections
    );
export const useSelectedItemOwnerSummaryWithSupplyChainGroups = () =>
    useSelector(
        (state: RootState) =>
            state.projects.selectedProject.selectedItemOwnerSummaryWithSupplyChainGroups
    );
export const useNumberOfItemsWithLockedAccess = () =>
    useSelector(
        (state: RootState) =>
            state.projects.selectedProject.items?.filter((i) => i.detailAccessLocked)?.length ?? 0
    );
export const useNumberOfNonModifiableItems = () =>
    useSelector(
        (state: RootState) =>
            state.projects.selectedProject.items?.filter((i) => !i.canBeModified)?.length ?? 0
    );
export const useNumberOfItemsWithBlockedMyTasksOperations = () =>
    useSelector(
        (state: RootState) =>
            state.projects.selectedProject.items?.filter((i) => i.myTasksOperationsBlocked)
                ?.length ?? 0
    );
export const useQrCodeExample = () =>
    useSelector((state: RootState) => state.projects.qrCodeExample);
export const { clearProjectItems, addProjects, clearItemDetails, clearItemFormState } =
    projectsSlice.actions;
export default projectsSlice.reducer;
