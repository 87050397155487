import { generatePath } from '@laingorourke/core-web-components';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { deleteLogo } from 'domain/store/reducers/projects/actions/qrCode';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ModalFooter, SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { ProjectParams } from '../../Project';

export const DeleteLogo: React.FC = () => {
    const dispatch = useAppDispatch();

    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();
    const history = useHistory();
    const itemTypeId = useSelectedItemTypeId();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async () => {
        setIsSubmitting(true);
        const result = await dispatch(deleteLogo({ projectNumber, itemTypeId }));
        if ((result as any).payload) {
            goToQrCode();
        }
        setIsSubmitting(false);
    };

    const goToQrCode = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.projectSetup.routes!.qrCode.path, {
                projectNumber,
                itemTypeCode,
            })
        );

    return (
        <Modal show={true} onHide={goToQrCode} animation={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Delete Logo</Modal.Title>
            </Modal.Header>
            <SecuredContent permissions={Permission.ProjectQrCodeDelete}>
                <Modal.Body>Are you sure you want to remove the custom logo?</Modal.Body>
            </SecuredContent>
            <ModalFooter
                submitText="Delete"
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                onCancel={goToQrCode}
            />
        </Modal>
    );
};
