import { Table } from '@laingorourke/core-web-components';
import { ComplySystemId } from 'domain/services/dataService/constants';
import { useGroupsByIdsWithMembersAndPermissions } from 'domain/services/dataService/useGroupsByIdsWithMembersAndPermissions';
import React, { PropsWithChildren } from 'react';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { getTableColumns } from './tableColumns';
import { toFlatData } from './toFlatData';

export const GroupsDataTable: React.FC<PropsWithChildren<GroupsDataTableContentProps>> = ({
    children,
    groupIds,
    projectNumber,
}) => (
    <SecuredContent permissions={Permission.ManageItemsRead}>
        <GroupsDataTableContent groupIds={groupIds} projectNumber={projectNumber}>
            {children}
        </GroupsDataTableContent>
    </SecuredContent>
);

interface GroupsDataTableContentProps {
    groupIds?: string[];
    projectNumber: string;
}

export const GroupsDataTableContent: React.FC<PropsWithChildren<GroupsDataTableContentProps>> = ({
    children,
    groupIds,
    projectNumber,
}) => {
    const columns = getTableColumns();
    const groups = useGroupsByIdsWithMembersAndPermissions(groupIds, ComplySystemId);
    const data = toFlatData(projectNumber, groups?.data);

    const dataLoaded = (groupIds?.length === 0 || groups.isSuccess) && !!data;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Subcontractors found');

    return (
        <>
            {children}
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(columns)}
                {...tableLoadingProps}
                data={dataLoaded ? data : []}
                keySelector={(d) => d.key}
                columns={columns}
                saveSelectedPageSize
                cacheKey="groups"
                hover
            />
        </>
    );
};
