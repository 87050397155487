import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { getAuth0Configuration } from 'helpers/applicationConfig';
import { History } from 'history';
import React, { PropsWithChildren } from 'react';

const Auth0ProviderWithHistory: React.FC<PropsWithChildren<{ history: History }>> = ({
    history,
    children,
}) => {
    const onRedirectCallback = (appState?: AppState) => {
        // Use the router's history module to replace the url
        if (history) history.push(appState?.returnTo || window.location.pathname);
    };
    const auth0Config = getAuth0Configuration()!;

    return (
        <Auth0Provider
            domain={auth0Config.domain!}
            clientId={auth0Config.clientId!}
            audience={auth0Config.audience}
            redirectUri={window.location.origin + '/callback'}
            onRedirectCallback={onRedirectCallback}
            cacheLocation="localstorage">
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
