import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationProject, Applications } from 'domain/models/ApplicationProject';
import { getApplicationProjects } from 'domain/services/dataService/getApplicationProjects';
import { RootState } from '../../rootStore';

export const fetchAsiteProjects = createAsyncThunk<
    ApplicationProject[],
    undefined,
    { state: RootState }
>(
    'applicationProject/asite/get',
    async () => {
        return await getApplicationProjects(Applications.Asite);
    },
    {
        condition: (args, { getState }) => !getState().applicationProjects.asiteProjects,
    }
);

export const fetchFieldViewProjects = createAsyncThunk<
    ApplicationProject[],
    undefined,
    { state: RootState }
>(
    'applicationProject/fieldview/get',
    async () => {
        return await getApplicationProjects(Applications.FieldView);
    },
    {
        condition: (args, { getState }) => !getState().applicationProjects.fieldViewProjects,
    }
);
