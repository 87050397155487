import { useItemStatuses } from 'domain/store/reducers/itemType';
import { useProjectControlMeasures } from 'domain/store/reducers/projects';
import React from 'react';
import { ProjectItemTableRow } from '../ProjectItemTableRow';
import { MissingDesignsIndicator } from './MissingDesignsIndicator';

export const StatusColumn: React.FC<ProjectItemTableRow> = (projectItem) => {
    const statuses = useItemStatuses();
    const status = statuses?.find((s) => s.id === projectItem.statusId);
    const projectControlMeasures = useProjectControlMeasures();
    const canShowDesignsMissing =
        !!status && !status!.statusRegressionEnabled && !!projectControlMeasures;
    return (
        <>
            <span>{status?.name}</span>
            {canShowDesignsMissing && (
                <MissingDesignsIndicator
                    projectItem={projectItem}
                    statuses={statuses!}
                    projectControlMeasures={projectControlMeasures}
                />
            )}
        </>
    );
};
