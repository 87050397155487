import { ColumnDefinition, selectFilter } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { ItemTypeCategory } from 'domain/models/api-models';
import styles from './CategoriesColumn.module.scss';

export const getCategoriesColumn = (
    inspectionTypesTableData: any[] | undefined,
    categoryOptions: OptionTypeBase[],
    categories: ItemTypeCategory[] | undefined,
    propertyName: string
): ColumnDefinition<any> => {
    return {
        field: (d) => d[propertyName],
        text: 'Category',
        filter: selectFilter({
            options: categoryOptions,
            filterField: (row) => row[propertyName],
        }),
        key: 'categoryId',
        headerClass: styles.minWidth150,
        renderer: (_, row) => (
            <>
                {categories
                    ?.filter((c) => row[propertyName]!.indexOf(c.id) > -1)
                    ?.map((c) => (
                        <span key={c.id} className={styles.categoryEntry}>
                            {c.name}
                        </span>
                    ))}
            </>
        ),
    };
};
