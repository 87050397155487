import { youAreOfflineFeatureNotAvailableMessage } from '@laingorourke/core-web-components';
import { StatusType, TASKKEY, useTasksSummaries } from '@laingorourke/core-web-mytasksreactsdk';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { usePerson } from 'domain/services/dataService';
import { Person } from 'domain/services/dataService/models';
import { updateProjectItemControlMeasureAssignee } from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import { useIsOffline } from 'hooks/useIsOffline';
import { default as React, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
    ModalFooter,
    PersonTableCell,
    ProjectPersonSelector,
    TooltipWrapper,
} from 'views/components';
import styles from './ControlMeasureAssignee.module.scss';

interface ControlMeasureAssigneeProps {
    projectNumber: string;
    projectItemId: string;
    nonEditableStatusesMessage: string;
    readonly: boolean;
    canUpdateControlMeasures: boolean;
    myTasksOperationsBlocked: boolean;
    currentOwnerId?: string;
    className: string;
}

export const ControlMeasureAssignee: React.FC<ControlMeasureAssigneeProps> = ({
    projectNumber,
    projectItemId,
    nonEditableStatusesMessage,
    myTasksOperationsBlocked,
    canUpdateControlMeasures,
    readonly,
    currentOwnerId,
    className
}) => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const isOffline = useIsOffline();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedAssigneeId, setSelectedAssigneeId] = useState<string | undefined>(undefined);
    const [updatingAssigneeInProgress, setUpdatingAssigneeInProgress] = useState(false);

    const query = {
        filter: {
            and: [
                {
                    meta: {
                        any: { label: 'ProjectItemId', value: projectItemId },
                    },
                },
                {
                    meta: {
                        any: { label: 'ControlMeasureId' },
                    },
                },
                {
                    or: [
                        { 'status/type': StatusType.ToDo },
                        { 'status/type': StatusType.InProgress },
                    ],
                },
            ],
        },
    };
    const tasks = useTasksSummaries(query);
    const tasksToBeUpdated = tasks.data?.filter(
        (t) =>
            (t.status.type === StatusType.ToDo || t.status.type === StatusType.InProgress) &&
            t.assignedUserId === currentOwnerId
    );
    const tasksToUpdateExists = !!tasksToBeUpdated?.length;
    const newAssignee = usePerson(selectedAssigneeId);

    let timer: NodeJS.Timeout;
    const updateControlMeasuresAssignee = () => {
        setUpdatingAssigneeInProgress(true);
        dispatch(
            updateProjectItemControlMeasureAssignee({
                projectNumber,
                projectItemId,
                assigneeId: selectedAssigneeId!,
            })
        ).then(() => {
            closeModal();
            queryClient.invalidateQueries(TASKKEY());

            // there is no pulling mechanism that would get refreshed MyTasks tasks. We will wait 5 sec and we will invalidate the query that will refresh results.
            // it it takes more time to service bus messages to process the data, the user has to update the page manually
            const refetchDelay = 5000;
            timer = setTimeout(() => {
                queryClient.invalidateQueries(TASKKEY());
            }, refetchDelay);
        });
    };

    const getTooltipContent = () => {
        if (isOffline) {
            return youAreOfflineFeatureNotAvailableMessage;
        }
        if (!canUpdateControlMeasures)
            return `Control Measure can not be updated as the Item is of status ${nonEditableStatusesMessage}`;
        if (myTasksOperationsBlocked) {
            return 'Unable to Add Control Measure until background processing is complete.';
        }
        if (!tasksToUpdateExists) {
            return 'There are no tasks that could be updated';
        }
        return '';
    };

    const closeModal = () => {
        setShowConfirmationModal(false);
        setUpdatingAssigneeInProgress(false);
        setSelectedAssigneeId('');
    };

    useEffect(() => {
        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <>
            {!readonly && (
                <>
                    <TooltipWrapper
                        id="update-control-measure-assignee-dropdown"
                        tooltipContent={getTooltipContent()}
                        show={
                            isOffline ||
                            myTasksOperationsBlocked ||
                            !canUpdateControlMeasures ||
                            !tasksToUpdateExists
                        }>
                        <ProjectPersonSelector
                            projectNumber={projectNumber}
                            placeholder="Select Control Measure Assignee..."
                            onChange={(value) => {
                                setSelectedAssigneeId(value as string | undefined);
                                setShowConfirmationModal(true);
                            }}
                            disabled={
                                isOffline ||
                                myTasksOperationsBlocked ||
                                !canUpdateControlMeasures ||
                                !tasksToUpdateExists
                            }
                            className={cn(styles.assignee, className)}
                            value={selectedAssigneeId}
                        />
                    </TooltipWrapper>
                    <Modal
                        show={showConfirmationModal}
                        onHide={closeModal}
                        animation={false}
                        backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Update Control Measure Assignee</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Please confirm update of all To Do &amp; In Progress Control Measures
                            <ul>
                                {tasksToBeUpdated?.map((t) => (
                                    <li key={t.id}>{t.name}</li>
                                ))}
                            </ul>
                            New assignee:
                            <Assignee person={newAssignee.data} isLoading={newAssignee.isLoading} />
                        </Modal.Body>
                        <ModalFooter
                            submitText="Update"
                            isSubmitting={updatingAssigneeInProgress}
                            onSubmit={updateControlMeasuresAssignee}
                            onCancel={closeModal}
                        />
                    </Modal>
                </>
            )}
        </>
    );
};

const Assignee: React.FC<{ person?: Person; isLoading: boolean }> = ({ person, isLoading }) => {
    return (
        <PersonTableCell
            contact={{
                email: person?.contact?.email ?? '',
            }}
            displayName={person?.displayName ?? ''}
            id={person?.id ?? ''}
            photo={person?.photo ?? ''}
            isLoading={isLoading}
            isNotFound={!person && !person}
        />
    );
};
