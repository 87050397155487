import { faLockKeyhole, faUnlockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Form,
    Input,
    Select,
    TextArea,
    WarningPanel,
    useForm,
} from '@laingorourke/core-web-components';
import { Area } from 'domain/models/api-models';
import { useAsiteProjects, useFieldViewProjects } from 'domain/store/reducers/applicationProject';
import { createArea, updateArea } from 'domain/store/reducers/areas';
import { useSelectedItemTypeOwnerLabel } from 'domain/store/reducers/itemType';
import { useAppDispatch } from 'domain/store/rootStore';
import { useAreaOwners } from 'hooks/useAreaOwners';
import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FieldInfoLabel, ModalFooter, OwnerPersonSelector, TooltipWrapper } from 'views/components';
import { getAreaSchema } from '../schemas';
import styles from './UpsertForm.module.scss';

interface UpsertAreaFormProps {
    isEditMode: boolean;
    isSubArea: boolean;
    area: Area | undefined;
    areas: Area[];
    projectNumber: string;
    itemTypeId: string;
    areaCodeInItemNumber: boolean;
    goToArea: () => void;
}

export const UpsertForm: React.FC<UpsertAreaFormProps> = ({
    isEditMode,
    isSubArea,
    area,
    areas,
    itemTypeId,
    projectNumber,
    areaCodeInItemNumber,
    goToArea,
}) => {
    const dispatch = useAppDispatch();

    const areaOwnersIds = useAreaOwners(projectNumber, area?.ownerId);
    const asiteProjects = useAsiteProjects();
    const fieldViewProjects = useFieldViewProjects();
    const ownerLabel = useSelectedItemTypeOwnerLabel() ?? 'Owner';

    const defaultCommencement = isSubArea ? null : 1;
    const form = useForm({
        initialValues: {
            id: isEditMode ? area!.id! : '',
            parentAreaId: isEditMode
                ? isSubArea
                    ? areas!.find((a) => a.name === area?.name && a.subAreaName?.length === 0)?.id!
                    : ''
                : isSubArea
                    ? ''
                    : '',
            name: isEditMode ? (isSubArea ? area?.subAreaName! : area?.name!) : '',
            description: isEditMode ? area!.description! : '',
            ownerId: isEditMode ? area!.ownerId! : undefined,
            fieldViewProjectId: isEditMode ? area!.fieldViewProjectId?.toString() ?? null : null,
            asiteWorkspaceId: isEditMode ? area!.asiteWorkspaceId?.toString() ?? null : null,
            inheritFieldViewProjectId: isEditMode
                ? area!.inheritFieldViewProjectId ?? null
                : isSubArea
                    ? true
                    : null,
            inheritAsiteWorkspaceId: isEditMode
                ? area!.inheritAsiteWorkspaceId ?? null
                : isSubArea
                    ? true
                    : null,
            itemNumberPrefix: isEditMode ? area!.itemNumberPrefix ?? null : null,
            itemNumberCommencement: isEditMode
                ? area!.itemNumberCommencement ?? defaultCommencement
                : defaultCommencement,
            isSubArea: isSubArea,
        },
        validationSchema: getAreaSchema(isSubArea, areaCodeInItemNumber),
        onSubmit: async (values) => {
            let result;
            if (isEditMode) {
                const {
                    fieldViewProjectId,
                    asiteWorkspaceId,
                    itemNumberPrefix,
                    itemNumberCommencement,
                    ...updateValues
                } = values;
                result = await dispatch(
                    updateArea({
                        ...updateValues,
                        itemTypeId,
                        projectNumber,
                        isSubArea,
                        fieldViewProjectId: !!values.inheritFieldViewProjectId
                            ? undefined
                            : parseInt(values.fieldViewProjectId!),
                        asiteWorkspaceId: !!values.inheritAsiteWorkspaceId
                            ? undefined
                            : parseInt(values.asiteWorkspaceId!),
                        itemNumberPrefix: isSubArea ? undefined : values.itemNumberPrefix,
                        itemNumberCommencement: isSubArea
                            ? undefined
                            : values.itemNumberCommencement,
                    })
                );
            } else {
                const {
                    id,
                    fieldViewProjectId,
                    asiteWorkspaceId,
                    itemNumberPrefix,
                    itemNumberCommencement,
                    ...createValues
                } = values;
                result = await dispatch(
                    createArea({
                        ...createValues,
                        itemTypeId,
                        projectNumber,
                        isSubArea,
                        fieldViewProjectId: !!values.inheritFieldViewProjectId
                            ? undefined
                            : !!values.fieldViewProjectId
                                ? parseInt(values.fieldViewProjectId!, 10)
                                : undefined,
                        asiteWorkspaceId: !!values.inheritAsiteWorkspaceId
                            ? undefined
                            : !!values.asiteWorkspaceId
                                ? parseInt(values.asiteWorkspaceId!, 10)
                                : undefined,
                        itemNumberPrefix: isSubArea ? undefined : values.itemNumberPrefix,
                        itemNumberCommencement: isSubArea
                            ? undefined
                            : values.itemNumberCommencement,
                    })
                );
            }
            if ((result as any).payload) goToArea();
        },
    });

    const { isSubmitting, values, setFieldValue } = form;

    const parentAreaFieldViewProjectId = areas!
        .find((a) => a.id === values.parentAreaId)
        ?.fieldViewProjectId?.toString();
    const parentAreaFieldViewProjectName = fieldViewProjects?.find(
        (p) => p.applicationProjectId === parentAreaFieldViewProjectId
    )?.applicationProjectName;

    const parentAreaAsiteWorkspaceId = areas!
        .find((a) => a.id === values.parentAreaId)
        ?.asiteWorkspaceId?.toString();
    const parentAreaAsiteWorkspaceName = asiteProjects?.find(
        (p) => p.applicationProjectId === parentAreaAsiteWorkspaceId
    )?.applicationProjectName;

    const parentAreaItemNumberPrefix = areas!.find((a) => a.id === values.parentAreaId)
        ?.itemNumberPrefix!;
    const parentAreaItemNumberCommencement = areas!.find((a) => a.id === values.parentAreaId)
        ?.itemNumberCommencement!;

    useEffect(() => {
        if (values.inheritFieldViewProjectId) {
            setFieldValue('fieldViewProjectId', parentAreaFieldViewProjectId ?? '');
        }
    }, [values.parentAreaId, values.inheritFieldViewProjectId]);

    useEffect(() => {
        if (values.inheritAsiteWorkspaceId) {
            setFieldValue('asiteWorkspaceId', parentAreaAsiteWorkspaceId ?? '');
        }
    }, [values.parentAreaId, values.inheritAsiteWorkspaceId]);

    useEffect(() => {
        if (!!values.parentAreaId)
            setFieldValue('itemNumberPrefix', parentAreaItemNumberPrefix ?? '');
    }, [values.parentAreaId]);

    useEffect(() => {
        if (!!values.parentAreaId)
            setFieldValue('itemNumberCommencement', parentAreaItemNumberCommencement ?? '');
    }, [values.parentAreaId]);

    const showOwnerChangeWarning = isEditMode && values.ownerId !== area?.ownerId;
    return (
        <Form form={form}>
            <Modal.Body>
                {isSubArea && (
                    <Form.Row>
                        <Form.Field name="parentAreaId" label="Area">
                            <Select
                                options={areas!}
                                dataShape={{
                                    value: 'id',
                                    label: 'name',
                                }}
                            />
                        </Form.Field>
                    </Form.Row>
                )}
                <Form.Row>
                    <Form.Field name="name" label={`${isSubArea ? 'Sub' : ''} Area`}>
                        <Input />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="description" label="Description">
                        <TextArea />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="ownerId" label={ownerLabel}>
                        <OwnerPersonSelector
                            ownersIds={areaOwnersIds}
                            projectNumber={projectNumber}
                            placeholder={`Select ${ownerLabel}...`}
                            isClearable={isSubArea}
                        />
                    </Form.Field>
                </Form.Row>
                {showOwnerChangeWarning && (
                    <WarningPanel className={styles.warning}>
                        The new item owner will only be applied to items if the item does not have
                        an owner or when the previous area owner and item owner match.
                    </WarningPanel>
                )}
                <Form.Row>
                    <Form.Field
                        name="fieldViewProjectId"
                        label="Field View Project"
                        className={styles['value-link-field']}>
                        <Select
                            options={fieldViewProjects!}
                            disabled={!!values.inheritFieldViewProjectId || !fieldViewProjects}
                            isClearable
                            dataShape={{
                                value: 'applicationProjectId',
                                label: 'applicationProjectName',
                            }}
                        />
                    </Form.Field>
                    {isSubArea && (
                        <TooltipWrapper
                            id="fieldViewProjectId"
                            className={styles.link}
                            placement="top"
                            tooltipContent={
                                !!values.inheritFieldViewProjectId
                                    ? `This is following Field View Project for the Area. To implement a different Field View Project, click the unlock icon.`
                                    : `This subarea has configured it's own Field View Project. The Area has set ${
                                          parentAreaFieldViewProjectName ?? 'no value'
                                      } for Field View. To follow the Area settings, click the lock icon.`
                            }>
                            <Button
                                variant={
                                    !!values.inheritFieldViewProjectId ? 'secondary' : 'primary'
                                }
                                onClick={() => {
                                    setFieldValue(
                                        'inheritFieldViewProjectId',
                                        !values.inheritFieldViewProjectId
                                    );
                                    if (values.inheritFieldViewProjectId) {
                                        setFieldValue('fieldViewProjectId', '');
                                    }
                                }}>
                                <FontAwesomeIcon
                                    icon={
                                        !!values.inheritFieldViewProjectId
                                            ? faUnlockKeyhole
                                            : faLockKeyhole
                                    }
                                    size="lg"
                                />
                            </Button>
                        </TooltipWrapper>
                    )}
                </Form.Row>
                <Form.Row>
                    <Form.Field
                        name="asiteWorkspaceId"
                        label="Asite Workspace"
                        className={styles['value-link-field']}>
                        <Select
                            options={asiteProjects!}
                            disabled={!!values.inheritAsiteWorkspaceId || !asiteProjects}
                            isClearable
                            dataShape={{
                                value: 'applicationProjectId',
                                label: 'applicationProjectName',
                            }}
                        />
                    </Form.Field>
                    {isSubArea && (
                        <TooltipWrapper
                            id="asiteWorkspaceId"
                            className={styles.link}
                            placement="top"
                            tooltipContent={
                                !!values.inheritAsiteWorkspaceId
                                    ? `This is following Asite Workspace for the Area. To implement a different Asite Workspace, click the lock icon.`
                                    : `This subarea has configured it's own Asite Workspace. The Area has set ${
                                          parentAreaAsiteWorkspaceName ?? 'no value'
                                      } for Asite. To follow the Area settings, click the unlock icon.`
                            }>
                            <Button
                                variant={!!values.inheritAsiteWorkspaceId ? 'secondary' : 'primary'}
                                onClick={() => {
                                    setFieldValue(
                                        'inheritAsiteWorkspaceId',
                                        !values.inheritAsiteWorkspaceId
                                    );
                                    if (values.inheritAsiteWorkspaceId) {
                                        setFieldValue('asiteWorkspaceId', '');
                                    }
                                }}>
                                <FontAwesomeIcon
                                    icon={
                                        !!values.inheritAsiteWorkspaceId
                                            ? faUnlockKeyhole
                                            : faLockKeyhole
                                    }
                                    size="lg"
                                />
                            </Button>
                        </TooltipWrapper>
                    )}
                </Form.Row>
                {areaCodeInItemNumber && (
                    <Form.Row className={styles['area-code-row']}>
                        <Form.Field
                            name="itemNumberPrefix"
                            label={
                                <FieldInfoLabel
                                    label="Item Number Prefix"
                                    showIcon={true}
                                    tooltipContent={
                                        <>
                                            An identifier to be included in the Item Number eg an
                                            acronym for the Area.
                                            {area?.hasProjectItems ? (
                                                <>
                                                    {/* Putting HTML to sort out UI concern is not a best practice. It looks like we might have more places that could use tooltip with spacing between messages. A new component will be created for that purpose */}
                                                    <br />
                                                    <br />
                                                    {existingItemsMessage}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    }
                                />
                            }>
                            <Input disabled={isSubArea || area?.hasProjectItems} />
                        </Form.Field>
                        <Form.Field
                            name="itemNumberCommencement"
                            label={
                                <FieldInfoLabel
                                    label="Item Number Commencement"
                                    showIcon={true}
                                    tooltipContent={
                                        <>
                                            Item Numbers for this area will commence from this
                                            number ie if the value is 400 then the first item in
                                            this area will be item number 400.
                                            {area?.hasProjectItems ? (
                                                <>
                                                    {/* Putting HTML to sort out UI concern is not a best practice. It looks like we might have more places that could use tooltip with spacing between messages. A new component will be created for that purpose */}
                                                    <br />
                                                    <br />
                                                    {existingItemsMessage}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    }
                                />
                            }>
                            <Input disabled={isSubArea || area?.hasProjectItems} />
                        </Form.Field>
                    </Form.Row>
                )}
            </Modal.Body>
            <ModalFooter
                submitText={isEditMode ? 'Update' : 'Create'}
                isSubmitting={isSubmitting}
                onCancel={goToArea}
            />
        </Form>
    );
};

const existingItemsMessage =
    'Item Number Prefix cannot be updated when there are existing items assigned to the area.';
