import { PropertyChange, Timeline } from '@laingorourke/core-web-components';
import { CustomPropertyChange } from 'domain/models/api-models';
import { EntityChange } from 'domain/models/EntityChange';
import { usePeople } from 'domain/services/dataService';
import { useSelectedItemTypeOwnerLabel } from 'domain/store/reducers/itemType';
import {
    fetchProjectItemDetailsHistory,
    useItemDetailsHistory,
} from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import { useEntityChangesWithAuthors } from 'hooks/useHistoryWithAuthors';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { OnlineContentLoader } from 'views/components';
import { DefaultItemAreaOwnerLabel } from '../../consts';
import { ProjectItemDetailsPage } from '../ProjectItemDetailsPage';
import { ProjectItemDetailsParams } from '../ProjectItemDetailsParams';

type commonPropertyChangeTypes = (React.ReactNode | CustomPropertyChange | PropertyChange)[];

export const ItemHistory: React.FC = () => {
    const dispatch = useAppDispatch();
    const { projectItemId, projectNumber } = useParams<ProjectItemDetailsParams>();
    const history = useItemDetailsHistory();

    useEffect(() => {
        dispatch(fetchProjectItemDetailsHistory({ projectItemId, projectNumber }));
    }, []);

    const ownerLabel = useSelectedItemTypeOwnerLabel() ?? DefaultItemAreaOwnerLabel;
    const ownersIds = history?.entityChanges?.reduce(function (owners, entityChange) {
        const ownersValues = entityChange.propertyChanges
            ?.filter((c) => c.propertyName === 'Owner' || c.propertyName === ownerLabel)
            .reduce((changeOwners, change) => {
                if (!!change.previousValue)
                    changeOwners = changeOwners.concat(change.previousValue);
                if (!!change.nextValue) changeOwners = changeOwners.concat(change.nextValue);
                return changeOwners;
            }, [] as string[]);
        if (!!ownersValues) owners = owners.concat(ownersValues);
        return owners;
    }, [] as string[]);

    const getPropertyChanges = (propertyChanges?: (React.ReactNode | PropertyChange)[]) => {
        return (
            propertyChanges?.map((c) => {
                const change = c as PropertyChange;
                switch (change.propertyName) {
                    case ownerLabel:
                    case 'Owner':
                        const previousOwner = ownersPeople.data?.find(
                            (o) => o.id === change.previousValue
                        );
                        const nextOwner = ownersPeople.data?.find((o) => o.id === change.nextValue);
                        return {
                            ...change,
                            previousValue: previousOwner
                                ? {
                                      displayName: previousOwner?.displayName,
                                      photo: previousOwner?.photo,
                                  }
                                : undefined,
                            nextValue: {
                                displayName: nextOwner?.displayName,
                                photo: nextOwner?.photo,
                            },
                        };
                    default:
                        return change;
                }
            }) ?? ([] as (React.ReactNode | PropertyChange)[])
        );
    };

    const getCustomPropertyChanges = (
        customPropertyChanges?: (React.ReactNode | CustomPropertyChange)[]
    ) => {
        return (
            customPropertyChanges?.map((c) => {
                const customChange = c as CustomPropertyChange;
                return (
                    <>
                        {customChange.propertyName} {customChange.action.toLowerCase()}{' '}
                        <b>{customChange.propertyValue}</b>
                    </>
                );
            }) ?? []
        );
    };

    const ownersPeople = usePeople(ownersIds);
    const [entityChangesWithAuthors, loading] = useEntityChangesWithAuthors(history);
    const entityChanges = entityChangesWithAuthors?.map((e: EntityChange) => ({
        ...e,
        propertyChanges: (
            getPropertyChanges(e.propertyChanges) as commonPropertyChangeTypes
        ).concat(getCustomPropertyChanges(e.customPropertyChanges)),
    }));

    return (
        <ProjectItemDetailsPage title={history?.itemName}>
            <OnlineContentLoader loading={loading}>
                <Timeline entityChanges={entityChanges!} loading={loading} />
            </OnlineContentLoader>
        </ProjectItemDetailsPage>
    );
};
