import { createAsyncThunk } from '@reduxjs/toolkit';
import { remove } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { getHeaders } from '../../fileUpload';
import { addNotification } from '../../notificationsReducer';

interface FetchQrCodeParams {
    projectNumber: string;
    itemTypeId: string;
    file?: File;
}

export const fetchQrCodeExample = createAsyncThunk<void, FetchQrCodeParams, { state: RootState }>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/qrcode-example/post',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const url = `/api/projects/${args.projectNumber}/itemtypes/${args.itemTypeId}/qrcode-example`;
            const headers = await getHeaders();
            const formData = new FormData();
            if (args.file) formData.set('file', args.file);

            return fetch(url, { method: 'POST', headers: headers, body: formData }).then(
                async (response) => {
                    if (!response.ok) {
                        const message = await response.json();
                        throw new Error(message.Title);
                    }

                    return {
                        qrCodeExample: await response.json(),
                        refresh: !args.file,
                    };
                }
            );
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface UploadQrCodeParams {
    projectNumber: string;
    itemTypeId: string;
    file: File;
}

export const uploadLogo = createAsyncThunk<void, UploadQrCodeParams, { state: RootState }>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/logo/post',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const url = `/api/projects/${args.projectNumber}/itemtypes/${args.itemTypeId}/logo`;
            const headers = await getHeaders();
            const formData = new FormData();
            formData.set('file', args.file);

            return fetch(url, { method: 'POST', headers: headers, body: formData }).then(() => {
                dispatch(
                    addNotification({
                        message: 'Logo has been added successfully',
                        isSuccess: true,
                    })
                );
                return true;
            });
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface DeleteQrCodeParams {
    projectNumber: string;
    itemTypeId: string;
}

export const deleteLogo = createAsyncThunk<void, DeleteQrCodeParams, { state: RootState }>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/logo/delete',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const url = `/projects/${args.projectNumber}/itemtypes/${args.itemTypeId}/logo`;
            await remove(url);
            dispatch(
                addNotification({
                    message: 'Logo has been deleted successfully',
                    isSuccess: true,
                })
            );
            return true;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);
