export const getItemsNotModifiableStatusMessage = (nonEditableStatusesMessage: string) => `Items can not be edited as it is of status ${nonEditableStatusesMessage}`;

export const getItemNotModifiableStatusMessage = (nonEditableStatusesMessage: string) => `Item can not be edited as it is of status ${nonEditableStatusesMessage}`;

//concatenates a list of sentences from a given message by adding "," after the second message and "and" before the last message.
export const concatMessage = (message: string, messagesToConcat: string[]) => {
    let result = message;
    const emptyMessageRemoved = messagesToConcat.filter(m => !!m);
    const messagesLastIndex = emptyMessageRemoved.length - 1;
    emptyMessageRemoved.forEach((toConcat: string, i: number) => {
        if (i === 0) {
            result = `${result} ${toConcat}`;
        } else if (i < messagesLastIndex) {
            const hasPreviousMessage = result[result.length - 1] !== ',';
            result = hasPreviousMessage ? `${result}, ${toConcat}` : toConcat;
        } else {
            result = `${result}, and ${toConcat}`;
        }
    });
    return result;
}
