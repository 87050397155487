import { ColumnDefinition, selectFilter, textFilter } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { format } from 'date-fns';
import { ProjectItem } from 'domain/services/dataService/models';
import { nameof } from 'helpers/nameOf';
import { ClassificationBadge } from 'views/components/';
import { getItemDetailsTableCell } from 'views/components/table';
import styles from 'views/components/table/Table.module.scss';

export const getTableColumns = (
    classificationOptions: OptionTypeBase[],
    onPreviewClicked: (itemId: string, projectNumber: string) => string
): ColumnDefinition<ProjectItem>[] => [
    getItemDetailsTableCell(onPreviewClicked),
    {
        text: 'Project',
        field: (result) => `${result.project.name} (${result.project.number})`,
        sortable: true,
        filter: textFilter(),
    },
    {
        text: 'Item',
        field: (result) => `${result.name} (${result.number})`,
        sortable: true,
        filter: textFilter(),
    },
    {
        text: 'Classification',
        renderer: (id) => <ClassificationBadge id={id} />,
        field: (result) => result.classification.id,
        sortable: true,
        filter: selectFilter({
            options: classificationOptions,
            filterField: (row) => row.classification.name,
            multi: true,
        }),
    },
    {
        text: 'Category',
        field: (result) => result.category.name,
        sortable: true,
        filter: textFilter(),
    },
    {
        text: 'Status',
        field: (result) => result.status.name,
        sortable: true,
        filter: textFilter(),
    },
    {
        key: nameof<ProjectItem>('dateInService'),
        text: 'Date In Service',
        field: (result) => format(new Date(result.dateInService), 'dd MMM yyyy'),
        sortable: true,
        cellClass: styles.noWrappedWitheSpace,
    },
    {
        text: 'Created',
        field: (result) => format(new Date(result.created), 'dd MMM yyyy'),
        sortable: true,
        cellClass: styles.noWrappedWitheSpace,
    },
];
