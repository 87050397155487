import { ColumnDefinition, textFilter } from '@laingorourke/core-web-components';
import { Classification } from 'domain/models/api-models';
import { nameof } from 'helpers/nameOf';
import { ProjectDisplayName, useClassificationsTableColumns } from 'views/components';
import { ClassificationTableRow } from 'views/routes/project/projectSetup/classifications/ClassificationTableRow';

const AllProjects = 'All';

export const getTableColumns = (
    classifications: Classification[]
): ColumnDefinition<ClassificationTableRow>[] => [
    {
        field: (d) => d.projectName,
        text: 'Project',
        sortable: true,
        filter: textFilter(),
        renderer: (_, row: ClassificationTableRow) => (
            <ProjectDisplayName projectNumber={row.projectNumber} />
        ),
        key: nameof<ClassificationTableRow>('projectName'),
        // sort by project and then by hub, All projects will stay at the top (bottom when desc)
        customSort: (rowA: any, rowB: any, order) => {
            if (rowA.projectName === AllProjects && rowB.projectName == AllProjects) {
                const hubSort =
                    classifications.indexOf(rowA.hub!) - classifications.indexOf(rowB.hub!);
                return order === 'asc' ? hubSort : !hubSort;
            }
            if (order === 'asc') {
                return rowA.projectName === AllProjects
                    ? -1
                    : rowB.projectName === AllProjects
                    ? 1
                    : rowA.projectName.localeCompare(rowB.projectName) ||
                      classifications.indexOf(rowA.hub!) - classifications.indexOf(rowB.hub!);
            }
            return rowB.projectName === AllProjects
                ? -1
                : rowA.projectName === AllProjects
                ? 1
                : rowB.projectName.localeCompare(rowA.projectName) ||
                  classifications.indexOf(rowA.hub!) - classifications.indexOf(rowB.hub!);
        },
    },
    ...useClassificationsTableColumns(true),
];
