import { Classification, ClassificationHub } from 'domain/models/api-models';
import {
    emojis,
    emojiValidationMessage,
    invalidCharacters,
} from 'views/components/form/validators';
import * as Yup from 'yup';

export const getItemSchema = (cancelledStatusesIds: string[], classifications: Classification[]) =>
    Yup.object().shape({
        name: Yup.string()
            .matches(emojis, emojiValidationMessage)
            .matches(invalidCharacters, 'Title contains invalid characters')
            .max(100, 'Title cannot be longer than 100 characters')
            .required('Title is required'),
        description: Yup.string()
            .matches(emojis, emojiValidationMessage)
            .matches(invalidCharacters, 'Description contains invalid characters')
            .max(250, 'Description cannot be longer than 250 characters'),
        categoryId: Yup.string().required('Category is required'),
        classificationId: Yup.string().required('Classification is required'),
        applyProjectComplianceTemplate: Yup.boolean().required(),
        dateInService: Yup.date()
            .required('Date In Service is required')
            .typeError('Date In Service is required'),
        areaId: Yup.string().required('Area is required'),
        subAreaId: Yup.string().nullable(),
        ownerId: Yup.string().nullable().required('Owner is required'),
        statusId: Yup.string().required('Status is required'),
        designerId: Yup.string()
            .nullable()
            .when('classificationId', {
                is: (classificationId: string) =>
                    classifications?.find((c) => c.id === classificationId)?.hub !==
                    ClassificationHub.Low,
                then: Yup.string().nullable().required('Designer is required'),
            }),
        reason: Yup.string()
            .nullable()
            .when('statusId', (statusId: string, schema: any) => {
                if (!!cancelledStatusesIds?.find((id) => id === statusId)) {
                    return schema
                        .matches(emojis, emojiValidationMessage)
                        .matches(invalidCharacters, 'Reason contains invalid characters')
                        .min(3, 'Reason cannot be shorter than 3 characters')
                        .max(100, 'Reason cannot be longer than 100 characters')
                        .required('Reason is required');
                }
                return schema;
            }),
        groupIds: Yup.array().nullable(),
        siteId: Yup.string().nullable(),
    });

export const UploadCsvSchema = Yup.object().shape({
    documents: Yup.array(Yup.object()).required('File is required'),
});
