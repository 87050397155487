import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory';
import { generatePath } from '@laingorourke/core-web-components';
import { deleteIntegration } from 'domain/store/reducers/integrations';
import { useAppDispatch } from 'domain/store/rootStore';
import { useIntegrationRoute } from 'hooks/useIntegrationRoute';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TableRowActionButtons } from 'views/components/table';
import { ActionButton } from 'views/components/table/tableRowActionButtons/ActionButton';
import { ProjectParams } from 'views/routes/project/Project';
import styles from './ActionButtons.module.scss';
import { IntegrationTableRow } from './IntegrationTableRow';

interface ActionButtonsProps {
    integration: IntegrationTableRow;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ integration }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();
    const isProjectMode = !!projectNumber;
    const integrationRoute = useIntegrationRoute();

    const [successfullyUpdated, setSuccessfullyUpdated] = useState<boolean | undefined>(undefined);
    const onDelete = async () => {
        const result = await dispatch(
            deleteIntegration({
                integrationId: integration.id,
                projectNumber: projectNumber ?? integration.projectNumber,
            })
        );
        if ((result as any).payload) setSuccessfullyUpdated(true);
    };

    const editPath = () => {
        return generatePath(integrationRoute.routes!.editIntegration.path, {
            itemTypeCode: itemTypeCode,
            projectNumber: projectNumber,
            integrationId: integration.id,
        });
    };

    const goToIntegrationDetails = () => {
        history.push(
            generatePath(
                isProjectMode
                    ? integrationRoute.routes!.projectIntegrationHistory.path
                    : integrationRoute.routes!.integrationHistory.path,
                {
                    itemTypeCode: itemTypeCode,
                    integrationId: integration.id,
                    ...(isProjectMode && { projectNumber }),
                }
            )
        );
    };

    return (
        <div className={styles.buttons}>
            <TableRowActionButtons
                editPath={editPath()}
                routeParams={{ projectNumber, itemTypeCode }}
                entityId={integration.id}
                deleteBody={`Are you sure you want to remove ${
                    integration.applicationType
                } form ${integration.formName!}?`}
                isDeleteDisabled={!integration.isActive}
                isEditDisabled={!integration.isActive}
                onDelete={onDelete}
                deleteTitle="Delete Integration"
                editDisabledMessage="This Integration has been deleted, it can no longer be edited"
                deleteDisabledMessage="This Integration has been deleted"
                successfullyUpdated={successfullyUpdated}
                hideEdit={!integration.canEdit}
                hideDelete={!integration.canDelete}
            />
            <ActionButton
                key={`history-${integration.id}`}
                icon={faHistory}
                onClick={goToIntegrationDetails}
            />
        </div>
    );
};

export default ActionButtons;
