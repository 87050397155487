import { emojis, emojiValidationMessage } from 'views/components/form/validators';
import * as Yup from 'yup';
import { IntegrationType } from '../IntegrationType';

export const IntegrationSchema = Yup.object().shape({
    allProjects: Yup.boolean(),
    projectNumber: Yup.string()
        .nullable()
        .when('allProjects', (allProjects: boolean, schema: any) => {
            if (!allProjects) {
                return schema.required('Project is required');
            }
            return schema;
        }),
    type: Yup.string().required('Type is required'),
    applicationType: Yup.string().required('Application Integration is required'),
    controlMeasureId: Yup.string()
        .nullable()
        .when('type', {
            is: (type: string) => type === IntegrationType.ControlMeasure,
            then: Yup.string().required('Control Measure is required'),
        }),
    inspectionTypeId: Yup.string()
        .nullable()
        .when('type', {
            is: (type: string) => type === IntegrationType.Inspection,
            then: Yup.string().required('Inspection is required'),
        }),
    formName: Yup.string()
        .matches(emojis, emojiValidationMessage)
        .max(100, 'Application Form Name cannot be longer than 100 characters')
        .required('Application Form Name is required'),
});
