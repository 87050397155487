import { StatusType } from '@laingorourke/core-web-mytasksreactsdk';
import { Recurrence } from '@laingorourke/core-web-types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    AssignedOwner,
    History,
    PersonGroups,
    ProjectClassificationGuidelines,
    ProjectControlMeasure,
    ProjectItem,
    ProjectItemCategoryInspection,
    ProjectItemDetailsSummary,
} from 'domain/models/api-models';
import { get, patch, post, remove } from 'domain/services/client';
import { SupplyChainPortalSystemId } from 'domain/services/dataService/constants';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from '../../../rootStore';
import { addNotification } from '../../notificationsReducer';
import { addPeopleWithSupplyChainGroups } from '../../people';
import { getCurrentUserHasProjectPermission } from '../../users';

export const fetchProjects = createAsyncThunk('projects/get', async () => await get('projects'));

interface Params {
    projectNumber: string;
    itemTypeId: string;
}

export const fetchProjectComplianceTemplates = createAsyncThunk<
    ProjectClassificationGuidelines,
    Params,
    { state: RootState }
>(
    'projects/{id}/classificationguidelines/{itemTypeId}/get',
    async (params) =>
        (await get(
            `projects/${params.projectNumber}/classificationguidelines/${params.itemTypeId}`
        ))!,
    {
        condition: (args, { getState }) =>
            !!args.itemTypeId &&
            !!args.projectNumber &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

export const fetchProjectItems = createAsyncThunk<ProjectItem[], Params, { state: RootState }>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/get',
    async (args) =>
        (await get(`projects/${args.projectNumber}/itemtypes/${args.itemTypeId}/projectitems`))!,
    {
        condition: (args, { getState }) =>
            !!args.itemTypeId &&
            !!args.projectNumber &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface ItemDetails {
    projectNumber: string;
    projectItemId: string;
}
export const fetchProjectItemDetails = createAsyncThunk<
    ProjectItem[],
    ItemDetails,
    { state: RootState }
>(
    'projects/{projectNumber}/projectitems/{projectItemId}/details/get',
    async (args) =>
        (await get(`projects/${args.projectNumber}/projectitems/${args.projectItemId}/details`))!,
    {
        condition: (args, { getState }) =>
            !!args.projectNumber &&
            !!args.projectItemId &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

export const fetchProjectItemDetailsSummary = createAsyncThunk<
    ProjectItemDetailsSummary,
    ItemDetails,
    { state: RootState }
>(
    'projects/{projectNumber}/projectitems/{projectItemId}/detailssummary/get',
    async (args) =>
        (await get(
            `projects/${args.projectNumber}/projectitems/${args.projectItemId}/detailssummary`
        ))!,
    {
        condition: (args, { getState }) =>
            !!args.projectNumber &&
            !!args.projectItemId &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

export const fetchProjectItemDetailsHistory = createAsyncThunk<
    History,
    ItemDetails,
    { state: RootState }
>(
    'projects/{projectNumber}/projectitems/{projectItemId}/history/get',
    async (args) =>
        (await get(`projects/${args.projectNumber}/projectitems/${args.projectItemId}/history`))!,
    {
        condition: (args, { getState }) =>
            !!args.projectNumber &&
            !!args.projectItemId &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

export const fetchProjectItemCategoryInspections = createAsyncThunk<
    ProjectItemCategoryInspection[],
    { projectNumber: string; projectItemId: string; categoryId: string },
    { state: RootState }
>(
    'projects/{projectNumber}/projectitems/{projectItemId}/categoryInspections/{categoryId}/get',
    async (args) =>
        (await get(
            `projects/${args.projectNumber}/projectitems/${args.projectItemId}/categoryInspections/${args.categoryId}`
        ))!,
    {
        condition: (args, { getState }) =>
            !!args.projectNumber &&
            !!args.projectItemId &&
            !!args.categoryId &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

export const fetchProjectControlMeasures = createAsyncThunk<
    ProjectControlMeasure[],
    { projectNumber: string; itemTypeId: string },
    { state: RootState }
>(
    'projects/{id}/controlmeasures/get',
    async (args) =>
        (await get(`projects/${args.projectNumber}/controlmeasures/${args.itemTypeId}`))!,
    {
        condition: (args, { getState }) =>
            !!args.projectNumber &&
            !!args.itemTypeId &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface CreateItem {
    projectNumber: string;
    name: string | undefined;
    description: string | undefined;
    categoryId: string;
    classificationId: string;
    applyProjectComplianceTemplate: boolean;
    dateInService: string;
    areaId: string;
    subAreaId: string | undefined;
    ownerId: string;
    statusId: string;
    designerId: string | undefined;
    groupIds: string[] | undefined;
}

export const createItem = createAsyncThunk<ProjectItem, CreateItem, { state: RootState }>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/post',
    async (item, { getState, dispatch }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            const createdItem = await post(
                `projects/${item.projectNumber}/itemtypes/${itemTypeId}/projectitems`,
                item
            );
            dispatch(
                addNotification({
                    message: 'Item has been added successfully',
                    isSuccess: true,
                })
            );
            return createdItem;
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!getState().itemTypes.selectedItemType.id &&
            !!args.projectNumber &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface UpdateItem {
    projectNumber: string;
    itemTypeId: string;
    id: string;
    name: string | undefined;
    description: string | undefined;
    applyProjectComplianceTemplate: boolean;
    dateInService: string;
    areaId: string;
    subAreaId: string | undefined;
    ownerId: string;
    statusId: string;
    reason?: string;
    designerId: string | undefined;
    lastModified: Date;
    groupIds: string[] | undefined;
}
export const updateItem = createAsyncThunk<ProjectItem, UpdateItem, { state: RootState }>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/{id}/patch',
    async (item, { dispatch }) => {
        return await runSafe(async () => {
            const updatedItem = await patch(
                `projects/${item.projectNumber}/itemtypes/${item.itemTypeId}/projectitems/${item.id}`,
                item
            );
            dispatch(
                addNotification({
                    message: 'Item has been updated successfully',
                    isSuccess: true,
                })
            );
            return updatedItem;
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!args.itemTypeId &&
            !!args.projectNumber &&
            !!args.id &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface CloneItem {
    projectNumber: string;
    itemTypeId: string;
    id: string;
    dateInService: string;
    areaId: string;
    subAreaId: string | undefined;
    ownerId: string;
    statusId: string;
}
export const cloneItem = createAsyncThunk<ProjectItem, CloneItem, { state: RootState }>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/{id}/clone/post',
    async (item, { dispatch }) => {
        return await runSafe(async () => {
            const clonedItem = await post(
                `projects/${item.projectNumber}/itemtypes/${item.itemTypeId}/projectitems/${item.id}/clone`,
                item
            );
            dispatch(
                addNotification({
                    message: 'Item has been cloned successfully',
                    isSuccess: true,
                })
            );
            return clonedItem;
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!args.itemTypeId &&
            !!args.projectNumber &&
            !!args.id &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface ItemBulkCommonProps {
    projectItemsIds: string[];
    projectNumber: string;
    itemTypeId: string;
}

interface AssignOwnerRequest extends ItemBulkCommonProps {
    ownerId: string;
}

export const assignOwner = createAsyncThunk<
    AssignedOwner,
    AssignOwnerRequest,
    { state: RootState }
>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/assignowner/patch',
    async (item, { dispatch }) => {
        return await runSafe(async () => {
            const assignedOwners = await patch(
                `projects/${item.projectNumber}/itemtypes/${item.itemTypeId}/projectitems/assignowner`,
                item
            );
            dispatch(
                addNotification({
                    message: 'Item owner has been assigned successfully',
                    isSuccess: true,
                })
            );

            return assignedOwners;
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!args.itemTypeId &&
            !!args.projectNumber &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface AssignDesignerRequest extends ItemBulkCommonProps {
    designerId: string;
}

export const assignDesigner = createAsyncThunk<void, AssignDesignerRequest, { state: RootState }>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/assigndesigner/patch',
    async (item, { dispatch }) => {
        return await runSafe(async () => {
            await patch(
                `projects/${item.projectNumber}/itemtypes/${item.itemTypeId}/projectitems/assigndesigner`,
                item
            );
            dispatch(
                addNotification({
                    message: 'Item designer has been assigned successfully',
                    isSuccess: true,
                })
            );
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!args.itemTypeId &&
            !!args.projectNumber &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface AssignGroupsRequest extends ItemBulkCommonProps {
    groupIds: string[];
}

export const assignGroups = createAsyncThunk<void, AssignGroupsRequest, { state: RootState }>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/assigngroups/patch',
    async (item, { dispatch }) => {
        return await runSafe(async () => {
            await patch(
                `projects/${item.projectNumber}/itemtypes/${item.itemTypeId}/projectitems/assigngroups`,
                item
            );
            const multipleGroupsAssigned = item.groupIds.length > 1;
            dispatch(
                addNotification({
                    message: `Item ${
                        multipleGroupsAssigned ? 'subcontractors have' : 'subcontractor has'
                    } been assigned successfully`,
                    isSuccess: true,
                })
            );
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!args.itemTypeId &&
            !!args.projectNumber &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface AssignSiteRequest extends ItemBulkCommonProps {
    siteId: string;
}

export const assignSite = createAsyncThunk<void, AssignSiteRequest, { state: RootState }>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/assignsite/patch',
    async (item, { dispatch }) => {
        return await runSafe(async () => {
            await patch(
                `projects/${item.projectNumber}/itemtypes/${item.itemTypeId}/projectitems/assignsite`,
                item
            );
            dispatch(
                addNotification({
                    message: 'Item site has been assigned successfully',
                    isSuccess: true,
                })
            );
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!args.itemTypeId &&
            !!args.projectNumber &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface ChangeStatusRequest extends ItemBulkCommonProps {
    statusId: string;
    reason: string;
}
export const changeStatus = createAsyncThunk<void, ChangeStatusRequest, { state: RootState }>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/status/patch',
    async (item, { dispatch }) => {
        return await runSafe(async () => {
            const changedStatuses = await patch(
                `projects/${item.projectNumber}/itemtypes/${item.itemTypeId}/projectitems/status`,
                item
            );
            dispatch(
                addNotification({
                    message: 'Item status has been assigned successfully',
                    isSuccess: true,
                })
            );

            return changedStatuses;
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!args.itemTypeId &&
            !!args.projectNumber &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface CreateProjectControlMeasure {
    projectNumber: string;
    projectItemId: string;
    controlMeasureId: string | undefined;
    name: string | undefined;
    statusType: StatusType;
    ownerId: string;
    dueDate: string;
}

export const createProjectItemControlMeasure = createAsyncThunk<
    string,
    CreateProjectControlMeasure,
    { state: RootState }
>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/{id}/controlmeasures/post',
    async (args, { dispatch, getState }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            const createdControlMeasureId = await post(
                `projects/${args.projectNumber}/itemtypes/${itemTypeId}/projectitems/${args.projectItemId}/controlmeasures`,
                args
            );
            dispatch(
                addNotification({
                    message: 'Control Measure has been added successfully',
                    isSuccess: true,
                })
            );
            return createdControlMeasureId;
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!getState().itemTypes.selectedItemType.id &&
            !!args.projectNumber &&
            !!args.projectItemId &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface CreateProjectSchedule {
    projectNumber: string;
    projectItemId: string;
    inspectionTypeId: string;
    recurrence: Recurrence;
    assigneeId: string;
    startDate: string;
    endDate?: string;
    addBeforeDays: number;
}

export const createProjectItemSchedule = createAsyncThunk<
    string,
    CreateProjectSchedule,
    { state: RootState }
>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/{id}/schedules/post',
    async (args, { dispatch, getState }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            const result = await post(
                `projects/${args.projectNumber}/itemtypes/${itemTypeId}/projectitems/${args.projectItemId}/schedules`,
                args
            );
            dispatch(
                addNotification({
                    message: 'Schedule has been added successfully',
                    isSuccess: true,
                })
            );
            return result;
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!getState().itemTypes.selectedItemType.id &&
            !!args.projectNumber &&
            !!args.projectItemId &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface UpdateProjectSchedule {
    scheduleId: string;
    projectNumber: string;
    projectItemId: string;
    inspectionTypeId: string;
    assigneeId: string;
    startDate: string;
    endDate?: string;
    addBeforeDays: number;
}

export const updateProjectItemSchedule = createAsyncThunk<
    string,
    UpdateProjectSchedule,
    { state: RootState }
>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/{id}/schedules/{scheduleId}/patch',
    async (args, { dispatch, getState }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            await patch(
                `projects/${args.projectNumber}/itemtypes/${itemTypeId}/projectitems/${args.projectItemId}/schedules/${args.scheduleId}`,
                args
            );
            dispatch(
                addNotification({
                    message: 'Schedule has been updated successfully',
                    isSuccess: true,
                })
            );
            return args.scheduleId;
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!getState().itemTypes.selectedItemType.id &&
            !!args.projectNumber &&
            !!args.projectItemId &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface UpdateProjectitemControlMeasureAssignee {
    projectNumber: string;
    projectItemId: string;
    assigneeId: string;
}

export const updateProjectItemControlMeasureAssignee = createAsyncThunk<
    void,
    UpdateProjectitemControlMeasureAssignee,
    { state: RootState }
>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/{id}/controlMeaures/assignee/patch',
    async (args, { dispatch, getState }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            await patch(
                `projects/${args.projectNumber}/itemtypes/${itemTypeId}/projectitems/${args.projectItemId}/controlMeasures/assignee`,
                args
            );
            dispatch(
                addNotification({
                    message: 'Assignees have been updated successfully',
                    isSuccess: true,
                })
            );
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!getState().itemTypes.selectedItemType.id &&
            !!args.projectNumber &&
            !!args.projectItemId &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface UpdateProjectScheduleAssignee {
    scheduleId: string;
    projectNumber: string;
    projectItemId: string;
    assigneeId: string;
}

export const updateProjectItemScheduleAssignee = createAsyncThunk<
    string,
    UpdateProjectScheduleAssignee,
    { state: RootState }
>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/{id}/schedules/{scheduleId}/assignee/patch',
    async (args, { dispatch, getState }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            await patch(
                `projects/${args.projectNumber}/itemtypes/${itemTypeId}/projectitems/${args.projectItemId}/schedules/${args.scheduleId}/assignee`,
                args
            );
            dispatch(
                addNotification({
                    message: 'Schedule has been updated successfully',
                    isSuccess: true,
                })
            );
            return args.scheduleId;
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!getState().itemTypes.selectedItemType.id &&
            !!args.projectNumber &&
            !!args.projectItemId &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface CreateProjectItemInspections {
    projectNumber: string;
    projectItemId: string;
    inspectionTypeId: string;
    statusType: StatusType;
    ownerId: string;
    dueDates: string[];
}

export const createProjectItemInspections = createAsyncThunk<
    string,
    CreateProjectItemInspections,
    { state: RootState }
>(
    'projects/{projectNumber}/projectitems/{id}/inspections/post',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const createdInspectionsIds = await post(
                `projects/${args.projectNumber}/projectitems/${args.projectItemId}/inspections`,
                args
            );
            dispatch(
                addNotification({
                    message:
                        args.dueDates.length === 1
                            ? 'Inspection has been added successfully'
                            : 'Inspections have been added successfully',
                    isSuccess: true,
                })
            );
            return createdInspectionsIds;
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!args.projectNumber &&
            !!args.projectItemId &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

interface MoveTasksToWontDo {
    projectNumber: string;
    projectItemId: string;
    reason: string;
    tasksIds: string[];
}

export const moveTasksToWontDo = createAsyncThunk<string, MoveTasksToWontDo, { state: RootState }>(
    'projects/{projectNumber}/projectitems/{id}/moveTasksToWontDo/patch',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await patch(
                `projects/${args.projectNumber}/projectitems/${args.projectItemId}/moveTasksToWontDo`,
                args
            );
            dispatch(
                addNotification({
                    message:
                        args.tasksIds.length === 1
                            ? 'Task has been scheduled for update'
                            : 'Tasks have been scheduled for update',
                    isSuccess: true,
                })
            );
            return args.tasksIds;
        }, dispatch);
    },
    {
        condition: (args) => !!args.projectNumber && !!args.projectItemId,
    }
);

interface DeleteProjectInspectionSchedule {
    projectNumber: string;
    projectItemId: string;
    scheduleId: string;
    currentEndDate?: string;
}

export const deleteProjectItemSchedule = createAsyncThunk<
    string,
    DeleteProjectInspectionSchedule,
    { state: RootState }
>(
    'projects/{projectNumber}/projectitems/{id}/schedules/delete',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await remove(
                `projects/${args.projectNumber}/projectitems/${args.projectItemId}/schedules/${args.scheduleId}?currentEndDate=${args.currentEndDate}`
            );
            dispatch(
                addNotification({
                    message: 'Schedule has been deleted successfully',
                    isSuccess: true,
                })
            );
            return args.scheduleId;
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!args.projectNumber &&
            !!args.projectItemId &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);

export const ownerSelected = createAsyncThunk<
    PersonGroups | undefined,
    { personId: string },
    { state: RootState }
>(
    'personGroups/{personId}/get',
    async (args, { getState, dispatch }) => {
        const person = getState().people.peopleWithSupplyChainGroups.find(
            (p) => p.id === args.personId
        );
        if (person === undefined) {
            const getPerson = (await get(
                `people/personGroups/${args.personId}?groupsManagedBySystemId=${SupplyChainPortalSystemId}`
            ))!;
            dispatch(addPeopleWithSupplyChainGroups({ personGroups: getPerson }));
            return getPerson;
        }
        return person;
    },
    {
        condition: (args) => !!args.personId,
    }
);
