import { createRoot } from 'react-dom/client';
import { ErrorPage } from 'views/routes/error/ErrorPage';
import ComplyApp from './ComplyApp';
import { ComplyLogoSrc } from './consts';
import { populateConfiguration } from './helpers/applicationConfig';

const container = document.getElementById('root');
const root = createRoot(container!);

// Splash screen effect with logo that will be displayed while Comply
// config is loading
root.render(
    <div
        style={{
            position: 'absolute',
            margin: 'auto',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#95969a',
        }}>
        <img
            width="192"
            height="192"
            style={{
                position: 'absolute',
                margin: 'auto',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
            }}
            src={ComplyLogoSrc}
        />
    </div>
);

populateConfiguration()
    .then(() => {
        root.render(<ComplyApp />);
    })
    .catch((error: Error) => {
        root.render(<ErrorPage error={error} />);
    });
