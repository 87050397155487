import { useState } from 'react';
import { UserSecurableStatus } from './UserSecurableStatus';

export interface PermissionsFilters {
    selectedRoles: string[];
    onSelectedRolesChange: (value: string | string[] | null) => void;
    selectedSecurable: string | undefined;
    onSelectedSecurableChange: (value: string | string[] | null) => void;
    selectedStatuses: UserSecurableStatus[];
    onSelectedStatusChange: (value: UserSecurableStatus | UserSecurableStatus[] | null) => void;
}

export const useFilters = (): PermissionsFilters => {
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [selectedSecurable, setSelectedSecurable] = useState<string | undefined>();
    const [selectedStatuses, setSelectedStatus] = useState<UserSecurableStatus[]>([]);
    const onSelectedRolesChange = (value: string | string[] | null) => {
        setSelectedRoles(value as string[]);
    };

    const onSelectedSecurableChange = (value: string | string[] | null) => {
        if (value === null) setSelectedSecurable(undefined);
        else setSelectedSecurable(value as string);
    };

    const onSelectedStatusChange = (value: string | UserSecurableStatus[] | null) => {
        setSelectedStatus(value as UserSecurableStatus[]);
    };

    return {
        selectedRoles,
        selectedSecurable,
        selectedStatuses,
        onSelectedRolesChange,
        onSelectedSecurableChange,
        onSelectedStatusChange,
    };
};
