import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Review } from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import { createReview, deleteReview, fetchReviews, updateReview } from './actions';

const initialState = {
    reviews: null as unknown as Review[],
};

const reviewsSlice = createSlice({
    name: 'reviews',
    initialState: initialState,
    reducers: {
        clearReviews() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchReviews.fulfilled, (state, action: PayloadAction<Review[]>) => {
            state.reviews = action.payload;
        });
        builder.addCase(createReview.fulfilled.type, (state, action: PayloadAction<Review>) => {
            if (action.payload === undefined) return;
            state.reviews.push(action.payload);
        });
        builder.addCase(updateReview.fulfilled.type, (state, action: PayloadAction<Review>) => {
            if (action.payload === undefined) return;
            state.reviews = state.reviews?.map((r) =>
                r.id === action.payload.id ? action.payload : r
            );
        });
        builder.addCase(deleteReview.fulfilled.type, (state, action: PayloadAction<string>) => {
            if (action.payload === undefined) return;
            state.reviews = state.reviews.filter((r) => r.id !== action.payload);
        });
    },
});

export const useReviews = () => useSelector((state: RootState) => state.reviews.reviews);
export const useReview = (id: string) =>
    useSelector((state: RootState) => state.reviews.reviews?.find((r) => r.id === id));

export const { clearReviews } = reviewsSlice.actions;
export default reviewsSlice.reducer;
