import { Link } from '..';

interface IgmsLinkRow {
    igmsLink?: string;
}

export const igmsLinkFormatter = (_: any, row: IgmsLinkRow) =>
    row.igmsLink && row.igmsLink ? (
        <Link href={row.igmsLink} target="_blank">
            Link
        </Link>
    ) : (
        ''
    );
