import {
    emojis,
    emojiValidationMessage,
    invalidCharacters,
} from 'views/components/form/validators';
import * as Yup from 'yup';

export const ReviewSchema = Yup.object().shape({
    comments: Yup.string()
        .matches(emojis, emojiValidationMessage)
        .matches(invalidCharacters, 'Comments contain invalid characters')
        .max(4000, 'Comments cannot be longer than 4000 characters')
        .required('Comments are required'),
    date: Yup.date().required('Review Date is required').typeError('Review Date is required'),
    reviewerId: Yup.string().nullable().required('Reviewer is required'),
});
