import format from 'date-fns/format';

export const groupBy = <T extends any>(
    array: T[],
    predicate: (value: T, index: number, array: T[]) => any
) =>
    array?.reduce((acc, value, index, array) => {
        (acc[predicate(value, index, array)] ||= []).push(value);
        return acc;
    }, {} as { [key: string]: T[] });

export const getFormattedDate = (date: Date | string) => format(new Date(date), 'd MMM yyyy');
