import { useAuth0 } from '@auth0/auth0-react';
import { useApps } from '@laingorourke/core-services-apps-web-client';
import {
    ApplicationMessage,
    Divider,
    InProgress,
    Layout,
    MessageType,
    Navigation,
    Router,
    useOfflineApp,
    youAreOfflineMessage,
} from '@laingorourke/core-web-components';
import { MyTasksComponentsProvider } from '@laingorourke/core-web-mytasks-components';
import { useTasksCount } from '@laingorourke/core-web-mytasksreactsdk';
import { AppDisplayName, ComplyLogoSrc } from 'consts';
import { auth0Token } from 'domain/services/auth0Service';
import { getProjects } from 'domain/services/dataService';
import { logGlobalError } from 'domain/store/actions/errorHandling';
import { clearError, useErrorState } from 'domain/store/reducers/applicationMessage';
import { useIsLoading } from 'domain/store/reducers/inProgress/reducer';
import {
    fetchItemTypes,
    setItemTypeCode,
    useItemTypes,
    useSelectedItemTypeCode,
} from 'domain/store/reducers/itemType';
import { addProjects } from 'domain/store/reducers/projects';
import { fetchFavouriteProjects } from 'domain/store/reducers/projects/actions';
import { fetchCurrentUserDetails } from 'domain/store/reducers/users/actions';
import { useCurrentUser, useIsAdmin } from 'domain/store/reducers/users/reducer';
import { useAppDispatch } from 'domain/store/rootStore';
import {
    getConfiguration,
    getCurrentVersion,
    getDataServiceConfiguration,
    getFeaturesConfiguration,
    getGtmConfiguration,
    getMyTasksConfiguration,
} from 'helpers/applicationConfig';
import { useIsOffline } from 'hooks/useIsOffline';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import TagManager from 'react-gtm-module';
import { BrowserRouter, Redirect } from 'react-router-dom';
import styles from './App.module.scss';
import ErrorFallback from './components/errorFallback/ErrorFallback';
import { FavouriteProjectsMenu } from './components/favouriteProjectsMenu/FavouriteProjectsMenu';
import { Main } from './components/layout/main/Main';
import { TourButton } from './components/tour/TourButton';
import { routes } from './routes/Routes';
import { ScanQrCodeButton } from './routes/scanQrCode';
import { SearchInput } from './routes/search';

interface AppProps {}

const App: React.FC<AppProps> = () => {
    const dispatch = useAppDispatch();
    const itemTypeCode = useSelectedItemTypeCode();
    const loading = useIsLoading();
    const errorState = useErrorState();
    const { loginWithRedirect, logout, getAccessTokenSilently, isAuthenticated, isLoading } =
        useAuth0();
    const isOffline = useIsOffline();
    const isAdmin = useIsAdmin();

    const currentUser = useCurrentUser();
    const gtmConfiguration = getGtmConfiguration();
    const myTaskConfiguration = getMyTasksConfiguration();
    const featuresConfiguration = getFeaturesConfiguration();
    const appsConfiguration = getConfiguration();
    const dataServiceConfig = getDataServiceConfiguration();

    const appLauncherApps = useApps(appsConfiguration?.servicesAppApiUrl!);
    const myTasksTotal = useTasksCount();

    const itemTypes = useItemTypes();
    // this is to rerender (close) header when "Scan QR Code" is selected from the menu
    // this will be removed once we rewrite this part in the component library
    const [headerKey, setHeaderKey] = useState(0);

    // We can render the app as soon as service worker is registered
    const offlineAppActivated = useOfflineApp(() => {
        // Let's make app config call again, just to cache it in case it's needed in the offline version before the app is reloaded
        fetch('/api/ClientAppConfiguration');
    });

    useEffect(() => {
        // serive worker is installed only in production (it means all envs including localhost in prod mode)
        if (
            !isAuthenticated ||
            (!offlineAppActivated && process.env.NODE_ENV === 'production') ||
            !auth0Token.getIsAccessTokenSilentlyDefined()
        ) {
            return;
        }
        dispatch(fetchCurrentUserDetails());
        getProjects().then((projects) => {
            if (projects) {
                dispatch(addProjects(projects));
                dispatch(fetchFavouriteProjects());
            }
        });
        dispatch(fetchItemTypes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, offlineAppActivated, auth0Token.getIsAccessTokenSilentlyDefined()]);

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            const returnTo = window.location.pathname + window.location.search;

            loginWithRedirect({
                appState: {
                    returnTo: returnTo,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
        auth0Token.setGetAccessTokenSilently(getAccessTokenSilently);
    }, [getAccessTokenSilently]);

    const handleError = (error: Error, info: { componentStack: string }) => {
        error.stack = info.componentStack;
        logGlobalError(error);
    };

    useEffect(() => {
        TagManager.initialize({
            gtmId: gtmConfiguration?.gtmId!,
            auth: gtmConfiguration?.auth!,
            preview: gtmConfiguration?.environmentId!,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Sets the item type code. This is needed to on the landing page to determine the routes for favourites projects in the menu.
    useEffect(() => {
        if (itemTypes.length !== 0) {
            dispatch(setItemTypeCode(itemTypeCode));
        }
    }, [itemTypes, itemTypeCode, dispatch]);

    return (
        <>
            <InProgress loading={loading} />
            <ApplicationMessage
                show={isOffline || !!errorState.message.length}
                message={isOffline ? youAreOfflineMessage : errorState.message}
                type={isOffline ? MessageType.Information : MessageType.Error}
                onClose={() => dispatch(clearError())}
                dismissible={!isOffline}
            />
            {(!isAuthenticated || !currentUser) && (
                <img src={ComplyLogoSrc} className={styles.logo} alt="logo" />
            )}
            {isAuthenticated && !!currentUser && (
                <BrowserRouter>
                    <Layout>
                        <Layout.Main>
                            <Layout.Header
                                key={headerKey}
                                sticky
                                tasksBadge={{
                                    count: myTasksTotal.data?.numberOfTodos,
                                    link: myTaskConfiguration?.baseUrl!,
                                }}
                                appLauncher={{
                                    apps: appLauncherApps,
                                    supportLinks: {
                                        helpUrl:
                                            'https://laingorourke.sharepoint.com/sites/Comply/',
                                        feedbackScriptSrc:
                                            'https://lorait.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-dtzt95/b/6/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=801e5fae',
                                    },
                                }}
                                brand={{
                                    name: AppDisplayName,
                                    logo: ComplyLogoSrc,
                                }}
                                userMenu={{
                                    onLogout: () => logout({ returnTo: window.location.origin }),
                                }}
                                rightMenu={
                                    <>
                                        {featuresConfiguration?.searchEnabled && (
                                            <>
                                                <SearchInput />
                                                <Divider vertical={true} />
                                            </>
                                        )}

                                        <TourButton />

                                        <ScanQrCodeButton
                                            closeMenu={() => setHeaderKey((key) => key + 1)}
                                        />
                                    </>
                                }
                                navigation={[
                                    <FavouriteProjectsMenu routes={routes} />,
                                    ...(isAdmin
                                        ? [
                                              <Navigation.Route
                                                  route={
                                                      itemTypeCode === ''
                                                          ? routes.adminRoot
                                                          : routes.admin
                                                  }
                                                  params={
                                                      itemTypeCode === ''
                                                          ? {}
                                                          : {
                                                                itemTypeCode: itemTypeCode,
                                                            }
                                                  }
                                              />,
                                          ]
                                        : []),
                                ]}
                            />
                            {/* Only wrap local code in StrictMode, to avoid warnings from @laingorourke/core-web-components */}
                            <Main>
                                <MyTasksComponentsProvider
                                    dataServiceEndpoint={dataServiceConfig?.endpoint!}>
                                    <ErrorBoundary
                                        FallbackComponent={ErrorFallback}
                                        onError={handleError}>
                                        <React.StrictMode>
                                            <Router
                                                routes={routes}
                                                appName={AppDisplayName}
                                                redirects={[
                                                    <Redirect
                                                        key={
                                                            routes.projects.routes!.project.routes!
                                                                .projectSetup.routes!.leadDays.path
                                                        }
                                                        from={
                                                            routes.projects.routes!.project.routes!
                                                                .projectSetup.path
                                                        }
                                                        to={
                                                            routes.projects.routes!.project.routes!
                                                                .projectSetup.routes!.leadDays.path
                                                        }
                                                        exact
                                                    />,
                                                    <Redirect
                                                        key={
                                                            routes.projects.routes!.project.routes!
                                                                .weather.routes!.weatherEventsItems
                                                                .path
                                                        }
                                                        from={
                                                            routes.projects.routes!.project.routes!
                                                                .weather.path
                                                        }
                                                        to={
                                                            routes.projects.routes!.project.routes!
                                                                .weather.routes!.weatherEventsItems
                                                                .path
                                                        }
                                                        exact
                                                    />,
                                                    <Redirect
                                                        key={routes.projects.path}
                                                        from={routes.home.path}
                                                        to={routes.projects.path}
                                                        exact
                                                    />,
                                                    ...(isAdmin
                                                        ? [
                                                              <Redirect
                                                                  key={
                                                                      routes.admin.routes!
                                                                          .complianceTemplates.path
                                                                  }
                                                                  from={routes.admin.path}
                                                                  to={
                                                                      routes.admin.routes!
                                                                          .complianceTemplates.path
                                                                  }
                                                                  exact
                                                              />,
                                                              <Redirect
                                                                  key={
                                                                      routes.admin.routes!.weather
                                                                          .routes!
                                                                          .weatherInspectionTypes
                                                                          .path
                                                                  }
                                                                  from={
                                                                      routes.admin.routes!.weather
                                                                          .path
                                                                  }
                                                                  to={
                                                                      routes.admin.routes!.weather
                                                                          .routes!
                                                                          .weatherInspectionTypes
                                                                          .path
                                                                  }
                                                                  exact
                                                              />,
                                                          ]
                                                        : []),
                                                    <Redirect
                                                        key={
                                                            routes.projects.routes!.project.routes!
                                                                .items.path
                                                        }
                                                        from={routes.projects.routes!.project.path}
                                                        to={
                                                            routes.projects.routes!.project.routes!
                                                                .items.path
                                                        }
                                                        exact
                                                    />,
                                                ]}
                                            />
                                        </React.StrictMode>
                                    </ErrorBoundary>
                                </MyTasksComponentsProvider>
                            </Main>
                        </Layout.Main>
                        <Layout.Footer
                            copyrightYear={2020}
                            appDisplayName={AppDisplayName}
                            version={getCurrentVersion()}
                        />
                    </Layout>
                </BrowserRouter>
            )}
        </>
    );
};

export default App;
