import { deleteInspectionType } from 'domain/store/reducers/inspectionType';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableRowActionButtons } from 'views/components/table';
import { routes } from 'views/routes/Routes';
import { InspectionTypeTableRow } from '../InspectionTypeTableRow';

interface ActionButtonsProps {
    inspectionType: InspectionTypeTableRow;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ inspectionType }) => {
    const dispatch = useAppDispatch();
    const routeParams = useParams();
    const [successfullyUpdated, setSuccessfullyUpdated] = useState<boolean | undefined>(undefined);
    const onDelete = async () => {
        const result = await dispatch(deleteInspectionType(inspectionType.id));
        if ((result as any).payload) setSuccessfullyUpdated(true);
    };
    return (
        <TableRowActionButtons
            editPath={routes.admin.routes!.inspectionTypes.path + `/edit/${inspectionType.id}`}
            routeParams={{ ...routeParams }}
            entityId={inspectionType.id}
            deleteBody={`Are you sure you want to remove '${inspectionType.name!}'?`}
            isDeleteDisabled={inspectionType.hasActiveInstances}
            onDelete={onDelete}
            deleteTitle="Delete Inspection"
            deleteDisabledMessage="You cannot delete this Inspection as it is assigned to a compliance template."
            successfullyUpdated={successfullyUpdated}
            hideEdit={!inspectionType.canEdit}
            hideDelete={!inspectionType.canDelete}
        />
    );
};

export default ActionButtons;
