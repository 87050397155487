import {
    Button,
    Checkbox,
    Form,
    Input,
    Select,
    TextArea,
    useForm,
    WarningPanel,
} from '@laingorourke/core-web-components';
import { Classification, ControlMeasure, Status } from 'domain/models/api-models';
import { createControlMeasure, updateControlMeasure } from 'domain/store/reducers/controlMeasure';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Permission } from 'security/Permission';
import { ClassificationBadge, ModalFooter, ProjectSelector } from 'views/components';
import { ControlMeasureLeadDaysSchema, ControlMeasureSchema } from './schema';
import styles from './UpsertForm.module.scss';

interface UpsertControlMeasureFormProps {
    isEditMode: boolean;
    controlMeasure: ControlMeasure | undefined;
    itemStatuses: Status[] | undefined;
    allClassifications: Classification[];
    goToControlMeasures: () => void;
}

export const UpsertForm: React.FC<UpsertControlMeasureFormProps> = ({
    isEditMode,
    controlMeasure,
    itemStatuses,
    allClassifications,
    goToControlMeasures,
}) => {
    const dispatch = useAppDispatch();
    const userHasPermissionsToAllProjects = useHasPermissions(
        Permission.ManageComplianceSettingsWrite,
        ''
    );

    const [showLeadDaysForm, setShowLeadDaysForm] = useState(false);
    const classifications = allClassifications;
    const controlMeasureForm = useForm({
        initialValues: {
            id: isEditMode ? controlMeasure!.id! : '',
            name: isEditMode ? controlMeasure!.name! : '',
            allProjects: !userHasPermissionsToAllProjects
                ? false
                : isEditMode
                ? !controlMeasure?.projectNumber
                : true,
            projectNumber: isEditMode ? controlMeasure!.projectNumber ?? undefined : undefined,
            description: isEditMode ? controlMeasure!.description! : '',
            igmsLink: isEditMode ? controlMeasure!.igmsLink! : '',
            itemStatusWhenControlMeasureCompleted: isEditMode
                ? controlMeasure?.itemStatusWhenControlMeasureCompleted ?? null
                : null,
            updateInspectionScheduleStartDate: isEditMode
                ? controlMeasure?.updateInspectionScheduleStartDate ?? false
                : false,
            evidenceRequired: isEditMode ? controlMeasure?.evidenceRequired ?? true : true,
        },
        validationSchema: ControlMeasureSchema,
        onSubmit: async (values) => {
            setShowLeadDaysForm(true);
        },
    });

    const controlMeasureLeadDaysForm = useForm({
        initialValues: {
            leadDays: isEditMode
                ? classifications
                      .filter(
                          (c) =>
                              c.projectNumber === controlMeasure?.projectNumber || !c.projectNumber
                      )
                      .map((c) => ({
                          classificationId: c.id,
                          leadDays: controlMeasure?.leadDays?.find(
                              (l) => l.classificationId === c.id
                          )?.leadDays,
                          comments: controlMeasure?.leadDays?.find(
                              (l) => l.classificationId === c.id
                          )?.comments,
                      }))
                : classifications
                      .filter((c) => c.projectNumber === undefined)
                      .map((c) => ({
                          classificationId: c.id,
                          leadDays: undefined,
                          comments: undefined,
                      })),
        },
        validationSchema: ControlMeasureLeadDaysSchema,
        onSubmit: async () => {
            const request = { ...controlMeasureForm.values, ...controlMeasureLeadDaysForm.values };
            let result;
            if (isEditMode) {
                const { projectNumber, ...updateValues } = request;
                result = await dispatch(updateControlMeasure(updateValues));
            } else {
                const { id, ...createValues } = request;
                result = await dispatch(createControlMeasure(createValues));
            }
            if ((result as any).payload) goToControlMeasures();
        },
    });

    useEffect(() => {
        const newLeadDays = classifications
            .filter(
                (c) =>
                    c.projectNumber === controlMeasureForm.values.projectNumber || !c.projectNumber
            )
            .map((c) => ({
                classificationId: c.id,
                leadDays: controlMeasureLeadDaysForm.values?.leadDays?.find(
                    (l) => l.classificationId === c.id
                )?.leadDays,
                comments: controlMeasureLeadDaysForm.values?.leadDays?.find(
                    (l) => l.classificationId === c.id
                )?.comments,
            }));
        controlMeasureLeadDaysForm.setFieldValue('leadDays', newLeadDays);
    }, [controlMeasureForm.values.projectNumber]);

    return (
        <>
            {!showLeadDaysForm ? (
                <Form form={controlMeasureForm}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Field name="name" label="Name">
                                <Input />
                            </Form.Field>
                        </Form.Row>
                        <ProjectSelector
                            permission={Permission.ManageComplianceSettingsWrite}
                            isEditMode={isEditMode}
                            allProjects={controlMeasureForm.values.allProjects}
                            setFieldValue={controlMeasureForm.setFieldValue}
                        />
                        <Form.Row>
                            <Form.Field name="description" label="Description">
                                <TextArea />
                            </Form.Field>
                        </Form.Row>
                        <Form.Row>
                            <Form.Field name="igmsLink" label="iGMS Link">
                                <Input />
                            </Form.Field>
                        </Form.Row>
                        <Form.Row>
                            <Form.Field
                                name="itemStatusWhenControlMeasureCompleted"
                                label="Item Status when Control Measure Completed">
                                <Select
                                    options={itemStatuses}
                                    loading={!itemStatuses}
                                    isClearable
                                    dataShape={{
                                        value: 'id',
                                        label: 'name',
                                    }}
                                />
                            </Form.Field>
                        </Form.Row>
                        <WarningPanel>
                            All applicable Control Measures must be Completed to progress the Item
                            Status as selected. eg: If two Control Measures progress the Item Status
                            to “Design Complete”, the Item Status will be updated when BOTH Control
                            Measures have been Completed.
                        </WarningPanel>
                        <Form.Row>
                            <Form.Field
                                name="updateInspectionScheduleStartDate"
                                label=""
                                className={styles['checkbox-container']}>
                                <Checkbox>Update Inspection Schedule Start Date</Checkbox>
                            </Form.Field>
                        </Form.Row>
                        <WarningPanel>
                            Check this checkbox to update the Inspection Schedule Start Date when
                            this Control Measure is completed.
                        </WarningPanel>
                        <Form.Row>
                            <Form.Field
                                name="evidenceRequired"
                                label=""
                                className={styles['checkbox-container']}>
                                <Checkbox>Evidence is required</Checkbox>
                            </Form.Field>
                        </Form.Row>
                        <WarningPanel>
                            Check this checkbox to only allow the Control Measure Task to be marked
                            as Completed when there is Evidence attached (one or more files or web
                            links). Note: Updating this setting only affects Control Measures
                            created after the change has been saved.
                        </WarningPanel>
                    </Modal.Body>
                    <ModalFooter submitText="Next" onCancel={goToControlMeasures} />
                </Form>
            ) : (
                <Form form={controlMeasureLeadDaysForm}>
                    <Modal.Body>
                        <Form.List
                            name="leadDays"
                            label="Lead Days"
                            render={(_) =>
                                controlMeasureLeadDaysForm.values.leadDays?.map((c, i) => (
                                    <React.Fragment key={c.classificationId}>
                                        <Form.Row className={styles.leadDaysRow}>
                                            <ClassificationBadge
                                                id={c.classificationId}
                                                className={styles.badge}
                                            />
                                            <Form.Field
                                                name={`leadDays[${i}].leadDays`}
                                                className={styles.leadDaysField}>
                                                <Input />
                                            </Form.Field>
                                        </Form.Row>
                                        <Form.Row className={styles.leadDaysRow}>
                                            <Form.Field
                                                name={`leadDays[${i}].comments`}
                                                className={styles.comments}>
                                                <TextArea placeholder="Comments" />
                                            </Form.Field>
                                        </Form.Row>
                                    </React.Fragment>
                                ))
                            }
                        />
                    </Modal.Body>
                    <ModalFooter
                        submitText={isEditMode ? 'Update' : 'Create'}
                        isSubmitting={controlMeasureLeadDaysForm.isSubmitting}
                        onCancel={goToControlMeasures}>
                        <Button variant="secondary" onClick={() => setShowLeadDaysForm(false)}>
                            Back
                        </Button>
                    </ModalFooter>
                </Form>
            )}
        </>
    );
};
