import { createAsyncThunk } from '@reduxjs/toolkit';
import { Area, AreaSummary } from 'domain/models/api-models';
import { get, patch, post, remove } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

const getSubAreaPrefix = (isSubArea: boolean) => (isSubArea ? 'Sub ' : '');

interface Params {
    projectNumber: string;
    itemTypeId: string;
}
export const fetchAreas = createAsyncThunk<Area[], Params, { state: RootState }>(
    'itemtypes/{itemTypeId}/areas/get',
    async (args) => {
        return (await get(`itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/areas`))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

export const fetchAreasSummaries = createAsyncThunk<AreaSummary[], Params, { state: RootState }>(
    'itemtypes/{itemTypeId}/areas/summaries/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/areas/summaries`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface CreateArea {
    itemTypeId: string;
    name: string;
    description: string;
    parentAreaId: string | undefined;
    ownerId?: string;
    isSubArea: boolean;
    projectNumber: string;
    fieldViewProjectId?: number | null;
    asiteWorkspaceId?: number | null;
    itemNumberPrefix?: string | null;
    itemNumberCommencement?: number | null;
}

export const createArea = createAsyncThunk<Area, CreateArea, { state: RootState }>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/areas/post',
    async (area, { getState, dispatch }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;

            const createdArea = await post(
                `itemtypes/${itemTypeId}/projects/${area.projectNumber}/areas`,
                area
            );
            dispatch(fetchAreas({ itemTypeId, projectNumber: area.projectNumber }));
            dispatch(
                addNotification({
                    message: `${getSubAreaPrefix(
                        area.isSubArea
                    )}Area has been created successfully`,
                    isSuccess: true,
                })
            );
            return createdArea;
        }, dispatch);
    }
);

interface UpdateArea {
    id: string;
    name: string;
    description: string;
    parentAreaId: string | undefined;
    ownerId?: string;
    isSubArea: boolean;
    itemTypeId: string;
    projectNumber: string;
    fieldViewProjectId?: number | null;
    asiteWorkspaceId?: number | null;
    itemNumberPrefix?: string | null;
    itemNumberCommencement?: number | null;
}

export const updateArea = createAsyncThunk<Area, UpdateArea, { state: RootState }>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/areas/patch',
    async (area, { dispatch }) => {
        return await runSafe(async () => {
            const updatedArea = await patch(
                `itemtypes/${area.itemTypeId}/projects/${area.projectNumber}/areas/${area.id}`,
                area
            );

            dispatch(
                fetchAreas({ itemTypeId: area.itemTypeId, projectNumber: area.projectNumber })
            );
            dispatch(
                addNotification({
                    message: `${getSubAreaPrefix(
                        area.isSubArea
                    )}Area has been updated successfully`,
                    isSuccess: true,
                })
            );
            return updatedArea;
        }, dispatch);
    }
);

interface DeleteParams {
    projectNumber: string;
    itemTypeId: string;
    areaId: string;
    isSubArea: boolean;
}
export const deleteArea = createAsyncThunk<string, DeleteParams, { state: RootState }>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/areas/delete',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await remove(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/areas/${args.areaId}`
            );
            dispatch(
                fetchAreas({ itemTypeId: args.itemTypeId, projectNumber: args.projectNumber })
            );
            dispatch(
                addNotification({
                    message: `${getSubAreaPrefix(
                        args.isSubArea
                    )}Area has been deleted successfully`,
                    isSuccess: true,
                })
            );
            return args.areaId;
        }, dispatch);
    }
);
