import { ColumnDefinition, selectFilter, textFilter } from '@laingorourke/core-web-components';
import { LorImagesCdn3rdPartyLogos } from 'consts';
import { nameof } from 'helpers/nameOf';
import { ProjectDisplayName } from 'views/components';
import { getFilterOptionsFromArray, TableCellActive, TableCellImage } from 'views/components/table';
import styles from '../../../../../components/table/Table.module.scss';
import { ApplicationType } from '../../ApplicationType';
import { IntegrationType } from '../../IntegrationType';
import { EnumMap, getKey } from '../applicationTypeEnumTypes';
import { SyncIntegrationModalButton } from '../SyncIntegrationModalButton';
import ActionButtons from './ActionButtons';
import { IntegrationTableRow } from './IntegrationTableRow';

const applicationLogosUrls: EnumMap = {
    [getKey(ApplicationType.Asite)]: `${LorImagesCdn3rdPartyLogos}/ASite.png`,
    [getKey(ApplicationType.FieldView)]: `${LorImagesCdn3rdPartyLogos}/FieldView.png`,
    [getKey(ApplicationType.TeamBinder)]: `${LorImagesCdn3rdPartyLogos}/InEightDocument.svg`,
};

const actionsFormatter = (cell: any, row: IntegrationTableRow) => (
    <ActionButtons integration={row} />
);

const syncColumn: ColumnDefinition<IntegrationTableRow> = {
    field: (d) => d.id,
    text: 'Sync',
    renderer: (_: any, row) =>
        row.isActive && !!row.controlMeasureId && row.canSync ? (
            <SyncIntegrationModalButton {...row} />
        ) : (
            <> </>
        ),
};

const integrationTypeOptions = getFilterOptionsFromArray(Object.keys(IntegrationType));

const applicationTypeOptions = getFilterOptionsFromArray(Object.keys(ApplicationType));

const actionsColumns: ColumnDefinition<IntegrationTableRow> = {
    field: (d) => d.id,
    text: '',
    renderer: actionsFormatter,
    headerClass: styles.minWidth100,
};
export const getTableColumns = (
    showSync: boolean,
    showActions: boolean
): ColumnDefinition<IntegrationTableRow>[] => [
    {
        field: (d) => d.projectName,
        text: 'Project',
        sortable: true,
        filter: textFilter(),
        headerClass: styles.minWidth300,
        renderer: (_, row: IntegrationTableRow) => (
            <ProjectDisplayName projectNumber={row.projectNumber} />
        ),
    },
    {
        field: (d) => d.type,
        text: 'Type',
        sortable: true,
        headerClass: styles.minWidth150,
        renderer: (_: any, row) => IntegrationType[row.type],
        filter: selectFilter({
            options: integrationTypeOptions,
        }),
    },
    {
        field: (d) => d.name,
        text: 'Name',
        key: nameof<IntegrationTableRow>('name'),
        sortable: true,
        filter: textFilter(),
        headerClass: styles.minWidth150,
    },
    {
        field: (d) => d.applicationType,
        text: 'Application',
        sortable: true,
        headerClass: styles.minWidth150,
        filter: selectFilter({
            options: applicationTypeOptions,
        }),
        renderer: (_: any, row) => {
            const applicationType =
                ApplicationType[row.applicationType as keyof typeof ApplicationType];

            return (
                <TableCellImage
                    text={applicationType}
                    src={applicationLogosUrls[row.applicationType as ApplicationType]}
                />
            );
        },
    },
    {
        field: (d) => d.formName,
        text: 'Form',
        sortable: true,
        filter: textFilter(),
    },
    {
        field: (d) => d.isActive,
        text: 'Status',
        sortable: true,
        renderer: (_: any, row: IntegrationTableRow) => <TableCellActive isActive={row.isActive} />,
    },
    {
        ...syncColumn,
        hidden: !showSync,
    },
    {
        ...actionsColumns,
        hidden: !showActions,
    },
];
