import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { History, Integration, Integrations, IntegrationsMetadata } from 'domain/models/api-models';
import {
    createIntegration,
    deleteIntegration,
    fetchIntegrationHistory,
    fetchIntegrations,
    fetchIntegrationsMetadata,
    updateIntegration,
} from './actions';

const initialState = {
    integrations: null as unknown as Integrations,
    metadata: null as unknown as IntegrationsMetadata,
    integrationHistory: undefined as unknown as History,
};

const integrationsSlice = createSlice({
    name: 'integrations',
    initialState: initialState,
    reducers: {
        clearIntegrations() {
            return initialState;
        },
        clearIntegrationsMetadata(state) {
            state.metadata = initialState.metadata;
        },
        clearIntegrationHistory(state) {
            state.integrationHistory = initialState.integrationHistory;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchIntegrations.fulfilled.type,
            (state, action: PayloadAction<Integrations>) => {
                state.integrations = action.payload;
            }
        );
        builder.addCase(
            fetchIntegrationsMetadata.fulfilled.type,
            (state, action: PayloadAction<IntegrationsMetadata>) => {
                state.metadata = action.payload;
            }
        );
        builder.addCase(
            fetchIntegrationHistory.fulfilled.type,
            (state, action: PayloadAction<History>) => {
                state.integrationHistory = action.payload;
            }
        );
        builder.addCase(
            createIntegration.fulfilled.type,
            (state, action: PayloadAction<Integration>) => {
                if (action.payload === undefined) return;
                state.integrations.integrationsList!.push(action.payload);
            }
        );
        builder.addCase(
            updateIntegration.fulfilled.type,
            (state, action: PayloadAction<Integration>) => {
                if (action.payload === undefined) return;
                state.integrations.integrationsList = state.integrations.integrationsList?.map(
                    (i) => (i.id === action.payload.id ? action.payload : i)
                );
            }
        );
        builder.addCase(
            deleteIntegration.fulfilled.type,
            (state, action: PayloadAction<string>) => {
                if (action.payload === undefined) return;
                state.integrations.integrationsList = state.integrations.integrationsList?.map(
                    (c) => (c.id === action.payload ? { ...c, isActive: false } : c)
                );
            }
        );
    },
});

export const { clearIntegrations, clearIntegrationsMetadata, clearIntegrationHistory } =
    integrationsSlice.actions;
export default integrationsSlice.reducer;
