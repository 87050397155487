import {
    ColumnDefinition,
    generatePath,
    Page,
    selectFilter,
    Table,
    textFilter,
} from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { Project } from 'domain/models/Project';
import {
    useSelectedItemTypeCode,
    useSelectedItemTypeCodeIsSet,
} from 'domain/store/reducers/itemType';
import { useProjects } from 'domain/store/reducers/projects/reducer';
import { getUserHasProjectPermission, useCurrentUser } from 'domain/store/reducers/users/reducer';
import { nameof } from 'helpers/nameOf';
import React, { PropsWithChildren, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ActionButtonsPanel } from 'views/components';
import Image from 'views/components/image/Image';
import {
    getDefaultPaginationOptions,
    getDefaultSortedByColummKey,
    getFilterOptions,
} from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { routes } from '../Routes';
import { useIsProjectsPage } from '../routesHooks';
import FavouriteButton from './components/FavouriteButton';
import styles from './Projects.module.scss';

const imageFormatter = (value: any, row: Project) => (
    <Image src={row.logoUrl} alt={row.name} width={40} />
);
const favouriteFormatter = (value: any, row: Project) => <FavouriteButton project={row} />;

export const Projects: React.FC<PropsWithChildren> = ({ children }) => {
    const [myProjectsFilter, setMyProjectsFilter] = useState(false);

    const currentUser = useCurrentUser();
    const projects = useProjects()?.filter(
        (p) => !!currentUser && getUserHasProjectPermission(currentUser, p.projectNumber)
    );
    const history = useHistory();
    const isSelectedItemTypeCodeIsSet = useSelectedItemTypeCodeIsSet();
    const selectedItemTypeCode = useSelectedItemTypeCode();
    const isProjectsPage = useIsProjectsPage();
    const tableLoadingProps = useTableLoading(!projects, 'No Projects found');
    const filteredProjects = projects?.filter((p) => !myProjectsFilter || p.isFavourite);

    const goToProject = (project: Project) => {
        if (isSelectedItemTypeCodeIsSet)
            history.push(
                generatePath(routes.projects.routes!.project.path, {
                    projectNumber: project.projectNumber,
                    itemTypeCode: selectedItemTypeCode,
                })
            );
        else
            history.push(
                generatePath(routes.projects.routes!.projectRoot.path, {
                    projectNumber: project.projectNumber,
                })
            );
    };

    const stageOptions = getFilterOptions(filteredProjects, 'stageDescription');
    const disciplineOptions = getFilterOptions(filteredProjects, 'discipline');
    const sectorOptions = getFilterOptions(filteredProjects, 'sector');

    const getSelectFilter = (options: OptionTypeBase[]) => ({
        filter: selectFilter({
            options: options,
        }),
    });

    const columns: ColumnDefinition<Project>[] = [
        {
            field: (d) => d.logoUrl,
            text: '',
            renderer: imageFormatter,
            headerClass: styles.logoUrl,
        },
        {
            field: (d) => d.name,
            text: 'Name',
            sortable: true,
            filter: textFilter(),
            headerClass: styles.minWidth200,
        },
        {
            field: (d) => d.stageDescription,
            text: 'Stage',
            sortable: true,
            ...getSelectFilter(stageOptions),
            headerClass: styles.minWidth150,
        },
        {
            field: (d) => d.discipline,
            text: 'Discipline',
            sortable: true,
            ...getSelectFilter(disciplineOptions),
            headerClass: styles.minWidth150,
        },
        {
            field: (d) => d.sector,
            text: 'Sector',
            sortable: true,
            ...getSelectFilter(sectorOptions),
        },
        {
            field: (d) => d.projectNumber,
            text: 'Project Number',
            sortable: true,
            filter: textFilter(),
            headerClass: styles.noWrappedWitheSpace,
        },
        {
            field: (d) => d.isFavourite,
            text: '',
            key: nameof<Project>('isFavourite'),
            sortable: true,
            renderer: favouriteFormatter,
            headerClass: styles.favoritesHeader,
            cellClass: styles.favoritesCell,
        },
    ];

    return (
        <>
            {isProjectsPage && (
                <Page
                    header={
                        <Page.Header
                            title="Projects"
                            actions={[
                                <ActionButtonsPanel>
                                    <Button
                                        variant="primary"
                                        onClick={() => setMyProjectsFilter(!myProjectsFilter)}>
                                        {myProjectsFilter ? 'All Projects' : 'My Projects'}
                                    </Button>
                                </ActionButtonsPanel>,
                            ]}></Page.Header>
                    }>
                    <Table
                        {...getDefaultPaginationOptions()}
                        {...getDefaultSortedByColummKey(
                            columns,
                            nameof<Project>('isFavourite'),
                            'desc'
                        )}
                        {...tableLoadingProps}
                        data={filteredProjects || []}
                        keySelector={(d) => d.id}
                        columns={columns}
                        saveSelectedPageSize
                        cacheKey="projects"
                        clickable
                        onRowClick={(row) => goToProject(row)}
                        className={styles.body}
                        hover
                    />
                </Page>
            )}
            {!isProjectsPage && <>{children}</>}
        </>
    );
};
