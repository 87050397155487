import { generatePath } from '@laingorourke/core-web-components';
import { useWorkflow } from '@laingorourke/core-web-mytasksreactsdk';
import { loadingCompleted, loadingStarted } from 'domain/store/reducers/inProgress/reducer';
import {
    useSelectedItemTypeCode,
    useSelectedItemTypeMyTasksWorkflowId,
} from 'domain/store/reducers/itemType';
import {
    fetchProjectItemCategoryInspections,
    useItemDetailsSummary,
    useProjectItemCategoryInspections,
} from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { ProjectItemDetailsParams } from '../../ProjectItemDetailsParams';
import { CreateForm } from './CreateForm';
import { EditSchedule } from './EditSchedue';

interface ScheduleParams extends ProjectItemDetailsParams {
    scheduleId?: string;
}

export const CreateSchedule: React.FC = () => {
    const dispatch = useAppDispatch();

    const { projectItemId, projectNumber, scheduleId } = useParams<ScheduleParams>();
    const isEditMode = !!scheduleId;
    const history = useHistory();
    const itemDetails = useItemDetailsSummary();
    const itemTypeCode = useSelectedItemTypeCode();
    const inspectionTypes = useProjectItemCategoryInspections()
        ?.map((i) => ({
            id: i.id,
            name: i.name!,
        }))
        .sort((a, b) => a.name!.localeCompare(b.name!));

    const workflowId = useSelectedItemTypeMyTasksWorkflowId();
    const workflow = useWorkflow(workflowId!);
    const statuses = workflow.data?.statuses;
    const itemDetailsLoaded = !!itemDetails;
    const categoryId = itemDetails?.categoryId;
    const isLoading = !(statuses && inspectionTypes && itemDetails);

    const goToSchedules = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.items.routes!.schedules.path, {
                projectItemId,
                projectNumber,
                itemTypeCode,
            })
        );

    const formProps = {
        projectNumber: projectNumber,
        projectItemId: projectItemId,
        dateInService: itemDetails?.dateInService,
        inspectionTypes: inspectionTypes,
        goBack: goToSchedules,
        isEditMode: isEditMode,
    };

    useEffect(() => {
        if (itemDetailsLoaded)
            dispatch(
                fetchProjectItemCategoryInspections({
                    projectNumber,
                    projectItemId,
                    categoryId: categoryId,
                })
            );
    }, [projectNumber, projectItemId, categoryId, itemDetailsLoaded, dispatch]);

    useEffect(() => {
        if (isLoading) dispatch(loadingStarted());
        else dispatch(loadingCompleted());
    }, [isLoading, dispatch]);

    return (
        <>
            {!isLoading && (
                <Modal show={true} onHide={goToSchedules} animation={false} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditMode ? 'Edit' : 'New'} Inspection Schedule</Modal.Title>
                    </Modal.Header>
                    <SecuredContent permissions={Permission.ManageItemsWrite}>
                        {!scheduleId && <CreateForm {...formProps} />}
                        {scheduleId && <EditSchedule {...formProps} scheduleId={scheduleId} />}
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
