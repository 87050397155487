import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker, Form, Input, Select, useForm } from '@laingorourke/core-web-components';
import { Status, TASKKEY } from '@laingorourke/core-web-mytasksreactsdk';
import { useQueryClient } from '@tanstack/react-query';
import format from 'date-fns/format';
import { ProjectControlMeasure } from 'domain/models/api-models';
import { createProjectItemControlMeasure } from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalFooter, ProjectPersonSelector } from 'views/components';
import { getStatusesForTaskCreation } from 'views/routes/project/myTasksHelpers';
import { NewItemControlMeasureSchema } from '../schemas';
import { DateInPastWarning } from './DateInPastWarning';

interface CreateControlMeasureFormProps {
    statuses: Status[];
    controlMeasures: ProjectControlMeasure[];
    projectNumber: string;
    projectItemId: string;
    goBack: () => void;
}

const emptyControlMeasureValue = 'other';

export const CreateForm: React.FC<CreateControlMeasureFormProps> = ({
    controlMeasures,
    projectNumber,
    projectItemId,
    statuses,
    goBack,
}) => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const statusTypes = getStatusesForTaskCreation(statuses);

    const form = useForm({
        initialValues: {
            controlMeasureId: '',
            name: '',
            ownerId: '',
            statusType: statusTypes[0].value,
            dueDate: '',
        },
        validationSchema: NewItemControlMeasureSchema,
        onSubmit: async (values) => {
            const { dueDate, controlMeasureId, ...createValues } = values;
            const result = await dispatch(
                createProjectItemControlMeasure({
                    ...createValues,
                    controlMeasureId:
                        controlMeasureId === emptyControlMeasureValue
                            ? undefined
                            : controlMeasureId,
                    dueDate: format(new Date(dueDate), 'yyyy-MM-dd'),
                    projectNumber,
                    projectItemId,
                })
            );

            if ((result as any).payload) {
                queryClient.invalidateQueries(TASKKEY());
                goBack();
            }
        },
    });

    const { isSubmitting, values, setFieldValue } = form;

    const controlMeasureOptions = [
        ...controlMeasures!.map((c) => ({ value: c.id, label: c.name! })),
        {
            label: 'Other',
            value: emptyControlMeasureValue,
        },
    ];

    useEffect(() => {
        if (values.controlMeasureId !== emptyControlMeasureValue) setFieldValue('name', '');
    }, [values.controlMeasureId, setFieldValue]);

    return (
        <Form form={form}>
            <Modal.Body>
                <Form.Row>
                    <Form.Field name="controlMeasureId" label="Control Measure">
                        <Select options={controlMeasureOptions} />
                    </Form.Field>
                </Form.Row>
                {values.controlMeasureId === emptyControlMeasureValue && (
                    <Form.Row>
                        <Form.Field name="name" label="Name">
                            <Input />
                        </Form.Field>
                    </Form.Row>
                )}
                <Form.Row>
                    <Form.Field name="statusType" label="Status">
                        <Select options={statusTypes} />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="ownerId" label="Assignee">
                        <ProjectPersonSelector
                            projectNumber={projectNumber}
                            placeholder="Select Assignee..."
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="dueDate" label="Forecasted Completion">
                        <DatePicker append={<FontAwesomeIcon icon={faCalendar} />} />
                    </Form.Field>
                </Form.Row>
                <DateInPastWarning dates={values.dueDate} label="Forecasted Completion" />
            </Modal.Body>
            <ModalFooter submitText="Create" isSubmitting={isSubmitting} onCancel={goBack} />
        </Form>
    );
};
