import { ColumnDefinition } from '@laingorourke/core-web-components';
import { useClassificationsTableColumns } from 'views/components';
import styles from '../../../../components/table/Table.module.scss';
import { ClassificationTableRow } from './ClassificationTableRow';
import ActionButtons from './components/ActionButtonts';

const actionsFormatter = (cell: any, row: ClassificationTableRow) => (
    <ActionButtons classification={row} />
);

const actionsColumn: ColumnDefinition<ClassificationTableRow> = {
    field: (d) => d.id,
    text: '',
    renderer: actionsFormatter,
    headerClass: styles.minWidth100,
};

export const useTableColumns = (
    showActions: boolean,
    itemTypeId: string,
    projectNumber: string
): ColumnDefinition<ClassificationTableRow>[] => {
    return [
        ...useClassificationsTableColumns(false, itemTypeId, projectNumber),
        {
            ...actionsColumn,
            hidden: !showActions,
        },
    ];
};
