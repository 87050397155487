import { ColumnDefinition, selectFilter } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { format } from 'date-fns';
import { nameof } from 'helpers/nameOf';
import { PersonInfo } from 'hooks/useWithPerson';
import { DateTableCell, TableCellActive } from 'views/components';
import { getPersonColumn } from 'views/components/table/personColumn';
import styles from '../../../../../components/table/Table.module.scss';
import { ActionButtons } from './components/ActionButtons';

interface TableRow {
    id: string;
    name: string;
    canBeEdited: boolean;
    canBeDeleted: boolean;
    startDate: Date;
    endDate?: Date;
    offset: number;
    editBlockedMessage: string;
    actionsBlockedMessage: string;
    assignee: PersonInfo;
    isActive: boolean;
    description: string;
    assignedUserId: string;
}

const actionsFormatter = (cell: any, row: TableRow) => (
    <ActionButtons
        id={row.id}
        name={row.name}
        canBeEdited={row.canBeEdited}
        editBlockedMessage={row.editBlockedMessage}
        canBeDeleted={row.canBeDeleted}
        actionsBlockedMessage={row.actionsBlockedMessage}
        currentEndDate={!!row.endDate ? format(new Date(row.endDate), 'yyyy-MM-dd') : undefined}
    />
);
const actionsColumn: ColumnDefinition<TableRow> = {
    field: (d) => d.id,
    text: '',
    renderer: actionsFormatter,
    headerClass: styles.minWidth100,
};

export const getTableColumns = (
    inspectionOptions: OptionTypeBase[],
    userHasWritePermissions: boolean,
    userHasDeletePermissions: boolean,
    projectId?: string
): ColumnDefinition<TableRow>[] => [
    {
        field: (d) => d.name,
        key: nameof<TableRow>('name'),
        text: 'Inspection Type',
        sortable: true,
        headerClass: styles.minWidth180,
        filter: selectFilter({
            options: inspectionOptions,
        }),
    },
    {
        field: (d) => d.assignedUserId,
        text: 'Assignee',
        ...getPersonColumn('assignee', true, true, projectId),
    },
    {
        field: (d) => d.description,
        text: 'Schedule',
    },
    {
        field: (d) => d.startDate,
        text: 'Start Date',
        sortable: true,
        headerClass: styles.minWidth150,
        renderer: (cell: any, row: TableRow) => <DateTableCell date={row.startDate} />,
    },
    {
        field: (d) => d.endDate,
        text: 'End Date',
        sortable: true,
        headerClass: styles.minWidth150,
        renderer: (cell: any, row: TableRow) => <DateTableCell date={row.endDate} />,
    },
    {
        field: (d) => d.offset,
        text: 'Add Before Days',
        sortable: true,
        headerClass: styles.minWidth150,
    },
    {
        field: (d) => d.isActive,
        text: 'Status',
        sortable: true,
        renderer: (cell: any, row: TableRow) => <TableCellActive isActive={row.isActive} />,
    },
    {
        ...actionsColumn,
        hidden: !(userHasDeletePermissions || userHasWritePermissions),
    },
];
