import { ColumnDefinition } from '@laingorourke/core-web-components';
import { DateTableCell } from 'views/components';
import { getPersonColumn } from 'views/components/table/personColumn';
import styles from './UpcomingInspection.module.scss';
import { UpcomingTaskTableRow } from './UpcomingTaskTableRow';

export const getTableColumns = (
    statusesSortOrder: string[] | undefined
): ColumnDefinition<UpcomingTaskTableRow>[] => [
    // This column has been added to store Task Id required to redirect user to MyTasks app
    {
        field: (d) => d.id,
        text: '',
        hidden: true,
    },
    {
        field: (d) => d.healthColor,
        text: '',
        headerClass: styles.healthHeader,
        cellStyle: (row) => ({
            ['--bs-table-accent-bg']: row.healthColor,
            padding: 0,
        }),
        renderer: (_: any, row: UpcomingTaskTableRow) => <></>,
    },
    {
        field: (d) => d.name,
        text: 'Task',
        sortable: true,
    },
    {
        field: (d) => d.forecastedOn,
        text: 'Forecasted Completion',
        sortable: true,
        renderer: (_: any, row: UpcomingTaskTableRow) => (
            <DateTableCell date={row.forecastedOn} />
        ),
    },
    {
        field: (d) => d.status,
        text: 'Status',
        sortable: true,
        headerClass: styles.noWrappedWitheSpace,
        customSort: (a, b, order) => {
            if (statusesSortOrder)
                return order === 'asc'
                    ? statusesSortOrder.indexOf(a.status) - statusesSortOrder.indexOf(b.status)
                    : statusesSortOrder.indexOf(b.status) - statusesSortOrder.indexOf(a.status);
            return 1;
        },
    },
    {
        field: (d) => d.assignedUserId,
        text: 'Assignee',
        ...getPersonColumn('assignee', false, true),
    },
    {
        field: (d) => d.health,
        text: 'Health',
        sortable: true,
    },
];
