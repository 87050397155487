import { ColumnDefinition, textFilter } from '@laingorourke/core-web-components';
import { Review } from 'domain/models/api-models';
import { nameof } from 'helpers/nameOf';
import { PersonInfo } from 'hooks/useWithPerson';
import { DateTableCell, Link } from 'views/components/';
import { getPersonColumn } from 'views/components/table/personColumn';
import styles from '../../../components/table/Table.module.scss';
import ActionButtons from './components/ActionButtons';
import columnsStyles from './tableColumns.module.scss';

const actionsFormatter = (cell: any, row: ReviewTableRow) => <ActionButtons review={row} />;

const actionsColumn: ColumnDefinition<ReviewTableRow> = {
    field: (d) => d.id,
    text: '',
    renderer: actionsFormatter,
    headerClass: styles.minWidth100,
};

interface ReviewTableRow extends Review {
    reviewer: PersonInfo;
}

export const getTableColumns = (
    showEdit: boolean,
    showDelete: boolean,
    projectId?: string
): ColumnDefinition<ReviewTableRow>[] => [
        {
            field: (d) => d.reviewerId,
            text: 'Reviewer',
            ...getPersonColumn('reviewer', true, true, projectId),
        },
        {
            field: (d) => d.date,
            text: 'Review Date',
            key: nameof<ReviewTableRow>('date'),
            sortable: true,
            renderer: (_: any, row: Review) => <DateTableCell date={row.date} />,
        },
        {
            field: (d) => d.comments,
            text: 'Comments',
            filter: textFilter(),
            cellClass: styles.longTextContent,
        },
        {
            field: (d) => d.evidence,
            text: 'Document Link',
            renderer: (_: any, row: Review) =>
                row.evidence && (<div>
                    <Link target="_blank" href={row.evidence.url}>
                        {row.evidence?.name}
                    </Link>
                    {!!row.evidence.error && <span className={columnsStyles.error}>{row.evidence.error}</span>}
                </div>
                ),
        },
        {
            ...actionsColumn,
            hidden: !(showEdit || showDelete),
        },
    ];
