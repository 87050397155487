import { faEdit } from '@fortawesome/free-regular-svg-icons/faEdit';
import React from 'react';
import { ButtonProps } from 'react-bootstrap';
import { ActionButton } from './ActionButton';

export interface EditButtonProps extends ButtonProps {
    message: string;
    key: string;
}

export const EditButton: React.FC<EditButtonProps> = (props) => {
    return <ActionButton {...props} icon={faEdit} />;
};
