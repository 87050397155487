import {
    emojis,
    emojiValidationMessage,
    invalidCharacters,
} from 'views/components/form/validators';
import * as Yup from 'yup';

export const NewItemControlMeasureSchema = Yup.object().shape({
    name: Yup.string()
        .matches(emojis, emojiValidationMessage)
        .matches(invalidCharacters, 'Name contains invalid characters')
        .max(100, 'Name cannot be longer than 100 characters')
        .when('controlMeasureId', {
            is: 'other',
            then: Yup.string().required('Name is required'),
        }),
    controlMeasureId: Yup.string().required('Control Measure is required'),
    dueDate: Yup.date().required('Forecasted Completion is required').typeError('Forecasted Completion is required'),
    ownerId: Yup.string().nullable().required('Assignee is required'),
    statusType: Yup.string().required('Status is required'),
});
