export enum Permission {
    ProjectDetailsRead = 'project-details-read',
    ProjectReportsRead = 'project-reports-read',
    ProjectLeadDaysRead = 'project-lead-days-read',
    ProjectLeadDaysWrite = 'project-lead-days-write',
    ManageItemsRead = 'manage-items-read',
    ManageItemsWrite = 'manage-items-write',
    ManageItemsDelete = 'manage-items-delete',
    ManageAreasRead = 'manage-areas-read',
    ManageAreasWrite = 'manage-areas-write',
    ManageAreasDelete = 'manage-areas-delete',
    ManageClassificationSettingsRead = 'manage-classification-settings-read',
    ManageComplianceSettingsRead = 'manage-compliance-settings-read',
    ManageComplianceSettingsWrite = 'manage-compliance-settings-write',
    ManageComplianceSettingsDelete = 'manage-compliance-settings-delete',
    ManageIntegrationSettingsRead = 'manage-integration-settings-read',
    ManageIntegrationSettingsWrite = 'manage-integration-settings-write',
    ManageIntegrationSettingsDelete = 'manage-integration-settings-delete',
    ManageTourSettingsRead = 'manage-tour-settings-read',
    ManageTourSettingsWrite = 'manage-tour-settings-write',
    ManageTourSettingsDelete = 'manage-tour-settings-delete',
    ManageWeatherSettingsRead = 'manage-weather-settings-read',
    ManageWeatherSettingsWrite = 'manage-weather-settings-write',
    ManageWeatherSettingsDelete = 'manage-weather-settings-delete',
    ManageDesignerSettingsRead = 'manage-designer-settings-read',
    ManageDesignerSettingsWrite = 'manage-designer-settings-write',
    ManageDesignerSettingsDelete = 'manage-designer-settings-delete',
    ProjectGovernanceRead = 'project-governance-read',
    ProjectGovernanceWrite = 'project-governance-write',
    ProjectGovernanceDelete = 'project-governance-delete',
    ProjectClassificationsRead = 'project-classifications-read',
    ProjectClassificationsWrite = 'project-classifications-write',
    ProjectClassificationsDelete = 'project-classifications-delete',
    ProjectDesignersRead = 'project-designers-read',
    ProjectDesignerWrite = 'project-designers-write',
    ProjectItemNumberRead = 'project-item-number-read',
    ProjectItemNumberWrite = 'project-item-number-write',
    ProjectQrCodeRead = 'project-qr-code-read',
    ProjectQrCodeWrite = 'project-qr-code-write',
    ProjectQrCodeDelete = 'project-qr-code-delete',
    TransferRead = 'transfer-read',
    TransferWrite = 'transfer-write',
    ProjectWeatherAcknowledgementOnBehalfOfRead = 'project-weather-acknowledgement-on-behalf-of-read',
    ProjectWeatherAcknowledgementRead = 'project-weather-acknowledgement-read',
    IncludeInOwnersListRead = 'include-in-owners-list-read',
    IncludeInAreaOwnersListRead = 'include-in-area-owners-list-read',
    ProjectPermissionManagementRead = 'project-permission-management-read',
    ProjectPermissionManagementWrite = 'project-permission-management-write',
    ProjectPermissionManagementDelete = 'project-permission-management-delete',
    ManageInspectionsWrite = 'manage-inspections-write',
    ManageInspectionsDelete = 'manage-inspections-delete',
}
