import { Button, Page } from '@laingorourke/core-web-components';
import { errorOccurredMessage, isDevelopment } from 'appSettings';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorFallback: React.FC<FallbackProps> = (props) => {
    const { error, resetErrorBoundary } = props;
    return (
        <Page header={{ title: 'An error occurred' }}>
            <Page.Section>
                <div role="alert">
                    <p>{errorOccurredMessage}</p>
                    {isDevelopment && (
                        <>
                            <pre>{error!.message}</pre>
                            <pre>{error.stack}</pre>
                        </>
                    )}
                    <Button variant="primary" onClick={resetErrorBoundary}>
                        Try again
                    </Button>
                </div>
            </Page.Section>
        </Page>
    );
};

export default ErrorFallback;
