import { ScanQrCodePage } from '@laingorourke/core-web-components-qr-reader';
import { Routes } from '@laingorourke/core-web-types';
import {
    Admin,
    Classifications as AdminClassifications,
    ControlMeasures,
    InspectionTypes,
    UpsertControlMeasure as UpsertAdminControlMeasure,
    UpsertInspectionType,
} from './admin';
import { Designers, UpsertDesigner } from './admin/designers';
import { Tours, UpdateTourStep } from './admin/tour';
import { CreateTransfer, Transfers } from './admin/transfers';
import { UpsertWeatherInspectionType, WeatherInspectionTypes } from './admin/weather';
import { Weather } from './admin/weather/Weather';
import {
    Areas,
    ClassificationGuidelines,
    Classifications,
    CreateControlMeasure as CreateProjectItemControlMeasure,
    CreateSchedule,
    CreateUpcomingInspection as CreateProjectItemInspection,
    Designers as ProjectDesigners,
    Details as AreaDetails,
    Integrations as ProjectIntegration,
    ItemBuiltViewMedia,
    ItemGroups,
    ItemHistory,
    ItemInfo,
    ItemNumber,
    Items,
    LeadDays,
    ProjectSetup,
    Reports,
    Schedules,
    UpdateLeadDays,
    UploadCsv,
    UpsertArea,
    UpsertClassification,
    UpsertItem,
} from './project';
import { Mode } from './project/items/list/components/Mode';
import { Preview } from './project/items/list/components/Preview';
import { Project } from './project/Project';
import { AssignPermission } from './project/projectSetup/permissionManagement/components/AssignPermission';
import { PermissionManagement } from './project/projectSetup/permissionManagement/PermissionManagement';
import { DeleteLogo, QrCode, UploadLogo } from './project/projectSetup/qrCode';
import { UpsertReview } from './project/reviews/components/UpsertReview';
import { Reviews } from './project/reviews/Reviews';
import Tasks from './project/tasks/Tasks';
// we cannot use ./project import TS is unhappy about circular reference (?)
import { WeatherEventItems } from './project/weather/components/weatherEventsItems';
import { Weather as ProjectWeather } from './project/weather/Weather';
import { Projects } from './projects/Projects';
import { SearchPage } from './search';
import { IntegrationHistory, Integrations, UpsertIntegration } from './shared';
import {
    ComplianceTemplateDetails,
    ComplianceTemplates,
    UpsertComplianceTemplate,
} from './shared/complianceTemplates';
import { WeatherEvents } from './shared/weatherEvents/WeatherEvents';

const getItemDetailsRoutes = (parentRouteName: string): Routes => ({
    newControlMeasure: {
        path: `/projects/:projectNumber/:itemTypeCode/items/:projectItemId/${parentRouteName}/control-measures/new`,
        exact: true,
        component: <CreateProjectItemControlMeasure />,
        title: 'Items',
    },
    newInspection: {
        path: `/projects/:projectNumber/:itemTypeCode/items/:projectItemId/${parentRouteName}/inspections/new`,
        exact: true,
        component: <CreateProjectItemInspection />,
        title: 'Items',
    },
});

export const routes: Routes = {
    home: {
        path: '/',
        exact: true,
    },
    projects: {
        path: '/projects',
        title: 'Projects',
        component: <Projects />,
        routes: {
            projectRoot: {
                path: '/projects/:projectNumber',
                component: <Project />,
                title: 'Project',
                exact: true,
            },
            project: {
                path: '/projects/:projectNumber/:itemTypeCode',
                component: <Project />,
                title: 'Project',
                routes: {
                    areas: {
                        path: '/projects/:projectNumber/:itemTypeCode/areas',
                        component: <Areas />,
                        title: 'Areas',
                        routes: {
                            details: {
                                path: '/projects/:projectNumber/:itemTypeCode/areas/:areaId/details',
                                exact: true,
                                title: 'Areas',
                                component: <AreaDetails />,
                            },
                            newArea: {
                                path: '/projects/:projectNumber/:itemTypeCode/areas/new-area',
                                exact: true,
                                component: <UpsertArea subArea={false} />,
                                title: 'Areas',
                            },
                            newSubArea: {
                                path: '/projects/:projectNumber/:itemTypeCode/areas/new-sub-area',
                                exact: true,
                                component: <UpsertArea subArea={true} />,
                                title: 'Areas',
                            },
                            editArea: {
                                path: '/projects/:projectNumber/:itemTypeCode/areas/edit/:areaId',
                                exact: true,
                                component: <UpsertArea />,
                                title: 'Areas',
                            },
                        },
                    },
                    reviews: {
                        path: '/projects/:projectNumber/:itemTypeCode/reviews',
                        component: <Reviews />,
                        title: 'Reviews',
                        routes: {
                            newReview: {
                                path: '/projects/:projectNumber/:itemTypeCode/reviews/new',
                                exact: true,
                                component: <UpsertReview />,
                                title: 'Reviews',
                            },
                            editReview: {
                                path: '/projects/:projectNumber/:itemTypeCode/reviews/edit/:reviewId',
                                exact: true,
                                component: <UpsertReview />,
                                title: 'Reviews',
                            },
                        },
                    },
                    weather: {
                        path: '/projects/:projectNumber/:itemTypeCode/weather',
                        component: <ProjectWeather />,
                        title: 'Weather',
                        routes: {
                            weatherEventsItems: {
                                path: '/projects/:projectNumber/:itemTypeCode/weather/items',
                                component: <WeatherEventItems />,
                                title: 'Items',
                                routes: {
                                    preview: {
                                        path: '/projects/:projectNumber/:itemTypeCode/weather/items/:projectItemId/preview',
                                        component: (
                                            <Preview returnPath="/projects/:projectNumber/:itemTypeCode/weather/items" />
                                        ),
                                        title: 'Item Details',
                                        routes: getItemDetailsRoutes('preview'),
                                    },
                                },
                            },
                            weatherEvents: {
                                path: '/projects/:projectNumber/:itemTypeCode/weather/site-events',
                                component: <WeatherEvents />,
                                title: 'Site Events',
                            },
                        },
                    },
                    projectSetup: {
                        path: '/projects/:projectNumber/:itemTypeCode/project-setup',
                        component: <ProjectSetup />,
                        title: 'Project Setup',
                        routes: {
                            leadDays: {
                                path: '/projects/:projectNumber/:itemTypeCode/project-setup/lead-days',
                                component: <LeadDays />,
                                title: 'Lead Days',
                                routes: {
                                    editLeadDays: {
                                        path: '/projects/:projectNumber/:itemTypeCode/project-setup/lead-days/edit/:controlMeasureId',
                                        exact: true,
                                        component: <UpdateLeadDays />,
                                        title: 'Lead Days',
                                    },
                                },
                            },
                            classifications: {
                                path: '/projects/:projectNumber/:itemTypeCode/project-setup/classifications',
                                component: <Classifications />,
                                title: 'Classifications',
                                routes: {
                                    newClassification: {
                                        path: '/projects/:projectNumber/:itemTypeCode/project-setup/classifications/new',
                                        exact: true,
                                        component: <UpsertClassification />,
                                        title: 'Classifications',
                                    },
                                    editClassification: {
                                        path: '/projects/:projectNumber/:itemTypeCode/project-setup/classifications/edit/:classificationId',
                                        exact: true,
                                        component: <UpsertClassification />,
                                        title: 'Classifications',
                                    },
                                },
                            },
                            designers: {
                                path: '/projects/:projectNumber/:itemTypeCode/project-setup/designers',
                                component: <ProjectDesigners />,
                                title: 'Designers',
                            },
                            itemNumber: {
                                path: '/projects/:projectNumber/:itemTypeCode/project-setup/item-number',
                                component: <ItemNumber />,
                                title: 'Item Number',
                            },
                            complianceTemplates: {
                                path: '/projects/:projectNumber/:itemTypeCode/project-setup/compliance-templates',
                                component: <ComplianceTemplates />,
                                title: 'Compliance Templates',
                                routes: {
                                    details: {
                                        path: '/projects/:projectNumber/:itemTypeCode/project-setup/compliance-templates/details/:complianceTemplateId',
                                        title: 'Compliance Templates',
                                        component: <ComplianceTemplateDetails />,
                                    },
                                    newComplianceTemplate: {
                                        path: '/projects/:projectNumber/:itemTypeCode/project-setup/compliance-templates/new',
                                        exact: true,
                                        component: <UpsertComplianceTemplate />,
                                        title: 'Compliance Templates',
                                    },
                                    editComplianceTemplate: {
                                        path: '/projects/:projectNumber/:itemTypeCode/project-setup/compliance-templates/edit/:complianceTemplateId',
                                        exact: true,
                                        component: <UpsertComplianceTemplate />,
                                        title: 'Compliance Templates',
                                    },
                                },
                            },
                            integrations: {
                                path: '/projects/:projectNumber/:itemTypeCode/project-setup/integrations',
                                component: <ProjectIntegration />,
                                title: 'Integrations',
                                routes: {
                                    newIntegration: {
                                        path: '/projects/:projectNumber/:itemTypeCode/project-setup/integrations/new',
                                        exact: true,
                                        component: <UpsertIntegration />,
                                        title: 'Integrations',
                                    },
                                    editIntegration: {
                                        path: '/projects/:projectNumber/:itemTypeCode/project-setup/integrations/edit/:integrationId',
                                        exact: true,
                                        component: <UpsertIntegration />,
                                        title: 'Integrations',
                                    },
                                    projectIntegrationHistory: {
                                        path: '/projects/:projectNumber/:itemTypeCode/project-setup/integrations/history/:integrationId',
                                        component: <IntegrationHistory />,
                                        title: 'Integration history',
                                        exact: true,
                                    },
                                },
                            },
                            qrCode: {
                                path: '/projects/:projectNumber/:itemTypeCode/project-setup/qrcode',
                                component: <QrCode />,
                                title: 'QR Code',
                                routes: {
                                    uploadLogo: {
                                        path: '/projects/:projectNumber/:itemTypeCode/project-setup/qrcode/upload-logo',
                                        exact: true,
                                        component: <UploadLogo />,
                                        title: 'Upload Logo',
                                    },
                                    deleteLogo: {
                                        path: '/projects/:projectNumber/:itemTypeCode/project-setup/qrcode/delete-logo',
                                        exact: true,
                                        component: <DeleteLogo />,
                                        title: 'Upload Logo',
                                    },
                                },
                            },
                            permissionManagement: {
                                path: '/projects/:projectNumber/:itemTypeCode/project-setup/permission-management',
                                component: <PermissionManagement />,
                                title: 'Permission Management',
                                routes: {
                                    assignPermission: {
                                        path: '/projects/:projectNumber/:itemTypeCode/project-setup/permission-management/assign-permission',
                                        exact: true,
                                        component: <AssignPermission />,
                                        title: 'Assign Permission',
                                    },
                                },
                            },
                        },
                    },
                    classificationGuidelines: {
                        path: '/projects/:projectNumber/:itemTypeCode/classification-guidelines/:categoryId?',
                        component: <ClassificationGuidelines />,
                        title: 'Classification Guidelines',
                    },
                    items: {
                        path: '/projects/:projectNumber/:itemTypeCode/items',
                        component: <Items />,
                        title: 'Items',
                        routes: {
                            newItem: {
                                path: '/projects/:projectNumber/:itemTypeCode/items/new',
                                exact: true,
                                component: <UpsertItem mode={Mode.New} />,
                                title: 'Items',
                            },
                            uploadCsv: {
                                path: '/projects/:projectNumber/:itemTypeCode/items/upload-csv',
                                exact: true,
                                component: <UploadCsv />,
                                title: 'Items',
                            },
                            schedules: {
                                path: '/projects/:projectNumber/:itemTypeCode/items/:projectItemId/schedules',
                                component: <Schedules />,
                                title: 'Inspection Schedules',
                                routes: {
                                    newSchedule: {
                                        path: '/projects/:projectNumber/:itemTypeCode/items/:projectItemId/schedules/new',
                                        exact: true,
                                        component: <CreateSchedule />,
                                        title: 'Create Schedule',
                                    },
                                    editSchedule: {
                                        path: '/projects/:projectNumber/:itemTypeCode/items/:projectItemId/schedules/edit/:scheduleId',
                                        exact: true,
                                        component: <CreateSchedule />,
                                        title: 'Edit Schedule',
                                    },
                                },
                            },
                            history: {
                                path: '/projects/:projectNumber/:itemTypeCode/items/:projectItemId/history',
                                exact: true,
                                component: <ItemHistory />,
                                title: 'History',
                            },
                            itemDetails: {
                                path: '/projects/:projectNumber/:itemTypeCode/items/:projectItemId/info',
                                component: <ItemInfo />,
                                title: 'Item Details',
                                routes: getItemDetailsRoutes('info'),
                            },
                            preview: {
                                path: '/projects/:projectNumber/:itemTypeCode/items/:projectItemId/preview',
                                component: (
                                    <Preview returnPath="/projects/:projectNumber/:itemTypeCode/items" />
                                ),
                                title: 'Item Details',
                                routes: getItemDetailsRoutes('preview'),
                            },
                            groups: {
                                path: '/projects/:projectNumber/:itemTypeCode/items/:projectItemId/groups',
                                exact: true,
                                component: <ItemGroups />,
                                title: 'Subcontractors',
                            },
                            builtViewMedia: {
                                path: '/projects/:projectNumber/:itemTypeCode/items/:projectItemId/builtview',
                                exact: true,
                                component: <ItemBuiltViewMedia />,
                                title: 'BuiltView',
                            },
                        },
                    },
                    reports: {
                        path: '/projects/:projectNumber/:itemTypeCode/reports',
                        component: <Reports />,
                        exact: true,
                        title: 'Reports',
                    },
                    tasks: {
                        path: '/projects/:projectNumber/:itemTypeCode/tasks',
                        component: <Tasks />,
                        exact: true,
                        title: 'Tasks',
                    },
                },
            },
        },
    },
    adminRoot: {
        path: '/admin',
        title: 'Administration',
        component: <Admin />,
        exact: true,
    },
    admin: {
        path: '/admin/:itemTypeCode',
        title: 'Administration',
        component: <Admin />,
        routes: {
            complianceTemplates: {
                path: '/admin/:itemTypeCode/compliance-templates',
                component: <ComplianceTemplates />,
                title: 'Compliance Templates',
                routes: {
                    details: {
                        path: '/admin/:itemTypeCode/compliance-templates/details/:complianceTemplateId',
                        exact: true,
                        title: 'Compliance Templates',
                        component: <ComplianceTemplateDetails />,
                    },
                    newComplianceTemplate: {
                        path: '/admin/:itemTypeCode/compliance-templates/new',
                        exact: true,
                        component: <UpsertComplianceTemplate />,
                        title: 'Compliance Templates',
                    },
                    editComplianceTemplate: {
                        path: '/admin/:itemTypeCode/compliance-templates/edit/:complianceTemplateId',
                        exact: true,
                        component: <UpsertComplianceTemplate />,
                        title: 'Compliance Templates',
                    },
                },
            },
            controlMeasures: {
                path: '/admin/:itemTypeCode/control-measures',
                component: <ControlMeasures />,
                title: 'Control Measures',
                routes: {
                    newControlMeasure: {
                        path: '/admin/:itemTypeCode/control-measures/new',
                        exact: true,
                        component: <UpsertAdminControlMeasure />,
                        title: 'Control Measures',
                    },
                    editControlMeasure: {
                        path: '/admin/:itemTypeCode/control-measures/edit/:controlMeasureId',
                        exact: true,
                        component: <UpsertAdminControlMeasure />,
                        title: 'Control Measures',
                    },
                },
            },
            inspectionTypes: {
                path: '/admin/:itemTypeCode/inspections',
                component: <InspectionTypes />,
                title: 'Inspections',
                routes: {
                    newInspectionType: {
                        path: '/admin/:itemTypeCode/inspections/new',
                        exact: true,
                        component: <UpsertInspectionType />,
                        title: 'Inspections',
                    },
                    editInspectionType: {
                        path: '/admin/:itemTypeCode/inspections/edit/:inspectionTypeId',
                        exact: true,
                        component: <UpsertInspectionType />,
                        title: 'Inspections',
                    },
                },
            },
            integrations: {
                path: '/admin/:itemTypeCode/integrations',
                component: <Integrations />,
                title: 'Integrations',
                routes: {
                    newIntegration: {
                        path: '/admin/:itemTypeCode/integrations/new',
                        exact: true,
                        component: <UpsertIntegration />,
                        title: 'Integrations',
                    },
                    editIntegration: {
                        path: '/admin/:itemTypeCode/integrations/edit/:integrationId',
                        exact: true,
                        component: <UpsertIntegration />,
                        title: 'Integrations',
                    },
                    integrationHistory: {
                        path: '/admin/:itemTypeCode/integrations/history/:integrationId/',
                        component: <IntegrationHistory />,
                        title: 'Integration history',
                        exact: true,
                    },
                },
            },
            designers: {
                path: '/admin/:itemTypeCode/designers',
                component: <Designers />,
                title: 'Designers',
                routes: {
                    newDesigner: {
                        path: '/admin/:itemTypeCode/designers/new',
                        exact: true,
                        component: <UpsertDesigner />,
                        title: 'Designers',
                    },
                    editDesigner: {
                        path: '/admin/:itemTypeCode/designers/edit/:designerId',
                        exact: true,
                        component: <UpsertDesigner />,
                        title: 'Designers',
                    },
                },
            },
            classifications: {
                path: '/admin/:itemTypeCode/classifications',
                component: <AdminClassifications />,
                title: 'Classifications',
            },
            transfers: {
                path: '/admin/:itemTypeCode/transfers',
                component: <Transfers />,
                title: 'Transfers',
                routes: {
                    newTransfer: {
                        path: '/admin/:itemTypeCode/transfers/new',
                        exact: true,
                        component: <CreateTransfer />,
                        title: 'Transfers',
                    },
                },
            },
            tour: {
                path: '/admin/:itemTypeCode/tour',
                component: <Tours />,
                title: 'Tour',
                routes: {
                    newTransfer: {
                        path: '/admin/:itemTypeCode/tour/edit/:tourStepId',
                        exact: true,
                        component: <UpdateTourStep />,
                        title: 'Tour',
                    },
                },
            },
            weather: {
                path: '/admin/:itemTypeCode/weather',
                component: <Weather />,
                title: 'Weather',
                routes: {
                    weatherInspectionTypes: {
                        path: '/admin/:itemTypeCode/weather/inspection-events',
                        component: <WeatherInspectionTypes />,
                        title: 'Inspection Events',
                        routes: {
                            newWeatherInspectionType: {
                                path: '/admin/:itemTypeCode/weather/inspection-events/new',
                                exact: true,
                                component: <UpsertWeatherInspectionType />,
                                title: 'Weather',
                            },
                            editWeatherInspectionType: {
                                path: '/admin/:itemTypeCode/weather/inspection-events/edit/:weatherInspectionTypeId',
                                exact: true,
                                component: <UpsertWeatherInspectionType />,
                                title: 'Weather',
                            },
                        },
                    },
                    weatherEvents: {
                        path: '/admin/:itemTypeCode/weather/site-events',
                        component: <WeatherEvents />,
                        title: 'Site Events',
                    },
                },
            },
        },
    },
    scanqrcode: {
        path: '/scanqrcode',
        title: 'Scan QR Code',
        component: <ScanQrCodePage />,
        exact: true,
    },
    search: {
        path: '/search/:term',
        title: 'Search',
        component: <SearchPage />,
        routes: {
            preview: {
                path: '/search/:term/:itemTypeCode/:projectNumber/:projectItemId/preview',
                component: <Preview returnPath="/search/:term" />,
                title: 'Item Details',
                routes: getItemDetailsRoutes('preview'),
            },
        },
    },
};
