import { useEffect, useRef } from 'react';

// stores the previous value for a given key
export const usePrevious = (value: any, key: string) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [key]);
    return ref.current;
};
