import { Table } from '@laingorourke/core-web-components';
import { StatusType, TaskType, useColumns, useTasks } from '@laingorourke/core-web-mytasksreactsdk';
import isBefore from 'date-fns/isBefore';
import max from 'date-fns/max';
import parseISO from 'date-fns/parseISO';
import { useItemTypeMetadata, useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useProjects } from 'domain/store/reducers/projects';
import { fetchsUsersWithProjectWeatherAcknowledgementRead, hasPermission, useCurrentUser } from 'domain/store/reducers/users';
import { fetchWeatherEventItems, useWeatherEventItems } from 'domain/store/reducers/weather';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import { getFormattedDate } from 'helpers/utils';
import { useUserHasProjectWeatherAcknowledgementReadPermission } from 'hooks/useIsUserInGlobalWeatherRole';
import { useWithOwners } from 'hooks/useWithPerson';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { ProjectParams } from 'views/routes/project/Project';
import { AcknowledgeButton } from './AcknowledgeButton';
import { UserDropdown } from './UserDropdown';
import { WeatherEventAcknowledgementItemTableRow } from './WeatherEventAcknowledgementItemTableRow';
import styles from './WeatherEventsAcknowledgements.module.scss';
import { WeatherEventsAcknowlegement } from './WeatherEventsAcknowlegement';
import { WeatherEventIdTaskMetaLabel, getAcknowledgmentsClosedAtLocalStorageKey } from './consts';
import { getTableColumns } from './tableColumns';

interface WeatherEventsAcknowledgementsProps { }

export const WeatherEventsAcknowledgements: React.FC<WeatherEventsAcknowledgementsProps> = ({ }) => {
    const dispatch = useAppDispatch();
    const { projectNumber } = useParams<ProjectParams>();
    const itemTypeId = useSelectedItemTypeId();
    const currentUser = useCurrentUser();
    const [selectedUserId, setSelectedUserId] = useState<string | undefined>(currentUser.id);
    const [closed, setClosed] = useState(true);
    // this is to avoid closing modal when the newest task is acknowledged
    // when the newest task is acknowledged then newTasksAdded flag is false as this tasks is removed from the list and the modal is closed
    // it can be that the user still wants to browse tasks
    const [taskAcknowledged, setTaskAcknowledged] = useState(false);

    const weatherEventItemsData = useWeatherEventItems();
    const weatherEventItems = useWithOwners(weatherEventItemsData) ?? [];

    // Whether user has ProjectWeatherAcknowledgementRead permission that is assigned to TWC, TWM, TWS roles
    const userHasProjectWeatherAcknowledgementReadPermission =
        useUserHasProjectWeatherAcknowledgementReadPermission(currentUser.id);
    const categories = useItemTypeMetadata()?.categories;
    const projects = useProjects();

    const query = {
        filter: {
            and: [
                { 'status/type': StatusType.ToDo },
                { taskType: TaskType.Acknowledgment },
                { projectNumber: projectNumber },
                {
                    assignedUserId: {
                        eq: {
                            type: 'guid',
                            value: selectedUserId,
                        },
                    },
                },
            ],
        },
    };

    const acknowledgmentTasks = useTasks(query);
    const weatherEventsAcknowlegements: WeatherEventsAcknowlegement[] =
        (!!weatherEventItems &&
            acknowledgmentTasks.data
                ?.map((t) => {
                    const weatherEventId = t.meta.find(
                        (m) =>
                            m.label.toLocaleLowerCase() ===
                            WeatherEventIdTaskMetaLabel.toLocaleLowerCase()
                    )!.value;
                    const eventItems = weatherEventItems.filter(
                        (e) => e.weatherEventId === weatherEventId
                    );
                    const eventData = eventItems[0];
                    if (!eventData) return;
                    return {
                        taskId: t.id,
                        date: eventData.date,
                        siteId: eventData.siteId,
                        siteName: projects
                            ?.find((p) => p.projectNumber === projectNumber)
                            ?.sites?.find(
                                (s) =>
                                    s.id.toLocaleLowerCase() ===
                                    eventData.siteId!.toLocaleLowerCase()
                            )?.name,
                        weatherEventType: eventData.weatherEventType,
                        recordedValue: eventData.recordedValue,
                        unit: eventData.unit,
                        items: eventItems
                            .filter(
                                (i) => !!selectedUserId && i.usersIds!.indexOf(selectedUserId) > -1
                            )
                            ?.map((i) => ({
                                ...i,
                                categoryName: categories?.find((c) => c.id === i.categoryId)?.name,
                            })) as WeatherEventAcknowledgementItemTableRow[],
                    };
                })
                .filter((e) => !!e && e?.items?.length)
                ?.map((e) => e!)
                ?.slice()
                ?.sort((a, b) =>
                    isBefore(parseISO(a.date.toString()), parseISO(b.date.toString())) ? 1 : -1
                )) ??
        [];
    const acknowledgmentsClosedAtLocalStorageKey =
        getAcknowledgmentsClosedAtLocalStorageKey(projectNumber);
    const acknowledgmentsClosedAt = localStorage.getItem(acknowledgmentsClosedAtLocalStorageKey);

    const userHasProjectWeatherAcknowledgementOnBehalfOfPermission = hasPermission(
        currentUser,
        Permission.ProjectWeatherAcknowledgementOnBehalfOfRead,
        projectNumber
    );

    const newTasksAdded =
        !userHasProjectWeatherAcknowledgementOnBehalfOfPermission &&
        ((!acknowledgmentsClosedAt && !!acknowledgmentTasks.data?.length) ||
            (!!acknowledgmentsClosedAt &&
                !!acknowledgmentTasks.data?.length &&
                isBefore(
                    new Date(acknowledgmentsClosedAt!),
                    max(
                        acknowledgmentTasks.data
                            .filter((t) => !!t.createdAt)
                            .map((t) => new Date(t.createdAt!))
                    )
                )));

    const showModal =
        newTasksAdded ||
        userHasProjectWeatherAcknowledgementOnBehalfOfPermission ||
        (taskAcknowledged &&
            !!weatherEventsAcknowlegements.length &&
            !userHasProjectWeatherAcknowledgementOnBehalfOfPermission);

    useEffect(() => {
        setClosed(!showModal);
    }, [showModal]);

    const columns = getTableColumns(projectNumber);

    // mytasks SDK require columns to be loaded into cache to do moveTask mutate operation
    // this is required for AcknowledgeButton component
    useColumns();

    useEffect(() => {
        // fetch new items when new acknowledgments are fetched from mytasks
        dispatch(fetchWeatherEventItems({ projectNumber, itemTypeId }));
    }, [projectNumber, itemTypeId, JSON.stringify(acknowledgmentTasks), dispatch]);

    useEffect(() => {
        dispatch(fetchsUsersWithProjectWeatherAcknowledgementRead({ projectNumber }));
    }, [projectNumber]);

    const noResults =
        !!weatherEventsAcknowlegements &&
        weatherEventsAcknowlegements.length === 0 &&
        acknowledgmentTasks.data === null &&
        !acknowledgmentTasks.isFetching;

    return (
        <>
            <Modal
                show={!closed}
                onHide={() => {
                    setClosed(true);
                    localStorage.setItem(
                        acknowledgmentsClosedAtLocalStorageKey,
                        new Date().toString()
                    );
                }}
                animation={false}
                className={styles.modal}
                backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className={styles.title}>
                        <span>Weather Events</span>
                        {userHasProjectWeatherAcknowledgementOnBehalfOfPermission && (
                            <UserDropdown onChange={(userId) => setSelectedUserId(userId)} />
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SecuredContent permissions={Permission.ManageItemsRead}>
                        {noResults && 'No Acknowledgments found'}
                        {weatherEventItems &&
                            weatherEventsAcknowlegements?.map((a) => (
                                <div className={styles.content} key={a.taskId}>
                                    <h3>
                                        {a.weatherEventType} Warning: {getFormattedDate(a.date)}
                                    </h3>
                                    <p>
                                        In the last 24 hours {a.weatherEventType} in excess of{' '}
                                        {a.recordedValue}
                                        {a.unit} was recorded in <b> {a.siteName} </b> site.
                                        Hardstands, working platforms, excavations, formwork and the
                                        like may have been compromised. Consider inspecting all
                                        temporary works items that may be affected by rain
                                        {userHasProjectWeatherAcknowledgementReadPermission && '.'}
                                        {!userHasProjectWeatherAcknowledgementReadPermission && (
                                            <span>
                                                {' '}
                                                including but not necessarily limited to the
                                                following items.
                                            </span>
                                        )}
                                    </p>
                                    <Table
                                        {...getDefaultPaginationOptions()}
                                        {...getDefaultSortedByColummKey(
                                            columns,
                                            nameof<WeatherEventAcknowledgementItemTableRow>(
                                                'number'
                                            )
                                        )}
                                        data={a.items}
                                        keySelector={(d) => d.id}
                                        columns={columns}
                                        saveSelectedPageSize
                                        cacheKey={`acknowledgment-${a.taskId}`}
                                        hover
                                    />
                                    {selectedUserId === currentUser.id && (
                                        <AcknowledgeButton
                                            taskId={a.taskId}
                                            onAcknowledged={() => setTaskAcknowledged(true)}
                                        />
                                    )}
                                </div>
                            ))}
                    </SecuredContent>
                </Modal.Body>
            </Modal>
        </>
    );
};
