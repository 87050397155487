import { Form, Input, useForm } from '@laingorourke/core-web-components';
import { Designer } from 'domain/models/api-models';
import { createDesigner, updateDesigner } from 'domain/store/reducers/designers';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalFooter } from 'views/components';
import { DesignerSchema } from './schema';

interface UpsertDesignerFormProps {
    isEditMode: boolean;
    designer: Designer | undefined;
    goToDesigners: () => void;
}

export const UpsertForm: React.FC<UpsertDesignerFormProps> = ({
    isEditMode,
    designer,
    goToDesigners,
}) => {
    const dispatch = useAppDispatch();
    const itemTypeId = useSelectedItemTypeId();

    const designerForm = useForm({
        initialValues: {
            id: isEditMode ? designer!.id! : '',
            name: isEditMode ? designer!.name! : '',
        },
        validationSchema: DesignerSchema,
        onSubmit: async (values) => {
            let result;
            if (isEditMode) {
                result = await dispatch(updateDesigner({ ...values, itemTypeId }));
            } else {
                result = await dispatch(createDesigner({ ...values, itemTypeId }));
            }
            if ((result as any).payload) goToDesigners();
        },
    });

    const { isSubmitting } = designerForm;
    return (
        <Form form={designerForm}>
            <Modal.Body>
                <Form.Row>
                    <Form.Field name="name" label="Name">
                        <Input />
                    </Form.Field>
                </Form.Row>
            </Modal.Body>
            <ModalFooter
                submitText={isEditMode ? 'Update' : 'Create'}
                isSubmitting={isSubmitting}
                onCancel={goToDesigners}
            />
        </Form>
    );
};
