import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { ApplicationProject, Applications } from '../../models/ApplicationProject';
import { getQueryResult } from './graphQlClient';

const query = gql`
    query getApplicationProject($id: String!) {
        applicationProject(id: $id) {
            id
            application
            applicationProjectId
            applicationProjectName
        }
    }
`;

export const useApplicationProjectById = (id?: number, application?: Applications) => {
    return useQuery(
        ['applicationProject', id],
        async () => {
            const result = await getQueryResult<{
                applicationProject: ApplicationProject;
            }>(query, {
                id: `${application}-${id}`,
            });
            return result.applicationProject;
        },
        {
            enabled: !!id && !!application,
        }
    );
};
