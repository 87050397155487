import { Form, Select, useForm, WarningPanel } from '@laingorourke/core-web-components';
import { Classification, ProjectLeadDays } from 'domain/models/api-models';
import { updateProjectLeadDays } from 'domain/store/reducers/projectLeadDays';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { LeadDaysFieldArray, ModalFooter } from 'views/components';
import { LeadDaysSchema } from '../schemas';
import styles from './UpdateForm.module.scss';

interface UpsertProjectLeadDaysFormProps {
    leadDays: ProjectLeadDays;
    projectNumber: string;
    itemTypeId: string;
    classifications: Classification[];
    isProjectControlMeasure: boolean;
    goToProjectLeadDays: () => void;
}

export const UpdateForm: React.FC<UpsertProjectLeadDaysFormProps> = ({
    itemTypeId,
    leadDays,
    projectNumber,
    classifications,
    isProjectControlMeasure,
    goToProjectLeadDays,
}) => {
    const dispatch = useAppDispatch();
    const form = useForm({
        initialValues: {
            controlMeasureId: leadDays.controlMeasureId,
            leadDays: leadDays.leadDays,
        },
        validationSchema: LeadDaysSchema,
        onSubmit: async (values) => {
            let result;
            result = await dispatch(
                updateProjectLeadDays({
                    ...values,
                    itemTypeId,
                    projectNumber,
                })
            );
            if ((result as any).payload) goToProjectLeadDays();
        },
    });

    const { isSubmitting, values, errors } = form;

    return (
        <Form form={form}>
            <Modal.Body>
                <Form.Row>
                    <Form.Field name="controlMeasureId" label="Control Measure">
                        <Select
                            disabled
                            options={[
                                {
                                    label: leadDays?.controlMeasureName,
                                    value: leadDays?.controlMeasureId,
                                },
                            ]}
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row className={styles.warningRow}>
                    <WarningPanel>
                        A positive lead day sets the Control Measure due date to be x days PRIOR to
                        the date in service on the Item. A negative lead day sets the Control
                        Measure due date to be x days AFTER the date in service on the Item.
                    </WarningPanel>
                </Form.Row>
                <LeadDaysFieldArray
                    classifications={classifications}
                    leadDays={values.leadDays!}
                    isProjectControlMeasure={isProjectControlMeasure}
                />
            </Modal.Body>
            <ModalFooter
                submitText="Update"
                isSubmitting={isSubmitting}
                onCancel={goToProjectLeadDays}
            />
        </Form>
    );
};
