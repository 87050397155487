import { Status, StatusType } from '@laingorourke/core-web-mytasksreactsdk';
import { getMyTasksConfiguration } from 'helpers/applicationConfig';

export const goToMyTasks = (taskId: string) =>
    window.open(getMyTasksConfiguration()?.baseUrl!.concat('/task/', taskId)!, '_blank');

export const getTaskName = (name: string) => {
    if (name === undefined || name === null) {
        return '';
    }
    const firstColonIndex = name.indexOf(':');
    return name.substring(firstColonIndex + 1).trim();
};

const statusTypesForTaskCreation = [StatusType.ToDo, StatusType.InProgress, StatusType.Completed];

export const getStatusesForTaskCreation = (statuses: Status[]) =>
    statuses
        .filter((s) => statusTypesForTaskCreation.indexOf(s.type) > -1)
        .map((s) => ({ value: s.type, label: s.name }));
