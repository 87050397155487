import { RouteLink as LorRouteLink } from '@laingorourke/core-web-components';
import { Route } from '@laingorourke/core-web-types';
import cn from 'classnames';
import React, { PropsWithChildren } from 'react';
import styles from './GenericLink.module.scss';

export const RouteLink: React.FC<
    PropsWithChildren<{
        route: Route;
        className?: string;
        params?: {
            [paramName: string]: string | number | boolean;
        };
    }>
> = ({ route, className, params, children }) => {
    return (
        <LorRouteLink
            className={className === undefined ? styles.link : cn(className, styles.link)}
            route={route}
            params={params}>
            {children}
        </LorRouteLink>
    );
};
