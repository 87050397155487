import { Button, useToasts } from '@laingorourke/core-web-components';
import { StatusType, TASKKEY, useMoveTask } from '@laingorourke/core-web-mytasksreactsdk';
import { useQueryClient } from '@tanstack/react-query';
import { useCurrentUser } from 'domain/store/reducers/users';
import { getToastMessage } from 'helpers/notificationHelper';
import { getFormattedDate } from 'helpers/utils';
import React from 'react';
import styles from './WeatherEventsAcknowledgements.module.scss';

interface AcknowledgeButtonProps {
    taskId: string;
    onAcknowledged: () => void;
}

export const AcknowledgeButton: React.FC<AcknowledgeButtonProps> = ({ taskId, onAcknowledged }) => {
    const moveTask = useMoveTask();
    const toasts = useToasts();
    const queryClient = useQueryClient();
    const currentUser = useCurrentUser();

    const onAcknowledgeTask = (taskId: string) => {
        moveTask.mutate(
            {
                data: {
                    isApproved: null,
                },
                params: {
                    taskId: taskId,
                    statusType: StatusType.Completed,
                },
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(TASKKEY());
                    onAcknowledged();
                    toasts.newMessage(
                        getToastMessage(
                            `Acknowledged by ${currentUser.displayName} - ${getFormattedDate(
                                new Date()
                            )}`,
                            true
                        )
                    );
                },
                onError: () =>
                    toasts.newMessage(
                        getToastMessage('Acknowledgement unsuccessful, please try again', false)
                    ),
            }
        );
    };

    return (
        <Button
            className={styles.button}
            variant="primary"
            loading={moveTask.isLoading}
            onClick={() => onAcknowledgeTask(taskId)}>
            Acknowledge
        </Button>
    );
};
