import * as Yup from 'yup';

export const NewInspectionSchema = Yup.object().shape({
    inspectionTypeId: Yup.string().required('Inspection Type is required'),
    assignee: Yup.string().nullable().required('Assignee is required'),
    dueDates: Yup.array(Yup.date())
        .min(1, 'Forecasted Completion Dates are required')
        .required('Forecasted Completion Dates are required'),
    statusType: Yup.string().required('Status is required'),
});
