import { Select } from '@laingorourke/core-web-components';
import { Site } from 'domain/services/dataService/models';
import React, { PropsWithChildren } from 'react';
import styles from './UpsertForm.module.scss';
//@ts-ignore
import { components } from 'react-select';

export const SiteSelect: React.FC<
    PropsWithChildren<{
        sites: Site[];
        selectedSiteId: string | undefined;
        onChange: (value: string | string[] | null) => void;
    }>
> = ({ sites, selectedSiteId, onChange }) => (
    <Select
        placeholder="Select Site..."
        isClearable
        options={sites.map((s) => ({
            label: s.name,
            value: s.id,
        }))}
        value={selectedSiteId}
        onChange={onChange}
        components={{
            Option: ({ children, ...props }: any) => (
                <components.Option {...props}>
                    <div>
                        {children}
                        <span className={styles.address}>
                            {sites.find((s) => s.id === props.value)?.address}
                        </span>
                    </div>
                </components.Option>
            ),
        }}
    />
);
