import { generatePath, Navigation, Page, Skeleton } from '@laingorourke/core-web-components';
import {
    fetchMetadata,
    fetchProjectStatuses,
    setItemTypeCode,
    useItemTypes,
    useSelectedItemTypeCode,
    useSelectedItemTypeCodeIsSet,
    useSelectedItemTypeId,
} from 'domain/store/reducers/itemType';
import { fetchProjectItemNumberConfiguration } from 'domain/store/reducers/projectItemNumberConfiguration';
import { clearProjectItems, useProject } from 'domain/store/reducers/projects/reducer';
import { useAppDispatch } from 'domain/store/rootStore';
import { getFeaturesConfiguration } from 'helpers/applicationConfig';
import { default as React, PropsWithChildren, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PageActions, ProjectSecuredContent } from 'views/components';
import { ItemTypeSelector } from 'views/components/layout/itemTypeSelector/ItemTypeSelector';
import { routes } from '../Routes';
import { useIsProjectItemsDetailsPage, useIsProjectRootPage } from '../routesHooks';

export interface ProjectParams {
    projectNumber: string;
    itemTypeCode: string;
}

const getPageTitle = (title: string, projectNumber: string) => `${title} (${projectNumber})`;

export const Project: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();
    const project = useProject(projectNumber);
    const isSelectedItemTypeCodeIsSet = useSelectedItemTypeCodeIsSet();
    const selectedItemTypeCode = useSelectedItemTypeCode();
    const routeParams = { projectNumber, itemTypeCode: selectedItemTypeCode ?? '' };
    const projectRoutes = routes.projects.routes!.project.routes!;
    const isItemDetailsPage = useIsProjectItemsDetailsPage();
    const isProjectRootPage = useIsProjectRootPage();
    const itemTypes = useItemTypes();
    const itemTypeId = useSelectedItemTypeId();
    const weatherEnabled = getFeaturesConfiguration()?.weatherEnabled;

    useEffect(() => {
        dispatch(clearProjectItems());
    }, [projectNumber, dispatch]);

    useEffect(() => {
        if (itemTypes.length !== 0) {
            dispatch(setItemTypeCode(itemTypeCode));
        }
    }, [itemTypes, itemTypeCode, dispatch]);

    useEffect(() => {
        if (isSelectedItemTypeCodeIsSet && isProjectRootPage) {
            history.push(
                generatePath(routes.projects.routes!.project.routes!.items.path, {
                    projectNumber,
                    itemTypeCode: selectedItemTypeCode,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemTypeCode]);

    useEffect(() => {
        if (itemTypeId) {
            dispatch(fetchMetadata({ itemTypeId }));
            dispatch(fetchProjectStatuses({ itemTypeId }));
        }
    }, [itemTypeId, dispatch]);

    // Fetch item number configuration, that information will be used in multiple project pages e.g. to hide project in item number
    useEffect(() => {
        dispatch(fetchProjectItemNumberConfiguration({ itemTypeId, projectNumber }));
    }, [itemTypeId, projectNumber]);

    return (
        <>
            {isItemDetailsPage ? (
                children
            ) : (
                <PageActions>
                    {(content) => (
                        <Page
                            header={
                                <Page.Header
                                    className="reverse-buttons"
                                    title={
                                        <Skeleton.Box height={36} loading={!project}>
                                            <h1>
                                                {getPageTitle(
                                                    project?.name!,
                                                    project?.projectNumber!
                                                )}
                                            </h1>
                                        </Skeleton.Box>
                                    }
                                    actions={
                                        content
                                            ? [<ItemTypeSelector />, content]
                                            : [<ItemTypeSelector />]
                                    }>
                                    {isSelectedItemTypeCodeIsSet && (
                                        <Navigation direction="horizontal">
                                            <Navigation.Route
                                                route={projectRoutes.items}
                                                params={routeParams}>
                                                Items
                                            </Navigation.Route>
                                            <Navigation.Route
                                                route={projectRoutes.tasks}
                                                params={routeParams}>
                                                Tasks
                                            </Navigation.Route>
                                            <Navigation.Route
                                                route={projectRoutes.areas}
                                                params={routeParams}>
                                                Areas
                                            </Navigation.Route>
                                            <Navigation.Route
                                                route={projectRoutes.reports}
                                                params={routeParams}>
                                                Reports
                                            </Navigation.Route>
                                            <Navigation.Route
                                                route={projectRoutes.classificationGuidelines}
                                                params={routeParams}>
                                                Classification Guidelines
                                            </Navigation.Route>
                                            <Navigation.Route
                                                route={projectRoutes.reviews}
                                                params={routeParams}>
                                                Governance
                                            </Navigation.Route>
                                            {weatherEnabled && (
                                                <Navigation.Route
                                                    route={projectRoutes.weather}
                                                    params={routeParams}>
                                                    Weather
                                                </Navigation.Route>
                                            )}
                                            <Navigation.Route
                                                route={projectRoutes.projectSetup}
                                                params={routeParams}>
                                                Project Setup
                                            </Navigation.Route>
                                        </Navigation>
                                    )}
                                </Page.Header>
                            }>
                            <ProjectSecuredContent>{children}</ProjectSecuredContent>
                        </Page>
                    )}
                </PageActions>
            )}
        </>
    );
};
