import { Route } from '@laingorourke/core-web-types';
import { matchPath, useHistory } from 'react-router-dom';
import { routes } from './Routes';

export function useIsExactRoute(route: Route) {
    const history = useHistory();
    const match = matchPath(history.location.pathname, {
        path: [route.path],
        exact: true,
    });

    return match !== null;
}

export const useIsAdminRootPage = () => useIsExactRoute(routes.adminRoot);
export const useIsProjectsPage = () => useIsExactRoute(routes.projects);
export const useIsProjectRootPage = () => useIsExactRoute(routes.projects.routes!.projectRoot);

export function useIsProjectItemsDetailsPage() {
    const history = useHistory();
    const itemDetailsPageMatch = matchPath<{ projectItemId: string }>(history.location.pathname, {
        path: [
            routes.projects.routes!.project.routes!.items.routes!.itemDetails.path,
            routes.projects.routes!.project.routes!.items.routes!.schedules.path,
            routes.projects.routes!.project.routes!.items.routes!.history.path,
            routes.projects.routes!.project.routes!.items.routes!.groups.path,
            routes.projects.routes!.project.routes!.items.routes!.builtViewMedia.path,
        ],
    });
    const isItemDetailsPage =
        itemDetailsPageMatch && itemDetailsPageMatch?.params?.projectItemId.toLowerCase() !== 'new';

    return isItemDetailsPage;
}

export function useIsNewProjectItemPage() {
    const history = useHistory();
    const newProjectItemPageMatch = matchPath<{ projectItemId: string }>(
        history.location.pathname,
        {
            path: [routes.projects.routes!.project.routes!.items.routes!.newItem.path],
            exact: true,
        }
    );

    return newProjectItemPageMatch !== null;
}

export function useIsUploadCsvPage() {
    const history = useHistory();
    const newProjectItemPageMatch = matchPath<{ projectItemId: string }>(
        history.location.pathname,
        {
            path: [routes.projects.routes!.project.routes!.items.routes!.uploadCsv.path],
            exact: true,
        }
    );

    return newProjectItemPageMatch !== null;
}
