import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { getDataServiceConfiguration } from 'helpers/applicationConfig';
import { SupplyChainPortalSystemId } from './constants';
import { getQueryResult } from './graphQlClient';
import { Group, Page } from './models';

const query = gql`
    query getGroups(
        $search: String!
        $memberRoleSystemId: ID!
        $memberSecurableId: String!
        $managedBySystemId: ID!
    ) {
        groups(
            search: $search
            memberRoleSystemId: $memberRoleSystemId
            memberSecurableId: $memberSecurableId
            managedBySystemId: $managedBySystemId
            type: GatekeeperGroup
            top: 20
        ) {
            data {
                id
                name
            }
        }
    }
`;

export const useGroups = (projectNumber: string, search?: string) => {
    const systemId = getDataServiceConfiguration()?.systemId;
    return useQuery(
        ['groups', search, systemId, projectNumber, SupplyChainPortalSystemId],
        async () => {
            const result = await getQueryResult<{
                groups: Page<Group>;
            }>(query, {
                search,
                memberRoleSystemId: systemId,
                memberSecurableId: projectNumber,
                managedBySystemId: SupplyChainPortalSystemId,
            });
            return result.groups.data;
        },
        {
            enabled: !!search,
        }
    );
};
