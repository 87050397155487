import { Table } from '@laingorourke/core-web-components';
import { usePeopleByEmails } from 'domain/services/dataService/usePeopleByEmails';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useProjects } from 'domain/store/reducers/projects/reducer';
import { fetchTransfers, useTransfers } from 'domain/store/reducers/transfers';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import { useWithPerson } from 'hooks/useWithPerson';
import React, { PropsWithChildren, useEffect } from 'react';
import { Permission } from 'security/Permission';
import { SecuredContent, getProjectDisplayName } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { TransferTableRow } from './TransferTableRow';
import { NewTransferButton } from './components/NewTransferButton';
import { tableColumns } from './tableColumns';

export const Transfers: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.TransferRead} ignoreSecurable={true}>
        <TransfersContent>{children}</TransfersContent>
    </SecuredContent>
);

const TransfersContent: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();

    const transfers = useTransfers();
    const projects = useProjects();
    const itemTypeId = useSelectedItemTypeId();
    const people = usePeopleByEmails(transfers?.map((t) => t.createdBy!));

    const transfersTableData = useWithPerson(
        'createdBy',
        'createdById',
        transfers?.map((i) => ({
            ...i,
            sourceProjectName: getProjectDisplayName(i.sourceProjectNumber, projects),
            targetProjectName: getProjectDisplayName(i.targetProjectNumber, projects),
            createdById: people?.data?.find(
                (p) => p.contact.email.toLocaleLowerCase() === i.createdBy!.toLocaleLowerCase()
            )?.id,
            description:
                'Compliance Templates, Control Measures, Integrations, Classifications, Lead Days, Designers, Item Number Configuration',
        }))
    ) as TransferTableRow[];

    const dataLoaded = !!transfersTableData;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Transfers found');

    useEffect(() => {
        dispatch(fetchTransfers({ itemTypeId }));
    }, [itemTypeId, dispatch]);

    return (
        <>
            <NewTransferButton />
            {children}
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(
                    tableColumns,
                    nameof<TransferTableRow>('createdOn'),
                    'desc'
                )}
                {...tableLoadingProps}
                data={dataLoaded ? transfersTableData! : []}
                columns={tableColumns}
                keySelector={(d) => d.id}
                saveSelectedPageSize
                cacheKey="transfers"
                hover
            />
        </>
    );
};
