import React, { PropsWithChildren } from 'react';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import {
    useIsNewProjectItemPage,
    useIsProjectItemsDetailsPage,
    useIsUploadCsvPage,
} from 'views/routes/routesHooks';
import { List } from '..';

export const Items: React.FC<PropsWithChildren> = ({ children }) => (
    <ItemsContent>{children}</ItemsContent>
);

const ItemsContent: React.FC<PropsWithChildren> = ({ children }) => {
    const isItemDetailsPage = useIsProjectItemsDetailsPage();
    const isNewItemPage = useIsNewProjectItemPage();
    const isUploadCsvPage = useIsUploadCsvPage();

    return (
        <>
            {(isItemDetailsPage || isNewItemPage || isUploadCsvPage) && children}
            {!isItemDetailsPage && (
                <SecuredContent permissions={Permission.ProjectDetailsRead}>
                    <List>{children}</List>
                </SecuredContent>
            )}
        </>
    );
};
