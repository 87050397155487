import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    CreateWeatherInspectionTypeRequest,
    UpdateWeatherInspectionTypeRequest,
    WeatherEvent,
    WeatherEventItem,
    WeatherInspectionType,
} from 'domain/models/api-models';
import { get, patch, post, remove } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

export const fetchWeatherInspectionTypes = createAsyncThunk<
    WeatherInspectionType[],
    void,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/weatherInspectionTypes/get',
    async (_, { getState }) => {
        const { itemTypes } = getState();
        const selectedItemTypeId = itemTypes.selectedItemType.id;
        return (await get(`itemtypes/${selectedItemTypeId}/weatherInspectionTypes`))!;
    },
    {
        condition: (_, { getState }) => getState().itemTypes.selectedItemType.id !== '',
    }
);

interface FetchWeatherEventsParams {
    itemTypeId: string;
    projectNumber?: string;
}

export const fetchWeatherEvents = createAsyncThunk<
    WeatherEvent[],
    FetchWeatherEventsParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/weatherEvents/get',
    async (args, { getState }) => {
        if (!!args.projectNumber)
            return (await get(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/weatherEvents`
            ))!;
        else return (await get(`itemtypes/${args.itemTypeId}/weatherEvents`))!;
    },
    {
        condition: (args) => !!args.itemTypeId,
    }
);

interface FetchWeatherEventItemsParams {
    itemTypeId: string;
    projectNumber: string;
}

export const fetchWeatherEventItems = createAsyncThunk<
    WeatherEventItem[],
    FetchWeatherEventItemsParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/weatherEvents/items/get',
    async (args, { getState }) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/weatherEvents/items`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface CreateWeatherInspectionType extends CreateWeatherInspectionTypeRequest {
    projectNumber: string | undefined;
}

export const createWeatherInspectionType = createAsyncThunk<
    WeatherInspectionType,
    CreateWeatherInspectionType,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/weatherInspectionTypes/post',
    async (weatherInspectionType, { getState, dispatch }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;

            const apiUrl = !!weatherInspectionType.projectNumber
                ? `itemtypes/${itemTypeId}/projects/${weatherInspectionType.projectNumber}/weatherInspectionTypes`
                : `itemtypes/${itemTypeId}/weatherInspectionTypes`;
            const createdWeatherInspectionType = await post(apiUrl, weatherInspectionType);
            dispatch(
                addNotification({
                    message: 'Weather Inspection Type has been created successfully',
                    isSuccess: true,
                })
            );
            return createdWeatherInspectionType;
        }, dispatch);
    }
);

interface UpdateWeatherInspectionType extends UpdateWeatherInspectionTypeRequest {
    id: string;
}

export const updateWeatherInspectionType = createAsyncThunk<
    WeatherInspectionType,
    UpdateWeatherInspectionType,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/weatherInspectionTypes/patch',
    async (weatherInspectionType, { getState, dispatch }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            const updatedWeatherInspectionType = await patch(
                `itemtypes/${itemTypeId}/weatherInspectionTypes/${weatherInspectionType.id}`,
                weatherInspectionType
            );

            dispatch(
                addNotification({
                    message: 'Weather Inspection Type has been updated successfully',
                    isSuccess: true,
                })
            );

            return updatedWeatherInspectionType;
        }, dispatch);
    }
);

export const deleteWeatherInspectionType = createAsyncThunk<string, string, { state: RootState }>(
    'itemtypes/{itemTypeId}/weatherInspectionTypes/delete',
    async (id, { dispatch, getState }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            await remove(`itemtypes/${itemTypeId}/weatherInspectionTypes/${id}`);
            dispatch(
                addNotification({
                    message: 'Weather Inspection Type has been deleted successfully',
                    isSuccess: true,
                })
            );
            return id;
        }, dispatch);
    }
);
