import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Button, useLayoutOptions } from '@laingorourke/core-web-components';
import React from 'react';
import { ButtonProps } from 'react-bootstrap';

interface AddButtonProps extends ButtonProps {
    text: string;
}

export const AddButton: React.FC<AddButtonProps> = (props) => {
    const { text, ...rest } = props;
    const layout = useLayoutOptions();
    return (
        <Button
            {...rest}
            variant={layout.theme === 'dark' ? 'outline-light' : 'outline-dark'}
            //@ts-ignore TODO: remove once component lib works with fa v6
            icon={faPlus}>
            {text}
        </Button>
    );
};
