import { youAreOfflineFeatureNotAvailableMessage } from '@laingorourke/core-web-components';
import { useIsOffline } from 'hooks/useIsOffline';
import { useItemInfoRoute } from 'hooks/useItemInfoRoute';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { AddButton, TooltipWrapper } from 'views/components';
import { ProjectItemDetailsParams } from '../../ProjectItemDetailsParams';

export const AddControlMeasureButton: React.FC<{
    myTasksOperationsBlocked: boolean;
    canAddControlMeasure: boolean;
    nonEditableStatusesMessage: string;
}> = ({
    myTasksOperationsBlocked,
    canAddControlMeasure,
    nonEditableStatusesMessage
}) => {
        const history = useHistory();
        const { projectItemId, projectNumber, itemTypeCode } = useParams<ProjectItemDetailsParams>();
        const isOffline = useIsOffline();

        const itemInfoRoute = useItemInfoRoute();
        const goToNewControlMeasure = () =>
            history.push(
                generatePath(itemInfoRoute.routes!.newControlMeasure.path, {
                    projectNumber,
                    projectItemId,
                    itemTypeCode,
                })
            );

        const getTooltipContent = () => {
            if (isOffline) {
                return youAreOfflineFeatureNotAvailableMessage;
            }
            if (!canAddControlMeasure)
                return `Control Measure can not be added as the Item is of status ${nonEditableStatusesMessage}`;
            if (myTasksOperationsBlocked) {
                return 'Unable to Add Control Measure until background processing is complete.';
            }
            return '';
        };

        return (
            <TooltipWrapper
                id="add-control-measure-btn"
                tooltipContent={getTooltipContent()}
                show={isOffline || myTasksOperationsBlocked || !canAddControlMeasure}>
                <AddButton
                    text="Add Control Measure"
                    onClick={goToNewControlMeasure}
                    disabled={isOffline || myTasksOperationsBlocked || !canAddControlMeasure}
                    style={
                        !canAddControlMeasure || myTasksOperationsBlocked
                            ? { pointerEvents: 'none' }
                            : {}
                    }
                />
            </TooltipWrapper>
        );
    };
