import { ColumnDefinition, selectFilter, textFilter } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { nameof } from 'helpers/nameOf';
import { ClassificationBadge, ItemNumber } from 'views/components';
import { getItemDetailsTableCell } from 'views/components/table';
import { getPersonColumn } from 'views/components/table/personColumn';
import styles from '../../../../components/table/Table.module.scss';
import { DateInService } from '../components/DateInService';
import { ProjectItemTableRow } from './ProjectItemTableRow';
import { StatusColumn } from './components/StatusColumn';

export const getTableColumns = (
    onPreviewClicked: (itemId: string) => string,
    classiticationsSortOrder: string[],
    classificationOptions: OptionTypeBase[],
    areaNameOptions: OptionTypeBase[],
    subAreaNameOptions: OptionTypeBase[],
    statusColumn: Partial<ColumnDefinition<any>>,
    hideItemDetailsColumn: boolean,
    projectNumber: string,
    projectId?: string,
): ColumnDefinition<any>[] => [
        {
            ...getItemDetailsTableCell(onPreviewClicked),
            hidden: hideItemDetailsColumn,
        },
        {
            key: nameof<ProjectItemTableRow>('name'),
            field: (d) => d.name,
            text: 'Title',
            sortable: true,
            filter: textFilter(),
            size: 15,
        },
        {
            key: nameof<ProjectItemTableRow>('number'),
            field: (d) => d.number,
            text: 'Number',
            sortable: true,
            filter: textFilter(),
            renderer: (number: string) => <ItemNumber itemNumber={number} projectNumber={projectNumber} copyTextEnabled />,
            size: 15,
        },
        {
            field: (d) => d.classificationId,
            text: 'Classif.',
            sortable: true,
            customSort: (a, b, direction) => {
                const firstElement = a.classificationId;
                const secondElement = b.classificationId;
                return direction === 'asc'
                    ? classiticationsSortOrder!.indexOf(firstElement) -
                    classiticationsSortOrder!.indexOf(secondElement)
                    : classiticationsSortOrder!.indexOf(secondElement) -
                    classiticationsSortOrder!.indexOf(firstElement);
            },
            filter: selectFilter({
                multi: true,
                options: classificationOptions,
            }),
            renderer: (classificationId: string) => <ClassificationBadge id={classificationId} />,
        },
        {
            field: (d) => d.area,
            text: 'Area',
            sortable: true,
            headerClass: styles.minWidth150,
            filter: selectFilter({
                multi: true,
                options: areaNameOptions,
            }),
        },
        {
            field: (d) => d.subArea,
            text: 'Sub Area',
            sortable: true,
            filter: selectFilter({
                multi: true,
                options: subAreaNameOptions,
            }),
        },
        {
            field: (d) => d.dateInService,
            text: 'Date In Service',
            sortable: true,
            renderer: (_: any, row: ProjectItemTableRow) => (
                <DateInService
                    id={row.id}
                    dateInService={row.dateInService}
                    isDateInServiceFromP6={row.isDateInServiceFromP6}
                />
            ),
        },
        {
            field: (d) => d.ownerId,
            text: 'Owner',
            ...getPersonColumn('owner', true, true, projectId),
            size: 20,
        },
        {
            field: (d) => d.statusId,
            text: 'Status',
            ...statusColumn,
            renderer: (_: any, row: ProjectItemTableRow) => <StatusColumn {...row} />,
        },
    ];
