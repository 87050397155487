import * as Core from '@laingorourke/core-web-clientservices';
import { LogErrorRequest } from 'domain/models/api-models';
import { post } from './client';

export class LogService extends Core.ServiceBase {
    public async logError(
        errorMessage: string,
        errorStack?: string,
        logLevel?: LogLevel
    ): Promise<void> {
        try {
            const request: LogErrorRequest = { message: errorMessage, stack: errorStack };
            await post(`/logs/${logLevel ?? 'error'}`, request);
        } catch (response) {
            await super.handleError(response);
        }
    }
}

export enum LogLevel {
    Error = 'error',
    Warning = 'warning',
}
