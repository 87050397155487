import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { getQueryResult } from './graphQlClient';
import { Page, Person } from './models';

const query = gql`
    query getAllPeople($search: String!) {
        people(search: $search, orderBy: { displayName: "asc" }) {
            data {
                id
                displayName
            }
        }
    }
`;

export const useAllPeople = (search?: string) => {
    return useQuery(
        ['allPeople', [search]],
        async () => {
            const result = await getQueryResult<{
                people: Page<Person>;
            }>(query, {
                search,
            });
            return result.people.data;
        },
        {
            enabled: !!search,
        }
    );
};
