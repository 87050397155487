import { Page } from '@laingorourke/core-web-components';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import {
    clearProjectItemNumberSetup,
    fetchProjectItemNumberConfiguration,
    fetchProjectItemNumberConfigurationNumberExamples,
    updateAreaCodeInItemNumber,
    updateHideProjectNumber,
    updateMaxItemNumber,
    updateSeparator,
    useItemNumberExamples,
    useProjectItemNumberConfiguration,
} from 'domain/store/reducers/projectItemNumberConfiguration';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { Setting } from 'views/components/setting/Setting';
import { ProjectParams } from '../../Project';
import { ExamplesTable } from './components/ExamplesTable';
import { ItemNumberPattern } from './components/ItemNumberPattern';
import styles from './ItemNumber.module.scss';
import { separatorOptions } from './separatorOptions';

const itemsExistMessate = 'Unable change configuration as project has items';
const noEditPermissionsMessage = 'You can not edit this configuration';

export const ItemNumber: React.FC = () => (
    <SecuredContent permissions={Permission.ProjectItemNumberRead} ignoreSecurable={false}>
        <ItemNumberContent />
    </SecuredContent>
);

const ItemNumberContent: React.FC = () => {
    const dispatch = useAppDispatch();

    const { projectNumber } = useParams<ProjectParams>();
    const itemTypeId = useSelectedItemTypeId();

    const currentUserHasProjectItemNumbersWritePermission = useHasPermissions(
        Permission.ProjectItemNumberWrite,
        projectNumber
    );

    const configuration = useProjectItemNumberConfiguration();
    const examples = useItemNumberExamples();
    const canEdit =
        currentUserHasProjectItemNumbersWritePermission && !configuration?.projectItemsExist;

    const tooltipMessage = useMemo(() => {
        if (!currentUserHasProjectItemNumbersWritePermission) {
            return noEditPermissionsMessage;
        }
        if (configuration?.projectItemsExist) {
            return itemsExistMessate;
        }
    }, [configuration?.projectItemsExist, noEditPermissionsMessage]);

    useEffect(() => {
        dispatch(fetchProjectItemNumberConfiguration({ itemTypeId, projectNumber }));
        return () => {
            dispatch(clearProjectItemNumberSetup());
        };
    }, [itemTypeId, projectNumber]);

    useEffect(() => {
        dispatch(fetchProjectItemNumberConfigurationNumberExamples({ itemTypeId, projectNumber }));
    }, [itemTypeId, projectNumber, configuration]);

    const dataLoaded = !!configuration;

    return (
        <>
            <Page.Section>
                <Setting<boolean>
                    label="Area Code in Item Number"
                    description="By turning on the area code all item numbers will be created sequentially within the area ie RSH.TW0001, CLD.TW0001 as opposed to TW0001 + TW0002"
                    canEdit={canEdit}
                    loading={!dataLoaded}
                    tooltipMessage={tooltipMessage}
                    value={configuration?.areaCodeInItemNumber}
                    onChange={(value) =>
                        dispatch(
                            updateAreaCodeInItemNumber({
                                itemTypeId,
                                projectNumber,
                                areaCodeInItemNumber: value,
                            })
                        )
                    }
                />
                <Setting<number>
                    label="Max Item Number"
                    description="This sets the maximum number of items per item number settings eg if Area Code in Item Number is turned on then the max item number per area is 9999."
                    canEdit={canEdit}
                    loading={!dataLoaded}
                    tooltipMessage={tooltipMessage}
                    value={configuration?.maxItemNumber}
                    min={999}
                    max={9999}
                    onChange={(value) => {
                        if (value != configuration.maxItemNumber)
                            dispatch(
                                updateMaxItemNumber({
                                    itemTypeId,
                                    projectNumber,
                                    maxItemNumber: value,
                                })
                            );
                    }}
                />
                <Setting<string>
                    label="Item Number Separator"
                    description="The character to be used to separate elements that make up the Item Number eg AU00000.TW0001 or RSH.TW0001 is separated by a ."
                    canEdit={canEdit}
                    loading={!dataLoaded}
                    tooltipMessage={tooltipMessage}
                    value={configuration?.separator}
                    options={separatorOptions}
                    onChange={(value) =>
                        dispatch(
                            updateSeparator({
                                itemTypeId,
                                projectNumber,
                                separator: value,
                            })
                        )
                    }
                />
                <Setting<boolean>
                    label="Hide Project Number"
                    description="By turning this on the project number will not be displayed as part of the
                    Item Number eg TW0001 will be displayed as opposed to AU00000.TW0001"
                    canEdit={canEdit}
                    loading={!dataLoaded}
                    tooltipMessage={tooltipMessage}
                    value={configuration?.hideProjectNumber}
                    onChange={(value) =>
                        dispatch(
                            updateHideProjectNumber({
                                itemTypeId,
                                projectNumber,
                                hideProjectNumber: value,
                            })
                        )
                    }
                />
            </Page.Section>
            {configuration && (
                <Page.Section className={styles.examples}>
                    <ItemNumberPattern configuration={configuration} />
                    {examples && (
                        <ExamplesTable configuration={configuration} examples={examples} />
                    )}
                </Page.Section>
            )}
        </>
    );
};
