import { useEffect, useState } from 'react';
import { post } from './client';

export const useCollaborationToken = () => {
    const [accessToken, setAccessToken] = useState('');

    useEffect(() => {
        const fetch = async () => {
            const token = await post<string>(`/document-collaboration/tokens/access-token`, null);
            setAccessToken(token);
        };
        fetch();
    }, []);

    return accessToken;
};
