import { Button } from '@laingorourke/core-web-components';
import React, { PropsWithChildren } from 'react';
import { Modal } from 'react-bootstrap';
import { TooltipButton } from '../tooltipButton';
import styles from './ModalFooter.module.scss';

interface ModalFooterProps {
    isSubmitting?: boolean;
    submitText: string;
    type?: 'submit' | 'reset' | 'button' | undefined;
    onSubmit?: () => void;
    onCancel: () => void;
}

export const ModalFooter: React.FC<PropsWithChildren<ModalFooterProps>> = ({
    isSubmitting,
    submitText,
    type,
    onSubmit,
    onCancel,
    children,
}) => {
    return (
        <Modal.Footer className={styles.footer}>
            <div className={styles['prepend-elements']}>{children}</div>
            <Button variant="link" onClick={onCancel}>
                Cancel
            </Button>
            <TooltipButton
                id="modal-submit-btn"
                variant="primary"
                type={type ?? 'submit'}
                onClick={onSubmit}
                loading={isSubmitting}
                disabled={isSubmitting}>
                {submitText}
            </TooltipButton>
        </Modal.Footer>
    );
};
