import { Form, Input, Select, useForm } from '@laingorourke/core-web-components';
import { loadingCompleted, loadingStarted } from 'domain/store/reducers/inProgress';
import { fetchProjectStatuses, useItemStatuses } from 'domain/store/reducers/itemType';
import { changeStatus } from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Permission } from 'security/Permission';
import { ModalFooter, SecuredContent } from 'views/components';
import {
    emojiValidationMessage,
    emojis,
    invalidCharacters,
} from 'views/components/form/validators';
import * as Yup from 'yup';
import { SelectedProjectItems } from '../components/SelectedProjectItem';

interface ChangeStatusProps {
    itemTypeId: string;
    projectNumber: string;
    projectItemsIds: string[];
    show: boolean;
    onClose: (success: boolean) => void;
}

export const ChangeStatus: React.FC<ChangeStatusProps> = ({
    itemTypeId,
    projectNumber,
    projectItemsIds,
    onClose,
}) => {
    const dispatch = useAppDispatch();

    const projectStatuses = useItemStatuses();

    const dataLoaded = !!projectStatuses;

    const cancelledStatusesIds = projectStatuses?.filter((s) => s.isCancelled).map((s) => s.id);

    const form = useForm({
        initialValues: {
            statusId: '',
            reason: '',
        },
        validationSchema: Yup.object().shape({
            statusId: Yup.string().required('Status is required'),
            reason: Yup.string()
                .nullable()
                .when('statusId', (statusId: string, schema: any) => {
                    if (!!cancelledStatusesIds?.find((id) => id === statusId)) {
                        return schema
                            .matches(emojis, emojiValidationMessage)
                            .matches(invalidCharacters, 'Reason contains invalid characters')
                            .min(3, 'Reason cannot be shorter than 3 characters')
                            .max(100, 'Reason cannot be longer than 100 characters')
                            .required('Reason is required');
                    }
                    return schema;
                }),
        }),
        onSubmit: async (values) => {
            const result = await dispatch(
                changeStatus({
                    statusId: values.statusId,
                    reason: values.reason,
                    projectItemsIds,
                    itemTypeId,
                    projectNumber,
                })
            );

            if ((result as any).payload) onClose(true);
        },
    });

    const { isSubmitting, values, setFieldValue } = form;

    useEffect(() => {
        dispatch(fetchProjectStatuses({ itemTypeId }));
    }, [projectNumber, itemTypeId, dispatch]);

    useEffect(() => {
        if (dataLoaded) dispatch(loadingCompleted());
        else dispatch(loadingStarted());
        return () => {
            dispatch(loadingCompleted());
        };
    }, [dataLoaded, dispatch]);

    const selectedCancelledStatusId = !!cancelledStatusesIds?.find((id) => id === values.statusId);
    useEffect(() => {
        if (selectedCancelledStatusId) setFieldValue('reason', '');
    }, [values.statusId]);

    return (
        <>
            {dataLoaded && (
                <Modal show={true} onHide={() => onClose(false)} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Change Status</Modal.Title>
                    </Modal.Header>
                    <SecuredContent permissions={Permission.ManageItemsWrite}>
                        <Form form={form}>
                            <Modal.Body>
                                <Form.Row>
                                    <Form.Field name="statusId" label="Status">
                                        <Select
                                            options={projectStatuses?.filter((p) => !p.isEditable)}
                                            dataShape={{
                                                value: 'id',
                                                label: 'name',
                                            }}
                                        />
                                    </Form.Field>
                                </Form.Row>
                                <Form.Row>
                                    {selectedCancelledStatusId && (
                                        <Form.Field name="reason" label="Reason">
                                            <Input />
                                        </Form.Field>
                                    )}
                                </Form.Row>
                                <SelectedProjectItems projectItemsIds={projectItemsIds} projectNumber={projectNumber} />
                            </Modal.Body>
                            <ModalFooter
                                submitText="Update"
                                isSubmitting={isSubmitting}
                                onCancel={() => onClose(false)}
                            />
                        </Form>
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
