import { Table } from '@laingorourke/core-web-components';
import { useSystemRolesUserPermmisions } from 'domain/services/dataService/useSystemRolesUserPermmisions';
import { useProjects } from 'domain/store/reducers/projects';
import { useHasPermissions } from 'domain/store/reducers/users';
import { getMyTasksConfiguration } from 'helpers/applicationConfig';
import { nameof } from 'helpers/nameOf';
import React, { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import {
    getDefaultPaginationOptions,
    getDefaultSortedByColummKey,
    getFilterOptions,
    SecuredContent,
} from 'views/components';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { ProjectParams } from '../../Project';
import AssignPermission from './components/AssignPermissionButton';
import { ExternalLabel, InternalLabel } from './consts';
import { getTableColumns } from './tableColumns';
import { useFilters } from './useFilters';
import { UserSecurableStatus } from './UserSecurableStatus';
import { RoleSecurbale, UserSecurableTableRow } from './UserSecurableTableRow';

export const PermissionManagement: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ProjectPermissionManagementRead}>
        <PermissionManagementContent>{children}</PermissionManagementContent>
    </SecuredContent>
);

const PermissionManagementContent: React.FC<PropsWithChildren> = ({ children }) => {
    const { projectNumber } = useParams<ProjectParams>();
    const myTasksConfig = getMyTasksConfiguration();
    const projects = useProjects();
    const useHasPermissionManagementWritePermission = useHasPermissions(
        Permission.ProjectPermissionManagementWrite,
        projectNumber
    );
    const filters = useFilters();

    const usersSecurables = useSystemRolesUserPermmisions(projectNumber);
    const usersSecurablesTableRows =
        usersSecurables.data
            ?.reduce((acc, current, index) => {
                if (acc.some((u) => u.userId === current.userId)) return acc;

                const userSecurables = usersSecurables?.data?.filter(
                    (u) => u.userId === current.userId
                );

                const row = {
                    userId: current.userId,
                    testId: index.toString(),
                    person: current.person,
                    isActive: current.person.isActive,
                    userType: !!current.person.external ? ExternalLabel : InternalLabel,
                    roles: userSecurables?.map((u) => {
                        // business rules for what status is the permission in
                        let status: UserSecurableStatus | undefined;
                        if (!u.person.isActive) status = UserSecurableStatus.Inactive;
                        else if (!u.roleIsActive || !u.securableIsActive)
                            status = UserSecurableStatus.Inactive;
                        else if (!!u.approvalTaskId && u.approvalRequired)
                            status = UserSecurableStatus.Pending;
                        else status = UserSecurableStatus.Active;

                        return {
                            roleId: u.roleId,
                            roleName: u.roleName,
                            securableTypeId: u.securableTypeId,
                            securableId: u.securableId,
                            approvalRequired: u.approvalRequired,
                            approvalTaskId: u.approvalTaskId,
                            roleIsActive: u.roleIsActive,
                            securableIsActive: u.securableIsActive,
                            status: status,
                        };
                    }),
                };
                acc.push(row);
                return acc;
            }, [] as UserSecurableTableRow[])
            ?.filter((u) =>
                (!filters.selectedRoles || filters.selectedRoles?.length === 0 || u.roles.some((r) => filters.selectedRoles.includes(r.roleName)))
                && (filters.selectedSecurable === undefined || u.roles.some((r) => filters.selectedSecurable === r.securableId))
                && (!filters.selectedStatuses || filters.selectedStatuses?.length === 0 || u.roles.some((r) => filters.selectedStatuses.includes(r.status)))
            ) ?? [];

    const dataLoaded = !!usersSecurables.data;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No User Permissions found');
    const allroles = usersSecurablesTableRows.flatMap((u) => u.roles);
    const rolesOptions = getFilterOptions(allroles, nameof<RoleSecurbale>('roleName'));
    const columns = getTableColumns(
        myTasksConfig?.baseUrl!,
        projects,
        projectNumber,
        rolesOptions,
        filters
    );

    return (
        <>
            {useHasPermissionManagementWritePermission && <AssignPermission />}
            {children}
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(columns, nameof<UserSecurableTableRow>('userId'))}
                {...tableLoadingProps}
                data={dataLoaded ? usersSecurablesTableRows! : []}
                keySelector={(d) => d.userId}
                columns={columns}
                saveSelectedPageSize
                cacheKey="permission-management"
                hover
            />
        </>
    );
};
