import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    Checkbox,
    DatePicker,
    Form,
    generatePath,
    Input,
    Select,
    TextArea,
    useForm,
    WarningPanel,
} from '@laingorourke/core-web-components';
import { TASKKEY } from '@laingorourke/core-web-mytasksreactsdk';
import { useQueryClient } from '@tanstack/react-query';
import format from 'date-fns/format';
import {
    AreaSummary,
    Classification,
    DesignerSummary,
    ExternalType,
    Group,
    ItemTypeCategory,
    ProjectItemDetails,
    Status,
} from 'domain/models/api-models';
import { Site } from 'domain/services/dataService/models';
import { useGroupsByIds } from 'domain/services/dataService/useGroupsByIds';
import { useNotification } from 'domain/store/reducers/notificationsReducer';
import {
    clearItemDetails,
    cloneItem,
    createItem,
    ownerSelected,
    updateItem,
    useSelectedItemOwnerSummaryWithSupplyChainGroups,
} from 'domain/store/reducers/projects';
import { useUserExternalType } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { useProjectOwnersIdsForCurrentUser } from 'hooks/useOwners';
import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
    ClassificationSelect,
    FieldInfoLabel,
    Link,
    ModalFooter,
    OwnerPersonSelector,
} from 'views/components';
import { GroupsSelector } from 'views/components/groupSelector';
import { routes } from 'views/routes/Routes';
import { DateInPastWarning } from '../../details/info/controlMeasures/components/DateInPastWarning';
import { getItemSchema } from '../schemas';
import { Mode } from './Mode';
import styles from './UpsertForm.module.scss';
//@ts-ignore
import { useSelectedItemTypeOwnerLabel } from 'domain/store/reducers/itemType';
import { DefaultItemAreaOwnerLabel } from '../../consts';
import { SiteSelect } from './SiteSelect';

const formRefreshRequiredApiMessage =
    'Item has been updated by another user or process - changes not saved.';

interface UpsertFormProps {
    mode: Mode;
    projectNumber: string;
    itemTypeCode: string;
    categories: ItemTypeCategory[];
    classifications: Classification[];
    areas: AreaSummary[];
    subAreas: AreaSummary[];
    projectStatuses: Status[];
    designers: DesignerSummary[];
    item?: ProjectItemDetails;
    areaCodeInProjectNumber: boolean;
    externalUserGroups?: Group[];
    isExternal?: boolean;
    sites: Site[];
    goToItems: () => void;
}

const submitButtonText = (mode: Mode) => {
    switch (mode) {
        case Mode.New:
            return 'Create';
        case Mode.Edit:
            return 'Update';
        case Mode.Clone:
            return 'Clone';
        default:
            return '';
    }
};

const mergeGroups = (group1?: Group[], group2?: Group[]) => {
    return [
        ...(group1 ?? []),
        ...(group2?.filter((s) => !group1?.some((ss) => ss.id === s.id)) ?? []),
    ];
};

const isClassificationProjectSpecific = (
    classifications: Classification[],
    classificationId: string
) => !!classifications?.find((c) => c.id === classificationId)?.projectNumber;

export const UpsertForm: React.FC<UpsertFormProps> = ({
    mode,
    projectNumber,
    itemTypeCode,
    categories,
    classifications,
    areas,
    subAreas,
    projectStatuses,
    designers,
    item,
    areaCodeInProjectNumber,
    goToItems,
    externalUserGroups,
    isExternal,
    sites,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const externalType = useUserExternalType();

    const isEditMode = mode !== Mode.New;
    const [requiresItemInit, setRequiresItemInit] = useState(mode !== Mode.New);
    const queryClient = useQueryClient();
    const classificationEnabled = useMemo(() => {
        if (mode === Mode.New) {
            return true;
        }
        const itemStatus = projectStatuses.find((p) => p.id === item?.statusId);
        const projectStatusSortOrder = itemStatus ? projectStatuses.indexOf(itemStatus) : -1;
        return projectStatusSortOrder === 0 || projectStatusSortOrder === 1;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cancelledStatusesIds = projectStatuses?.filter((s) => s.isCancelled).map((s) => s.id);
    const ownersIds = useProjectOwnersIdsForCurrentUser(projectNumber, item?.ownerId);

    const isCurrentClassificationActive = isEditMode
        ? classifications?.find((c) => c.id === item?.classificationId)?.isActive
        : true;

    const ownerLabel = useSelectedItemTypeOwnerLabel() ?? DefaultItemAreaOwnerLabel;

    const form = useForm({
        initialValues: {
            name: isEditMode ? item?.name : '',
            description: isEditMode
                ? `${mode === Mode.Clone ? `Cloned from ${item?.number}: ` : ''}${item?.description
                    }`.substring(0, 250)
                : '',
            categoryId: isEditMode ? item?.categoryId! : '',
            classificationId: isEditMode ? item?.classificationId! : '',
            applyProjectComplianceTemplate: isEditMode
                ? isClassificationProjectSpecific(classifications, item!.classificationId!)
                    ? true
                    : item?.applyProjectComplianceTemplate!
                : false,
            dateInService: isEditMode ? item?.dateInService.toString()! : '',
            areaId: isEditMode
                ? areas.find((a) => a.id === item?.areaId) === undefined
                    ? ''
                    : item?.areaId!
                : '',
            subAreaId: isEditMode
                ? subAreas.find((a) => a.id === item?.subAreaId) === undefined
                    ? ''
                    : item?.subAreaId!
                : '',
            areaOwnerId: isEditMode ? item?.areaOwnerId! : '',
            ownerId: isEditMode ? item?.ownerId! : '',
            statusId: isEditMode
                ? mode === Mode.Clone
                    ? projectStatuses[2].id // this is the default status for clone
                    : item?.statusId!
                : projectStatuses[0].id,
            designerId: isEditMode ? item?.designerId! : '',
            reason: isEditMode ? item?.reason : '',
            groupIds: isEditMode ? item?.groupIds : [],
            // only in New Item Mode: if there is only 1 site available, it's selected by default
            siteId: isEditMode
                ? item?.siteId
                : sites.length === 1
                    ? sites[0].id.toLocaleLowerCase()
                    : undefined,
        },
        validationSchema: getItemSchema(cancelledStatusesIds, classifications),
        onSubmit: async (values) => {
            let result;
            const { dateInService, ...valuesToSend } = values;
            const dateInServiceFormatted = format(new Date(dateInService), 'yyyy-MM-dd');
            switch (mode) {
                case Mode.New:
                    result = await dispatch(
                        createItem({
                            ...valuesToSend,
                            projectNumber,
                            dateInService: dateInServiceFormatted,
                        })
                    );
                    break;
                case Mode.Edit:
                    result = await dispatch(
                        updateItem({
                            ...valuesToSend,
                            id: item?.id!,
                            projectNumber,
                            itemTypeId: item?.itemTypeId!,
                            dateInService: dateInServiceFormatted,
                            lastModified: item?.lastModified!,
                        })
                    );
                    break;
                case Mode.Clone:
                    result = await dispatch(
                        cloneItem({
                            ...valuesToSend,
                            id: item?.id!,
                            projectNumber,
                            itemTypeId: item?.itemTypeId!,
                            dateInService: dateInServiceFormatted,
                        })
                    );
                    if ((result as any).payload) {
                        // In case of clone mode, we redirect to a newly created item
                        history.push(
                            generatePath(
                                routes.projects.routes!.project.routes!.items.routes!.itemDetails
                                    .path,
                                {
                                    projectNumber,
                                    itemTypeCode,
                                    projectItemId: (result as any).payload.id,
                                }
                            )
                        );
                    }
                    break;
                default:
                    break;
            }

            if ((result as any).payload) {
                queryClient.invalidateQueries(TASKKEY());
                goToItems();
            }
        },
    });

    const { isSubmitting, values, setFieldValue } = form;

    const setOwnerIdFromSelectedArea = () => {
        const areaOwnerId =
            areas?.find((a) => a.id.toLocaleLowerCase() === values.areaId.toLocaleLowerCase())
                ?.ownerId || '';
        setFieldValue('ownerId', areaOwnerId.toLocaleLowerCase());
        dispatch(ownerSelected({ personId: areaOwnerId }));
    };

    const notification = useNotification();
    const [refreshRequired, setRefreshRequired] = useState(false);

    const selectedOwnerSummary = useSelectedItemOwnerSummaryWithSupplyChainGroups();
    const selectedGroups = useGroupsByIds(item?.groupIds);
    const [selectedSubcontractors, setSelectedSubcontractors] = useState<Group[] | undefined>(
        mergeGroups(selectedGroups?.data, externalUserGroups)
    );

    useEffect(() => {
        // this is the easiest way to detect whether we need a refresh or not
        // this is based on the notification message that comes from the API
        if (!notification.isSuccess && notification.message === formRefreshRequiredApiMessage) {
            setRefreshRequired(true);
        }
    }, [notification.message]);

    useEffect(() => {
        if (requiresItemInit) {
            setRequiresItemInit(false);
        }

        if (!requiresItemInit) setFieldValue('subAreaId', '');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.areaId, setFieldValue]);

    useEffect(() => {
        if (classifications === undefined || values.classificationId === '') return;
    }, [classifications, values.classificationId, setFieldValue]);

    const getSubAreas = (parentAreaId: string) => {
        if (parentAreaId === '') return [];

        return subAreas!
            .filter((a) => a.parentAreaId === parentAreaId)
            .sort((a, b) => a.name!.localeCompare(b.name!));
    };

    const selectedCancelledStatusId = !!cancelledStatusesIds?.find((id) => id === values.statusId);
    useEffect(() => {
        if (selectedCancelledStatusId) setFieldValue('reason', '');
    }, [values.statusId]);

    useEffect(() => {
        if (!requiresItemInit) {
            const ownerSubcontractors = !!selectedOwnerSummary?.external
                ? selectedOwnerSummary?.groups
                : [];
            const selectedWithSubcontractorsUpdated = mergeGroups(
                selectedSubcontractors,
                ownerSubcontractors
            );

            setSelectedSubcontractors(selectedWithSubcontractorsUpdated);
            setFieldValue(
                'groupIds',
                selectedWithSubcontractorsUpdated.map((v) => v.id)
            );
        }
        if (requiresItemInit) {
            setRequiresItemInit(false);
        }
    }, [selectedOwnerSummary]);

    const isSelectedClassificationProjectSpecific = isClassificationProjectSpecific(
        classifications,
        values.classificationId
    );
    useEffect(() => {
        if (isSelectedClassificationProjectSpecific)
            setFieldValue('applyProjectComplianceTemplate', true);
    }, [isSelectedClassificationProjectSpecific]);

    useEffect(() => {
        const subAreaOwnerId = !!values.subAreaId
            ? subAreas.find((a) => a.id === values.subAreaId)?.ownerId
            : undefined;
        const areaOwnerId = areas.find((a) => a.id === values.areaId)?.ownerId;

        setFieldValue('areaOwnerId', subAreaOwnerId ?? areaOwnerId);
    }, [values.areaId, values.subAreaId]);

    return (
        <Form form={form}>
            <Modal.Body>
                <Form.Row>
                    <Form.Field name="name" label="Title">
                        <Input disabled={mode === Mode.Clone} />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="description" label="Description">
                        <TextArea disabled={mode === Mode.Clone} />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="categoryId" label="Category">
                        <Select
                            options={categories}
                            dataShape={{
                                value: 'id',
                                label: 'name',
                            }}
                            disabled={mode === Mode.Edit || mode === Mode.Clone}
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="classificationId" label="Classification">
                        <ClassificationSelect
                            disabled={!classificationEnabled}
                            options={classifications!.map((c) => ({
                                value: c.id,
                                label: c.name!,
                            }))}
                            isDisabled={!isCurrentClassificationActive}
                        />
                    </Form.Field>
                </Form.Row>
                {item?.schedulesAmended && <WarningPanel className={styles.warning}>The original Inspection Schedule has been ammended</WarningPanel>}
                <Form.Row>
                    <Link
                        className={styles.classificationGuidelinesLink}
                        target={'_blank'}
                        href={generatePath(
                            routes.projects.routes!.project.routes!.classificationGuidelines.path,
                            {
                                projectNumber,
                                itemTypeCode,
                            }
                        )}>
                        Classification Guidelines
                    </Link>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="applyProjectComplianceTemplate" label="">
                        <Checkbox
                            disabled={
                                mode === Mode.Clone ||
                                !isCurrentClassificationActive ||
                                isSelectedClassificationProjectSpecific
                            }>
                            Apply Project Compliance Template
                        </Checkbox>
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="dateInService" label="Date In Service">
                        <DatePicker append={<FontAwesomeIcon icon={faCalendar} />} />
                    </Form.Field>
                </Form.Row>
                <DateInPastWarning dates={values.dateInService} label="Date In Service" />
                <Form.Row>
                    <Form.Field name="areaId" label="Area">
                        <Select
                            options={areas}
                            dataShape={{
                                value: 'id',
                                label: 'name',
                            }}
                            disabled={isEditMode && areaCodeInProjectNumber}
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="subAreaId" label="Sub Area">
                        <Select
                            options={getSubAreas(values.areaId)}
                            dataShape={{
                                value: 'id',
                                label: 'name',
                            }}
                            isClearable
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="areaOwnerId" label={ownerLabel}>
                        <OwnerPersonSelector
                            ownersIds={!!values.areaOwnerId ? [values.areaOwnerId] : []}
                            projectNumber={projectNumber}
                            disabled={true}
                            placeholder="Select Area or Sub Area..."
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="ownerId" label="Owner">
                        <OwnerPersonSelector
                            ownersIds={ownersIds}
                            projectNumber={projectNumber}
                            onOwnerSelected={(ownerId: string) => {
                                dispatch(ownerSelected({ personId: ownerId }));
                            }}
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="designerId" label="Designer">
                        <Select
                            options={designers}
                            dataShape={{
                                value: 'id',
                                label: 'name',
                            }}
                            isClearable
                            disabled={mode === Mode.Clone}
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="statusId" label="Status" disabled={mode === Mode.New}>
                        <Select
                            options={
                                mode === Mode.Edit
                                    ? projectStatuses.filter(
                                        (p) => !p.isEditable || p.id === item?.statusId
                                    )
                                    : projectStatuses
                            }
                            dataShape={{
                                value: 'id',
                                label: 'name',
                            }}
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    {selectedCancelledStatusId && (
                        <Form.Field name="reason" label="Reason">
                            <Input />
                        </Form.Field>
                    )}
                </Form.Row>
                <Form.Row>
                    <Form.Field name="groupIds" label="Subcontractors">
                        {
                            //Only internal users or external JVPartners can update this field
                            isExternal && externalType !== ExternalType.JVPartner ? (
                                <Select
                                    isMulti
                                    options={selectedSubcontractors?.map((g) => {
                                        return {
                                            value: g.id,
                                            label: g.name,
                                        };
                                    })}
                                    value={selectedSubcontractors?.map((g) => g.id)}
                                    disabled
                                />
                            ) : (
                                <GroupsSelector
                                    selectedGroups={[...(selectedSubcontractors ?? [])]}
                                    projectNumber={projectNumber}
                                    groupSelected={(selectedGroups: Group[]) => {
                                        setSelectedSubcontractors(selectedGroups);
                                    }}
                                />
                            )
                        }
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field
                        name="siteId"
                        label={
                            <FieldInfoLabel
                                label="Site"
                                tooltipContent="This information is sourced from Project Central"
                            />
                        }>
                        <SiteSelect
                            sites={sites}
                            selectedSiteId={values.siteId}
                            onChange={(v) => setFieldValue('siteId', v)}
                        />
                    </Form.Field>
                </Form.Row>
                {refreshRequired && (
                    <WarningPanel>
                        Sorry! This Item has been updated by another user or process. To prevent
                        potential data loss, your changes have not been saved. Please note or copy
                        your changes then click "Refresh" to load the updated data. Once the updated
                        data has loaded, review and make any required changes before clicking
                        "Update" again.
                    </WarningPanel>
                )}
            </Modal.Body>
            <ModalFooter
                submitText={submitButtonText(mode)}
                isSubmitting={isSubmitting}
                onCancel={goToItems}>
                {refreshRequired && (
                    <Button
                        variant="secondary"
                        onClick={() => {
                            dispatch(clearItemDetails());
                            setRefreshRequired(false);
                        }}>
                        Refresh
                    </Button>
                )}
            </ModalFooter>
        </Form>
    );
};
