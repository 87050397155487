import { Navigation, Page } from '@laingorourke/core-web-components';
import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { ProjectParams } from '../Project';

export const ProjectSetup: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ProjectDetailsRead}>
        <ProjectSetupContent>{children}</ProjectSetupContent>
    </SecuredContent>
);

const ProjectSetupContent: React.FC<PropsWithChildren> = ({ children }) => {
    const params = useParams<ProjectParams>();
    const projectSetupRoutes = routes.projects.routes!.project.routes!.projectSetup.routes!;

    return (
        <Row>
            <Col md="3" lg="2">
                <Page.Section noPadding>
                    <Navigation direction="vertical" id="project-setup-navigation">
                        <Navigation.Route
                            route={projectSetupRoutes.leadDays}
                            params={{ ...params }}>
                            Lead Days
                        </Navigation.Route>
                        <Navigation.Route
                            route={projectSetupRoutes.classifications}
                            params={{ ...params }}>
                            Classifications
                        </Navigation.Route>
                        <Navigation.Route
                            route={projectSetupRoutes.complianceTemplates}
                            params={{ ...params }}>
                            Compliance Templates
                        </Navigation.Route>
                        <Navigation.Route
                            route={projectSetupRoutes.designers}
                            params={{ ...params }}>
                            Designers
                        </Navigation.Route>
                        <Navigation.Route
                            route={projectSetupRoutes.itemNumber}
                            params={{ ...params }}>
                            Item Number
                        </Navigation.Route>
                        <Navigation.Route
                            route={projectSetupRoutes.integrations}
                            params={{ ...params }}>
                            Integrations
                        </Navigation.Route>
                        <Navigation.Route route={projectSetupRoutes.qrCode} params={{ ...params }}>
                            QR Code
                        </Navigation.Route>
                        <Navigation.Route
                            route={projectSetupRoutes.permissionManagement}
                            params={{ ...params }}>
                            Permission Management
                        </Navigation.Route>
                    </Navigation>
                </Page.Section>
            </Col>
            <Col md="9" lg="10">
                {children}
            </Col>
        </Row>
    );
};
