import cn from 'classnames';
import React from 'react';
import styles from './QrCodeImage.module.scss';

interface QrCodeImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    base64Image?: string;
}

export const QrCodeImage: React.FC<QrCodeImageProps> = ({ base64Image, className, ...props }) => {
    if (!base64Image) return null;
    return (
        <img
            src={`data:image/png;base64,${base64Image}`}
            className={cn(styles.qrCode, className)}
            {...props}
        />
    );
};
