import { Skeleton } from '@laingorourke/core-web-components';
import { ProjectItemDetails } from 'domain/models/api-models';
import { useGroupsByIds } from 'domain/services/dataService/useGroupsByIds';
import { useProjectClassifications } from 'domain/store/reducers/classifications';
import { useSelectedItemTypeOwnerLabel } from 'domain/store/reducers/itemType';
import { default as React } from 'react';
import {
    ClassificationBadge,
    getDefaultValueWhenEmpty,
    ReadOnlyDetailsList,
    Site,
} from 'views/components';
import { PersonDisplayName } from 'views/components/personDisplayName';
import { ItemNumber } from '../../../../../../components/itemNumber/ItemNumber';
import { DateInService } from '../../../components/DateInService';
import { DefaultItemAreaOwnerLabel } from '../../../consts';

const detailFieldHeight = 17;
interface ItemDetailsProps {
    itemDetails?: ProjectItemDetails;
}

export const ItemDetails: React.FC<ItemDetailsProps> = ({ itemDetails }) => {
    const groups = useGroupsByIds(itemDetails?.groupIds);
    const classifications = useProjectClassifications();
    const ownerLabel = useSelectedItemTypeOwnerLabel() ?? DefaultItemAreaOwnerLabel;

    return (
        <ReadOnlyDetailsList
            items={[
                { label: 'Title', value: itemDetails?.name },
                {
                    label: 'Classification',
                    value: (
                        <Skeleton.Box
                            height={detailFieldHeight}
                            loading={!itemDetails || !classifications}>
                            <ClassificationBadge id={itemDetails?.classificationId} />
                        </Skeleton.Box>
                    ),
                },
                { label: 'Description', value: getDefaultValueWhenEmpty(itemDetails?.description) },
                {
                    label: 'Number',
                    value: itemDetails?.number ? (
                        <ItemNumber itemNumber={itemDetails.number} projectNumber={itemDetails.projectNumber as string} copyTextEnabled />
                    ) : undefined,
                },
                { label: 'Category', value: itemDetails?.categoryName },
                {
                    label: 'Date In Service',
                    value: itemDetails?.dateInService ? (
                        <DateInService
                            id={itemDetails.id}
                            dateInService={itemDetails.dateInService}
                            isDateInServiceFromP6={itemDetails.isDateInServiceFromP6}
                        />
                    ) : undefined,
                },
                { label: 'Area', value: itemDetails?.areaName },
                { label: 'Sub Area', value: getDefaultValueWhenEmpty(itemDetails?.subAreaName) },
                {
                    label: ownerLabel,
                    value: <PersonDisplayName personId={itemDetails?.areaOwnerId} />,
                },
                {
                    label: 'Owner',
                    value: <PersonDisplayName personId={itemDetails?.ownerId} />,
                },
                { label: 'Designer', value: getDefaultValueWhenEmpty(itemDetails?.designerName) },
                { label: 'Status', value: itemDetails?.statusName },
                ...(itemDetails?.reason ? [{ label: 'Reason', value: itemDetails?.reason }] : []),
                {
                    label: 'Project Compliance Template',
                    value: itemDetails
                        ? itemDetails?.applyProjectComplianceTemplate
                            ? 'Applied'
                            : 'Not Applied'
                        : undefined,
                },
                {
                    label: 'Subcontractors',
                    value: !!itemDetails
                        ? getDefaultValueWhenEmpty(
                            !!itemDetails?.groupIds
                                ? itemDetails?.groupIds.length === 0
                                    ? ''
                                    : groups.data?.map((g) => g.name).join(', ')
                                : undefined
                        )
                        : undefined,
                },
                {
                    label: 'Site',
                    value: getDefaultValueWhenEmpty(
                        !itemDetails ? undefined : !!itemDetails && !itemDetails.siteId ? (
                            ''
                        ) : (
                            <Site
                                projectNumber={itemDetails!.projectNumber!}
                                siteId={itemDetails?.siteId}
                            />
                        )
                    ),
                },
            ]}
        />
    );
};
