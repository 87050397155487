import { fetchProjectStatuses, useItemStatuses } from 'domain/store/reducers/itemType';
import {
    fetchProjectItemDetailsSummary,
    useItemDetailsSummary,
    useProjectId,
} from 'domain/store/reducers/projects';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { PropsWithChildren, useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ActionButtonsPanel, TooltipButton } from 'views/components';
import { routes } from 'views/routes/Routes';
import { ProjectItemDetailsPage } from '../ProjectItemDetailsPage';
import { ProjectItemDetailsParams } from '../ProjectItemDetailsParams';
import { getNonEditableStatusesMessage } from '../statusesHelper';
import { SchedulesDataTable } from './SchedulesDataTable';

export const Schedules: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const { projectItemId, projectNumber, itemTypeCode } = useParams<ProjectItemDetailsParams>();
    const history = useHistory();
    const itemStatuses = useItemStatuses();
    const itemDetails = useItemDetailsSummary();
    const currentUserHasManageItemsWritePermission = useHasPermissions(
        Permission.ManageItemsWrite,
        projectNumber
    );
    const currentUserHasManageItemsDeletePermission = useHasPermissions(
        Permission.ManageItemsDelete,
        projectNumber
    );
    const projectId = useProjectId(projectNumber);

    const itemTypeId = itemDetails?.itemTypeId;

    const itemDetailsLoaded = !!itemDetails;
    const dataLoaded = itemDetailsLoaded && !!itemStatuses;

    const nonEditableStatusesMessage = getNonEditableStatusesMessage(itemStatuses);

    const goToNewSchedule = () => {
        history.push(
            generatePath(
                routes.projects.routes!.project.routes!.items.routes!.schedules.routes!.newSchedule
                    .path,
                {
                    projectItemId,
                    projectNumber,
                    itemTypeCode,
                }
            )
        );
    };

    const getTooltipContent = () => {
        if (!itemDetails) {
            return 'Loading...';
        }
        if (!itemDetails.canBeUpdated)
            return `Inspection Schedule can not be added as the Item is of status ${nonEditableStatusesMessage}`;
        if (itemDetails.myTasksOperationsBlocked) {
            return 'Unable to Add Inspection Schedule until background processing is complete.';
        }
        if (itemDetails.detailAccessLocked) {
            return "You don't have permission to add an Inspection Schedule.";
        }
        return '';
    };

    useEffect(() => {
        dispatch(fetchProjectItemDetailsSummary({ projectItemId, projectNumber }));
    }, [projectNumber, projectItemId, dispatch]);

    useEffect(() => {
        if (itemDetailsLoaded) dispatch(fetchProjectStatuses({ itemTypeId }));
    }, [itemDetailsLoaded, itemTypeId, dispatch]);

    return (
        <ProjectItemDetailsPage
            title={itemDetails?.name}
            actionButtons={
                currentUserHasManageItemsWritePermission && (
                    <ActionButtonsPanel>
                        <TooltipButton
                            id="new-schedule"
                            onClick={goToNewSchedule}
                            disabled={
                                !itemDetails ||
                                !itemDetails.canBeUpdated ||
                                itemDetails.myTasksOperationsBlocked ||
                                itemDetails.detailAccessLocked
                            }
                            tooltipContent={getTooltipContent()}>
                            New Inspection Schedule
                        </TooltipButton>
                    </ActionButtonsPanel>
                )
            }>
            {dataLoaded && !itemDetails.detailAccessLocked && (
                <SchedulesDataTable
                    currentUserHasManageItemsDeletePermission={
                        currentUserHasManageItemsDeletePermission
                    }
                    currentUserHasManageItemsWritePermission={
                        currentUserHasManageItemsWritePermission
                    }
                    itemDetails={itemDetails}
                    itemTypeId={itemTypeId}
                    nonEditableStatusesMessage={nonEditableStatusesMessage}
                    projectItemId={projectItemId}
                    projectId={projectId}
                />
            )}
            {children}
        </ProjectItemDetailsPage>
    );
};
