import { generatePath } from '@laingorourke/core-web-components';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActionButtonsPanel, usePageActions } from 'views/components';
import { TooltipButton } from 'views/components/tooltipButton';
import { routes } from 'views/routes/Routes';
import { ReviewRouteParams } from '../ReviewsRouteParams';

const AddReview: React.FC = () => {
    const history = useHistory();
    const params = useParams<ReviewRouteParams>();

    const goToNewReview = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.reviews.routes!.newReview.path, {
                ...params,
            })
        );

    usePageActions(
        <ActionButtonsPanel>
            <TooltipButton id="new-review" variant="primary" onClick={goToNewReview}>
                New Review
            </TooltipButton>
        </ActionButtonsPanel>,
        [params.itemTypeCode]
    );

    return <></>;
};

export default AddReview;
