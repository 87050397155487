import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    ProjectItemNumberConfiguration,
    UpdateAreaCodeInItemNumberRequest,
    UpdateHideProjectNumberRequest,
    UpdateItemNumberSeparatorRequest,
    UpdateMaxItemNumberRequest,
} from 'domain/models/api-models';
import { get, patch } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

interface FetchParams {
    projectNumber: string;
    itemTypeId: string;
}

export const fetchProjectItemNumberConfiguration = createAsyncThunk<
    ProjectItemNumberConfiguration,
    FetchParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/projectItemNumberConfigurations/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/projectItemNumberConfigurations`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface FetchExampleParams {
    projectNumber: string;
    itemTypeId: string;
}

export const fetchProjectItemNumberConfigurationNumberExamples = createAsyncThunk<
    ProjectItemNumberConfiguration,
    FetchExampleParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/projectItemNumberConfigurations/examples/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/projectItemNumberConfigurations/examples`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface UpdateAreaCodeInItemNumberRequestParams extends UpdateAreaCodeInItemNumberRequest {
    projectNumber: string;
    itemTypeId: string;
}

export const updateAreaCodeInItemNumber = createAsyncThunk<
    ProjectItemNumberConfiguration,
    UpdateAreaCodeInItemNumberRequestParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/projectItemNumberConfigurations/patch',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const updatedConfiguration = await patch(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/projectItemNumberConfigurations/areaCodeInItemNumber`,
                args
            );

            dispatch(
                addNotification({
                    message: updatedConfiguration.areaCodeInItemNumber
                        ? 'Project will now include area codes in Item Numbers'
                        : 'Project will not include area codes in Item Numbers',
                    isSuccess: true,
                })
            );
            return updatedConfiguration;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface UpdateItemNumberSeparatorRequestParams extends UpdateItemNumberSeparatorRequest {
    projectNumber: string;
    itemTypeId: string;
}

export const updateSeparator = createAsyncThunk<
    ProjectItemNumberConfiguration,
    UpdateItemNumberSeparatorRequestParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/projectItemNumberConfigurations/patch',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const updatedConfiguration = await patch(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/projectItemNumberConfigurations/separator`,
                args
            );

            dispatch(
                addNotification({
                    message: `Project Item Number separator has been changed to ${updatedConfiguration.separator}`,
                    isSuccess: true,
                })
            );
            return updatedConfiguration;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface UpdateHideProjectNumberRequestParams extends UpdateHideProjectNumberRequest {
    projectNumber: string;
    itemTypeId: string;
}

export const updateHideProjectNumber = createAsyncThunk<
    ProjectItemNumberConfiguration,
    UpdateHideProjectNumberRequestParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/ProjectItemNumberConfigurations/hideProjectNumber/patch',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const updatedConfiguration = await patch(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/projectItemNumberConfigurations/hideProjectNumber`,
                args
            );

            dispatch(
                addNotification({
                    message: updatedConfiguration.hideProjectNumber
                        ? 'Project Number will be hidden in the Item Number'
                        : 'Project Number will be visible in the Item Number',
                    isSuccess: true,
                })
            );
            return updatedConfiguration;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface UpdateMaxItemNumberRequestParams extends UpdateMaxItemNumberRequest {
    projectNumber: string;
    itemTypeId: string;
}

export const updateMaxItemNumber = createAsyncThunk<
    ProjectItemNumberConfiguration,
    UpdateMaxItemNumberRequestParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/projectItemNumberConfigurations/maxItemNumber/patch',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const updatedConfiguration = await patch(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/projectItemNumberConfigurations/maxItemNumber`,
                args
            );

            dispatch(
                addNotification({
                    message: `Max Item Number has been updated to ${args.maxItemNumber}`,
                    isSuccess: true,
                })
            );
            return updatedConfiguration;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);
