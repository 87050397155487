import { ClassificationHub } from 'domain/models/api-models';
import {
    emojis,
    emojiValidationMessage,
    invalidCharacters
} from 'views/components/form/validators';
import { ComplianceTemplateSchemaBase } from 'views/routes/shared/complianceTemplates';
import * as Yup from 'yup';

export const numbersAndLettersAllowed = /^(.|\s)*[a-zA-Z0-9]+(.|\s)*$/;

export const ClassificationSchema = Yup.object().shape({
    name: Yup.string()
        .matches(emojis, emojiValidationMessage)
        .matches(numbersAndLettersAllowed, 'Name contains invalid characters')
        .max(20, 'Name cannot be longer than 20 characters')
        .required('Name is required'),
    shortName: Yup.string()
        .matches(emojis, emojiValidationMessage)
        .matches(numbersAndLettersAllowed, 'Short name contains invalid characters')
        .max(6, 'Short name cannot be longer than 6 characters')
        .required('Short name is required'),
    description: Yup.string()
        .matches(emojis, emojiValidationMessage)
        .matches(invalidCharacters, 'Description contains invalid characters')
        .max(100, 'Description cannot be longer than 100 characters'),
    hub: Yup.mixed<ClassificationHub>()
        .nullable()
        .oneOf(Object.values(ClassificationHub))
        .required('Hub is required'),
    color: Yup.string()
        .matches(emojis, emojiValidationMessage)
        .matches(invalidCharacters, 'Color contains invalid characters')
        .max(7, 'Color cannot be longer than 7 characters')
        .required('Color is required')
});

export const ComplianceTemplateSchema = Yup.object().shape(ComplianceTemplateSchemaBase);
