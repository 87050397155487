import { generatePath } from '@laingorourke/core-web-components';
import { useWorkflow } from '@laingorourke/core-web-mytasksreactsdk';
import { loadingCompleted, loadingStarted } from 'domain/store/reducers/inProgress/reducer';
import {
    useItemTypeId,
    useSelectedItemTypeCode,
    useSelectedItemTypeMyTasksWorkflowId,
} from 'domain/store/reducers/itemType';
import {
    fetchProjectControlMeasures,
    useProjectControlMeasures,
} from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import { useItemInfoRoute } from 'hooks/useItemInfoRoute';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { ProjectItemDetailsParams } from '../../../ProjectItemDetailsParams';
import { CreateForm } from './CreateForm';

export const CreateControlMeasure: React.FC = () => {
    const dispatch = useAppDispatch();

    const { projectItemId, projectNumber } = useParams<ProjectItemDetailsParams>();
    const history = useHistory();
    const itemTypeCode = useSelectedItemTypeCode();
    const itemTypeId = useItemTypeId(itemTypeCode);
    const workflowId = useSelectedItemTypeMyTasksWorkflowId();
    const workflow = useWorkflow(workflowId!);
    const statuses = workflow.data?.statuses;
    const controlMeasures = useProjectControlMeasures();

    const itemInfoRoute = useItemInfoRoute();
    const goToItemDetails = () =>
        history.push(
            generatePath(itemInfoRoute.path, {
                projectNumber,
                projectItemId,
                itemTypeCode,
            })
        );

    useEffect(() => {
        if (!itemTypeId) {
            return;
        }
        dispatch(loadingStarted());
        dispatch(fetchProjectControlMeasures({ projectNumber, itemTypeId: itemTypeId }));
    }, [projectNumber, itemTypeId, dispatch]);

    useEffect(() => {
        if (!!controlMeasures) dispatch(loadingCompleted());
    }, [controlMeasures, dispatch]);

    return (
        <>
            {statuses && controlMeasures && (
                <Modal show={true} onHide={goToItemDetails} animation={false} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Add Control Measure</Modal.Title>
                    </Modal.Header>
                    <SecuredContent permissions={Permission.ManageItemsWrite}>
                        <CreateForm
                            controlMeasures={controlMeasures}
                            statuses={statuses}
                            projectNumber={projectNumber}
                            projectItemId={projectItemId}
                            goBack={goToItemDetails}
                        />
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
