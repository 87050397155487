import { Table } from '@laingorourke/core-web-components';
import { ProjectItem } from 'domain/services/dataService/models';
import { nameof } from 'helpers/nameOf';
import { useMemo } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { defaultPageSizes } from 'views/components';
import { getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { routes } from 'views/routes/Routes';
import { ItemTypeResults } from '../../models/ItemTypeResults';
import { SearchPageParams } from '../../SearchPage';
import styles from './SearchTable.module.scss';
import { getTableColumns } from './tableColumns';

interface SearchTableProps {
    itemType: ItemTypeResults;
    isLoading: boolean;
}

export const SearchTable: React.FC<SearchTableProps> = ({ itemType, isLoading }) => {
    const history = useHistory();

    const { term } = useParams<SearchPageParams>();

    const tableLoadingProps = useTableLoading(isLoading, 'No Items found');
    let redirectToComply = (projectItem: ProjectItem) =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.items.routes!.itemDetails.path, {
                projectNumber: projectItem.project.number,
                projectItemId: projectItem.id,
                itemTypeCode: itemType.code,
            })
        );

    const classificationOptions = useMemo(
        () =>
            itemType.items
                .map((item) => item.classification.name)
                .filter((name, idx, arr) => arr.findIndex((n) => n === name) === idx)
                .map((name) => ({
                    label: name,
                    value: name,
                })),
        [itemType.items.length]
    );

    const items = itemType.items.map((i) => ({ ...i, projectNumber: i.project.number }));

    const onPreviewClicked = (projectItemId: string, projectNumber: string) => {
        return history.push(
            generatePath(routes.search.routes!.preview.path, {
                projectItemId,
                term,
                itemTypeCode: itemType.code,
                projectNumber,
            })
        );
    };
    const columns = getTableColumns(classificationOptions, onPreviewClicked);

    return (
        <div className={styles.searchTable}>
            <Table<ProjectItem>
                {...tableLoadingProps}
                data={items}
                {...getDefaultSortedByColummKey(
                    columns,
                    nameof<ProjectItem>('dateInService'),
                    'desc'
                )}
                clickable={true}
                onRowClick={redirectToComply}
                pageSize={10}
                pageSizes={defaultPageSizes}
                keySelector={(data) => data.id}
                columns={columns}
            />
        </div>
    );
};
