import { CollapsibleCard, Table } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { nameof } from 'helpers/nameOf';
import { getFormattedDate } from 'helpers/utils';
import React, { PropsWithChildren } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { routes } from 'views/routes/Routes';
import { WeatherEventItemTableRow } from './WeatherEventItemTableRow';
import styles from './WeatherEventItems.module.scss';
import { getTableColumns } from './tableColumns';

interface WeatherEventItemsTableProps extends PropsWithChildren {
    itemTypeCode: string;
    projectNumber: string;
    date: string;
    items: WeatherEventItemTableRow[];
    classiticationsSortOrder: string[];
    classificationOptions: OptionTypeBase[];
    areaNameOptions: OptionTypeBase[];
    subAreaNameOptions: OptionTypeBase[];
}

export const WeatherEventItemsTable: React.FC<WeatherEventItemsTableProps> = ({
    date,
    items,
    itemTypeCode,
    projectNumber,
    classiticationsSortOrder,
    classificationOptions,
    areaNameOptions,
    subAreaNameOptions,
}) => {
    const history = useHistory();
    const goToItemDetailsPreview = (projectItemId: string) => {
        return history.push(
            generatePath(
                routes.projects.routes!.project.routes!.weather.routes!.weatherEventsItems.routes!
                    .preview.path,
                {
                    projectNumber,
                    projectItemId,
                    itemTypeCode,
                }
            )
        );
    };

    const tableLoadingProps = useTableLoading(false, 'No Items found');
    const hideItemDetailsColumn = !!items
        ? items?.filter((i) => i.detailAccessLocked)?.length === items?.length
        : true;
    const columns = getTableColumns(
        (projectItemId: string) => goToItemDetailsPreview(projectItemId),
        classiticationsSortOrder,
        classificationOptions,
        areaNameOptions,
        subAreaNameOptions,
        projectNumber,
        hideItemDetailsColumn
    );

    return (
        <CollapsibleCard
            key={date}
            className={styles.card}
            title={getFormattedDate(date)}
            collapsed={true}>
            <Table<WeatherEventItemTableRow>
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(
                    columns,
                    nameof<WeatherEventItemTableRow>('number')
                )}
                {...tableLoadingProps}
                columns={columns}
                data={items}
                keySelector={(d) => d.id}
                saveSelectedPageSize
                cacheKey="weather-event-items"
                hover
            />
        </CollapsibleCard>
    );
};
