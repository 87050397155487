import { Form, useForm } from '@laingorourke/core-web-components';
import { loadingCompleted, loadingStarted } from 'domain/store/reducers/inProgress/reducer';
import { assignOwner } from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import { useProjectOwnersIdsForCurrentUser } from 'hooks/useOwners';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Permission } from 'security/Permission';
import { ModalFooter, OwnerPersonSelector, SecuredContent } from 'views/components';
import * as Yup from 'yup';
import { SelectedProjectItems } from '../components/SelectedProjectItem';

interface AssignOwnerProps {
    itemTypeId: string;
    projectNumber: string;
    projectItemsIds: string[];
    show: boolean;
    onClose: (success: boolean) => void;
}

export const AssignOwner: React.FC<AssignOwnerProps> = ({
    itemTypeId,
    projectNumber,
    projectItemsIds,
    onClose,
}) => {
    const dispatch = useAppDispatch();

    const ownersIds = useProjectOwnersIdsForCurrentUser(projectNumber, undefined);

    const form = useForm({
        initialValues: {
            ownerId: '',
        },
        validationSchema: Yup.object().shape({
            ownerId: Yup.string().required('Owner is required'),
        }),
        onSubmit: async (values) => {
            const result = await dispatch(
                assignOwner({
                    projectItemsIds,
                    ownerId: values.ownerId,
                    itemTypeId,
                    projectNumber,
                })
            );

            if ((result as any).payload) onClose(true);
        },
    });

    const { isSubmitting } = form;

    const dataLoaded = !!ownersIds;

    useEffect(() => {
        if (dataLoaded) dispatch(loadingCompleted());
        else dispatch(loadingStarted());
        return () => {
            dispatch(loadingCompleted());
        };
    }, [dataLoaded, dispatch]);

    return (
        <>
            {dataLoaded && (
                <Modal
                    show={true}
                    onHide={() => onClose(false)}
                    animation={false}
                    backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Assign Owner</Modal.Title>
                    </Modal.Header>
                    <SecuredContent permissions={Permission.ManageItemsWrite}>
                        <Form form={form}>
                            <Modal.Body>
                                <Form.Row>
                                    <Form.Field name="ownerId" label="Owner">
                                        <OwnerPersonSelector
                                            ownersIds={ownersIds}
                                            projectNumber={projectNumber}
                                        />
                                    </Form.Field>
                                </Form.Row>
                                <SelectedProjectItems projectItemsIds={projectItemsIds} projectNumber={projectNumber} />
                            </Modal.Body>
                            <ModalFooter
                                submitText="Update"
                                isSubmitting={isSubmitting}
                                onCancel={() => onClose(false)}
                            />
                        </Form>
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
