import {
    ColumnDefinition,
    RowSelectable,
    Table,
    TableChangeEventHandler,
    TablePaging,
    TableSelectionChangeEventHandler,
    TableSort,
} from '@laingorourke/core-web-components';
import cn from 'classnames';
import { useWithAssignee } from 'hooks/useWithPerson';
import { getDefaultSorted } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { goToMyTasks } from 'views/routes/project/myTasksHelpers';
import styles from './TaskTable.module.scss';

interface PaginationProps extends TablePaging {
    cacheKey?: string;
    pageSizes?: number[];
}

interface TaskTableProps<TData> {
    columns: ColumnDefinition<TData>[];
    data: any[] | undefined;
    noDataMessage: string;
    sorted?: TableSort;
    pagination?: PaginationProps;
    isFetching?: boolean;
    onTableChange?: TableChangeEventHandler;
    isTableChanging?: boolean;
    isRemote?: boolean;
    selectable?: boolean;
    onSelectionChange?: TableSelectionChangeEventHandler<TData>;
    overriddenSelected?: TData[];
    customRowSelectable?: (row: TData) => RowSelectable;
}

export const TaskTable = <TData,>({
    columns,
    data,
    noDataMessage,
    sorted,
    pagination,
    isFetching,
    onTableChange,
    isTableChanging,
    isRemote,
    selectable,
    onSelectionChange,
    overriddenSelected,
    customRowSelectable,
}: TaskTableProps<TData>) => {
    const tasks = useWithAssignee(data);
    const tableLoadingProps = useTableLoading(!data || isFetching === true, noDataMessage);

    return (
        <Table
            {...getDefaultSorted(sorted?.sortColumnIndex, sorted?.sortDirection)}
            {...pagination}
            {...tableLoadingProps}
            data={tasks ?? []}
            keySelector={(d: any) => d.id}
            columns={columns}
            className={cn(styles.table)}
            onTableChange={onTableChange}
            remote={isRemote}
            clickable
            onRowClick={(row) => goToMyTasks(row.id)}
            hover
            enableSelectInAllPagesOption
            selectable={selectable}
            onSelectionChange={onSelectionChange}
            overriddenSelected={overriddenSelected}
            selectedColumnIndex={2}
            customRowSelectable={customRowSelectable}
        />
    );
};
