import { ButtonProps, generatePath } from '@laingorourke/core-web-components';
import { useHasPermissions } from 'domain/store/reducers/users';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ActionButtonsPanel, usePageActions } from 'views/components';
import { TooltipButton } from 'views/components/tooltipButton';
import { routes } from 'views/routes/Routes';
import { AdminRouteParams } from '../../../AdminRouteParams';

interface NewWeatherInspectionTypeButtonProps extends ButtonProps {}

export const NewWeatherInspectionTypeButton: React.FC<NewWeatherInspectionTypeButtonProps> = ({
    disabled,
}) => {
    const routeParams = useParams<AdminRouteParams>();
    const history = useHistory();

    const currentUserHasAnyManageWeatherSettingsWritePermission = useHasPermissions(
        Permission.ManageWeatherSettingsWrite,
        undefined,
        true
    );

    const goToNewWeatherInspectionType = () =>
        history.push(
            generatePath(
                routes.admin.routes!.weather.routes!.weatherInspectionTypes.routes!
                    .newWeatherInspectionType.path,
                {
                    ...routeParams,
                }
            )
        );

    usePageActions(
        currentUserHasAnyManageWeatherSettingsWritePermission && (
            <ActionButtonsPanel>
                <TooltipButton
                    variant="primary"
                    onClick={goToNewWeatherInspectionType}
                    id="new-weather-inspection-type"
                    disabled={disabled}
                    tooltipContent="Loading...">
                    New Weather Inspection Type
                </TooltipButton>
            </ActionButtonsPanel>
        ),
        [currentUserHasAnyManageWeatherSettingsWritePermission, routeParams.itemTypeCode, disabled]
    );

    return <> </>;
};
