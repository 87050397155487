import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';

const sort = (items: any) => items.sort((a: any, b: any) => a.localeCompare(b));

export const getFilterOptions = (
    items: any[] | undefined,
    columnName: string,
    sortOrder?: string[]
): OptionTypeBase[] => {
    if (!items) return [];
    let options = items
        .filter((item: any) => !!item[columnName])
        .map((item: any) => item[columnName]);

    options = Array.from(new Set(options));
    options = sortOrder
        ? options.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
        : sort(options);

    return options.map((item: any) => {
        return { value: item, label: item } as OptionTypeBase;
    });
};

export const getFilterOptionsFromArray = (
    items: string[] | number[] | undefined
): OptionTypeBase[] => {
    if (!items) return [];

    return items.map((i) => {
        return {
            value: i,
            label: i,
        } as OptionTypeBase;
    });
};
