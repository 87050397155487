import { ColumnDefinition, textFilter } from '@laingorourke/core-web-components';
import cn from 'classnames';
import { Classification, Status } from 'domain/models/api-models';
import { nameof } from 'helpers/nameOf';
import {
    defaultVerticalAlignment,
    EvidenceRequiredCell,
    igmsLinkFormatter,
    LeadDaysCell,
    LeadDaysTableHeader,
    ProjectDisplayName,
} from 'views/components';
import { getFilterOptions, getItemTypeStatusColumn } from 'views/components/table';
import styles from '../../../components/table/Table.module.scss';
import ActionButtons from './components/ActionButtons';
import { ControlMeasureTableRow } from './ControlMeasureTableRow';

const actionsFormatter = (cell: any, row: ControlMeasureTableRow) => (
    <ActionButtons controlMeasure={row} />
);

const actionsColumn: ColumnDefinition<ControlMeasureTableRow> = {
    field: (d) => d.id,
    text: '',
    renderer: actionsFormatter,
    headerClass: styles.minWidth100,
};
export const getTableColumns = (
    showActions: boolean,
    statuses: Status[] | undefined,
    controlMeasures?: ControlMeasureTableRow[],
    classifications?: Classification[]
): ColumnDefinition<ControlMeasureTableRow>[] => {
    const statusSortOrder = statuses && statuses.map((p) => p.name!);

    const statusOptions = getFilterOptions(controlMeasures, 'status', statusSortOrder);
    const statusColumn = getItemTypeStatusColumn(statuses, statusOptions);

    return [
        {
            field: (d) => d.name,
            text: 'Name',
            sortable: true,
            filter: textFilter(),
            headerClass: styles.width300,
            renderer: (_: any, row) => (
                <EvidenceRequiredCell id={row.id} evidenceRequired={row.evidenceRequired}>
                    {row.name}
                </EvidenceRequiredCell>
            ),
        },
        {
            field: (d) => d.projectName,
            text: 'Project',
            sortable: true,
            headerClass: styles.minWidth150,
            filter: textFilter(),
            renderer: (_, row: ControlMeasureTableRow) => (
                <ProjectDisplayName projectNumber={row.projectNumber} />
            ),
        },
        {
            field: (d) => d.description,
            text: 'Description',
            sortable: true,
            filter: textFilter(),
        },
        {
            cellClass: `${defaultVerticalAlignment} relative`,
            field: (d) => d.leadDays,
            text: '',
            headerRenderer: () => <LeadDaysTableHeader classifications={classifications} />,
            renderer: (_: any, row: ControlMeasureTableRow) => (
                <LeadDaysCell leadDays={row.leadDays} classifications={classifications} />
            ),
        },
        {
            ...statusColumn,
            field: (d) => d.status,
            text: 'Status when Completed',
            headerClass: styles.noWrappedWitheSpace,
            key: nameof<ControlMeasureTableRow>('status'),
        },
        {
            field: (d) => d.updateInspectionScheduleStartDate,
            sortable: true,
            text: 'Update Inspection Schedule Start Date',
            headerClass: cn(styles.minWidth130, styles.preWrappedHeaderTitle),
            renderer: (_: any, row: ControlMeasureTableRow) =>
                row.updateInspectionScheduleStartDate ? 'Yes' : 'No',
        },
        {
            field: (d) => d.igmsLink,
            text: 'iGMS Link',
            renderer: igmsLinkFormatter,
            headerClass: cn(styles.width90, styles.noWrappedWitheSpace),
        },
        {
            ...actionsColumn,
            hidden: !showActions,
        },
    ];
};
