import { useCurrentExternalUserGroups, usePeople } from 'domain/services/dataService';
import { usePeopleByIdsWithSCPGroups } from 'domain/services/dataService/usePeopleByIdsWithGroups';
import { fetchOwnersIds, useIsExternalUser, useOwnersIds } from 'domain/store/reducers/users';
import { AppDispatch } from 'domain/store/rootStore';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

export const useOwners = (projectNumber: string, currentOwnerId?: string) => {
    const dispatch = useDispatch<AppDispatch>();
    let ownersIds = useOwnersIds();
    if (currentOwnerId) {
        ownersIds = ownersIds.concat([currentOwnerId]);
    }

    const owners = usePeople(ownersIds);

    useEffect(() => {
        dispatch(fetchOwnersIds({ projectNumber }));
    }, [projectNumber]);

    return owners.data?.sort((a, b) => a.displayName.localeCompare(b.displayName));
};

/**
 * @param {number} projectNumber
 * @param {number} ownerId
 * @returns {string[]}
 * @description If the current user is internal, it returns all project owners ids
 * If the user is external, it returns project owners ids of those owners that have at least 1 group
 * in common with the groups of the logged user.
 */
export const useProjectOwnersIdsForCurrentUser = (projectNumber: string, ownerId?: string) => {
    const dispatch = useDispatch<AppDispatch>();

    const isExternal = useIsExternalUser();
    const externalUserGroups = useCurrentExternalUserGroups();
    let ownersIds = useOwnersIds();
    if (ownerId) {
        ownersIds = ownersIds.concat([ownerId]);
    }

    const ownersWithGroups = usePeopleByIdsWithSCPGroups(
        ownersIds,
        !!externalUserGroups.data && (!ownerId || ownersIds.some((o) => o === ownerId))
    );

    const visibleOwnersForCurrentExternal = useMemo(() => {
        if (!!externalUserGroups.data && !!ownersWithGroups.data) {
            return ownersWithGroups.data?.filter((og) =>
                externalUserGroups.data?.some((eg) => og.groups?.some((g) => g.id === eg.id))
            );
        }
    }, [externalUserGroups.data?.length, ownersWithGroups.data?.length]);

    useEffect(() => {
        dispatch(fetchOwnersIds({ projectNumber }));
    }, [projectNumber]);

    if (isExternal) {
        return [
            ...(visibleOwnersForCurrentExternal?.map((o) => o.id) ?? []),
            ...(!!ownerId ? [ownerId] : []),
        ];
    }

    return ownersIds;
};
