import { Table } from '@laingorourke/core-web-components';
import {
    ItemNumberExamples as Examples,
    ProjectItemNumberConfiguration,
} from 'domain/models/api-models';
import React, { useMemo } from 'react';
import { ItemNumber } from 'views/components';
import { ExampleTableRow } from './ExampleTableRow';
import { formatOrdinals } from './formatOrdinals';

interface ExamplesProps {
    configuration: ProjectItemNumberConfiguration;
    examples: Examples;
}

export const ExamplesTable: React.FC<ExamplesProps> = ({ configuration, examples }) => {
    const numbers: ExampleTableRow[] = useMemo(
        () => [
            ...(examples.firstNumbers?.map((n, i) => ({
                label: `${formatOrdinals(i + 1)} number`,
                value: n,
            })) ?? []),
            ...(examples.lastNumbers
                ?.slice()
                ?.reverse()
                ?.map((itemNumber, i) => ({
                    label: `${i === 0 ? '' : `${formatOrdinals(i + 1)} to`} last number`,
                    value: itemNumber,
                }))
                ?.reverse() ?? []),
        ],
        [examples]
    );

    return (
        <>
            {configuration.areaCodeInItemNumber && !examples.areaName && (
                <span>
                    There are no Areas with Area Code configured. Please configre at least one Area
                    to see Item Number examples
                </span>
            )}

            {(examples.firstNumbers || examples.lastNumbers) && (
                <>
                    {configuration.areaCodeInItemNumber && (
                        <h4>Item numbers for Area {examples.areaName}</h4>
                    )}
                    <Table
                        columns={[
                            {
                                field: (d) => d.label,
                                text: 'No.',
                            },
                            {
                                field: (d) => d.value,
                                text: 'Item Number',
                                renderer: (_, row) => <ItemNumber itemNumber={row.value} projectNumber='kalsjhdfkalsjhf' />,
                            },
                        ]}
                        data={numbers}
                        keySelector={(d) => d.label}
                        hover
                    />
                </>
            )}
        </>
    );
};
