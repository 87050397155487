import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Designer, ItemType, ItemTypeMetadata, ProjectStatuses } from 'domain/models/api-models';
import { fetchItemTypes, fetchMetadata, fetchProjectStatuses } from './actions';

const itemTypesSlice = createSlice({
    name: 'itemTypes',
    initialState: {
        itemTypes: [] as ItemType[],
        selectedItemType: {
            id: '' as string,
            code: '' as string | undefined,
            ownerLabel: undefined as string | undefined,
            workflowId: '' as string | undefined,
            metadata: undefined as ItemTypeMetadata | undefined,
            designers: null as unknown as Designer[],
            projectStatuses: null as unknown as ProjectStatuses,
        },
    },
    reducers: {
        setItemTypeId(state, action: PayloadAction<string>) {
            if (state.itemTypes.length === 0) return;
            const { id, code, myTasksWorkflowId, ownerLabel } =
                state.itemTypes?.find((i) => i.id === action.payload) ?? {};

            state.selectedItemType.id = id ?? '';
            state.selectedItemType.workflowId = myTasksWorkflowId ?? '';
            state.selectedItemType.code = code ?? '';
            state.selectedItemType.metadata = undefined;
            state.selectedItemType.ownerLabel = ownerLabel;
        },
        setItemTypeCode(state, action: PayloadAction<string>) {
            if (state.itemTypes.length === 0) return;

            let item: ItemType | undefined = state.itemTypes?.find(
                (i) => i.code === action.payload
            );
            if (item === undefined) {
                item = state.itemTypes[0];
            }
            const { id, code, myTasksWorkflowId, ownerLabel } = item;
            state.selectedItemType.id = id ?? '';
            state.selectedItemType.workflowId = myTasksWorkflowId ?? '';
            state.selectedItemType.code = code ?? '';
            state.selectedItemType.metadata = undefined;
            state.selectedItemType.ownerLabel = ownerLabel;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchItemTypes.fulfilled.type,
            (state, action: PayloadAction<ItemType[]>) => {
                state.itemTypes.push(...action.payload);
                //state.selectedItemType.id = state.itemTypes[0]?.id;
            }
        );
        builder.addCase(
            fetchMetadata.fulfilled.type,
            (state, action: PayloadAction<ItemTypeMetadata>) => {
                state.selectedItemType.metadata = {
                    ...action.payload,
                    categories: action.payload.categories?.sort(
                        (a, b) => a.sortOrder - b.sortOrder
                    ),
                };
            }
        );
        builder.addCase(
            fetchProjectStatuses.fulfilled.type,
            (state, action: PayloadAction<ProjectStatuses>) => {
                state.selectedItemType.projectStatuses = action.payload;
            }
        );
    },
});

export const { setItemTypeId, setItemTypeCode } = itemTypesSlice.actions;
export default itemTypesSlice.reducer;
