import { Drawer, useDevice } from '@laingorourke/core-web-components';
import {
    useComplianceTemplate,
    useControlMeasureSummaries,
    useInspectionTypesSummaries,
} from 'domain/store/reducers/complianceTemplates';
import { useProject } from 'domain/store/reducers/projects';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ClassificationBadge, ReadOnlyDetailsList, TableCellActive } from 'views/components';
import { useGenerateComplianceTemplatePath } from '../hooks/useGenerateComplianceTemplatePath';

interface ComplianceTemplateDetailsParams {
    complianceTemplateId: string;
}

export const ComplianceTemplateDetails: React.FC = () => {
    const { complianceTemplateId } = useParams<ComplianceTemplateDetailsParams>();
    const complianceTemplate = useComplianceTemplate(complianceTemplateId);

    const generateComplianceTemplatePath = useGenerateComplianceTemplatePath();

    const project = useProject(complianceTemplate?.projectNumber);
    const controlMeasures = useControlMeasureSummaries();
    const inspectionTypesSummaries = useInspectionTypesSummaries();
    const { isMobile } = useDevice();
    const history = useHistory();
    const goToComplianceTemplates = () =>
        history.push(generateComplianceTemplatePath((baseRoute) => baseRoute.path));

    return (
        <>
            {complianceTemplate && (
                <Drawer
                    push={true}
                    closeOnClickAway={false}
                    open={true}
                    onChange={() => goToComplianceTemplates()}
                    title="Compliance Template"
                    width={isMobile ? '100vw' : '30vw'}>
                    <ReadOnlyDetailsList
                        items={[
                            { label: 'Project', value: project?.displayName ?? 'All' },
                            { label: 'Category', value: complianceTemplate.categoryName },
                            {
                                label: 'Classification',
                                value: (
                                    <ClassificationBadge id={complianceTemplate.classificationId} />
                                ),
                            },
                            { label: 'Classification Example', value: complianceTemplate.example },
                            {
                                label: 'Inspections',
                                value: (
                                    <ul>
                                        {complianceTemplate.inspectionTypes?.map((i) => {
                                            const inspectionType = inspectionTypesSummaries?.find(
                                                (its) => its.id === i.id
                                            );
                                            if (!inspectionType) return undefined;
                                            return (
                                                <li key={i.id}>
                                                    {inspectionType.name}: {i.frequency} day
                                                    {i.frequency > 1 ? 's' : ''}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ),
                            },
                            {
                                label: 'Control Meaures',
                                value: (
                                    <ul>
                                        {complianceTemplate.controlMeasuresIds?.map((id) => (
                                            <li key={id}>
                                                {controlMeasures?.find((c) => c.id === id)?.name}
                                            </li>
                                        ))}
                                    </ul>
                                ),
                            },
                            {
                                label: 'Status',
                                value: <TableCellActive isActive={complianceTemplate.isActive} />,
                            },
                        ]}
                    />
                </Drawer>
            )}
        </>
    );
};
