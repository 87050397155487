import { generatePath } from '@laingorourke/core-web-components';
import { useParentAreas } from 'domain/store/reducers/areas';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActionButtonsPanel, usePageActions } from 'views/components';
import { TooltipButton } from 'views/components/tooltipButton';
import { routes } from 'views/routes/Routes';
import { AreaRouteParams } from '../AreaRouteParams';
import styles from './AddAreaButtons.module.scss';

const AddAreaButtons: React.FC = () => {
    const history = useHistory();
    const params = useParams<AreaRouteParams>();
    const areas = useParentAreas();

    const goToNewArea = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.areas.routes!.newArea.path, {
                ...params,
            })
        );
    const goToNewSubArea = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.areas.routes!.newSubArea.path, {
                ...params,
            })
        );

    usePageActions(
        <ActionButtonsPanel>
            <TooltipButton
                id="new-area"
                variant="secondary"
                onClick={goToNewArea}
                className={styles.addAreaBtn}>
                New Area
            </TooltipButton>
            <TooltipButton
                id="new-sub-area"
                onClick={goToNewSubArea}
                disabled={!areas?.length}
                tooltipContent="You haven't added any Areas yet.">
                New Sub Area
            </TooltipButton>
        </ActionButtonsPanel>,
        [areas?.length, params.itemTypeCode]
    );

    return <></>;
};

export default AddAreaButtons;
