import {
    Form,
    HorizontalSelect,
    Input,
    RadioButtonGroup,
    Select,
    useForm,
} from '@laingorourke/core-web-components';
import { Integration, IntegrationMetaItem, IntegrationsMetadata } from 'domain/models/api-models';
import { createIntegration, updateIntegration } from 'domain/store/reducers/integrations';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Permission } from 'security/Permission';
import { ModalFooter, ProjectSelector } from 'views/components';
import { FieldInfoLabel } from 'views/components/fieldInfoLabel/FieldInfoLabel';
import { ApplicationType } from '../ApplicationType';
import { IntegrationType } from '../IntegrationType';
import { EnumMap, getKey } from './applicationTypeEnumTypes';
import { IntegrationSchema } from './schemas';

interface UpsertIntegrationFormProps {
    isEditMode: boolean;
    itemTypeId: string;
    integration: Integration | undefined;
    metadata: IntegrationsMetadata;
    goToIntegrations: () => void;
    projectNumber?: string;
}

export const UpsertForm: React.FC<UpsertIntegrationFormProps> = ({
    isEditMode,
    itemTypeId,
    integration,
    metadata,
    goToIntegrations,
    projectNumber,
}) => {
    const isProjectMode = !!projectNumber;
    const [controlMeasures, setControlMeasures] = useState<IntegrationMetaItem[]>();
    const [requiresAllProjectsInit, setRequiresAllProjectsInit] = useState(
        integration !== undefined
    );
    const [requiresControlMeasureInit, setRequiresControlMeasureInit] = useState(
        integration !== undefined
    );
    const dispatch = useAppDispatch();

    const userHasPermissionsToAllProjects = useHasPermissions(
        Permission.ManageIntegrationSettingsWrite,
        ''
    );
    const form = useForm({
        initialValues: {
            allProjects: !userHasPermissionsToAllProjects
                ? false
                : isEditMode
                ? integration?.projectNumber === null
                : !isProjectMode,
            id: isEditMode ? integration!.id! : '',
            projectNumber: isEditMode ? integration!.projectNumber! : projectNumber ?? '',
            type: isEditMode
                ? integration!.controlMeasureId === null
                    ? IntegrationType.Inspection
                    : IntegrationType.ControlMeasure
                : IntegrationType.ControlMeasure,
            controlMeasureId: isEditMode ? integration!.controlMeasureId! : '',
            inspectionTypeId: isEditMode ? integration!.inspectionTypeId! : '',
            applicationType: isEditMode ? integration!.applicationType : '',
            formName: isEditMode ? integration!.formName : '',
        },
        validationSchema: IntegrationSchema,
        onSubmit: async (values) => {
            let result;
            if (isEditMode) {
                const { allProjects, ...updateValues } = values;
                result = await dispatch(
                    updateIntegration({
                        ...updateValues,
                        itemTypeId,
                    })
                );
            } else {
                const { id, allProjects, ...createValues } = values;
                result = await dispatch(
                    createIntegration({
                        ...createValues,
                        itemTypeId,
                    })
                );
            }
            if ((result as any).payload) goToIntegrations();
        },
    });

    const { isSubmitting, values, setFieldValue } = form;

    useEffect(() => {
        if (requiresAllProjectsInit) {
            setRequiresAllProjectsInit(false);
            return;
        }

        setControlMeasures(metadata?.controlMeasures?.filter((c) => c.projectNumber === null));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.allProjects, setFieldValue]);

    useEffect(() => {
        const projectControlMeasures =
            values.projectNumber === ''
                ? metadata?.controlMeasures?.filter((c) => c.projectNumber === null)
                : metadata?.controlMeasures?.filter(
                      (c) => c.projectNumber === null || c.projectNumber === values.projectNumber
                  );

        setControlMeasures(projectControlMeasures);

        if (requiresControlMeasureInit) {
            setRequiresControlMeasureInit(false);
            return;
        }

        setFieldValue('controlMeasureId', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.projectNumber, setFieldValue]);

    useEffect(() => {
        if (values.controlMeasureId !== '' && values.controlMeasureId !== null)
            setFieldValue('inspectionTypeId', '');
    }, [values.controlMeasureId, setFieldValue]);

    useEffect(() => {
        if (values.inspectionTypeId !== '' && values.inspectionTypeId !== null)
            setFieldValue('controlMeasureId', '');
    }, [values.inspectionTypeId, setFieldValue]);

    return (
        <Form form={form}>
            <Modal.Body>
                <ProjectSelector
                    permission={Permission.ManageIntegrationSettingsWrite}
                    isEditMode={isEditMode}
                    allProjects={values.allProjects}
                    setFieldValue={setFieldValue}
                    isProjectMode={isProjectMode}
                />
                <Form.Row>
                    <Form.Field name="type" label="Type">
                        <HorizontalSelect
                            name="type"
                            options={[
                                {
                                    value: IntegrationType.ControlMeasure,
                                    label: IntegrationType.ControlMeasure,
                                },
                                {
                                    value: IntegrationType.Inspection,
                                    label: IntegrationType.Inspection,
                                },
                            ]}
                        />
                    </Form.Field>
                </Form.Row>
                {values.type === IntegrationType.Inspection && (
                    <Form.Row>
                        <Form.Field name="inspectionTypeId" label="Inspection">
                            <Select
                                options={metadata.inspectionTypes}
                                dataShape={{
                                    value: 'id',
                                    label: 'name',
                                }}
                            />
                        </Form.Field>
                    </Form.Row>
                )}
                {values.type === IntegrationType.ControlMeasure && (
                    <Form.Row>
                        <Form.Field name="controlMeasureId" label="Control Measure">
                            <Select
                                options={controlMeasures}
                                dataShape={{
                                    value: 'id',
                                    label: 'name',
                                }}
                            />
                        </Form.Field>
                    </Form.Row>
                )}
                <Form.Row>
                    <Form.Field name="applicationType" label="Application Integration">
                        <RadioButtonGroup
                            options={Object.keys(ApplicationType).map((type: string) => ({
                                label: ApplicationType[type as keyof typeof ApplicationType],
                                value: type,
                            }))}
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field
                        name="formName"
                        label={
                            <FieldInfoLabel
                                label="Application Form Name"
                                showIcon={values.applicationType !== ''}
                                tooltipContent={
                                    !!values.applicationType
                                        ? applicationFormExamples[
                                              values.applicationType! as ApplicationType
                                          ]
                                        : ''
                                }
                            />
                        }>
                        <Input />
                    </Form.Field>
                </Form.Row>
            </Modal.Body>
            <ModalFooter
                submitText={isEditMode ? 'Update' : 'Create'}
                isSubmitting={isSubmitting}
                onCancel={goToIntegrations}
            />
        </Form>
    );
};

const applicationFormExamples: EnumMap = {
    [getKey(ApplicationType.Asite)]:
        'Please provide the Asite Form Name e.g. Temporary Works Design Brief or Construction Support Form',
    [getKey(ApplicationType.FieldView)]:
        'Please provide the Field View Form Name e.g. E-T-8-0548 SCAFFOLD HANDOVER CHECKLIST',
    [getKey(ApplicationType.TeamBinder)]: 'Please provide the InEight Document Form Name e.g. TWB',
};
