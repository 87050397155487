import { generatePath } from '@laingorourke/core-web-components';
import { Route } from '@laingorourke/core-web-types';
import { useParams } from 'react-router-dom';
import { SharedRouteParams } from '../../SharedRouteParams';
import { useBaseComplianceTemplateRoute } from './useBaseComplianceTemplateRoute';

export const useGenerateComplianceTemplatePath = () => {
    const sharedRouteParams = useParams<SharedRouteParams>();
    const baseRoute = useBaseComplianceTemplateRoute();

    return (path: (baseRoute: Route) => string, params?: any) =>
        generatePath(path(baseRoute), { ...params, ...sharedRouteParams });
};
