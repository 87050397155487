import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationProject } from 'domain/models/ApplicationProject';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import { fetchAsiteProjects, fetchFieldViewProjects } from './actions';

const initialState = {
    asiteProjects: null as unknown as ApplicationProject[],
    fieldViewProjects: null as unknown as ApplicationProject[],
};

const applicationProjectSlice = createSlice({
    name: 'applicationProjectIntegration',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            fetchAsiteProjects.fulfilled.type,
            (state, action: PayloadAction<ApplicationProject[]>) => {
                state.asiteProjects = action.payload;
            }
        );
        builder.addCase(
            fetchFieldViewProjects.fulfilled.type,
            (state, action: PayloadAction<ApplicationProject[]>) => {
                state.fieldViewProjects = action.payload;
            }
        );
    },
});

export const useAsiteProjects = () =>
    useSelector((state: RootState) => state.applicationProjects?.asiteProjects);

export const useFieldViewProjects = () =>
    useSelector((state: RootState) => state.applicationProjects?.fieldViewProjects);

export default applicationProjectSlice.reducer;
