import { ColumnDefinition, selectFilter, textFilter } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { Classification } from 'domain/models/api-models';
import { nameof } from 'helpers/nameOf';
import { getString } from 'helpers/stringHelpers';
import { ClassificationBadge, ProjectDisplayName, TableCellActive } from 'views/components';
import styles from '../../../../../components/table/Table.module.scss';
import ActionButtons from './ActionButtons';
import { ComplianceTemplateTableRow } from './ComplianceTemplateTableRow';

const maxExampleLength = 80;
const actionsFormatter = (value: any, row: ComplianceTemplateTableRow) => (
    <ActionButtons complianceTemplate={row} />
);

const actionsColumn: ColumnDefinition<ComplianceTemplateTableRow> = {
    field: (d) => d.id,
    text: '',
    renderer: actionsFormatter,
    headerClass: styles.minWidth100,
};

export const getTableColumns = (
    categoryOptions: OptionTypeBase[],
    categoriesSortOrder: string[],
    classificationOptions: OptionTypeBase[],
    classiticationsSortOrder: string[] | undefined,
    classifications: Classification[] | undefined,
    showActions: boolean
): ColumnDefinition<ComplianceTemplateTableRow>[] => [
    {
        field: (d) => d.categoryId,
        text: 'Category',
        key: nameof<ComplianceTemplateTableRow>('categoryId'),
        sortable: true,
        customSort: (a, b, order) =>
            order === 'asc'
                ? categoriesSortOrder.indexOf(a.categoryId!) -
                  categoriesSortOrder.indexOf(b.categoryId!)
                : categoriesSortOrder.indexOf(b.categoryId!) -
                  categoriesSortOrder.indexOf(a.categoryId!),
        headerClass: styles.minWidth180,
        filter: selectFilter({
            options: categoryOptions,
        }),
        renderer: (_: any, row) => row.categoryName,
    },
    {
        field: (d) => d.projectName,
        text: 'Project',
        sortable: true,
        headerClass: styles.minWidth130,
        filter: textFilter(),
        renderer: (_, row: ComplianceTemplateTableRow) => (
            <ProjectDisplayName projectNumber={row.projectNumber} />
        ),
    },
    {
        field: (d) => d.classificationName,
        text: 'Classification',
        sortable: true,
        customSort: (a, b, order) =>
            order === 'asc'
                ? classiticationsSortOrder!.indexOf(a.classificationName!) -
                  classiticationsSortOrder!.indexOf(b.classificationName!)
                : classiticationsSortOrder!.indexOf(b.classificationName!) -
                  classiticationsSortOrder!.indexOf(a.classificationName!),
        filter: selectFilter({
            options: classificationOptions,
        }),
        renderer: (value: any, row) => <ClassificationBadge id={row.classificationId} />,
    },
    {
        field: (d) => d.inspectionsCount,
        text: 'Inspections',
        sortable: true,
    },
    {
        field: (d) => d.controlMeasuresCount,
        text: 'Control Measures',
        sortable: true,
        headerClass: styles.noWrappedWitheSpace,
    },
    {
        field: (d) => d.example,
        text: 'Classification Examples',
        sortable: true,
        headerClass: styles.noWrappedWitheSpace,
        renderer: (value: any, row) => getString(row.example, maxExampleLength),
    },
    {
        field: (d) => d.isActive,
        text: 'Status',
        sortable: true,
        renderer: (value: any, row) => <TableCellActive isActive={row.isActive} />,
    },
    {
        ...actionsColumn,
        hidden: !showActions,
    },
];
