import { createAsyncThunk } from '@reduxjs/toolkit';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from '../../../rootStore';
import { downloadBlob, getFileName, getHeaders } from '../../fileUpload';
import { addNotification } from '../../notificationsReducer';

interface FetchCsvTemplateParams {
    projectNumber: string;
    itemTypeId: string;
}

export const fetchCsvTemplate = createAsyncThunk<any, FetchCsvTemplateParams, { state: RootState }>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/csvTemplate/get',
    async (args) => {
        let url = `/api/projects/${args.projectNumber}/itemtypes/${args.itemTypeId}/projectitems/csvTemplate`;
        const headers = await getHeaders();

        fetch(url, { headers })
            .then(async (response) => {
                const fileName = getFileName(response);
                return {
                    blob: await response.blob(),
                    fileName: fileName,
                };
            })
            .then((data) => {
                downloadBlob(data.blob, data.fileName);
            });
    },
    { condition: (args) => !!args.itemTypeId && !!args.projectNumber }
);

interface UploadCsvTemplateParams {
    projectNumber: string;
    file: File;
}

export const uploadCsvTemplate = createAsyncThunk<
    void,
    UploadCsvTemplateParams,
    { state: RootState }
>(
    'projects/{projectNumber}/itemtypes/{itemTypeId}/projectitems/csvTemplate/post',
    async (args, { getState, dispatch }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            const url = `/api/projects/${args.projectNumber}/itemtypes/${itemTypeId}/projectitems/csvTemplate`;
            const headers = await getHeaders();
            const formData = new FormData();
            formData.set('file', args.file);

            return fetch(url, { method: 'POST', headers: headers, body: formData })
                .then(async (response) => {
                    const fileName = getFileName(response);
                    if (!response.ok) {
                        if (response.status === 404) {
                            throw new Error(
                                'Not a valid CSV file. Please download the CSV template and try again.'
                            );
                        }
                        const message = await response.json();
                        throw new Error(message.Title);
                    }

                    return {
                        blob: !!fileName ? await response.blob() : null,
                        fileName: fileName,
                    };
                })
                .then((data) => {
                    if (data.blob) {
                        downloadBlob(data.blob, data.fileName);
                        dispatch(
                            addNotification({
                                message:
                                    'Items have not been uploaded due to validation errors. Please refer to the downloaded feedback file.',
                                isSuccess: false,
                            })
                        );
                        return false;
                    }
                    dispatch(
                        addNotification({
                            message: 'Items have been added successfully',
                            isSuccess: true,
                        })
                    );
                    return true;
                });
        }, dispatch);
    }
);
