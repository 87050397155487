import { Avatar, Skeleton } from '@laingorourke/core-web-components';
import { default as React } from 'react';
import styles from './ReadOnlyDetailsList.module.scss';

export const detailFieldHeight = 17;
export const defaultStringWhenEmtpy = '-';

const itemProperty = (
    label: string | undefined | React.ReactNode,
    value: string | undefined | React.ReactNode
) => (
    <li key={label?.toLocaleString()}>
        <span className={styles.title}>{label}</span>
        <Skeleton.Box height={detailFieldHeight} loading={value === undefined}>
            <span className={styles.value}>{value}</span>
        </Skeleton.Box>
    </li>
);

export const getDefaultValueWhenEmpty = (value?: string | React.ReactNode, photo?: string) =>
    value === undefined ? undefined : value === '' ? (
        defaultStringWhenEmtpy
    ) : (
        <span>
            {!!photo && (
                <Avatar className={styles.photo} src={photo} displayName={value as string} />
            )}
            {value}
        </span>
    );

interface Item {
    label: string | undefined | React.ReactNode;
    value: string | undefined | React.ReactNode;
}

export interface ReadOnlyDetailsListProps {
    items: Item[];
}

export const ReadOnlyDetailsList: React.FC<ReadOnlyDetailsListProps> = ({ items }) => {
    return (
        <ul className={styles.propertiesList}>
            {items.map((i) => itemProperty(i.label, i.value))}
        </ul>
    );
};
