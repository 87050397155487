import { deleteComplianceTemplate } from 'domain/store/reducers/complianceTemplates';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useState } from 'react';
import { TableRowActionButtons } from 'views/components/table';
import { useBaseComplianceTemplateRoute } from '../../hooks/useBaseComplianceTemplateRoute';
import { ComplianceTemplateTableRow } from './ComplianceTemplateTableRow';

interface ActionButtonsProps {
    complianceTemplate: ComplianceTemplateTableRow;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ complianceTemplate }) => {
    const dispatch = useAppDispatch();
    const baseRoute = useBaseComplianceTemplateRoute();
    const [successfullyUpdated, setSuccessfullyUpdated] = useState<boolean | undefined>(undefined);
    const itemTypeId = useSelectedItemTypeId();

    const onDelete = async () => {
        const result = await dispatch(
            deleteComplianceTemplate({
                itemTypeId,
                projectNumber: complianceTemplate.projectNumber,
                id: complianceTemplate.id,
            })
        );
        if ((result as any).payload) setSuccessfullyUpdated(true);
    };

    const deleteDisabledMessage = complianceTemplate.isInUse
        ? 'You cannot delete this Compliance Template as long as it is in use'
        : !complianceTemplate.isActive
        ? 'This Compliance Template has been deleted'
        : '';

    const routeParams = {
        itemTypeCode: complianceTemplate.itemTypeCode,
        projectNumber: complianceTemplate.projectNumber,
        complianceTemplateId: complianceTemplate.id,
    };

    return (
        <TableRowActionButtons
            editPath={baseRoute.routes!.editComplianceTemplate.path}
            entityId={complianceTemplate.id}
            routeParams={routeParams}
            deleteBody={`Are you sure you want to remove this compliance template?`}
            isDeleteDisabled={complianceTemplate.isInUse || !complianceTemplate.isActive}
            isEditDisabled={!complianceTemplate.isActive}
            onDelete={onDelete}
            deleteTitle="Delete Compliance Template"
            deleteDisabledMessage={deleteDisabledMessage}
            editDisabledMessage="This Compliance Template has been deleted, it can no longer be edited"
            successfullyUpdated={successfullyUpdated}
            hideEdit={complianceTemplate.hideEdit}
            hideDelete={complianceTemplate.hideDelete}
        />
    );
};

export default ActionButtons;
