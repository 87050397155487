import { useProjects } from 'domain/store/reducers/projects';
import React from 'react';
import styles from './Site.module.scss';

interface SiteProps {
    projectNumber: string;
    siteId?: string;
}

export const Site: React.FC<SiteProps> = ({ projectNumber, siteId }) => {
    const projects = useProjects();
    const site = projects
        ?.find((p) => p.projectNumber === projectNumber)
        ?.sites?.find((s) => s.id.toLowerCase() === siteId?.toLocaleLowerCase());

    const siteDeleted = !!siteId && !!projects && site === undefined;
    return (
        <>
            {!!site ? (
                <>
                    <span>{site?.name}</span>
                    <span className={styles.address}>{site?.address}</span>
                </>
            ) : siteDeleted ? (
                <span className={styles.error}>
                    The site has been removed in Project Central. Please select a new site.
                </span>
            ) : undefined}
        </>
    );
};
