import { generatePath } from '@laingorourke/core-web-components';

import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { AdminRouteParams } from '../../AdminRouteParams';
import { CreateForm } from './CreateForm';

interface CreateTransferParams extends AdminRouteParams {
    transferId: string;
}

export const CreateTransfer: React.FC = () => {
    const history = useHistory();
    const { itemTypeCode } = useParams<CreateTransferParams>();
    const itemTypeId = useSelectedItemTypeId();

    const goToTransfer = () =>
        history.push(generatePath(routes.admin.routes!.transfers.path, { itemTypeCode }));

    return (
        <Modal show={true} onHide={goToTransfer} animation={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>New Transfer</Modal.Title>
            </Modal.Header>
            <SecuredContent permissions={Permission.TransferWrite} ignoreSecurable={true}>
                <CreateForm itemTypeId={itemTypeId} goToTransfers={goToTransfer} />
            </SecuredContent>
        </Modal>
    );
};
