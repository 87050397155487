import { ColumnDefinition, textFilter } from '@laingorourke/core-web-components';
import { ProjectDesigner } from 'domain/models/api-models';
import { nameof } from 'helpers/nameOf';
import DisabledDesignerSwitch from './components/DisabledDesignerSwitch';

export const getTableColumns = (): ColumnDefinition<ProjectDesigner>[] => {
    const isDisabledFormatter = (value: any, row: ProjectDesigner) => (
        <DisabledDesignerSwitch designer={row} />
    );
    return [
        {
            field: (d) => d.name,
            text: 'Name',
            key: nameof<ProjectDesigner>('name'),
            sortable: true,
            filter: textFilter(),
        },
        {
            field: (d) => d.isDisabled,
            text: '',
            renderer: isDisabledFormatter,
        },
    ];
};
