import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SecurableType, User } from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import { Permission } from 'security/Permission';
import {
    fetchAreaOwnersIds,
    fetchCurrentUserDetails,
    fetchOwnersIds,
    fetchsUsersWithProjectWeatherAcknowledgementRead
} from './actions';

const initialState = {
    current: undefined as unknown as User,
    ownersIds: undefined as unknown as string[],
    areaOwnersIds: undefined as unknown as string[],
    usersWithProjectWeatherAcknowledgementReadPermission: undefined as unknown as string[],
};

const usersSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCurrentUserDetails.fulfilled, (state, action: PayloadAction<User>) => {
            state.current = action.payload;
        });
        builder.addCase(fetchOwnersIds.fulfilled, (state, action: PayloadAction<string[]>) => {
            state.ownersIds = action.payload;
        });
        builder.addCase(fetchAreaOwnersIds.fulfilled, (state, action: PayloadAction<string[]>) => {
            state.areaOwnersIds = action.payload;
        });
        builder.addCase(
            fetchsUsersWithProjectWeatherAcknowledgementRead.fulfilled,
            (state, action: PayloadAction<string[]>) => {
                state.usersWithProjectWeatherAcknowledgementReadPermission = action.payload;
            }
        );
    },
});

export const useCurrentUser = () => useSelector((state: RootState) => state.users.current);

export const useIsAdmin = () =>
    useSelector((state: RootState) =>
        hasAnyPermission(state.users.current, [
            Permission.ManageComplianceSettingsRead,
            Permission.ManageIntegrationSettingsRead,
            Permission.ManageClassificationSettingsRead,
            Permission.ManageWeatherSettingsRead,
            Permission.TransferRead,
        ])
    );

export const useIsExternalUser = () =>
    useSelector((state: RootState) => state.users.current.isExternalUser);

export const useUserExternalType = () =>
    useSelector((state: RootState) => state.users.current.externalType);

export const useHasPermissions = (
    permission: Permission | Permission[],
    projectNumber?: string,
    ignoreSecurable?: boolean,
    denyExternalUserAccess?: boolean
) =>
    useSelector(
        (state: RootState) =>
            hasPermission(state.users.current, permission, projectNumber, ignoreSecurable) &&
            (!denyExternalUserAccess || !state.users.current?.isExternalUser)
    );

export const useCurrentUserHasProjectPermissions = (projectNumber: string) =>
    useSelector((state: RootState) => getCurrentUserHasProjectPermission(state, projectNumber));

export const getCurrentUserHasProjectPermission = (state: RootState, projectNumber: string) =>
    hasPermission(state.users.current, Permission.ProjectDetailsRead, undefined) ||
    hasPermission(state.users.current, Permission.ProjectDetailsRead, projectNumber);

export const getUserHasProjectPermission = (user: User, projectNumber: string) =>
    hasPermission(user, Permission.ProjectDetailsRead, undefined) ||
    hasPermission(user, Permission.ProjectDetailsRead, projectNumber);

export const hasPermission = (
    user: User,
    permissions: Permission | Permission[],
    projectNumber?: string,
    ignoreSecurable?: boolean
): boolean => {
    const permissionsToCheck = Array.isArray(permissions) ? permissions : [permissions];

    const hasPermissions =
        permissionsToCheck.filter((permission) =>
            user?.securables!.find(
                (s) =>
                    s.permission === permission &&
                    (ignoreSecurable ||
                        s.securableType === SecurableType.All ||
                        (s.securableType === SecurableType.Project &&
                            (s.id === '' || s.id === projectNumber)))
            )
        ).length === permissionsToCheck.length;

    return hasPermissions;
};

export const hasAnyPermission = (user: User, permissions: Permission[]): boolean =>
    permissions.some((permission) => user?.securables!.find((s) => s.permission === permission));

export const useOwnersIds = () => useSelector((state: RootState) => state.users.ownersIds ?? []);
export const useAreaOwnersIds = () =>
    useSelector((state: RootState) => state.users.areaOwnersIds ?? []);

export const useUsersWithProjectWeatherAcknowledgementReadPermission = () =>
    useSelector(
        (state: RootState) => state.users.usersWithProjectWeatherAcknowledgementReadPermission ?? []
    );

export default usersSlice.reducer;
