import { getBaseUrl, HttpStatusCodes } from '@laingorourke/core-web-clientservices';
import { ApiVersionHeaderKey, ApiVersionStorageKey, AppDisplayName } from 'consts';
import {
    ApplicationInsightsConfiguration,
    Auth0Configuration,
    AuthServiceConfiguration,
    ClientBasicSettings,
    ClientConfigurationResponse,
    DataServiceConfiguration,
    DocumentCollaborationConfiguration,
    FeaturesConfiguration,
    GoogleTagManagerConfiguration,
    ReportsUrlsConfiguration,
    UsersServiceConfiguration,
} from 'domain/models/api-models';

export const populateConfiguration = async (): Promise<void> => {
    const baseUrl = getBaseUrl();

    try {
        const response = await fetch(`${baseUrl}/api/ClientAppConfiguration`);

        if (response.status === HttpStatusCodes.OK && response.body) {
            const result = (await response.json()) as ClientConfigurationResponse;
            sessionStorage.setItem('ClientConfig', JSON.stringify(result));
            window.localStorage.setItem(
                ApiVersionStorageKey,
                response.headers.get(ApiVersionHeaderKey) || '0.0.0.0'
            );
            return Promise.resolve();
        } else {
            throw new Error(
                `${AppDisplayName} is currently unavailable. Please try again later or contact the <a href="mailto:itservicedesk@laingorourke.com.au?subject=Comply Unavailable">Service Desk</a>.`
            );
        }
    } catch (response) {
        throw new Error(
            `${AppDisplayName} is currently unavailable. Please try again later or contact the <a href="mailto:itservicedesk@laingorourke.com.au?subject=Comply Unavailable">Service Desk</a>.`
        );
    }
};

export const getConfiguration = (): ClientBasicSettings | undefined =>
    getClientConfig()?.clientBasicSettings;

export const getAuth0Configuration = (): Auth0Configuration | undefined =>
    getClientConfig()?.auth0Configuration;

export const getDataServiceConfiguration = (): DataServiceConfiguration | undefined =>
    getClientConfig()?.dataServiceConfiguration;

export const getApplicationInsightsConfiguration = ():
    | ApplicationInsightsConfiguration
    | undefined => getClientConfig()?.applicationInsightsConfiguration;

export const getGtmConfiguration = (): GoogleTagManagerConfiguration | undefined =>
    getClientConfig()?.googleTagManagerConfiguration;

export const getAuthServiceConfiguration = (): AuthServiceConfiguration | undefined =>
    getClientConfig()?.authServiceConfiguration;

export const getUsersServiceServiceConfiguration = (): UsersServiceConfiguration | undefined =>
    getClientConfig()?.usersServiceConfiguration;

export const getFeaturesConfiguration = (): FeaturesConfiguration | undefined =>
    getClientConfig()?.featuresConfiguration;

export const getMyTasksConfiguration = () => {
    const config = getClientConfig()?.myTasksConfiguration;
    if (!config) {
        return;
    }
    return {
        api: `${config?.baseUrl}/api`,
        baseUrl: config?.baseUrl,
        audience: config?.audience,
    };
};

export const getDocumentCollaborationConfiguration = ():
    | DocumentCollaborationConfiguration
    | undefined => getClientConfig()?.documentCollaborationConfiguration;

export const getReportsUrlsConfiguration = (): ReportsUrlsConfiguration | undefined =>
    getClientConfig()?.reportsUrlsConfiguration;

const getClientConfig = (): ClientConfigurationResponse => {
    return JSON.parse(sessionStorage.getItem('ClientConfig')!) as ClientConfigurationResponse;
};

export const getCurrentVersion = (): string => {
    return window.localStorage.getItem(ApiVersionStorageKey) ?? '';
};
