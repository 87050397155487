import { ProjectItem } from 'domain/models/api-models';
import { useProjectItems } from 'domain/store/reducers/projects';
import { ItemNumber } from 'views/components';
import { SelectedItems } from 'views/components/selectedItems';

interface SelectedProjectItemsProps {
    projectItemsIds: string[];
    projectNumber: string;
}

export const SelectedProjectItems: React.FC<SelectedProjectItemsProps> = ({ projectItemsIds, projectNumber }) => {
    const items = useProjectItems();
    const entities = items
        .filter((i) => projectItemsIds.indexOf(i.id) > -1)
        .sort((a, b) => {
            return items!
                .find((i) => i.id === a.id)!
                .number!.localeCompare(items.find((i) => i.id === b.id)!.number!);
        });

    return (
        <SelectedItems<ProjectItem>
            entities={entities}
            selectedEntityName="Item"
            entityRenderer={(item) => (
                <>
                    <ItemNumber itemNumber={item.number} projectNumber={projectNumber} /> - {item.name}
                </>
            )}
        />
    );
};
