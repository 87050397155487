import React from 'react';
import { ImageProps } from 'react-bootstrap';
import styles from './Image.module.scss';

const Image: React.FC<ImageProps> = (props) => {
    const { src, alt } = props;
    return (
        <img
            {...props}
            className={styles.projectIcon}
            height={props.width ?? '40'}
            alt={alt}
            src={
                src ??
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAjVBMVEUAAADj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4ODj4OAKKbHsAAAALnRSTlMAAQIDBAUGBwskKzU+QlFiY35/gomOkZKVl5iam52jpbK6yMrMzs/R09rv8fP9W4/i9gAAANBJREFUOMu9kslywjAQRGeMZbOGNSQkEDazx+//Py8Hl4LkknyhoI/Tr+Ryd4s8X8n4Sky/s1RkQpO+Ra60NSYDKpDEvw9JBfR3l6E2AH2AaQOwB6D1f0/rwAUAY8/ve1MDRgAb68+hMD6gU9jk1U0/AA6Z/xfSso/qZ5XQMfMBK13YDE9ZDTAdEdGve8qn3ANMUXZFl24PWxcwBZS9lVfU2QHMIdCkA2Qh3wWCvgvwMPAW1CCUZGAPN/L44kFl1rjqtUi6KOP+T9V6dPbyGv0BdYZLza+JEmIAAAAASUVORK5CYII='
            }
        />
    );
};

export default Image;
