import * as Yup from 'yup';

export const invalidCharacters = /^(.|\s)*[a-zA-Z]+(.|\s)*$/;
export const emojis = /^(?!.*(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]))/;
export const emojiValidationMessage = '🙅‍♂️ Emojis are not allowed';

export const getIntegerYup = (name: string, min: number, max: number) =>
    Yup.string()
        .matches(/^-?\d+$/, `${name} must be a whole number between ${min} and ${max}`)
        .typeError(`${name} must be a whole number between ${min} and ${max}`)
        .test(
            'min',
            `${name} cannot be less than ${min}`,
            (value) => value === null || value === undefined || (!!value && parseInt(value) >= min)
        )
        .test(
            'max',
            `${name} cannot be greater than ${max}`,
            (value) => value === null || value === undefined || (!!value && parseInt(value) <= max)
        );
