import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateTransferRequest, Transfer } from 'domain/models/api-models';
import { get, post } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

interface FetchTransfersParams {
    itemTypeId: string;
}

export const fetchTransfers = createAsyncThunk<
    Transfer[],
    FetchTransfersParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/transfers/get',
    async (args) => {
        return (await get(`itemtypes/${args.itemTypeId}/transfers`))!;
    },
    {
        condition: (args) => !!args.itemTypeId,
    }
);

interface CreateTransferParms extends CreateTransferRequest {
    itemTypeId: string;
}

export const createTransfer = createAsyncThunk<Transfer, CreateTransferParms, { state: RootState }>(
    'itemtypes/{itemTypeId}/transfers/post',
    async (transfer, { dispatch }) => {
        return await runSafe(async () => {
            const createdTransfer = await post(
                `itemtypes/${transfer.itemTypeId}/transfers`,
                transfer
            );
            dispatch(
                addNotification({
                    message: 'Transfer has been created successfully',
                    isSuccess: true,
                })
            );
            return createdTransfer;
        }, dispatch);
    }
);

interface ValidateTransferParms extends CreateTransferRequest {
    itemTypeId: string;
}

export const validateTransfer = createAsyncThunk<
    boolean,
    ValidateTransferParms,
    { state: RootState }
>('itemtypes/{itemTypeId}/transfers/validate/post', async (transfer, { dispatch }) => {
    return await runSafe(async () => {
        return await post(`itemtypes/${transfer.itemTypeId}/transfers/validate`, transfer);
    }, dispatch);
});
