import { PersonSelector } from '@laingorourke/core-web-components-personselector';
import { PersonSelectorProps } from '@laingorourke/core-web-components-personselector/lib/PersonSelectorProps';

interface OwnerPersonSelectorProps
    extends Omit<PersonSelectorProps, 'contextId' | 'searchOptions'> {
    ownersIds: string[];
    projectNumber: string;
    placeholder?: string;
    onOwnerSelected?: (ownerId: string) => void;
}

export const OwnerPersonSelector: React.FC<OwnerPersonSelectorProps> = (props) => {
    const { ownersIds, projectNumber, placeholder, onOwnerSelected, ...rest } = props;
    return (
        <PersonSelector
            {...rest}
            contextId={`owners-${projectNumber}`}
            searchOptions={{
                allowedPeopleIds: ownersIds || [],
            }}
            disabled={!ownersIds || rest.disabled}
            placeholder={placeholder ?? 'Select Owner...'}
            onChange={(selectedValue, action) => {
                props.onChange?.(selectedValue, action);
                onOwnerSelected?.(selectedValue as string);
            }}
        />
    );
};
