import { Table } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { fetchInspectionTypes, useInspectionTypes } from 'domain/store/reducers/inspectionType';
import { useItemTypeMetadata, useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { hasPermission, useCurrentUser } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { PropsWithChildren, useEffect } from 'react';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { InspectionTypeTableRow } from './InspectionTypeTableRow';
import { NewInspectionTypeButton } from './NewInspectionTypeButton';
import { getTableColumns } from './tableColumns';

export const InspectionTypes: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ManageComplianceSettingsRead} ignoreSecurable={true}>
        <InspectionTypesContent>{children}</InspectionTypesContent>
    </SecuredContent>
);

const InspectionTypesContent: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const currentUser = useCurrentUser();

    const inspectionTypes = useInspectionTypes();
    const itemTypeId = useSelectedItemTypeId();
    const metadata = useItemTypeMetadata();
    const canEdit = hasPermission(
        currentUser,
        Permission.ManageComplianceSettingsWrite,
        undefined,
        true
    );
    const canDelete = hasPermission(
        currentUser,
        Permission.ManageComplianceSettingsDelete,
        undefined,
        true
    );

    const getCategoryName = (id: string) => metadata?.categories?.find((p) => p.id === id)?.name!;
    const inspectionTypesTableData: InspectionTypeTableRow[] = inspectionTypes?.map((i) => ({
        ...i,
        categoryNames: i.categoryIds!.map((c) => getCategoryName(c)),
        canEdit,
        canDelete,
    }));

    const dataLoaded = !!metadata && !!inspectionTypesTableData;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Inspections found');

    const allCategories = inspectionTypes
        ? inspectionTypes.reduce((acc, cur) => {
            acc.push(...cur.categoryIds!);
            return acc;
        }, [] as string[])
        : [];
    const categoryOptions = !!metadata?.categories
        ? metadata.categories
            .filter((c) => allCategories.indexOf(c.id) > -1)
            .map((d) => {
                return {
                    label: d.name,
                    value: d.id,
                } as OptionTypeBase;
            })
        : [];

    const columns = getTableColumns(
        inspectionTypesTableData,
        categoryOptions,
        metadata?.categories,
        canEdit || canDelete
    );

    useEffect(() => {
        dispatch(fetchInspectionTypes());
    }, [itemTypeId, dispatch]);

    return (
        <>
            <NewInspectionTypeButton disabled={!dataLoaded} />
            {children}
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(columns)}
                {...tableLoadingProps}
                data={dataLoaded ? inspectionTypesTableData : []}
                keySelector={(d) => d.id}
                columns={columns}
                saveSelectedPageSize
                cacheKey="inspection-types"
                hover
            />
        </>
    );
};
