import { WarningPanel } from '@laingorourke/core-web-components';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import React from 'react';
import styles from './DateInPastWarning.module.scss';
export const DateInPastWarning: React.FC<{ dates: Date | string | Date[]; label: string }> = ({
    dates,
    label,
}) => {
    const isDateInPast = (date: Date | string) => isBefore(new Date(date), startOfDay(new Date()));

    const isInThePast = Array.isArray(dates)
        ? (dates as Date[]).filter((d) => isDateInPast(d)).length > 0
        : isDateInPast(dates);
    return (
        <>
            {isInThePast && (
                <WarningPanel className={styles.warning}>{`${label} is in the past`}</WarningPanel>
            )}
        </>
    );
};
