import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TourStep } from 'domain/models/api-models';
import {
    deleteTourStep,
    fetchActiveTourSteps,
    fetchTourSteps,
    reactivateTourStep,
    updateTourStep,
} from './actions';

const initialState = {
    tourSteps: null as unknown as TourStep[],
    activeTourSteps: null as unknown as TourStep[],
};
const tourStepsSlice = createSlice({
    name: 'tourSteps',
    initialState: initialState,
    reducers: {
        clearTourSteps() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchTourSteps.fulfilled.type,
            (state, action: PayloadAction<TourStep[]>) => {
                state.tourSteps = action.payload;
            }
        );
        builder.addCase(
            fetchActiveTourSteps.fulfilled.type,
            (state, action: PayloadAction<TourStep[]>) => {
                state.activeTourSteps = action.payload;
            }
        );
        builder.addCase(updateTourStep.fulfilled.type, (state, action: PayloadAction<TourStep>) => {
            if (action.payload === undefined) return;
            // update administration tour step and active tour steps separately
            // ALL steps displayed in Administration
            state.tourSteps = state.tourSteps?.map((s) =>
                s.id === action.payload.id ? action.payload : s
            );
            // steps used in the Tour
            state.activeTourSteps = state.activeTourSteps?.map((s) =>
                s.id === action.payload.id ? action.payload : s
            );
        });
        builder.addCase(
            reactivateTourStep.fulfilled.type,
            (state, action: PayloadAction<TourStep>) => {
                if (action.payload === undefined) return;
                state.tourSteps = state.tourSteps?.map((s) =>
                    s.id === action.payload.id ? action.payload : s
                );
                state.activeTourSteps = state.tourSteps?.filter((s) => s.isActive);
            }
        );
        builder.addCase(deleteTourStep.fulfilled.type, (state, action: PayloadAction<string>) => {
            if (action.payload === undefined) return;
            state.tourSteps = state.tourSteps.map((s) =>
                s.id === action.payload ? { ...s, isActive: false } : s
            );
            state.activeTourSteps = state.activeTourSteps.filter((s) => s.id !== action.payload);
        });
    },
});

export const { clearTourSteps } = tourStepsSlice.actions;
export default tourStepsSlice.reducer;
