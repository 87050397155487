import { ProjectItemNumberConfiguration } from 'domain/models/api-models';
import { useProjectItemNumberConfiguration } from 'domain/store/reducers/projectItemNumberConfiguration';
import { CopyText } from 'views/components';

const getFormattedItemNumber = (
    itemNumber?: string,
    projectNumber?: string,
    itemNumberConfiguration?: ProjectItemNumberConfiguration,
) => {
    if (!itemNumberConfiguration) return undefined;
    if (!itemNumberConfiguration?.hideProjectNumber || !itemNumber) return itemNumber;

    return itemNumber.replace(`${projectNumber}${itemNumberConfiguration.separator}`, '');
};

interface ItemNumberProps {
    itemNumber: string | undefined;
    copyTextEnabled?: boolean;
    projectNumber: string;
}

export const ItemNumber: React.FC<ItemNumberProps> = ({ itemNumber, copyTextEnabled, projectNumber }) => {
    const itemNumberConfiguration = useProjectItemNumberConfiguration();
    const itemNumberFormatted = getFormattedItemNumber(itemNumber, projectNumber, itemNumberConfiguration);
    return copyTextEnabled ? <CopyText text={itemNumberFormatted} /> : <>{itemNumberFormatted}</>;
};
