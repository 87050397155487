import {
    Button,
    ColumnDefinition,
    selectFilter,
    textFilter,
} from '@laingorourke/core-web-components';
import { Classification, ClassificationHub } from 'domain/models/api-models';
import { reactivateClassification } from 'domain/store/reducers/classifications';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import { ClassificationBadge, TableCellActive } from 'views/components';
import { ClassificationTableRow } from 'views/routes/project/projectSetup/classifications/ClassificationTableRow';
import { getFilterOptionsFromArray } from '../table';
import styles from './Classifications.module.scss';

const hubs = getFilterOptionsFromArray(Object.keys(ClassificationHub));

export const useClassificationsTableColumns = (
    readOnlyMode: boolean,
    itemTypeId?: string,
    projectNumber?: string
): ColumnDefinition<ClassificationTableRow>[] => {
    const dispatch = useAppDispatch();
    return [
        {
            field: (d) => d.name,
            text: 'Name',
            sortable: true,
            filter: readOnlyMode ? textFilter() : undefined,
        },
        {
            field: (d) => d.shortName,
            text: 'Short name',
            sortable: true,
            renderer: (_: any, row: Classification) => <ClassificationBadge id={row.id} />,
            filter: readOnlyMode ? textFilter() : undefined,
        },
        {
            field: (d) => d.hub,
            text: 'Hub',
            key: nameof<ClassificationTableRow>('hub'),
            sortable: true,
            customSort: (rowA, rowB, order) => {
                const classifications = Object.keys(ClassificationHub);
                if (order === 'asc') {
                    return (
                        classifications.indexOf(rowA.hub!) - classifications.indexOf(rowB.hub!) ||
                        rowA.name!.localeCompare(rowB.name!)
                    );
                }
                return (
                    classifications.indexOf(rowB.hub!) - classifications.indexOf(rowA.hub!) ||
                    rowB.name!.localeCompare(rowA.name!)
                );
            },
            renderer: (_: any, row: Classification) => (
                <span className={styles[`hub-${row.hub.toLocaleLowerCase()}`]}>{row.hub}</span>
            ),
            headerClass: styles.hubHeader,
            ...(readOnlyMode && {
                filter: selectFilter({
                    multi: true,
                    options: hubs,
                }),
            }),
        },
        {
            field: (d) => d.description,
            text: 'Description',
            sortable: true,
            filter: readOnlyMode ? textFilter() : undefined,
        },
        {
            field: (d) => d.isActive,
            text: 'Status',
            sortable: true,
            renderer: (_: any, row: Classification) => (
                <>
                    <TableCellActive isActive={row.isActive} className={styles.status} />
                    {!row.isActive && !readOnlyMode && itemTypeId && projectNumber && (
                        <Button
                            size="sm"
                            onClick={() => {
                                dispatch(
                                    reactivateClassification({
                                        id: row.id,
                                        itemTypeId,
                                        projectNumber,
                                    })
                                );
                            }}>
                            Activate
                        </Button>
                    )}
                </>
            ),
        },
    ];
};
