import { Button, ButtonProps, generatePath } from '@laingorourke/core-web-components';
import { useHasPermissions } from 'domain/store/reducers/users';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ActionButtonsPanel, usePageActions } from 'views/components';
import { routes } from 'views/routes/Routes';
import { AdminRouteParams } from '../../AdminRouteParams';

interface NewTransferButtonProps extends ButtonProps {}

export const NewTransferButton: React.FC<NewTransferButtonProps> = (props) => {
    const routeParams = useParams<AdminRouteParams>();
    const history = useHistory();

    const currentUserHasTransferWritePermission = useHasPermissions(
        Permission.TransferWrite,
        undefined,
        true
    );

    const goToNewTransfers = () =>
        history.push(
            generatePath(routes.admin.routes!.transfers.routes!.newTransfer.path, {
                ...routeParams,
            })
        );

    usePageActions(
        currentUserHasTransferWritePermission && (
            <ActionButtonsPanel>
                <Button variant="primary" onClick={goToNewTransfers} {...props}>
                    New Transfer
                </Button>
            </ActionButtonsPanel>
        ),
        [routeParams.itemTypeCode, currentUserHasTransferWritePermission]
    );

    return <> </>;
};
