import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ItemNumberExamples, ProjectItemNumberConfiguration } from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import {
    fetchProjectItemNumberConfiguration,
    fetchProjectItemNumberConfigurationNumberExamples,
    updateAreaCodeInItemNumber,
    updateHideProjectNumber,
    updateMaxItemNumber,
} from './actions';

const initialState = {
    configuration: null as unknown as ProjectItemNumberConfiguration,
    examples: null as unknown as ItemNumberExamples,
};

const classificationsSlice = createSlice({
    name: 'projectItemNumber',
    initialState: initialState,
    reducers: {
        clearProjectItemNumberSetup() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchProjectItemNumberConfiguration.fulfilled.type,
            (state, action: PayloadAction<ProjectItemNumberConfiguration>) => {
                state.configuration = action.payload;
            }
        );

        builder.addCase(
            updateAreaCodeInItemNumber.fulfilled.type,
            (state, action: PayloadAction<ProjectItemNumberConfiguration>) => {
                state.configuration = action.payload;
            }
        );

        builder.addCase(
            updateMaxItemNumber.fulfilled.type,
            (state, action: PayloadAction<ProjectItemNumberConfiguration>) => {
                state.configuration = action.payload;
            }
        );

        builder.addCase(
            updateHideProjectNumber.fulfilled.type,
            (state, action: PayloadAction<ProjectItemNumberConfiguration>) => {
                state.configuration = action.payload;
            }
        );

        builder.addCase(
            fetchProjectItemNumberConfigurationNumberExamples.fulfilled.type,
            (state, action: PayloadAction<ItemNumberExamples>) => {
                state.examples = action.payload;
            }
        );
    },
});

export const useProjectItemNumberConfiguration = () =>
    useSelector((state: RootState) => state.projectItemNumberConfiguration.configuration);

export const useAreaCodeInItemNumber = () =>
    useSelector((state: RootState) =>
        state.projectItemNumberConfiguration.configuration === null
            ? undefined
            : state.projectItemNumberConfiguration.configuration.areaCodeInItemNumber
    );

export const useItemNumberExamples = () =>
    useSelector((state: RootState) => state.projectItemNumberConfiguration.examples);

export const { clearProjectItemNumberSetup } = classificationsSlice.actions;
export default classificationsSlice.reducer;
