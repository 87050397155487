import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ComplianceTemplate,
    ControlMeasureSummary,
    InspectionTypeSummary,
} from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import {
    createComplianceTemplate,
    deleteComplianceTemplate,
    fetchComplianceTemplates,
    fetchControlMeasureSummaries,
    fetchInspectionTypesSummaries,
    updateComplianceTemplate,
} from './actions';

const initialState = {
    complianceTemplates: undefined as unknown as ComplianceTemplate[],
    controlMeasures: undefined as unknown as ControlMeasureSummary[],
    inspectionTypes: undefined as unknown as InspectionTypeSummary[],
};

const complianceTemplatesSlice = createSlice({
    name: 'complianceTemplates',
    initialState: initialState,
    reducers: {
        clearComplianceTemplates() {
            return initialState;
        },
        clearControlMeasureSummaries(state) {
            state.controlMeasures = initialState.controlMeasures;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchComplianceTemplates.fulfilled.type,
            (state, action: PayloadAction<ComplianceTemplate[]>) => {
                state.complianceTemplates = action.payload;
            }
        );
        builder.addCase(
            fetchControlMeasureSummaries.fulfilled.type,
            (state, action: PayloadAction<ControlMeasureSummary[]>) => {
                state.controlMeasures = action.payload;
            }
        );
        builder.addCase(
            fetchInspectionTypesSummaries.fulfilled.type,
            (state, action: PayloadAction<InspectionTypeSummary[]>) => {
                state.inspectionTypes = action.payload;
            }
        );
        builder.addCase(
            createComplianceTemplate.fulfilled.type,
            (state, action: PayloadAction<ComplianceTemplate>) => {
                if (action.payload === undefined) return;
                state.complianceTemplates.push(action.payload);
            }
        );
        builder.addCase(
            updateComplianceTemplate.fulfilled.type,
            (state, action: PayloadAction<ComplianceTemplate>) => {
                if (action.payload === undefined) return;
                state.complianceTemplates = state.complianceTemplates.map((c) =>
                    c.id === action.payload.id ? action.payload : c
                );
            }
        );
        builder.addCase(
            deleteComplianceTemplate.fulfilled.type,
            (state, action: PayloadAction<string>) => {
                if (action.payload === undefined) return;
                state.complianceTemplates = state.complianceTemplates.map((c) =>
                    c.id === action.payload ? { ...c, isActive: false } : c
                );
            }
        );
    },
});
export const useComplianceTemplates = () =>
    useSelector((state: RootState) => state.complianceTemplates.complianceTemplates);
export const useControlMeasureSummaries = () =>
    useSelector((state: RootState) => state.complianceTemplates.controlMeasures);
export const useInspectionTypesSummaries = () =>
    useSelector((state: RootState) => state.complianceTemplates.inspectionTypes);

export const useComplianceTemplate = (id: string) =>
    useSelector((state: RootState) =>
        state.complianceTemplates.complianceTemplates?.find((cm) => cm.id === id)
    );

export const { clearComplianceTemplates, clearControlMeasureSummaries } =
    complianceTemplatesSlice.actions;
export default complianceTemplatesSlice.reducer;
