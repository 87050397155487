import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';

export const useIntegrations = () =>
    useSelector((state: RootState) => state.integrations.integrations);

export const useIntegrationsMetadata = () =>
    useSelector((state: RootState) => state.integrations.metadata);

export const useIntegrationHistory = () =>
    useSelector((state: RootState) => state.integrations.integrationHistory);

export const useIntegration = (integrationId: string) =>
    useSelector((state: RootState) =>
        state.integrations.integrations?.integrationsList?.find((i) => i.id === integrationId)
    );
