import { Select } from '@laingorourke/core-web-components';
import { usePeopleByIds } from '@laingorourke/core-web-components-personselector';
import { StatusType, useTasksAssignees } from '@laingorourke/core-web-mytasksreactsdk';
import { useCurrentUser } from 'domain/store/reducers/users';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectParams } from 'views/routes/project/Project';

interface UserDropdownProps {
    onChange: (userId: string) => void;
}

export const UserDropdown: React.FC<UserDropdownProps> = ({ onChange }) => {
    const { projectNumber } = useParams<ProjectParams>();
    const currentUser = useCurrentUser();

    const [selectedUserId, setSelectedUserId] = useState<string | undefined>(currentUser.id);

    const allProjectUsersWithAcknowledgments = useTasksAssignees({
        filter: {
            and: [
                { 'status/type': StatusType.ToDo },
                { taskType: 'Acknowledgment' },
                { projectNumber: projectNumber },
            ],
        },
    });
    const userIds = allProjectUsersWithAcknowledgments.data?.map((t) => t.assignedUserId!) ?? [];
    const people = usePeopleByIds([...new Set(userIds.filter((id) => id !== currentUser.id))]);
    const peopleOptions = !!people.data
        ? people.data!.map((p) => ({ label: p.displayName, value: p.id }))
        : [];
    const assignees = [
        { label: `${currentUser.displayName} (current user)`, value: currentUser.id! },
        ...peopleOptions,
    ];
    return (
        <>
            <span>For </span>
            <Select
                options={assignees}
                onChange={(v) => {
                    setSelectedUserId(v as string);
                    onChange(v as string);
                }}
                value={selectedUserId}
            />
        </>
    );
};
