import { Table } from '@laingorourke/core-web-components';
import { fetchProjectDesigners, useProjectDesigners } from 'domain/store/reducers/designers';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { PropsWithChildren, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { ProjectParams } from '../../Project';
import { getTableColumns } from './tableColumns';

export const Designers: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ProjectDesignersRead}>
        <DesignersContent>{children}</DesignersContent>
    </SecuredContent>
);

const DesignersContent: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const { projectNumber } = useParams<ProjectParams>();
    const itemTypeId = useSelectedItemTypeId();
    const columns = getTableColumns();
    const designers = useProjectDesigners();
    const dataLoaded = !!designers;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Designers found');

    useEffect(() => {
        dispatch(fetchProjectDesigners({ projectNumber, itemTypeId }));
    }, [projectNumber, itemTypeId, dispatch]);

    return (
        <>
            {children}
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(columns)}
                {...tableLoadingProps}
                data={dataLoaded ? designers! : []}
                keySelector={(d) => d.id}
                columns={columns}
                saveSelectedPageSize
                cacheKey="designers"
                hover
            />
        </>
    );
};
