import { Drawer, useDevice } from '@laingorourke/core-web-components';
import React, { PropsWithChildren } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ItemInfo } from '../../details';
import { ProjectItemDetailsParams } from '../../details/ProjectItemDetailsParams';
import styles from './Preview.module.scss';

interface PreviewProps extends PropsWithChildren {
    returnPath: string;
}

export const Preview: React.FC<PreviewProps> = ({ returnPath, children }) => {
    const { projectItemId, projectNumber, itemTypeCode, ...rest } =
        useParams<ProjectItemDetailsParams>();

    const history = useHistory();

    const isMobile = useDevice().isMobile;

    const goToItems = () =>
        history.push(
            generatePath(returnPath, {
                projectNumber,
                projectItemId,
                itemTypeCode,
                ...rest,
            })
        );

    return (
        <Drawer
            push={isMobile}
            closeOnClickAway={false}
            open={true}
            onChange={goToItems}
            className={styles.drawer}>
            <ItemInfo showHeader={false}>{children}</ItemInfo>
        </Drawer>
    );
};
