import { ColumnDefinition, CompareType, selectFilter } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { Status } from 'domain/models/api-models';

export const getItemTypeStatusColumn = (
    statuses: Status[] | undefined,
    statusOptions: OptionTypeBase[],
    multi?: boolean,
    compareType?: CompareType
): Partial<ColumnDefinition<any>> => {
    const statusSortOrder = statuses && statuses.map((p) => p.name!);
    return {
        sortable: true,
        filter: selectFilter({
            multi: multi,
            options: statusOptions,
            compareType: compareType ?? CompareType.Equals
        }),
        customSort: (a, b, direction) => {
            const firstElement = a.status;
            const secondElement = b.status;
            return statusSortOrder
                ? direction === 'asc'
                    ? statusSortOrder.indexOf(firstElement) - statusSortOrder.indexOf(secondElement)
                    : statusSortOrder.indexOf(secondElement) - statusSortOrder.indexOf(firstElement)
                : 0;
        }
    } as ColumnDefinition<any>;
};
