import { generatePath } from '@laingorourke/core-web-components';
import { useItemTypeCategories } from 'domain/store/reducers/itemType';
import { useWeatherInspectionType } from 'domain/store/reducers/weather';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { AdminRouteParams } from '../../../AdminRouteParams';
import { UpsertForm } from './UpsertForm';

interface WeatherInspectionTypeParams extends AdminRouteParams {
    weatherInspectionTypeId: string;
}

export const UpsertWeatherInspectionType: React.FC = () => {
    const history = useHistory();
    const { weatherInspectionTypeId, itemTypeCode } = useParams<WeatherInspectionTypeParams>();

    const weatherInspectionType = useWeatherInspectionType(weatherInspectionTypeId);

    const isEditMode = weatherInspectionType !== undefined;

    const categories = useItemTypeCategories();

    const goToWeather = () =>
        history.push(
            generatePath(routes.admin.routes!.weather.routes!.weatherInspectionTypes.path, {
                itemTypeCode,
            })
        );

    return (
        <>
            {(weatherInspectionTypeId === undefined || weatherInspectionType !== undefined) &&
                !!categories && (
                    <Modal show={true} onHide={goToWeather} animation={false} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {isEditMode ? 'Edit' : 'New'} Weather Inspection Type
                            </Modal.Title>
                        </Modal.Header>
                        <SecuredContent
                            permissions={Permission.ManageWeatherSettingsWrite}
                            ignoreSecurable={true}>
                            <UpsertForm
                                weatherInspectionType={weatherInspectionType}
                                isEditMode={isEditMode}
                                categories={categories}
                                goToWeatherInspectionTypes={goToWeather}
                            />
                        </SecuredContent>
                    </Modal>
                )}
        </>
    );
};
