import { ColumnDefinition } from '@laingorourke/core-web-components';
import { userSelectFilter } from '@laingorourke/core-web-components-personselector';
import { getDataServiceConfiguration } from 'helpers/applicationConfig';
import { PersonTableCell } from '.';
import styles from './PersonColumn.module.scss';

export const getPersonColumn = (
    propertyName: string,
    showFilters: boolean,
    sort: boolean,
    projectId?: string,
    ignoreSystemId?: boolean
): Partial<ColumnDefinition<any>> => {
    const systemId = getDataServiceConfiguration()?.systemId!;

    let filtersProps: Partial<ColumnDefinition<any>> = {
        headerClass: styles.personFilterWidth,
        filter: userSelectFilter({
            contextId: 'user-select-filter',
            isMulti: true,
            placeholder: 'Select...',
            ...(!ignoreSystemId && {
                searchOptions: {
                    projectPeopleSearchOptions: {
                        projectId: projectId!,
                        systemId: systemId,
                    },
                },
            }),
        }),
    };

    let column: Partial<ColumnDefinition<any>> = {
        renderer: (_: any, row: any) => <PersonTableCell {...row[propertyName]} />,
    };

    if (showFilters) {
        column = { ...column, ...filtersProps };
    }

    if (sort) {
        column = {
            ...column,
            ...{
                sortable: true,
                customSort: (a, b, direction) => {
                    const rowAPersonDisplayName = a[propertyName]?.displayName;
                    const rowBPersonDisplayName = b[propertyName]?.displayName;
                    const sortOrder = !!rowAPersonDisplayName
                        ? !!rowBPersonDisplayName
                            ? rowAPersonDisplayName?.localeCompare(rowBPersonDisplayName)
                            : 1
                        : -1;
                    if (direction === 'asc') {
                        return sortOrder;
                    }
                    return sortOrder * -1;
                },
            },
        };
    }

    return column;
};
