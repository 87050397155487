import { generatePath } from '@laingorourke/core-web-components';
import { TourStep } from 'domain/models/api-models';

import { useTourStep } from 'domain/store/reducers/tourSteps';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { AdminRouteParams } from '../../AdminRouteParams';
import { UpdateForm } from './UpdateForm';

interface UpdateTourStepParams extends AdminRouteParams {
    tourStepId: string;
}

export const UpdateTourStep: React.FC = () => {
    const history = useHistory();
    const { itemTypeCode, tourStepId } = useParams<UpdateTourStepParams>();

    const tourStep: TourStep | undefined = useTourStep(tourStepId);

    const goToTour = () =>
        history.push(generatePath(routes.admin.routes!.tour.path, { itemTypeCode }));

    return (
        <>
            {tourStep && (
                <Modal show={true} onHide={goToTour} animation={false} backdrop="static" size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Update Tour Step</Modal.Title>
                    </Modal.Header>
                    <SecuredContent
                        permissions={Permission.ManageTourSettingsWrite}
                        ignoreSecurable={true}>
                        <UpdateForm tourStep={tourStep} goToTour={goToTour} />
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
