import { Skeleton } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import isBefore from 'date-fns/isBefore';
import {
    fetchProjectClassifications,
    useProjectClassifications,
} from 'domain/store/reducers/classifications';
import { useItemTypeMetadata, useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useProjects } from 'domain/store/reducers/projects';
import { fetchWeatherEventItems, useWeatherEventItems } from 'domain/store/reducers/weather';
import { useAppDispatch } from 'domain/store/rootStore';
import { getFeaturesConfiguration } from 'helpers/applicationConfig';
import { groupBy } from 'helpers/utils';
import React, { PropsWithChildren, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { InfoPanel, SecuredContent } from 'views/components';
import { getFilterOptions } from 'views/components/table';
import { ProjectParams } from 'views/routes/project/Project';
import { WeatherEventItemsTable } from './WeatherEventItemsTable';
import { WeatherEventItemTableRow } from './WeatherEventItemTableRow';

export const WeatherEventItems: React.FC<PropsWithChildren> = ({ children }) => (
    // TODO: the story says " ...is not client type". Does it mean external?
    <SecuredContent permissions={Permission.ManageItemsRead} denyExternalUserAccess={true}>
        <WeatherEventItemsContent>{children}</WeatherEventItemsContent>
    </SecuredContent>
);

const WeatherEventItemsContent: React.FC<PropsWithChildren> = ({ children }) => {
    const weatherEnabled = getFeaturesConfiguration()?.weatherEnabled;
    if (!weatherEnabled) return null;

    const dispatch = useAppDispatch();
    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();
    const itemTypeId = useSelectedItemTypeId();
    const categories = useItemTypeMetadata()?.categories;
    const weatherEventItems = useWeatherEventItems();
    const projects = useProjects();

    const weatherEventItemsData: WeatherEventItemTableRow[] = weatherEventItems?.map((i) => ({
        ...i,
        projectNumber,
        categoryName: categories?.find((c) => c.id === i.categoryId)?.name,
        siteName:
            projects
                ?.find((p) => p.projectNumber === projectNumber)
                ?.sites?.find((s) => s.id.toLocaleLowerCase() === i.siteId?.toLocaleLowerCase())
                ?.name ?? '',
    }));

    // get info related to classifications, it's required in the table
    const classifications = useProjectClassifications(false);
    const classiticationsSortOrder = classifications && classifications.map((p) => p.id!);
    const classificationOptions = classifications
        ?.filter((c) => weatherEventItems?.find((p) => p.classificationId === c.id))
        ?.map((c) => {
            return { value: c.id, label: c.name } as OptionTypeBase;
        });

    // data required to build table filters and sorting
    const areaNameOptions = getFilterOptions(weatherEventItems, 'area');
    const subAreaNameOptions = getFilterOptions(weatherEventItems, 'subArea');

    // all the items have to be groupped by the weather event date
    const grouppedWeatherEventItems = !!weatherEventItemsData
        ? groupBy(weatherEventItemsData, (g) => g.date)
        : undefined;

    const groups = !!grouppedWeatherEventItems
        ? Object.keys(grouppedWeatherEventItems)
              ?.map((date) => ({
                  date: date,
                  items: grouppedWeatherEventItems[date] as WeatherEventItemTableRow[],
              }))
              ?.sort((a, b) => (isBefore(new Date(a.date), new Date(b.date)) ? 1 : -1))
        : undefined;

    // fetch weather event items
    useEffect(() => {
        dispatch(fetchWeatherEventItems({ projectNumber, itemTypeId }));
        dispatch(fetchProjectClassifications({ itemTypeId: itemTypeId!, projectNumber }));
    }, [projectNumber, itemTypeId, dispatch]);

    const dataLoaded =
        !!weatherEventItems &&
        !!groups &&
        !!classiticationsSortOrder &&
        !!classificationOptions &&
        !!areaNameOptions &&
        !!subAreaNameOptions;
    return (
        <>
            {!dataLoaded && <Skeleton.Text />}
            {!!weatherEventItemsData && !weatherEventItemsData.length && (
                <InfoPanel message="No Items found" />
            )}
            {dataLoaded &&
                groups.map((g) => (
                    <WeatherEventItemsTable
                        key={g.date}
                        date={g.date}
                        items={g.items}
                        itemTypeCode={itemTypeCode}
                        projectNumber={projectNumber}
                        classiticationsSortOrder={classiticationsSortOrder}
                        classificationOptions={classificationOptions}
                        areaNameOptions={areaNameOptions}
                        subAreaNameOptions={subAreaNameOptions}
                    />
                ))}
            {children}
        </>
    );
};
