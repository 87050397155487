import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Transfer } from 'domain/models/api-models';
import { createTransfer, fetchTransfers } from './actions';

const initialState = {
    transfers: null as unknown as Transfer[],
};
const transfersSlice = createSlice({
    name: 'transfers',
    initialState: initialState,
    reducers: {
        clearTransfers() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchTransfers.fulfilled.type,
            (state, action: PayloadAction<Transfer[]>) => {
                state.transfers = action.payload;
            }
        );
        builder.addCase(createTransfer.fulfilled.type, (state, action: PayloadAction<Transfer>) => {
            if (action.payload === undefined) return;
            state.transfers!.push(action.payload);
        });
    },
});

export const { clearTransfers } = transfersSlice.actions;
export default transfersSlice.reducer;
