const maxTotalNumber = 7;
const maxNumberOfFirstItems = 4;
const maxNumberOfLastItems = maxTotalNumber - maxNumberOfFirstItems;

export const useTableSelectedRowsSummary = (ids: string[]) => {
    const firstItems = ids.length > maxTotalNumber ? ids.slice(0, maxNumberOfFirstItems) : ids;
    const lastItems =
        ids.length > maxTotalNumber ? ids.slice(ids.length - maxNumberOfLastItems, ids.length) : [];
    return { firstItems, lastItems };
};
