import { Form, Select, TextArea, WarningPanel } from '@laingorourke/core-web-components';
import {
    Classification,
    ClassificationComplianceTemplateInspectionFrequency,
    ComplianceTemplate,
    ControlMeasureSummary,
    InspectionTypeSummary,
    ItemTypeCategory,
} from 'domain/models/api-models';
import { FormikProps } from 'formik';
import { haveSameMembers } from 'helpers/arrayHelper';
import React, { useEffect, useState } from 'react';
import { Permission } from 'security/Permission';
import { ClassificationSelect, ProjectSelector } from 'views/components';
import { ComplianceTemplateFormProps } from '../../../schemas';
import styles from './AddOrEditForm.module.scss';
import { ControlMeasuresFieldArray } from './ControlMeasuresFieldArray';
import { InspectionTypeFieldArray } from './InspectionTypeFieldArray';

export interface UpsertComplianceTemplateFormFieldsProps {
    complianceTemplate?: ComplianceTemplate;
    form: FormikProps<ComplianceTemplateFormProps>;
    inspectionTypes: InspectionTypeSummary[];
    controlMeasures: ControlMeasureSummary[];
    classifications?: Classification[];
    classificationName?: string;
    categories: ItemTypeCategory[];
    isEditMode: boolean;
    writePermission: Permission;
    isProjectMode?: boolean;
    suggestedControlMeasureIds?: string[];
    suggestedInspections?: ClassificationComplianceTemplateInspectionFrequency[];
}

export const UpsertComplianceTemplateFormFields: React.FC<
    UpsertComplianceTemplateFormFieldsProps
> = ({
    complianceTemplate,
    form,
    inspectionTypes,
    controlMeasures,
    classifications,
    classificationName,
    categories,
    isEditMode,
    writePermission,
    isProjectMode,
    suggestedControlMeasureIds,
    suggestedInspections,
}) => {
    const [requiresAllProjectsInit, setRequiresAllProjectsInit] = useState(
        complianceTemplate !== undefined
    );
    const [requiresProjectInit, setRequiresProjectInit] = useState(
        complianceTemplate !== undefined
    );
    const [requiresCategoryInit, setRequiresCategoryInit] = useState(
        complianceTemplate !== undefined
    );
    const [suggestedControlMeasureChanged, setSuggestedControlMeasureChanged] = useState(false);
    const [suggestedInspectionsChanged, setSuggestedInspectionsChanged] = useState(false);

    const { setFieldValue, values, touched, errors } = form;

    const classificationOptions = classifications
        ?.filter((c) => c.projectNumber === values.projectNumber || !c.projectNumber)
        .map((c) => ({ label: c.name!, value: c.id }));

    const getInspectionsToDisplay = (categoryId?: string | null) =>
        inspectionTypes?.filter(
            (i) =>
                categoryId === null ||
                categoryId === undefined ||
                i.categoriesIds!.indexOf(categoryId) > -1
        );

    useEffect(() => {
        if (requiresProjectInit) {
            setRequiresProjectInit(false);
            return;
        }
        setFieldValue('controlMeasuresIds', []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.projectNumber, setFieldValue]);

    useEffect(() => {
        if (requiresCategoryInit) {
            setRequiresCategoryInit(false);
            return;
        }
        setFieldValue('inspections', []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.categoryId, setFieldValue]);

    useEffect(() => {
        if (requiresAllProjectsInit) {
            setRequiresAllProjectsInit(false);
            return;
        }
        if (values.allProjects) {
            setFieldValue('projectNumber', null);
            setFieldValue('categoryId', values.categoryId);
        } else {
            setFieldValue('projectNumber', values.projectNumber);
            setFieldValue('categoryId', null);
        }
        setFieldValue('inspections', []);
        setFieldValue('controlMeasuresIds', []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.allProjects, setFieldValue]);

    useEffect(() => {
        if (!!suggestedControlMeasureIds) {
            setSuggestedControlMeasureChanged(
                !haveSameMembers(suggestedControlMeasureIds, values.controlMeasuresIds)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.controlMeasuresIds]);

    useEffect(() => {
        if (!!suggestedInspections) {
            const inspectionChanged = !haveSameMembers(
                suggestedInspections.map((si) => si.inspectionTypeId),
                values.inspections.map((i) => i.inspectionTypeId)
            );

            const frequencyChanged = !values.inspections.every((i) =>
                suggestedInspections.find(
                    (si) =>
                        si.inspectionTypeId === i.inspectionTypeId &&
                        si.frequency.toString() === i.frequency?.toString()
                )
            );

            setSuggestedInspectionsChanged(inspectionChanged || frequencyChanged);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.inspections]);

    return (
        <>
            <ProjectSelector
                permission={writePermission}
                isEditMode={isEditMode}
                allProjects={values.allProjects}
                setFieldValue={setFieldValue}
                isProjectMode={isProjectMode}
            />
            {values.allProjects && (
                <Form.Row>
                    <Form.Field name="categoryId" label="Category">
                        <Select
                            options={categories}
                            dataShape={{
                                value: 'id',
                                label: 'name',
                            }}
                            disabled={isEditMode}
                            placeholder="Select Category..."
                        />
                    </Form.Field>
                </Form.Row>
            )}
            {!!classificationOptions && !classificationName && (
                <Form.Row>
                    <Form.Field name="classificationId" label="Classification">
                        <ClassificationSelect
                            name="classificationId"
                            disabled={isEditMode}
                            options={classificationOptions}
                        />
                    </Form.Field>
                </Form.Row>
            )}
            {/* This is to support the new classification mode, we don't have a classification that could be used here, so we are mocking the options to make it look like there is a disabled dropdown */}
            {!!classificationName && (
                <Form.Row>
                    <Form.Field name="classificationId" label="Classification">
                        <ClassificationSelect
                            name="classificationId"
                            disabled={true}
                            options={[{ label: classificationName, value: '' }]}
                            value=""
                        />
                    </Form.Field>
                </Form.Row>
            )}
            <Form.Row className={styles.example}>
                <Form.Field name="example" label="Classification Example">
                    <TextArea />
                </Form.Field>
            </Form.Row>
            {(suggestedControlMeasureChanged || suggestedInspectionsChanged) && (
                <WarningPanel className={styles.warning}>
                    A template of suggested Inspections and Control Measures has been provided by
                    SME, please consider the implications.
                </WarningPanel>
            )}
            <Form.List
                name="inspections"
                render={(arrayHelpers) => (
                    <InspectionTypeFieldArray
                        inspectionTypes={inspectionTypes}
                        arrayHelpers={arrayHelpers}
                        values={values}
                        errors={errors}
                        touched={touched}
                        getInspectionsToDisplay={getInspectionsToDisplay}
                    />
                )}
            />
            <hr />
            <Form.List
                name="controlMeasuresIds"
                render={(arrayHelpers) => (
                    <ControlMeasuresFieldArray
                        arrayHelpers={arrayHelpers}
                        values={values}
                        errors={errors}
                        touched={touched}
                        controlMeasures={controlMeasures}
                    />
                )}
            />
        </>
    );
};
