import { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { SharedRouteParams } from '../SharedRouteParams';
import { ComplianceTemplateTable } from './components/complianceTemplateTable/ComplianceTemplateTable';
import { NewComplianceTemplateButton } from './components/NewComplianceTemplateButton';

export const ComplianceTemplates: React.FC<PropsWithChildren> = ({ children }) => {
    const { projectNumber, itemTypeCode } = useParams<SharedRouteParams>();
    const isProjectMode = !!projectNumber;
    const complianceTemplateProps = {
        isProjectMode,
        projectNumber,
        itemTypeCode,
    };

    return (
        <SecuredContent
            permissions={Permission.ManageComplianceSettingsRead}
            projectNumber={projectNumber}
            ignoreSecurable={!isProjectMode}>
            <NewComplianceTemplateButton {...complianceTemplateProps} />
            {children}
            <ComplianceTemplateTable {...complianceTemplateProps} />
        </SecuredContent>
    );
};
