import { youAreOfflineDataNotAvailableMessage } from '@laingorourke/core-web-components';
import { useIsOffline } from 'hooks/useIsOffline';

export const useTableLoading = (isLoading: boolean, noDataMessage: string) => {
    const isOffline = useIsOffline();
    return {
        loading: isLoading && !isOffline,
        noDataMessage: isOffline ? youAreOfflineDataNotAvailableMessage : noDataMessage,
    };
};
