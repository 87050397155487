import { generatePath } from '@laingorourke/core-web-components';
import { useWorkflow } from '@laingorourke/core-web-mytasksreactsdk';
import { loadingCompleted, loadingStarted } from 'domain/store/reducers/inProgress/reducer';
import {
    useSelectedItemTypeCode,
    useSelectedItemTypeMyTasksWorkflowId,
} from 'domain/store/reducers/itemType';
import {
    fetchProjectItemCategoryInspections,
    useItemDetails,
    useProjectItemCategoryInspections,
} from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import { useItemInfoRoute } from 'hooks/useItemInfoRoute';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { ProjectItemDetailsParams } from '../../../ProjectItemDetailsParams';
import { CreateForm } from './CreateForm';

export const CreateUpcomingInspection: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { projectItemId, projectNumber } = useParams<ProjectItemDetailsParams>();

    const itemDetails = useItemDetails();
    const itemTypeCode = useSelectedItemTypeCode();
    const inspectionTypes = useProjectItemCategoryInspections()
        ?.map((i) => ({
            id: i.id,
            name: i.name!,
        }))
        .sort((a, b) => a.name!.localeCompare(b.name!));
    const workflowId = useSelectedItemTypeMyTasksWorkflowId();
    const workflow = useWorkflow(workflowId!);
    const statuses = workflow.data?.statuses;

    const categoryId = itemDetails?.categoryId;
    const dataLoaded = !!statuses && !!inspectionTypes;

    const itemInfoRoute = useItemInfoRoute();

    const goToItemDetails = () =>
        history.push(
            generatePath(itemInfoRoute.path, {
                projectNumber,
                projectItemId,
                itemTypeCode,
            })
        );

    useEffect(() => {
        dispatch(
            fetchProjectItemCategoryInspections({
                projectNumber,
                projectItemId,
                categoryId,
            })
        );
    }, [projectNumber, projectItemId, categoryId, dispatch]);

    useEffect(() => {
        if (dataLoaded) dispatch(loadingCompleted());
        else dispatch(loadingStarted());
    }, [dataLoaded, dispatch]);

    return (
        <>
            {dataLoaded && (
                <Modal show={true} onHide={goToItemDetails} animation={false} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Add Inspection</Modal.Title>
                    </Modal.Header>
                    <SecuredContent permissions={Permission.ManageItemsWrite}>
                        <CreateForm
                            projectNumber={projectNumber}
                            projectItemId={projectItemId}
                            inspectionTypes={inspectionTypes}
                            statuses={statuses!}
                            goBack={goToItemDetails}
                        />
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
