import {
    ColumnDefinition,
    selectFilter,
    Skeleton,
    textFilter,
} from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { nameof } from 'helpers/nameOf';
import { getPersonColumn } from 'views/components/table/personColumn';
import styles from '../../../components/table/Table.module.scss';
import { AreaTableRow } from './AreaTableRow';
import ActionButtons from './components/ActionButtons';

export const getTableColumns = (
    areaNameOptions: OptionTypeBase[],
    subAreaNameOptions: OptionTypeBase[],
    userHasWritePermissions: boolean,
    userHasDeletePermissions: boolean,
    ownerLabel: string,
    projectId?: string
): ColumnDefinition<AreaTableRow>[] => [
    {
        field: (d) => d.name,
        text: 'Area',
        key: nameof<AreaTableRow>('name'),
        sortable: true,
        headerClass: styles.minWidth150,
        customSort: (a, b, direction) => {
            if (direction === 'asc') {
                return (
                    a.name!.localeCompare(b.name!) || a.subAreaName!.localeCompare(b.subAreaName!)
                );
            }
            return b.name!.localeCompare(a.name!) || b.subAreaName!.localeCompare(a.subAreaName!);
        },
        filter: selectFilter({
            multi: true,
            options: areaNameOptions,
        }),
    },
    {
        field: (d) => d.subAreaName,
        text: 'Sub Area',
        sortable: true,
        filter: selectFilter({
            multi: true,
            options: subAreaNameOptions,
        }),
        headerClass: styles.minWidth150,
    },
    {
        field: (d) => d.ownerId,
        text: ownerLabel,
        ...getPersonColumn('owner', true, true, projectId),
    } as ColumnDefinition<AreaTableRow>,
    {
        field: (d) => d.description,
        text: 'Description',
        sortable: true,
        filter: textFilter(),
    },
    {
        field: (d) => d.fieldViewProjectName,
        text: 'Field View Project',
        sortable: true,
        filter: textFilter(),
        renderer: (fieldViewProjectName) => (
            <Skeleton.Box height={17} loading={fieldViewProjectName === undefined}>
                {fieldViewProjectName}
            </Skeleton.Box>
        ),
        headerClass: styles.noWrappedWitheSpace,
    },
    {
        field: (d) => d.asiteWorskpaceName,
        text: 'Asite Workspace',
        sortable: true,
        filter: textFilter(),
        headerClass: styles.noWrappedWitheSpace,
        renderer: (asiteWorskpaceName) => (
            <Skeleton.Box height={17} loading={asiteWorskpaceName === undefined}>
                {asiteWorskpaceName}
            </Skeleton.Box>
        ),
    },
    {
        ...actionsColumn,
        hidden: !(userHasDeletePermissions || userHasWritePermissions),
    },
];

const actionsFormatter = (value: string, row: AreaTableRow) => <ActionButtons area={row} />;

const actionsColumn: ColumnDefinition<AreaTableRow> = {
    field: (d) => d.id,
    text: '',
    renderer: actionsFormatter,
    headerClass: styles.minWidth100,
};
