import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker, Form, TextArea, Upload, useForm } from '@laingorourke/core-web-components';
import { PersonSelector } from '@laingorourke/core-web-components-personselector';
import { LorImagesCdn } from 'consts';
import format from 'date-fns/format';
import { Review } from 'domain/models/api-models';
import { usePerson } from 'domain/services/dataService';
import { createReview, updateReview } from 'domain/store/reducers/reviews';
import { useCurrentUser } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalFooter } from 'views/components';
import { ReviewSchema } from '../schemas';
import styles from './UpsertForm.module.scss';

export const filesSvg = `${LorImagesCdn}/files.svg`;
const acceptableFileExtensions = '.pdf,.xlsx,.xls,.doc,.docx,.jpeg,.png,.txt,.ppt,.pptx';

interface UpsertReviewFormProps {
    isEditMode: boolean;
    review: Review | undefined;
    projectNumber: string;
    itemTypeId: string;
    goToReview: () => void;
}

export const UpsertForm: React.FC<UpsertReviewFormProps> = ({
    isEditMode,
    itemTypeId,
    review,
    projectNumber,
    goToReview,
}) => {
    const dispatch = useAppDispatch();

    const currentUser = useCurrentUser();

    // Looks like the Upload component doesn't work well when you have both defaultValue and value, therefore the value cannot be managed by the form
    const existingEvidence = !!review?.evidence ?
        {
            id: review.evidence.id,
            name: review.evidence.name!,
            url: review.evidence.url! || "",
            uploaded: true,
            error: review.evidence.error,
            meta: {
                id: review.evidence.id,
            },
        }
        : undefined;

    const [evidence, setEvidence] = useState<Upload | undefined>(existingEvidence);

    const form = useForm({
        initialValues: {
            id: isEditMode ? review!.id! : '',
            reviewerId: isEditMode ? review!.reviewerId : currentUser.id,
            comments: isEditMode ? review!.comments! : '',
            date: isEditMode ? review!.date! : new Date(),
        },
        validationSchema: ReviewSchema,
        onSubmit: async (values) => {
            let result;
            const dateFormatted = format(new Date(values.date), 'yyyy-MM-dd');
            if (isEditMode) {
                const { ...updateValues } = values;
                result = await dispatch(
                    updateReview({
                        ...updateValues,
                        itemTypeId,
                        projectNumber,
                        date: dateFormatted,
                        file: evidence?.file,
                        removeExistingEvidence: evidence === undefined
                    })
                );
            } else {
                const { id, ...createValues } = values;
                result = await dispatch(
                    createReview({
                        ...createValues,
                        projectNumber,
                        date: dateFormatted,
                        itemTypeId,
                        file: evidence?.file,
                    })
                );
            }
            if ((result as any).payload) goToReview();
        },
    });

    const { isSubmitting, values } = form;
    const userName = usePerson(values.reviewerId);

    return (
        <Form form={form}>
            <Modal.Body>
                <Form.Row>
                    <Form.Field name="reviewerId" label="Reviewer">
                        <PersonSelector
                            contextId={userName.data?.id!}
                            searchOptions={{
                                allowedPeopleIds: [userName.data?.id!],
                            }}
                            disabled
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="date" label="Review Date">
                        <DatePicker append={<FontAwesomeIcon icon={faCalendar} />} />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="comments" label="Comments">
                        <TextArea className={styles.comments} />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Upload
                        accept={acceptableFileExtensions}
                        onAdd={(upload) => {
                            upload.file = new File([upload.file!], upload.name);
                            setEvidence(upload);
                        }}
                        multiple={false}
                        value={!!evidence ? [evidence] : []}
                        overwriteExistingFileOnAdd={true}
                        itemRender={(upload, { handleDownload }, index) => (
                            <Upload.Item
                                className={styles.uploadItem}
                                id={upload.id}
                                isInvalid={!!upload.error}
                                error={upload.error}
                                onRemove={() => setEvidence(undefined)}>
                                {upload.name}
                            </Upload.Item>
                        )}
                    >
                        <Upload.Placeholder
                            image={filesSvg}
                            title="Upload Evidence"
                            description="Drag & drop or click here to upload files"
                        />
                    </Upload>
                </Form.Row>
            </Modal.Body>
            <ModalFooter
                submitText={isEditMode ? 'Update' : 'Create'}
                isSubmitting={isSubmitting}
                onCancel={goToReview}
            />
        </Form>
    );
};
