import { useQuery } from '@tanstack/react-query';
import { useCurrentUser, useIsExternalUser } from 'domain/store/reducers/users';
import { gql } from 'graphql-request';
import { SupplyChainPortalSystemId } from './constants';
import { getQueryResult } from './graphQlClient';
import { PersonGroups } from './models';

const query = gql`
    query getPersonGroups($personId: ID!, $managedBySystemId: ID!) {
        person(id: $personId) {
            groups(type: GatekeeperGroup, managedBySystemId: $managedBySystemId) {
                id
                name
                members {
                    data {
                        id
                        displayName
                    }
                }
            }
        }
    }
`;

export const useGroupMemberIdsFromCurrentUserGroups = (disabled?: boolean) => {
    const currentUser = useCurrentUser();
    const isExternal = useIsExternalUser();
    return useQuery(
        ['person', [currentUser.id]],
        async () => {
            const result = await getQueryResult<{
                person: PersonGroups;
            }>(query, {
                personId: currentUser.id,
                managedBySystemId: SupplyChainPortalSystemId,
            });

            const groups = result.person.groups;

            const allMembers = groups?.flatMap((g) => {
                return g.members?.data.map((m) => m.id) ?? [];
            });

            const setMembers = [...new Set(allMembers)];

            return setMembers;
        },
        {
            enabled: !!currentUser.id && isExternal && !disabled,
        }
    );
};
