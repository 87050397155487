import {
    emojis,
    emojiValidationMessage,
    invalidCharacters,
} from 'views/components/form/validators';
import * as Yup from 'yup';

const requiredMessage = 'At least 1 category is required';
export const InspectionTypeSchema = Yup.object().shape({
    name: Yup.string()
        .matches(emojis, emojiValidationMessage)
        .matches(invalidCharacters, 'Name contains invalid characters')
        .max(100, 'Name cannot be longer than 100 characters')
        .required('Name is required'),
    description: Yup.string()
        .matches(emojis, emojiValidationMessage)
        .matches(invalidCharacters, 'Description contains invalid characters')
        .max(250, 'Description cannot be longer than 250 characters'),
    igmsLink: Yup.string().url('IGMS Link must be valid. Make sure it begins with https://'),
    categoryIds: Yup.array(Yup.string())
        .nullable()
        .min(1, requiredMessage)
        .required(requiredMessage),
    evidenceRequired: Yup.boolean(),
});
