import { Table } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import {
    clearAllProjectsClassifications,
    fetchAllProjectsClassifications,
    useAllProjectsClassifications,
} from 'domain/store/reducers/classifications';
import {
    fetchComplianceTemplates,
    fetchControlMeasureSummaries,
    fetchInspectionTypesSummaries,
    useComplianceTemplates,
    useControlMeasureSummaries,
} from 'domain/store/reducers/complianceTemplates';
import { useItemTypeCategories, useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useProjects } from 'domain/store/reducers/projects/reducer';
import { hasPermission, useCurrentUser } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { getProjectDisplayName } from 'views/components';
import {
    getDefaultPaginationOptions,
    getDefaultSortedByColummKey,
    getFilterOptions,
} from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { ComplianceTemplateProps } from '../../ComplianceTemplateProps';
import { useGenerateComplianceTemplatePath } from '../../hooks/useGenerateComplianceTemplatePath';
import { ComplianceTemplateTableRow } from './ComplianceTemplateTableRow';
import { getTableColumns } from './tableColumns';

const notSetOptionsLabel = 'Project Specific';
const notSetOptionsValue = 'notSet';

interface ComplianceTemplateTableProps extends ComplianceTemplateProps {}

export const ComplianceTemplateTable: React.FC<ComplianceTemplateTableProps> = ({
    isProjectMode,
    itemTypeCode,
    projectNumber,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const user = useCurrentUser();

    const complianceTemplates = useComplianceTemplates();
    const itemTypeId = useSelectedItemTypeId();

    const controlMeasures = useControlMeasureSummaries();
    const projects = useProjects();
    const classifications = useAllProjectsClassifications();
    const categories = useItemTypeCategories();
    const generateComplianceTemplatePath = useGenerateComplianceTemplatePath();
    const dataLoaded =
        !!complianceTemplates && !!classifications && !!categories && !!controlMeasures;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Compliance Templates found');

    const classiticationsSortOrder = classifications?.map((p) => p.name!);
    const categoriesSortOrder = [
        ...(categories
            ?.slice()
            .sort((c) => c.sortOrder)
            .map((p) => p.id!) ?? []),
        notSetOptionsValue,
    ];

    const getClassificationName = (id: string) => classifications?.find((p) => p.id === id)?.name!;
    const showDetails = (complianceTemplateId: string) => {
        history.push(
            generateComplianceTemplatePath((baseRoute) => baseRoute.routes!.details.path, {
                complianceTemplateId,
            })
        );
    };

    const hideEdit = (projNum: string | undefined): boolean => {
        if (isProjectMode && !projNum) return true;
        return !hasPermission(user, Permission.ManageComplianceSettingsWrite, projNum);
    };

    const hideDelete = (projNum: string | undefined): boolean => {
        if (isProjectMode && !projNum) return true;
        return !hasPermission(user, Permission.ManageComplianceSettingsDelete, projNum);
    };

    const complianceTemplatesTableData = complianceTemplates?.map(
        (c) =>
        ({
            ...c,
            projectName: getProjectDisplayName(c.projectNumber, projects),
            categoryId: c.categoryId ?? notSetOptionsValue,
            categoryName: c.categoryId ? c.categoryName : notSetOptionsLabel,
            classificationName: getClassificationName(c.classificationId),
            inspectionsCount: c.inspectionTypes!.length,
            controlMeasuresCount: c.controlMeasuresIds!.length,
            itemTypeCode,
            showDetails: () => showDetails(c.id),
            hideEdit: hideEdit(c.projectNumber),
            hideDelete: hideDelete(c.projectNumber),
        } as ComplianceTemplateTableRow)
    );

    const complianceTemplatesAsString = JSON.stringify(complianceTemplates);
    const hasNullableCategories =
        complianceTemplates && complianceTemplates.filter((c) => !c.categoryId).length > 0;

    const categoryOptions = useMemo(() => {
        let result = [] as OptionTypeBase[];
        result =
            categories && complianceTemplates
                ? categories
                    .filter(
                        (c) => complianceTemplates?.map((c) => c.categoryId).indexOf(c.id) > -1
                    )
                    .map((v) => {
                        let label = v.name;
                        if (hasNullableCategories && v.id === notSetOptionsValue) {
                            label = notSetOptionsLabel;
                        }
                        return { label: label, value: v.id };
                    })
                : [];

        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataLoaded, itemTypeId, complianceTemplatesAsString]);

    const classificationOptions = getFilterOptions(
        complianceTemplatesTableData,
        'classificationName',
        classiticationsSortOrder
    );

    const columns = getTableColumns(
        categoryOptions,
        categoriesSortOrder,
        classificationOptions,
        classiticationsSortOrder,
        classifications,
        complianceTemplatesTableData?.some((c) => !c.hideEdit || !c.hideDelete)
    );

    useEffect(() => {
        dispatch(fetchComplianceTemplates({ projectNumber, itemTypeId }));
        dispatch(fetchControlMeasureSummaries());
        dispatch(fetchAllProjectsClassifications({ itemTypeId }));
        dispatch(fetchInspectionTypesSummaries());
        return () => {
            dispatch(clearAllProjectsClassifications());
        };
    }, [itemTypeId, dispatch]);

    return (
        <>
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(
                    columns,
                    nameof<ComplianceTemplateTableRow>('categoryId')
                )}
                {...tableLoadingProps}
                // this is far from ideal but this is really the only way to "enfore re-sort" once all categories are loaded
                // this is obviously not enforcing sort, but rerendering an entire component
                // Something like (table.current as any).sortContext.handleSort(columns[0]); cannot be used
                // as this just simulates "click" and doesn't consider sort order (asc / desc) and might lead to bugs
                // bootstrap react table doesn't support it in any other way
                key={categories?.length}
                data={dataLoaded ? complianceTemplatesTableData : []}
                keySelector={(d) => d.id}
                columns={columns}
                saveSelectedPageSize
                cacheKey="compliance-templates"
                clickable
                onRowClick={(r) => r.showDetails()}
                hover
            />
        </>
    );
};
