import { useQuery } from '@tanstack/react-query';
import { useCurrentUser, useIsExternalUser } from 'domain/store/reducers/users';
import { gql } from 'graphql-request';
import { SupplyChainPortalSystemId } from './constants';
import { getQueryResult } from './graphQlClient';
import { PersonGroups } from './models';

const query = gql`
    query getPersonGroups($personId: ID!, $managedBySystemId: ID!) {
        person(id: $personId) {
            groups(type: GatekeeperGroup, managedBySystemId: $managedBySystemId) {
                id
                name
            }
        }
    }
`;

export const useCurrentExternalUserGroups = () => {
    const currentUser = useCurrentUser();
    const isExternal = useIsExternalUser();
    return useQuery(
        ['person', [currentUser.id]],
        async () => {
            const result = await getQueryResult<{
                person: PersonGroups;
            }>(query, {
                personId: currentUser.id,
                managedBySystemId: SupplyChainPortalSystemId,
            });
            return result.person.groups;
        },
        {
            enabled: !!currentUser.id && isExternal,
        }
    );
};
