import { generatePath } from '@laingorourke/core-web-components';
import { ControlMeasure } from 'domain/models/api-models';
import { useAllProjectsClassifications } from 'domain/store/reducers/classifications';
import { useControlMeasure } from 'domain/store/reducers/controlMeasure';
import {
    fetchProjectStatuses,
    useItemStatuses,
    useSelectedItemTypeId,
} from 'domain/store/reducers/itemType';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { AdminRouteParams } from '../../AdminRouteParams';
import styles from './AddControlMeasure.module.scss';
import { UpsertForm } from './UpsertForm';

interface ControlMeasureParams extends AdminRouteParams {
    controlMeasureId: string;
}

export const UpsertControlMeasure: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { controlMeasureId, itemTypeCode } = useParams<ControlMeasureParams>();

    const controlMeasure: ControlMeasure | undefined = useControlMeasure(controlMeasureId);

    const isEditMode = controlMeasure !== undefined;
    const itemTypeId = useSelectedItemTypeId();
    const itemStatuses = useItemStatuses();
    const classifications = useAllProjectsClassifications()?.filter((c) => c.isActive);

    const goToControlMeasures = () =>
        history.push(generatePath(routes.admin.routes!.controlMeasures.path, { itemTypeCode }));

    useEffect(() => {
        dispatch(fetchProjectStatuses({ itemTypeId: itemTypeId! }));
    }, [itemTypeId, dispatch]);

    return (
        <>
            {(controlMeasureId === undefined || controlMeasure !== undefined) && !!classifications && (
                <Modal show={true} onHide={goToControlMeasures} animation={false} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title className={styles.title}>
                            {isEditMode ? 'Edit' : 'New'} Control Measure
                        </Modal.Title>
                    </Modal.Header>
                    <SecuredContent
                        permissions={Permission.ManageComplianceSettingsWrite}
                        projectNumber={controlMeasure?.projectNumber}
                        ignoreSecurable={!isEditMode}>
                        <UpsertForm
                            controlMeasure={controlMeasure}
                            isEditMode={isEditMode}
                            goToControlMeasures={goToControlMeasures}
                            itemStatuses={itemStatuses}
                            allClassifications={classifications}
                        />
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
