import { faStream } from '@fortawesome/free-solid-svg-icons';
import { Button, generatePath, Select } from '@laingorourke/core-web-components';
import { clearAreas } from 'domain/store/reducers/areas';
import { clearComplianceTemplates } from 'domain/store/reducers/complianceTemplates';
import { clearControlMeasures } from 'domain/store/reducers/controlMeasure';
import { clearInspectionTypes } from 'domain/store/reducers/inspectionType';
import { clearIntegrations } from 'domain/store/reducers/integrations';
import {
    fetchMetadata,
    setItemTypeId,
    useItemTypes,
    useSelectedItemTypeCode,
    useSelectedItemTypeCodeIsSet,
    useSelectedItemTypeId,
} from 'domain/store/reducers/itemType';
import { clearProjectLeadDays } from 'domain/store/reducers/projectLeadDays';
import { clearProjectItems } from 'domain/store/reducers/projects';
import { clearReviews } from 'domain/store/reducers/reviews';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styles from './ItemTypeSelector.module.scss';

export const ItemTypeSelector: React.FC = () => {
    const params = useParams<{ itemTypeCode: string }>();
    const itemTypes = useItemTypes();
    const history = useHistory();
    const location = useLocation();
    const selectedItemTypeId = useSelectedItemTypeId();
    const selectedItemTypeCode = useSelectedItemTypeCode();
    const isSelectedItemTypeCodeIsSet = useSelectedItemTypeCodeIsSet();
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const setSelectedItem = (value: any) => {
        if (value === selectedItemTypeId) return;
        dispatch(setItemTypeId(value));
        dispatch(clearAreas());
        dispatch(clearComplianceTemplates());
        dispatch(clearControlMeasures());
        dispatch(clearInspectionTypes());
        dispatch(clearProjectItems());
        dispatch(clearIntegrations());
        dispatch(clearReviews());
        dispatch(clearProjectLeadDays());
    };

    // we want to change item type in the current url
    useEffect(() => {
        if (isSelectedItemTypeCodeIsSet)
            history.push(
                generatePath(
                    location.pathname.replace(
                        `/${params.itemTypeCode}/`,
                        `/${selectedItemTypeCode}/`
                    )
                )
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemTypeCode, history]);

    useEffect(() => {
        dispatch(fetchMetadata({ itemTypeId: selectedItemTypeId }));
    }, [selectedItemTypeId, dispatch]);

    return (
        itemTypes && (
            <Select
                className={styles.itemType}
                searchable={false}
                value={selectedItemTypeId}
                onChange={setSelectedItem}
                options={itemTypes}
                onBlur={() => setIsOpen(false)}
                onMenuClose={() => setIsOpen(false)}
                dataShape={{
                    value: 'id',
                    label: 'name',
                }}
                components={{
                    Control: ({ children, innerRef, innerProps }: any) => (
                        <div ref={innerRef} {...innerProps}>
                            <Button
                                className={styles.selectorBtn}
                                icon={{
                                    icon: faStream as any,
                                    size: 'lg',
                                }}
                                onClick={() => setIsOpen((o) => !o)}>
                                {children}
                            </Button>
                        </div>
                    ),
                }}
                styles={{
                    dropdownIndicator: (base: any) => ({
                        ...base,
                        padding: 0,
                        paddingRight: 4,
                    }),
                    clearIndicator: (base: any) => ({
                        ...base,
                        padding: 0,
                        paddingRight: 4,
                    }),
                }}
            />
        )
    );
};
