import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Checkbox,
    DatePicker,
    Form,
    Input,
    RecurrenceBuilder,
    Select,
    useForm,
} from '@laingorourke/core-web-components';
import { SCHEDULEKEY, Schedule } from '@laingorourke/core-web-mytasksreactsdk';
import { Recurrence } from '@laingorourke/core-web-types';
import { useQueryClient } from '@tanstack/react-query';
import format from 'date-fns/format';
import startOfToday from 'date-fns/startOfToday';
import {
    createProjectItemSchedule,
    updateProjectItemSchedule,
    updateProjectItemScheduleAssignee,
} from 'domain/store/reducers/projects';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Permission } from 'security/Permission';
import { ModalFooter, ProjectPersonSelector, TooltipWrapper } from 'views/components';
import { DateInPastWarning } from '../../info/controlMeasures/components/DateInPastWarning';
import { getScheduleSchema } from '../schemas';
import styles from './CreateForm.module.scss';

interface CreateFormProps {
    inspectionTypes: { id: string; name: string }[];
    dateInService: Date;
    projectNumber: string;
    projectItemId: string;
    isEditMode: boolean;
    goBack: () => void;
    schedule?: Schedule | null;
}

export const CreateForm: React.FC<CreateFormProps> = ({
    inspectionTypes,
    dateInService,
    projectNumber,
    projectItemId,
    isEditMode,
    schedule,
    goBack,
}) => {
    const dispatch = useAppDispatch();
    const currentUserHasManageInspectionsWritePermission = useHasPermissions(
        Permission.ManageInspectionsWrite,
        projectNumber
    );
    const isComplianceTemplateCreatedSchedule =
        isEditMode &&
        !schedule?.meta.find(
            (m) => m.label.toLowerCase() === 'source' && m.value.toLowerCase() === 'manual'
        );
    const disableEditing = isComplianceTemplateCreatedSchedule && !currentUserHasManageInspectionsWritePermission;
    const queryClient = useQueryClient();
    const today = startOfToday();
    const form = useForm({
        initialValues: {
            inspectionTypeId:
                schedule?.meta.find((m) => m.label === 'InspectionTypeId')?.value ?? '',
            assignee: schedule?.template.assignedUserId ?? '',
            // MyTasks keeps offset as a negative number, we want to display positive
            addBeforeDueDays: schedule?.offset ? schedule?.offset * -1 : 14,
            startDate:
                schedule?.startDate ?? (new Date(dateInService) < today ? today : dateInService),
            endDate: schedule?.endDate ?? '',
            recurrence: (schedule?.recurrence ?? null) as unknown as Recurrence,
        },
        validationSchema: getScheduleSchema(schedule?.startDate),
        onSubmit: async (values) => {
            let result;
            if (disableEditing) {
                result = await dispatch(
                    updateProjectItemScheduleAssignee({
                        scheduleId: schedule!.id,
                        projectNumber: projectNumber,
                        projectItemId: projectItemId,
                        assigneeId: values.assignee,
                    })
                );
            } else if (isEditMode) {
                result = await dispatch(
                    updateProjectItemSchedule({
                        scheduleId: schedule!.id,
                        projectNumber: projectNumber,
                        projectItemId: projectItemId,
                        inspectionTypeId: values.inspectionTypeId,
                        assigneeId: values.assignee,
                        startDate: format(new Date(values.startDate), 'yyyy-MM-dd'),
                        endDate: !!values.endDate
                            ? format(new Date(values.endDate), 'yyyy-MM-dd')
                            : undefined,
                        addBeforeDays: values.addBeforeDueDays,
                    })
                );
            } else {
                result = await dispatch(
                    createProjectItemSchedule({
                        projectNumber: projectNumber,
                        projectItemId: projectItemId,
                        inspectionTypeId: values.inspectionTypeId,
                        recurrence: values.recurrence,
                        assigneeId: values.assignee,
                        startDate: format(new Date(values.startDate), 'yyyy-MM-dd'),
                        endDate: !!values.endDate
                            ? format(new Date(values.endDate), 'yyyy-MM-dd')
                            : undefined,
                        addBeforeDays: values.addBeforeDueDays,
                    })
                );
            }
            if ((result as any).payload) {
                queryClient.invalidateQueries(SCHEDULEKEY());
                goBack();
            }
        },
    });

    const { isSubmitting, values } = form;

    return (
        <Form form={form}>
            <Modal.Body>
                <Form.Row>
                    <Form.Field
                        name="inspectionTypeId"
                        label="Inspection Type"
                        disabled={isComplianceTemplateCreatedSchedule || isEditMode}>
                        <Select
                            options={inspectionTypes}
                            dataShape={{
                                value: 'id',
                                label: 'name',
                            }}
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="assignee" label="Assignee">
                        <ProjectPersonSelector
                            projectNumber={projectNumber}
                            placeholder="Select Assignee..."
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field
                        name="recurrence"
                        label=""
                        disabled={isComplianceTemplateCreatedSchedule || isEditMode}>
                        <RecurrenceBuilder />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <span className={styles.info}>Calculated off 7 day week</span>
                </Form.Row>
                <Form.Row>
                    <Form.Field
                        name="startDate"
                        label="Start Date"
                        disabled={disableEditing}>
                        <DatePicker append={<FontAwesomeIcon icon={faCalendar} />} />
                    </Form.Field>
                </Form.Row>
                <DateInPastWarning dates={values.startDate} label="Start Date" />
                <Form.Row>
                    <Form.Field
                        name="endDate"
                        label="End Date (Optional)"
                        disabled={disableEditing}>
                        <DatePicker append={<FontAwesomeIcon icon={faCalendar} />} />
                    </Form.Field>
                </Form.Row>
                <DateInPastWarning dates={values.endDate} label="End Date" />
                <Form.Row className={styles.addBeforeDueDays}>
                    <TooltipWrapper
                        tooltipContent="Tasks must be added before the Due Date"
                        id="add-before-due-days-tooltip"
                        show={true}
                        placement="right">
                        <Checkbox value={true} disabled={true} />
                    </TooltipWrapper>
                    <span>Add tasks</span>
                    <Form.Field
                        name="addBeforeDueDays"
                        label=""
                        className={styles.addBeforeDueDaysInput}>
                        <Input
                            className={styles.addBeforeDueDays}
                            disabled={disableEditing}
                        />
                    </Form.Field>
                    <span>days before due days</span>
                </Form.Row>
            </Modal.Body>
            <ModalFooter
                submitText={isEditMode ? 'Update' : 'Create'}
                isSubmitting={isSubmitting}
                onCancel={goBack}
            />
        </Form>
    );
};
