import { ColumnDefinition, textFilter } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { ItemTypeCategory } from 'domain/models/api-models';
import { nameof } from 'helpers/nameOf';
import { EvidenceRequiredCell } from 'views/components';
import { Link } from 'views/components/links';
import { getCategoriesColumn } from 'views/components/table/categoriesColumn';
import styles from '../../../components/table/Table.module.scss';
import ActionButtons from './components/ActionButtons';
import inspectionStyles from './InspectionTypes.module.scss';
import { InspectionTypeTableRow } from './InspectionTypeTableRow';

const actionsFormatter = (cell: any, row: InspectionTypeTableRow) => (
    <ActionButtons inspectionType={row} />
);
const linkFormatter = (cell: any, row: InspectionTypeTableRow) =>
    row.igmsLink && row.igmsLink ? (
        <Link href={row.igmsLink} target="_blank">
            Link
        </Link>
    ) : (
        ''
    );

const actionsColumn: ColumnDefinition<InspectionTypeTableRow> = {
    field: (d) => d.id,
    text: '',
    renderer: actionsFormatter,
    headerClass: styles.minWidth150,
};
export const getTableColumns = (
    inspectionTypesTableData: InspectionTypeTableRow[] | undefined,
    categoryOptions: OptionTypeBase[],
    categories: ItemTypeCategory[] | undefined,
    showActions: boolean
): ColumnDefinition<InspectionTypeTableRow>[] => [
    getCategoriesColumn(inspectionTypesTableData, categoryOptions, categories, 'categoryIds'),
    {
        field: (d) => d.name,
        text: 'Name',
        sortable: true,
        filter: textFilter(),
        headerClass: styles.width300,
        key: nameof<InspectionTypeTableRow>('name'),
        renderer: (_: any, row) => (
            <EvidenceRequiredCell id={row.id} evidenceRequired={row.evidenceRequired}>
                {row.name}
            </EvidenceRequiredCell>
        ),
    },
    {
        field: (d) => d.description,
        text: 'Description',
        sortable: true,
        filter: textFilter(),
    },
    {
        field: (d) => d.igmsLink,
        text: 'iGMS Link',
        renderer: linkFormatter,
        headerClass: inspectionStyles.igmsLinkHeaderCell,
    },
    {
        ...actionsColumn,
        hidden: !showActions,
    },
];
