import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { P6Integration } from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import {
    activateP6Integration,
    deactivateP6Integration,
    fetchP6ProjectIntegration,
} from './actions';

const initialState = {
    p6ProjectIntegration: null as unknown as P6Integration,
};

const p6ProjectIntegrationSlice = createSlice({
    name: 'p6ProjectIntegration',
    initialState: initialState,
    reducers: {
        clearP6ProjectIntegrations() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchP6ProjectIntegration.fulfilled,
            (state, action: PayloadAction<P6Integration>) => {
                state.p6ProjectIntegration =
                    action.payload ??
                    ({
                        isActive: false,
                    } as P6Integration);
            }
        );
        builder.addCase(activateP6Integration.fulfilled.type, (state) => {
            state.p6ProjectIntegration.isActive = true;
        });
        builder.addCase(deactivateP6Integration.fulfilled.type, (state) => {
            state.p6ProjectIntegration.isActive = false;
        });
    },
});

export const useP6ProjectIntegration = () => {
    return useSelector((state: RootState) => state.p6ProjectIntegration.p6ProjectIntegration);
};

export const { clearP6ProjectIntegrations } = p6ProjectIntegrationSlice.actions;
export default p6ProjectIntegrationSlice.reducer;
