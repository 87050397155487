import { useParams } from 'react-router-dom';
import { routes } from 'views/routes/Routes';
import { SharedRouteParams } from '../../SharedRouteParams';

export const useBaseComplianceTemplateRoute = () => {
    const sharedRouteParams = useParams<SharedRouteParams>();
    const isProjectMode = !!sharedRouteParams.projectNumber;

    return isProjectMode
        ? routes.projects.routes!.project.routes!.projectSetup.routes!.complianceTemplates
        : routes.admin.routes!.complianceTemplates;
};
