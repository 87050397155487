import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { PersonInfo } from 'hooks/useWithPerson';
import { ComplySystemId } from './constants';
import { getQueryResult } from './graphQlClient';

const query = gql`
    query getSystemRolesUserPermmisions($projectNumber: String!) {
        roles(roleWithSystemIdPermissions: "${ComplySystemId}") {
            totalCount
            data {
                id
                name
                isActive
                securables(securableId: $projectNumber, securableTypes: [All, Project]) {
                    id
                    typeId
                    users {
                        userId
                        approvalTaskId
                        isActive
                        approvalRequired
                        person {
                            isActive
                            id
                            displayName
                            company
                            type
                            photo
                            jobTitle
                            external {
                                type
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const useSystemRolesUserPermmisions = (projectNumber: string) => {
    return useQuery(['roles'], async () => {
        const result = await getQueryResult<SystemRolesUserPermmisions>(query, {
            projectNumber: projectNumber,
        });

        return result.roles.data.flatMap((r) => {
            const users = r.securables.flatMap((s) => {
                return s.users
                    .filter((s) => s.isActive)
                    .map((u) => {
                        const { isActive, ...userData } = u;
                        return {
                            ...userData,
                            securableTypeId: s.typeId,
                            securableId: s.id,
                            securableIsActive: u.isActive,
                        };
                    });
            });

            return users
                .filter((u) => !!u.userId && !!u.person)
                .map((u) => {
                    return {
                        ...u,
                        roleId: r.id,
                        roleName: r.name,
                        roleIsActive: r.isActive,
                        person: {
                            ...u.person,
                            isLoading: false,
                            isNotFound: false,
                            isExternal: !!u.person.external,
                        },
                    };
                });
        });
    });
};

export interface SystemRolesUserPermmisions {
    roles: Roles;
}

export interface Roles {
    totalCount: number;
    data: Role[];
}
export interface Role {
    id: string;
    name: string;
    isActive: boolean;
    securables: Securable[];
}

export interface Securable {
    id: string;
    typeId: string;
    users: User[];
}

export interface User {
    userId: string;
    isActive: boolean;
    approvalRequired: boolean;
    approvalTaskId?: string;
    person: PersonInfo;
}
