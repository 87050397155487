import { youAreOfflineFeatureNotAvailableMessage } from '@laingorourke/core-web-components';
import {
    allItemBlockedBackgroundProcessMessage,
    allItemsLockedDueToPermissionMessage,
} from '../details/messages';
import { concatMessage, getItemsNotModifiableStatusMessage } from '../details/messagesHelper';
import { ProjectItemTableRow } from './ProjectItemTableRow';

export const itemIsModifiable = (item: ProjectItemTableRow) =>
    item.canBeModified && !item.myTasksOperationsBlocked && !item.detailAccessLocked;

export const getAllItemsDisableddMessage = (
    nonEditableStatusesMessage: string,
    isOffline: boolean,
    projectItems: ProjectItemTableRow[]
) => {
    const numberOfNonModifiableItems = projectItems.filter((i) => !i.canBeModified).length;
    const allItemsAreNotModifiable =
        !!numberOfNonModifiableItems && numberOfNonModifiableItems === projectItems.length;

    const numberOfItemsWithLockedAccess = projectItems.filter((i) => i.detailAccessLocked).length;
    const allItemWithLockedAccess =
        !!numberOfItemsWithLockedAccess && numberOfItemsWithLockedAccess === projectItems?.length;

    const numberOfItemsWithBlockedMyTasksOperations = projectItems.filter(
        (i) => i.myTasksOperationsBlocked
    ).length;
    const allItemsBlocked =
        !!numberOfItemsWithBlockedMyTasksOperations &&
        numberOfItemsWithBlockedMyTasksOperations === projectItems?.length;

    if (isOffline) return youAreOfflineFeatureNotAvailableMessage;
    if (allItemsBlocked) return allItemBlockedBackgroundProcessMessage;
    if (allItemWithLockedAccess) return allItemsLockedDueToPermissionMessage;
    if (allItemsAreNotModifiable)
        return getItemsNotModifiableStatusMessage(nonEditableStatusesMessage);

    const hasItemWithNotAccess =
        numberOfItemsWithLockedAccess > 0
            ? "you don't have permission to view or edit some items"
            : '';
    const someItemBlocked =
        !!numberOfItemsWithBlockedMyTasksOperations && numberOfItemsWithBlockedMyTasksOperations > 0
            ? 'there are blocked items due to background processing'
            : '';
    const cannotBeModifiedDueStatus = numberOfNonModifiableItems
        ? `some of them are of status ${nonEditableStatusesMessage}`
        : '';

    const allMessages = [hasItemWithNotAccess, someItemBlocked, cannotBeModifiedDueStatus];

    return concatMessage('You can not select any item as', allMessages);
};
