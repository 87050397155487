import { Form, Input, Select, WarningPanel, useForm } from '@laingorourke/core-web-components';
import { TASKKEY, TaskHealth } from '@laingorourke/core-web-mytasksreactsdk';
import { useQueryClient } from '@tanstack/react-query';
import { moveTasksToWontDo } from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import { getFormattedDate } from 'helpers/utils';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalFooter } from 'views/components';
import {
    emojiValidationMessage,
    emojis,
    invalidCharacters,
} from 'views/components/form/validators';
import { SelectedItems } from 'views/components/selectedItems';
import * as Yup from 'yup';
import { UpcomingTaskTableRow } from '../UpcomingTaskTableRow';
import styles from './ChangeStatus.module.scss';

interface ChangeStatusProps {
    projectItemId: string;
    projectNumber: string;
    tasks: UpcomingTaskTableRow[];
    show: boolean;
    onClose: (success: boolean) => void;
}

export const ChangeStatus: React.FC<ChangeStatusProps> = ({
    projectItemId,
    projectNumber,
    tasks,
    show,
    onClose,
}) => {
    const dispatch = useAppDispatch();
    const tasksIds = tasks.map((t) => t.id);
    const queryClient = useQueryClient();

    let timer: NodeJS.Timeout;
    const form = useForm({
        initialValues: {
            reason: '',
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string()
                .nullable()
                .matches(emojis, emojiValidationMessage)
                .matches(invalidCharacters, 'Reason contains invalid characters')
                .min(3, 'Reason cannot be shorter than 3 characters')
                .max(1000, 'Reason cannot be longer than 1000 characters')
                .required('Reason is required'),
        }),
        onSubmit: async (values) => {
            const result = await dispatch(
                moveTasksToWontDo({
                    reason: values.reason,
                    tasksIds,
                    projectItemId,
                    projectNumber,
                })
            );
            if ((result as any).payload) {
                queryClient.invalidateQueries(TASKKEY());
                onClose(true);

                // there is no pulling mechanism that would get refreshed MyTasks tasks. We will wait 5 sec and we will invalidate the query that will refresh results.
                // it it takes more time to service bus messages to process the data, the user has to update the page manually
                const refetchDelay = 5000;
                timer = setTimeout(() => {
                    queryClient.invalidateQueries(TASKKEY());
                }, refetchDelay);
            }
        },
    });

    const { isSubmitting } = form;

    const selectedNotOverdueTasks = tasks.some(t => t.health !== TaskHealth.Overdue);

    useEffect(() => {
        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <>
            <Modal show={show} onHide={() => onClose(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Change Status</Modal.Title>
                </Modal.Header>
                <Form form={form}>
                    <Modal.Body>
                        <Form.Row>
                            <Select
                                disabled
                                value=""
                                options={[{ label: 'Will Not Occur', value: '' }]}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Form.Field name="reason" label="Reason">
                                <Input />
                            </Form.Field>
                        </Form.Row>
                        {selectedNotOverdueTasks &&
                            <WarningPanel className={styles.warning}>
                                You have selected some Inspections that are not Overdue
                            </WarningPanel>
                        }
                        <SelectedItems<UpcomingTaskTableRow>
                            entities={tasks}
                            selectedEntityName="Task"
                            entityRenderer={(task) => (
                                <>
                                    {!!task.forecastedOn ? (
                                        getFormattedDate(task.forecastedOn!)
                                    ) : (
                                        <i>Date Not Available</i>
                                    )}
                                    - {task.name}
                                </>
                            )}
                        />
                    </Modal.Body>
                    <ModalFooter
                        submitText="Update"
                        isSubmitting={isSubmitting}
                        onCancel={() => onClose(false)}
                    />
                </Form>
            </Modal>
        </>
    );
};
