import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InspectionType } from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import {
    createInspectionType,
    deleteInspectionType,
    fetchInspectionTypes,
    updateInspectionType,
} from './actions';

const initialState = {
    inspectionTypes: (undefined as unknown) as InspectionType[],
};

const inspectionTypesSlice = createSlice({
    name: 'inspectionTypes',
    initialState: initialState,
    reducers: {
        clearInspectionTypes() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchInspectionTypes.fulfilled.type,
            (state, action: PayloadAction<InspectionType[]>) => {
                state.inspectionTypes = action.payload;
            }
        );
        builder.addCase(
            createInspectionType.fulfilled.type,
            (state, action: PayloadAction<InspectionType>) => {
                if (action.payload === undefined) return;
                state.inspectionTypes.push(action.payload);
            }
        );
        builder.addCase(
            updateInspectionType.fulfilled.type,
            (state, action: PayloadAction<InspectionType>) => {
                if (action.payload === undefined) return;
                state.inspectionTypes = state.inspectionTypes.map((c) =>
                    c.id === action.payload.id ? action.payload : c
                );
            }
        );
        builder.addCase(
            deleteInspectionType.fulfilled.type,
            (state, action: PayloadAction<string>) => {
                if (action.payload === undefined) return;
                state.inspectionTypes = state.inspectionTypes.filter(
                    (i) => i.id !== action.payload
                );
            }
        );
    },
});

export const useInspectionTypes = () =>
    useSelector((state: RootState) => state.inspectionTypes.inspectionTypes);

export const useInspectionType = (id: string) =>
    useSelector((state: RootState) =>
        state.inspectionTypes.inspectionTypes?.find((cm) => cm.id === id)
    );

export const { clearInspectionTypes } = inspectionTypesSlice.actions;
export default inspectionTypesSlice.reducer;
