import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React from 'react';
import styles from './InfoIcon.module.scss';

interface InfoIconProps extends Omit<FontAwesomeIconProps, 'icon'> {}

export const InfoIcon: React.FC<InfoIconProps> = (props) => {
    const { className, ...rest } = props;
    return (
        <FontAwesomeIcon className={cn(styles.infoIcon, className)} icon={faInfoCircle} {...rest} />
    );
};
