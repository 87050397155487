import { ColumnDefinition, selectFilter, textFilter } from '@laingorourke/core-web-components';
import { nameof } from 'helpers/nameOf';
import { ProjectDisplayName, Site } from 'views/components';
import { DateTableCell, getFilterOptionsFromArray } from 'views/components/table';
import styles from 'views/components/table/Table.module.scss';
import { Unit } from 'views/routes/admin/weather/Unit';
import { WeatherEventType } from 'views/routes/admin/weather/WeatherEvent';
import { WeatherEventTableRow } from './WeatherEventTableRow';
export const getTableColumns = (
    showProjectNumber: boolean
): ColumnDefinition<WeatherEventTableRow>[] => [
    ...(showProjectNumber
        ? [
              {
                  field: (d) => d.projectName,
                  text: 'Project',
                  sortable: true,
                  filter: textFilter(),
                  renderer: (_, row: WeatherEventTableRow) => (
                      <ProjectDisplayName projectNumber={row.projectNumber} />
                  ),
              } as ColumnDefinition<WeatherEventTableRow>,
          ]
        : []),
    {
        field: (d) => d.siteName,
        text: 'Site',
        sortable: true,
        filter: textFilter(),
        renderer: (_: any, row: WeatherEventTableRow) => (
            <Site projectNumber={row.projectNumber!} siteId={row.siteId} />
        ),
    },
    {
        field: (d) => d.date,
        text: 'Date',
        key: nameof<WeatherEventTableRow>('date'),
        sortable: true,
        renderer: (_: any, row: WeatherEventTableRow) => <DateTableCell date={row.date} />,
    },
    {
        field: (d) => d.weatherEventType,
        text: 'Type',
        sortable: true,
        filter: selectFilter({
            options: getFilterOptionsFromArray(Object.values(WeatherEventType)),
        }),
        renderer: (_: any, row: WeatherEventTableRow) =>
            WeatherEventType[row.weatherEventType as keyof typeof WeatherEventType],
    },
    {
        field: (d) => d.recordedValue,
        text: 'Recorded Measure',
        sortable: true,
        cellClass: styles.noWrappedWitheSpace,
        renderer: (_: any, row: WeatherEventTableRow) => `${row.recordedValue} ${Unit[row.unit]}`,
    },
];
