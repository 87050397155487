import { createAsyncThunk } from '@reduxjs/toolkit';
import { Review } from 'domain/models/api-models';
import { get, remove } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { getHeaders } from '../fileUpload';
import { addNotification } from '../notificationsReducer';

interface FetchParams {
    projectNumber: string;
    itemTypeId: string;
}

export const fetchReviews = createAsyncThunk<Review[], FetchParams, { state: RootState }>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/reviews/get',
    async (args) => {
        return (await get(`itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/reviews`))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface CreateReview {
    itemTypeId: string;
    projectNumber: string;
    reviewerId?: string;
    date?: string;
    comments?: string;
    file?: File;
}

export const createReview = createAsyncThunk<Review, CreateReview, { state: RootState }>(
    'itemtypes/{itemTypeId}/project/{projectNumber}/reviews/post',
    async (args, { dispatch, getState }) => {
        return await runSafe(async () => {
            const url = `/api/itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/reviews`;
            const headers = await getHeaders();
            const formData = new FormData();
            if (!!args.file) formData.set('evidence', args.file);

            formData.set('date', args.date ?? '');
            formData.set('comments', args.comments ?? '');
            formData.set('reviewerId', args.reviewerId ?? '');

            return fetch(url, { method: 'POST', headers: headers, body: formData }).then(
                async (response) => {
                    if (!response.ok) {
                        const message = await response.json();
                        throw new Error(message.Title);
                    }
                    dispatch(
                        addNotification({
                            message: 'Review has been created successfully',
                            isSuccess: true,
                        })
                    );
                    return await response.json();
                }
            );
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface UpdateReview {
    itemTypeId: string;
    projectNumber: string;
    id: string;
    reviewerId?: string;
    date?: string;
    comments?: string;
    file?: File;
    removeExistingEvidence: boolean;
}

export const updateReview = createAsyncThunk<Review, UpdateReview, { state: RootState }>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/reviews/patch',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const url = `/api/itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/reviews/${args.id}`;
            const headers = await getHeaders();
            const formData = new FormData();
            if (!!args.file) formData.set('evidence', args.file);

            formData.set('date', args.date ?? '');
            formData.set('comments', args.comments ?? '');
            formData.set('removeExistingEvidence', args.removeExistingEvidence.toString());

            return fetch(url, { method: 'PATCH', headers: headers, body: formData }).then(
                async (response) => {
                    if (!response.ok) {
                        const message = await response.json();
                        throw new Error(message.Title);
                    }
                    dispatch(
                        addNotification({
                            message: 'Review has been updated successfully',
                            isSuccess: true,
                        })
                    );
                    return await response.json();
                }
            );
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface DeleteParams {
    projectNumber: string;
    itemTypeId: string;
    reviewId: string;
}

export const deleteReview = createAsyncThunk<string, DeleteParams, { state: RootState }>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/reviews/delete',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await remove(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/reviews/${args.reviewId}`
            );
            dispatch(
                addNotification({
                    message: 'Review has been deleted successfully',
                    isSuccess: true,
                })
            );
            return args.reviewId;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber && !!args.reviewId,
    }
);
