import cn from 'classnames';
import React, { PropsWithChildren } from 'react';
import styles from './GenericLink.module.scss';

export const Link: React.FC<PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>>> = ({
    className,
    children,
    ...props
}) => {
    return (
        <a className={cn(className, styles.link)} {...props}>
            {children}
        </a>
    );
};
