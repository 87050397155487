import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Button, Form, Input, Select } from '@laingorourke/core-web-components';
import { InspectionTypeSummary } from 'domain/models/api-models';
import { useSelectedItemTypeCode } from 'domain/store/reducers/itemType';
import { FieldArrayRenderProps } from 'formik';
import { default as React } from 'react';
import { AddButton } from 'views/components';
import { RouteLink } from 'views/components/links';
import { routes } from 'views/routes/Routes';
import {
    ComplianceTemplateFormikProps,
    InspectionTypeFormikProps,
} from './ComplianceTemplateFormikProps';
import styles from './InspectionTypeFieldArray.module.scss';

const getFieldName = (index: number, propName: 'inspectionTypeId' | 'frequency') =>
    `inspections[${index}].${propName}`;

interface InspectionTypeFieldArrayProps {
    values: ComplianceTemplateFormikProps;
    errors: any;
    touched: any;
    arrayHelpers: FieldArrayRenderProps;
    inspectionTypes: InspectionTypeSummary[];
    getInspectionsToDisplay: (categoryId: string | null) => InspectionTypeSummary[];
}

export const InspectionTypeFieldArray: React.FC<InspectionTypeFieldArrayProps> = ({
    inspectionTypes,
    values,
    getInspectionsToDisplay,
    arrayHelpers,
}) => {
    const itemTypeCode = useSelectedItemTypeCode();
    const inspectionsToDispaly = getInspectionsToDisplay(values.categoryId)!;

    const onAdd = () => arrayHelpers.push({ inspectionTypeId: undefined, frequency: undefined });

    const onRemove = (inspectionType: InspectionTypeFormikProps) => {
        const idx = values.inspections.indexOf(inspectionType);
        arrayHelpers.remove(idx);
    };

    return (
        <div className={styles.col1}>
            <table className={styles.inspectionsTypes}>
                <thead>
                    <tr>
                        <th>Inspection Type</th>
                        <th>Frequency</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {values.inspections?.map((inspectionType, i) => (
                        <tr key={inspectionType.inspectionTypeId} className={styles.tableRow}>
                            <td>
                                <Form.Field name={getFieldName(i, 'inspectionTypeId')}>
                                    <Select
                                        options={inspectionsToDispaly}
                                        dataShape={{
                                            value: 'id',
                                            label: 'name',
                                        }}
                                        isClearable
                                    />
                                </Form.Field>
                            </td>
                            <td>
                                <Form.Row>
                                    <Form.Field
                                        name={getFieldName(i, 'frequency')}
                                        className={styles.frequencyInputGroup}>
                                        <Input append="days" />
                                    </Form.Field>
                                </Form.Row>
                            </td>
                            <td>
                                <Button
                                    variant="icon"
                                    onClick={() => onRemove(inspectionType)}
                                    icon={{
                                        icon: faTrashAlt as any,
                                        size: 'lg',
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {!inspectionTypes?.length && (
                <>
                    <p>No Inspection Types have been created yet.</p>
                    <RouteLink
                        route={routes.admin.routes!.inspectionTypes.routes?.newInspectionType!}
                        params={{ itemTypeCode }}>
                        Create An Inspection Type
                    </RouteLink>
                </>
            )}
            {!values.inspections?.length && (
                <p className={styles.warningMessage}>You haven't added any Inspections yet.</p>
            )}
            <AddButton
                className={styles.addButton}
                disabled={!inspectionTypes?.length}
                onClick={onAdd}
                text={!!values.inspections?.length ? 'Add Another' : 'Add Inspection'}
            />
        </div>
    );
};
