class Auth0Token {
    private getAccessTokenSilently: () => Promise<string> | undefined = () => undefined;

    setGetAccessTokenSilently(getAccessTokenSilently: () => Promise<string>) {
        this.getAccessTokenSilently = getAccessTokenSilently;
    }

    getAccessToken = () => {
        return this.getAccessTokenSilently();
    };

    getIsAccessTokenSilentlyDefined = () => !!this.getAccessTokenSilently();
}
const auth0Token = new Auth0Token();

export { auth0Token };
