import { auth0Token } from 'domain/services/auth0Service';

export const downloadBlob = (blob: Blob, fileName: string) => {
    let anchor = document.createElement('a');
    document.body.appendChild(anchor);

    let objectUrl = window.URL.createObjectURL(blob);
    anchor.href = objectUrl;
    anchor.download = fileName;
    anchor.click();

    window.URL.revokeObjectURL(objectUrl);
};

export const getHeaders = async () => {
    const token = await auth0Token.getAccessToken();
    let headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    return headers;
};

export const getFileName = (response: Response) => {
    let fileName = '';
    const disposition = response.headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) fileName = matches[1].replace(/['"]/g, '');
    }

    return fileName;
};
