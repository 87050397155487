import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloseBadge, DatePicker } from '@laingorourke/core-web-components';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';
import { FieldArrayRenderProps } from 'formik';
import React from 'react';
import styles from './DueDatesFieldArray.module.scss';

interface DueDatesFieldArrayProps {
    values: Date[];
    arrayHelpers: FieldArrayRenderProps;
}

export const DueDatesFieldArray: React.FC<DueDatesFieldArrayProps> = ({ values, arrayHelpers }) => {
    const onAdd = (selectedDueDate: Date) => {
        if (selectedDueDate && values.find((d) => isEqual(d, selectedDueDate))) {
            return;
        }
        arrayHelpers.push(selectedDueDate);
    };

    const onRemove = (date: Date) => {
        const idx = values.indexOf(date);
        arrayHelpers.remove(idx);
    };

    return (
        <>
            <DatePicker
                onChange={(date) => onAdd(date as Date)}
                append={<FontAwesomeIcon icon={faCalendar} />}
            />
            {values.length > 0 && (
                <div className={styles.list}>
                    {values
                        .sort((a: Date, b: Date) => (isAfter(a, b) ? 1 : -1))
                        .map((date) => (
                            <CloseBadge
                                key={date.toString()}
                                className={styles.badge}
                                onClose={() => onRemove(date)}>
                                {format(date, 'dd MMM yyyy')}
                            </CloseBadge>
                        ))}
                </div>
            )}
        </>
    );
};
