import {
    ColumnDefinition,
    Divider,
    Select,
    selectFilter,
    textFilter,
} from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { Project } from 'domain/models/Project';
import { nameof } from 'helpers/nameOf';
import { Badge } from 'react-bootstrap';
import {
    TableCellActive,
    getFilterOptionsFromArray,
    getProjectDisplayName,
} from 'views/components';
import { getPersonColumn } from 'views/components/table/personColumn';
import styles from './PermissionManagement.module.scss';
import { UserSecurableStatus } from './UserSecurableStatus';
import { UserSecurableTableRow } from './UserSecurableTableRow';
import { ExternalLabel, InternalLabel } from './consts';
import { PermissionsFilters } from './useFilters';

const cellStyles: Partial<ColumnDefinition<UserSecurableTableRow>> = {
    cellStyle: (row: UserSecurableTableRow) => (!row.person.isActive ? { opacity: 0.7 } : {}),
};

export const getTableColumns = (
    myTasksUrl: string,
    projects: Project[],
    projectNumber: string,
    rolesOptions: OptionTypeBase[],
    filters: PermissionsFilters
): ColumnDefinition<UserSecurableTableRow>[] => {
    return [
        {
            field: (d) => d.userId,
            text: 'Name',
            key: nameof<UserSecurableTableRow>('userId'),
            ...getPersonColumn('person', true, true, undefined, true),
            ...cellStyles,
        },
        {
            field: (d) => d.person.company,
            text: 'Company',
            key: 'company',
            sortable: true,
            filter: textFilter(),
            ...cellStyles,
        },
        {
            field: (d) => d.person.jobTitle,
            text: 'Job Title',
            key: 'jobTitle',
            sortable: true,
            filter: textFilter(),
            ...cellStyles,
        },
        {
            field: (d) => d.userType,
            text: 'User Type',
            key: 'userType',
            sortable: true,
            filter: selectFilter({
                options: [
                    {
                        label: ExternalLabel,
                        value: ExternalLabel,
                    },
                    {
                        label: InternalLabel,
                        value: InternalLabel,
                    },
                ],
            }),
            ...cellStyles,
        },
        {
            field: (d) => d.roles,
            key: 'roles',
            ...cellStyles,
            headerRenderer: () => {
                return (
                    <div className={styles['multi-header']}>
                        <div>
                            <span className="column-header-title">Roles</span>
                            <Select
                                placeholder="Select Roles..."
                                multi
                                value={filters.selectedRoles}
                                onChange={filters.onSelectedRolesChange}
                                options={rolesOptions}
                            />
                        </div>
                        <div>
                            <span className="column-header-title">Securables</span>
                            <Select
                                placeholder="Select Securables..."
                                isClearable
                                options={[
                                    {
                                        label: 'All',
                                        value: '',
                                    },
                                    {
                                        label: getProjectDisplayName(projectNumber, projects),
                                        value: projectNumber,
                                    },
                                ]}
                                value={filters.selectedSecurable}
                                onChange={filters.onSelectedSecurableChange}
                            />
                        </div>
                        <div>
                            <span className="column-header-title">Status</span>
                            <Select
                                placeholder="Select Status..."
                                multi
                                options={getFilterOptionsFromArray(
                                    Object.keys(UserSecurableStatus)
                                )}
                                value={filters.selectedStatuses}
                                onChange={filters.onSelectedStatusChange}
                            />
                        </div>
                    </div>
                );
            },
            renderer: (_: any, row: UserSecurableTableRow) => {
                return row.roles.map((r, index) => {
                    return (
                        <>
                            <div className={styles['multi-cell']}>
                                <div>{r.roleName}</div>
                                <div>{getProjectDisplayName(r.securableId, projects)}</div>
                                <div>
                                    {r.status !== UserSecurableStatus.Pending && (
                                        <TableCellActive
                                            isActive={r.status === UserSecurableStatus.Active}
                                        />
                                    )}
                                    {r.status === UserSecurableStatus.Pending && (
                                        <div className={styles.approval}>
                                            <Badge>Pending</Badge>
                                            <a
                                                href={`${myTasksUrl}/?taskId=${r.approvalTaskId}`}
                                                target="_blank"
                                                className={styles.link}>
                                                View Approval Task
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {index !== row.roles.length - 1 && (
                                <Divider className={styles.divider} />
                            )}
                        </>
                    );
                });
            },
        },
    ];
};
