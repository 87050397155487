import cn from 'classnames';
import React from 'react';
import { InfoIcon } from '../infoIcon';

interface InfoPanel
    extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
    > {
    message: string | React.ReactNode;
    showIcon?: boolean
}

export const InfoPanel: React.FC<InfoPanel> = ({ message, showIcon, className, ...props }) => {
    return (
        <p {...props} className={cn('info-panel', className)}>
            {showIcon && <InfoIcon />}
            {message}
        </p>
    );
};
