import { usePeople } from 'domain/services/dataService';
import { Person } from 'domain/services/dataService/models';
import { useEffect, useMemo } from 'react';

export interface PersonInfo extends Person {
    isLoading: boolean;
    isNotFound?: boolean;
}

export const useWithPerson = (
    personProperty: string,
    personIdPropertyName: string,
    objects?: any[]
) => {
    const ids = useMemo(() => objects?.filter((a) => !!a[personIdPropertyName]).map((a) => a[personIdPropertyName]!),
        [objects, personProperty, personIdPropertyName]);

    const people = usePeople(ids);
    const objectsWithPeople = useMemo(() => objects?.map((entity) => {
        const person = people?.data?.find((p) => p.id == entity[personIdPropertyName]);
        const entityWithPerson = {
            ...entity,
            [personProperty]: {
                ...person,
                isLoading: people.isLoading,
                isNotFound:
                    people.isLoading || !entity[personIdPropertyName]
                        ? undefined
                        : !people?.data?.find((p) => p.id == entity[personIdPropertyName]),
            } as PersonInfo,
        };
        return entityWithPerson;
    }), [objects, people?.data, personProperty, personIdPropertyName]);

    return objectsWithPeople;
};

export const useWithOwners = (objects?: any[]) => {
    return useWithPerson('owner', 'ownerId', objects);
};

export const useWithAssignee = (objects?: any[]) => {
    return useWithPerson('assignee', 'assignedUserId', objects);
};
