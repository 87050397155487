import { Select, SelectProps } from '@laingorourke/core-web-components';
import { PropsWithChildren } from 'react';
import { components } from 'react-select';
import { ClassificationBadge } from '../classificationBadge/ClassificationBadge';
import styles from './ClassificationSelect.module.scss';

export const ClassificationSelect: React.FC<PropsWithChildren<SelectProps<string, false>>> = (props) => {
    return (
        <Select
            {...props}
            components={{
                Option: ({ children, ...props }: any) => (
                    <components.Option {...props}>
                        <div className={styles['option-container']}>
                            <div className={styles['badge-container']}>
                                <ClassificationBadge id={props.value} />
                            </div>
                            {children}
                        </div>
                    </components.Option>
                ),
            }}
        />
    );
};
