import { Skeleton } from '@laingorourke/core-web-components';
import cn from 'classnames';
import { useClassifications } from 'domain/store/reducers/classifications';
import React from 'react';
import { Badge } from 'react-bootstrap';
import styles from './ClassificationBadge.module.scss';

export const ClassificationBadge: React.FC<{
    id: string | undefined;
    className?: string;
}> = ({ id, className }) => {
    const classifications = useClassifications();
    const classification = classifications?.find((c) => c.id === id);
    return (
        <>
            {classification ? (
                // https://stackoverflow.com/questions/23074748/important-inline-styles-in-react
                // a workaround to override "important" boostrap style in inline styles
                <Badge
                    ref={(node: any) => {
                        if (node) {
                            node.style.setProperty(
                                'background-color',
                                classification?.color,
                                'important'
                            );
                        }
                    }}
                    className={cn(styles.classificationBadge, className)}>
                    {classification?.shortName}
                    {!classification && <span>not found</span>}
                </Badge>
            ) : (
                <Skeleton.Box height={21} />
            )}
        </>
    );
};
