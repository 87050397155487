import { Button, Form, Input, Select, TextArea, useForm } from '@laingorourke/core-web-components';
import {
    Classification,
    ClassificationHub,
    ComplianceTemplateData,
    ControlMeasureSummary,
    InspectionTypeSummary,
    ItemTypeCategory,
} from 'domain/models/api-models';
import { createClassification, updateClassification } from 'domain/store/reducers/classifications';
import { useAppDispatch } from 'domain/store/rootStore';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BlockPicker, ColorResult } from 'react-color';
import { Permission } from 'security/Permission';
import { ModalFooter } from 'views/components';
import {
    ComplianceTemplateFormProps,
    UpsertComplianceTemplateFormFields,
} from 'views/routes/shared/complianceTemplates';
import { ClassificationSchema, ComplianceTemplateSchema } from '../schemas';

const colors = [
    '#e30613',
    '#ba7725',
    '#0f8713',
    '#00D084',
    '#8ED1FC',
    '#0693E3',
    '#ABB8C3',
    '#EB144C',
    '#F78DA7',
    '#9900EF',
];

interface UpsertFormProps {
    isEditMode: boolean;
    classification: Classification | undefined;
    projectNumber: string;
    itemTypeId: string;
    inspectionTypes: InspectionTypeSummary[];
    controlMeasures: ControlMeasureSummary[];
    categories?: ItemTypeCategory[];
    allowedBaseComplianceTemplates: ComplianceTemplateData[];
    goToClassification: () => void;
}

export const UpsertForm: React.FC<UpsertFormProps> = ({
    isEditMode,
    itemTypeId,
    classification,
    projectNumber,
    inspectionTypes,
    controlMeasures,
    categories,
    allowedBaseComplianceTemplates,
    goToClassification,
}) => {
    const dispatch = useAppDispatch();

    const [isLastStep, setIsLastStep] = useState(false);
    const [complianceTemplateFromSelectedHub, setComplianceTemplateFromSelectedHub] = useState<
        ComplianceTemplateData | undefined
    >(undefined);

    const classificationForm = useForm({
        initialValues: {
            id: isEditMode ? classification!.id! : '',
            name: isEditMode ? classification!.name! : '',
            shortName: isEditMode ? classification!.shortName! : '',
            description: isEditMode ? classification!.description : '',
            hub: isEditMode ? classification!.hub! : null,
            color: isEditMode ? classification!.color! : colors[0],
        },
        validationSchema: ClassificationSchema,
        onSubmit: async (values) => {
            let result;
            if (isEditMode) {
                result = await dispatch(
                    updateClassification({
                        ...classificationForm.values,
                        itemTypeId,
                        projectNumber,
                    })
                );
                if ((result as any).payload) goToClassification();
            } else {
                setIsLastStep(true);
            }
        },
    });

    const { values, setFieldValue } = classificationForm;

    const complianceTemplateForm = useForm({
        initialValues: {
            allProjects: false,
            projectNumber: projectNumber,
            example: '',
            inspections: [] as { inspectionTypeId: string; frequency: number }[],
            controlMeasuresIds: [] as string[],
            categoryId: null,
        },
        validationSchema: ComplianceTemplateSchema,
        onSubmit: async (values) => {
            let result;
            if (!isEditMode) {
                const { allProjects, ...createValues } = values;
                result = await dispatch(
                    createClassification({
                        itemTypeId,
                        ...createValues,
                        ...classificationForm.values,
                        hub: classificationForm.values.hub!,
                    })
                );
            }
            if ((result as any).payload) goToClassification();
        },
    });

    useEffect(() => {
        if (!isLastStep) {
            return;
        }
        const complianceTemplate = allowedBaseComplianceTemplates.find(
            (c) => c.hub === classificationForm.values.hub
        );

        setComplianceTemplateFromSelectedHub(complianceTemplate);

        complianceTemplateForm.setFieldValue('example', complianceTemplate?.example ?? '');
        complianceTemplateForm.setFieldValue('inspections', complianceTemplate?.inspections ?? []);
        complianceTemplateForm.setFieldValue(
            'controlMeasuresIds',
            complianceTemplate?.controlMeasureIds ?? []
        );
    }, [isLastStep]);

    return (
        <>
            {!isLastStep && (
                <Form form={classificationForm}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Field name="name" label="Name">
                                <Input />
                            </Form.Field>
                        </Form.Row>
                        <Form.Row>
                            <Form.Field name="shortName" label="Short Name">
                                <Input />
                            </Form.Field>
                        </Form.Row>
                        <Form.Row>
                            <Form.Field name="description" label="Description">
                                <TextArea />
                            </Form.Field>
                        </Form.Row>
                        <Form.Row>
                            <Form.Field name="hub" label="Hub">
                                <Select
                                    disabled={isEditMode}
                                    options={Object.keys(ClassificationHub).map((c) => ({
                                        label: c,
                                        value: c,
                                    }))}
                                />
                            </Form.Field>
                        </Form.Row>
                        <Form.Row>
                            <Form.Field name="color" label="Color">
                                <BlockPicker
                                    color={values.color}
                                    onChange={(color: ColorResult) => {
                                        setFieldValue('color', color.hex);
                                    }}
                                    width="auto"
                                    triangle="hide"
                                    colors={colors}
                                />
                            </Form.Field>
                        </Form.Row>
                    </Modal.Body>
                    <ModalFooter
                        submitText={isEditMode ? 'Update' : 'Next'}
                        isSubmitting={classificationForm.isSubmitting}
                        onCancel={goToClassification}
                    />
                </Form>
            )}
            {isLastStep && (
                <Form form={complianceTemplateForm}>
                    <Modal.Body>
                        <UpsertComplianceTemplateFormFields
                            form={
                                complianceTemplateForm as unknown as FormikProps<ComplianceTemplateFormProps>
                            }
                            isEditMode={isEditMode}
                            categories={categories!}
                            inspectionTypes={inspectionTypes}
                            classificationName={values.name}
                            controlMeasures={controlMeasures}
                            writePermission={Permission.ProjectClassificationsWrite}
                            isProjectMode={true}
                            suggestedControlMeasureIds={
                                complianceTemplateFromSelectedHub?.controlMeasureIds
                            }
                            suggestedInspections={complianceTemplateFromSelectedHub?.inspections}
                        />
                    </Modal.Body>
                    <ModalFooter
                        submitText="Create"
                        isSubmitting={complianceTemplateForm.isSubmitting}
                        onCancel={goToClassification}>
                        {!isEditMode && (
                            <Button variant="secondary" onClick={() => setIsLastStep(false)}>
                                Back
                            </Button>
                        )}
                    </ModalFooter>
                </Form>
            )}
        </>
    );
};
