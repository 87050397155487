import { SCHEDULEKEY } from '@laingorourke/core-web-mytasksreactsdk';
import { useQueryClient } from '@tanstack/react-query';
import { deleteProjectItemSchedule } from 'domain/store/reducers/projects';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { TableRowActionButtons } from 'views/components/table';
import { routes } from 'views/routes/Routes';
import { ProjectItemDetailsParams } from '../../ProjectItemDetailsParams';

interface ActionButtonsProps {
    id: string;
    name: string;
    currentEndDate?: string;
    canBeEdited: boolean;
    editBlockedMessage: string;
    canBeDeleted: boolean;
    actionsBlockedMessage: string;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
    id,
    name,
    currentEndDate,
    canBeEdited,
    editBlockedMessage,
    canBeDeleted,
    actionsBlockedMessage,
}) => {
    const dispatch = useAppDispatch();
    const [successfullyUpdated, setSuccessfullyUpdated] = useState<boolean | undefined>(undefined);

    const { projectNumber, itemTypeCode, projectItemId } = useParams<ProjectItemDetailsParams>();

    const queryClient = useQueryClient();
    const currentUserHasManageItemsWritePermission = useHasPermissions(
        Permission.ManageItemsWrite,
        projectNumber
    );
    const currentUserHasManageItemsDeletePermission = useHasPermissions(
        Permission.ManageItemsDelete,
        projectNumber
    );

    const onDelete = async () => {
        const result = await dispatch(
            deleteProjectItemSchedule({
                projectNumber,
                projectItemId,
                scheduleId: id,
                currentEndDate: currentEndDate,
            })
        );
        if ((result as any).payload) {
            queryClient.invalidateQueries(SCHEDULEKEY());
            setSuccessfullyUpdated(true);
        }
    };

    return (
        <TableRowActionButtons
            editPath={
                routes.projects.routes!.project.routes!.items.routes!.schedules.routes!.editSchedule
                    .path
            }
            routeParams={{ projectNumber, itemTypeCode, projectItemId, scheduleId: id }}
            entityId={id}
            isEditDisabled={!canBeEdited}
            editDisabledMessage={editBlockedMessage}
            deleteBody={`Are you sure you want to remove '${name}'?`}
            isDeleteDisabled={!canBeDeleted}
            deleteDisabledMessage={actionsBlockedMessage}
            onDelete={onDelete}
            deleteTitle={`Delete Schedule`}
            successfullyUpdated={successfullyUpdated}
            hideEdit={!currentUserHasManageItemsWritePermission}
            hideDelete={!currentUserHasManageItemsDeletePermission}
        />
    );
};
