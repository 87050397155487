import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

interface State {
    inProgress: InProgressLoadingState;
}

export interface InProgressLoadingState {
    loading: boolean;
}

export const InProgressSlice = createSlice({
    name: 'InProgressSlice',
    initialState: {
        loading: false,
    },
    reducers: {
        loadingStarted: (state: InProgressLoadingState) => {
            state.loading = true;
        },
        loadingCompleted: (state: InProgressLoadingState) => {
            state.loading = false;
        },
    },
});

export const useIsLoading = () => useSelector((state: State) => state.inProgress.loading);

export const { loadingStarted, loadingCompleted } = InProgressSlice.actions;
export default InProgressSlice.reducer;
