import { Skeleton } from '@laingorourke/core-web-components';
import { useAllProjectsClassifications } from 'domain/store/reducers/classifications';
import {
    useComplianceTemplate,
    useControlMeasureSummaries,
    useInspectionTypesSummaries,
} from 'domain/store/reducers/complianceTemplates';
import { useItemTypeMetadata } from 'domain/store/reducers/itemType';
import { useProjects } from 'domain/store/reducers/projects';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { SharedRouteParams } from 'views/routes/shared/SharedRouteParams';
import { useGenerateComplianceTemplatePath } from '../../hooks/useGenerateComplianceTemplatePath';
import { AddOrEditForm } from './components/AddOrEditForm';

interface AddComplianceTemplateParams extends SharedRouteParams {
    complianceTemplateId: string;
}

export const UpsertComplianceTemplate: React.FC = () => {
    const { projectNumber, complianceTemplateId } = useParams<AddComplianceTemplateParams>();

    const complianceTemplate = useComplianceTemplate(complianceTemplateId);
    const controlMeasures = useControlMeasureSummaries();
    const inspectionTypesSummaries = useInspectionTypesSummaries();
    const metadata = useItemTypeMetadata();
    const projects = useProjects();
    const generateComplianceTemplatePath = useGenerateComplianceTemplatePath();

    const isEditMode = !!complianceTemplate;
    const isProjectMode = !!projectNumber;

    const classifications = useAllProjectsClassifications()?.filter((c) => c.isActive);
    const categories = metadata?.categories;
    const dataLoaded =
        !!classifications &&
        !!categories &&
        !!controlMeasures &&
        !!inspectionTypesSummaries &&
        !!projects;

    const history = useHistory();
    const goToComplianceTemplates = () =>
        history.push(generateComplianceTemplatePath((baseRoute) => baseRoute.path));

    return (
        <Modal show={true} onHide={goToComplianceTemplates} animation={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? 'Edit' : 'New'} Compliance Template</Modal.Title>
            </Modal.Header>
            <SecuredContent
                permissions={Permission.ManageComplianceSettingsWrite}
                projectNumber={complianceTemplate?.projectNumber}
                ignoreSecurable={!isProjectMode}>
                {dataLoaded && (
                    <AddOrEditForm
                        isEditMode={isEditMode}
                        isProjectMode={isProjectMode}
                        complianceTemplate={complianceTemplate}
                        projectNumber={projectNumber}
                        categories={categories!}
                        inspectionTypes={inspectionTypesSummaries}
                        classifications={classifications!}
                        controlMeasures={controlMeasures}
                        onCancel={goToComplianceTemplates}
                    />
                )}
                {!dataLoaded && (
                    <Modal.Body>
                        <Skeleton.Text />
                    </Modal.Body>
                )}
            </SecuredContent>
        </Modal>
    );
};
