import { ButtonProps, generatePath } from '@laingorourke/core-web-components';
import { useHasPermissions } from 'domain/store/reducers/users';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ActionButtonsPanel, usePageActions } from 'views/components';
import { TooltipButton } from 'views/components/tooltipButton';
import { routes } from 'views/routes/Routes';
import { AdminRouteParams } from '../../AdminRouteParams';

interface NewDesignerButtonProps extends ButtonProps {}

export const NewDesignerButton: React.FC<NewDesignerButtonProps> = ({ disabled }) => {
    const routeParams = useParams<AdminRouteParams>();
    const history = useHistory();

    const currentUserHasAnyManageDesignerSettingsWritePermission = useHasPermissions(
        Permission.ManageDesignerSettingsWrite,
        undefined,
        true
    );

    const goToNewDesigner = () =>
        history.push(
            generatePath(routes.admin.routes!.designers.routes!.newDesigner.path, {
                ...routeParams,
            })
        );

    usePageActions(
        currentUserHasAnyManageDesignerSettingsWritePermission && (
            <ActionButtonsPanel>
                <TooltipButton
                    variant="primary"
                    onClick={goToNewDesigner}
                    id="new-designer"
                    disabled={disabled}
                    tooltipContent="Loading...">
                    New Designer
                </TooltipButton>
            </ActionButtonsPanel>
        ),
        [currentUserHasAnyManageDesignerSettingsWritePermission, routeParams.itemTypeCode, disabled]
    );

    return <> </>;
};
