import { Classification } from 'domain/models/api-models';
import React from 'react';
import { InfoIcon, TooltipWrapper } from 'views/components';
import styles from './LeadDaysCell.module.scss';

interface LeadDays {
    classificationId: string;
    leadDays?: number;
    comments?: string;
}

interface LeadDaysCellProps {
    leadDays?: LeadDays[];
    classifications?: Classification[];
}

export const LeadDaysCell: React.FC<LeadDaysCellProps> = ({ leadDays, classifications }) => (
    <div className={styles.row}>
        {classifications?.map((c) => (
            <ClassificationCell
                key={c.id}
                classification={c}
                leadDays={leadDays?.find((l) => l.classificationId == c.id)}
            />
        ))}
    </div>
);

export const ClassificationCell: React.FC<{
    classification: Classification;
    leadDays?: LeadDays;
}> = ({ leadDays, classification }) => {
    return (
        <div
            style={{
                backgroundColor: addAlphaToHexColor(classification?.color, 0.07),
            }}>
            {leadDays && (
                <>
                    <span>{leadDays.leadDays}</span>
                    {!!leadDays.comments && (
                        <TooltipWrapper
                            id={leadDays.classificationId}
                            placement="top"
                            tooltipContent={leadDays.comments}>
                            <InfoIcon />
                        </TooltipWrapper>
                    )}
                </>
            )}
        </div>
    );
};

const addAlphaToHexColor = (color: string | undefined, opacity: number) => {
    if (!color) {
        return '';
    }
    //https://stackoverflow.com/questions/19799777/how-to-add-transparency-information-to-a-hex-color-code
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
};
