import { ColumnDefinition, TableSort } from '@laingorourke/core-web-components';

export const getDefaultPaginationOptions = () => {
    return {
        pageSize: 20,
        pageSizes: defaultPageSizes,
    };
};

export const defaultPageSizes = [10, 20, 50];

export const defaultSortColumnName = 'name';

export const getDefaultSorted = (index?: number, sortDirection?: string) => {
    return {
        sortColumnIndex: index ?? 0,
        sortDirection: sortDirection ?? 'asc',
    } as TableSort;
};

export const getDefaultSortedByColummKey = (
    columns: ColumnDefinition<any>[],
    columnKeyName?: string,
    sortDirection?: string
) => {
    const columnKey = columnKeyName ?? defaultSortColumnName;
    const indexByColumnKey = columns.findIndex((c) => c.key === columnKey);
    const index = indexByColumnKey !== -1 ? indexByColumnKey : 0;
    return {
        sortColumnIndex: index ?? 0,
        sortDirection: sortDirection ?? 'asc',
    } as TableSort;
};

export const defaultVerticalAlignment = 'align-top';
