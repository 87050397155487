import { useQuery } from '@tanstack/react-query';
import { useProjectId } from 'domain/store/reducers/projects';
import { gql } from 'graphql-request';
import { getDataServiceConfiguration } from 'helpers/applicationConfig';
import { getQueryResult } from './graphQlClient';
import { ProjectPeople } from './models';

const query = gql`
    query getProjectPeople($search: String!, $projectId: ID!, $systemId: Guid!) {
        project(id: $projectId, wbsCategory: "", contractCode: "") {
            people(search: $search, systemId: $systemId, orderBy: { displayName: "asc" }) {
                data {
                    id
                    displayName
                }
            }
        }
    }
`;

export const useProjectPeople = (projectNumber: string, search?: string) => {
    const projectId = useProjectId(projectNumber);
    const systemId = getDataServiceConfiguration()?.systemId;
    return useQuery(
        ['projectPeople', search, systemId, projectId],
        async () => {
            const result = await getQueryResult<{
                project: ProjectPeople;
            }>(query, {
                search,
                projectId,
                systemId,
            });
            return result.project.people.data;
        },
        {
            enabled: !!search,
        }
    );
};
