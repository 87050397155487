import { CollapsibleCard, Page } from '@laingorourke/core-web-components';
import { ItemType } from 'domain/models/api-models';
import { useProjectItems } from 'domain/services/dataService';
import { ProjectItem } from 'domain/services/dataService/models';
import {
    clearAllItemTypesClassifications,
    fetchAllItemTypesClassifications,
} from 'domain/store/reducers/classifications';
import { useItemTypes } from 'domain/store/reducers/itemType';
import { useAppDispatch } from 'domain/store/rootStore';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { SearchTable } from './components/searchTable/SearchTable';
import { ItemTypeResults } from './models/ItemTypeResults';

export interface SearchPageParams {
    term: string;
}

const MapToItemTypeResults = (
    projectItems: ProjectItem[],
    itemTypes: ItemType[]
): ItemTypeResults[] =>
    itemTypes.map((itemType) => ({
        ...itemType,
        items: projectItems.filter((pi) => pi.itemType.id === itemType.id),
    })) as ItemTypeResults[];

export const SearchPage: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const { term } = useParams<SearchPageParams>();
    const itemTypes = useItemTypes();
    useEffect(() => {
        dispatch(fetchAllItemTypesClassifications());
        return () => {
            dispatch(clearAllItemTypesClassifications());
        };
    }, [dispatch]);

    const { data: projectItems, isLoading } = useProjectItems(term);
    const results = useMemo(
        () => MapToItemTypeResults(projectItems ?? [], itemTypes),
        [projectItems, itemTypes.length]
    );

    return (
        <Page header={<Page.Header title="Search" />}>
            {results.map((itemType) => (
                <CollapsibleCard
                    key={itemType.name}
                    title={itemType.name}
                    collapsed={false}
                    className="mt-0 mb-3 overflow-auto">
                    <SearchTable {...{ itemType, isLoading }} />
                </CollapsibleCard>
            ))}
            {children}
        </Page>
    );
};
