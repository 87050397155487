import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import startOfToday from 'date-fns/startOfToday';
import subWeeks from 'date-fns/subWeeks';
import { getIntegerYup } from 'views/components/form/validators';
import * as Yup from 'yup';

const weekAgo = subWeeks(startOfToday(), 1);

export const getScheduleSchema = (allowedStartDate?: string) =>
    Yup.object().shape({
        inspectionTypeId: Yup.string().required('Inspection Type is required'),
        assignee: Yup.string().nullable().required('Assignee is required'),
        addBeforeDueDays: getIntegerYup('Days', 1, 31).required('Days are required'),
        startDate: Yup.date()
            .nullable()
            .required('Start Date is required')
            .min(weekAgo)
            .test(
                'min',
                'Start Date cannot be earlier than a week ago',
                (value) =>
                    !!value &&
                    // when edit: the previously selected date is allowed even if it's already in the past
                    ((!!allowedStartDate && isSameDay(value, new Date(allowedStartDate))) ||
                        // when add / edit: dates up to a week in the past are allowed
                        isSameDay(value, weekAgo) ||
                        isAfter(value, weekAgo))
            ),
        endDate: Yup.date()
            .nullable()
            .min(Yup.ref('startDate'), 'End Date must be the same or after Start Date'),
    });
