import { Form, Select, useForm } from '@laingorourke/core-web-components';
import { Status, TASKKEY } from '@laingorourke/core-web-mytasksreactsdk';
import { useQueryClient } from '@tanstack/react-query';
import format from 'date-fns/format';
import { createProjectItemInspections } from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalFooter, ProjectPersonSelector } from 'views/components';
import { getStatusesForTaskCreation } from 'views/routes/project/myTasksHelpers';
import { DateInPastWarning } from '../../controlMeasures/components/DateInPastWarning';
import { NewInspectionSchema } from '../schemas';
import { DueDatesFieldArray } from './DueDatesFieldArray';

interface CreateFormProps {
    projectNumber: string;
    projectItemId: string;
    inspectionTypes: { id: string; name: string }[];
    statuses: Status[];
    goBack: () => void;
}

export const CreateForm: React.FC<CreateFormProps> = ({
    inspectionTypes,
    projectNumber,
    projectItemId,
    statuses,
    goBack,
}) => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const statusTypes = getStatusesForTaskCreation(statuses);

    const form = useForm({
        initialValues: {
            inspectionTypeId: '',
            assignee: '',
            dueDates: [],
            statusType: statusTypes[0].value,
        },
        validationSchema: NewInspectionSchema,
        onSubmit: async (values) => {
            const result = await dispatch(
                createProjectItemInspections({
                    ownerId: values.assignee,
                    inspectionTypeId: values.inspectionTypeId,
                    statusType: values.statusType,
                    dueDates: values.dueDates.map((dueDate) => format(dueDate, 'yyyy-MM-dd')!),
                    projectNumber,
                    projectItemId,
                })
            );

            if ((result as any).payload) {
                queryClient.invalidateQueries(TASKKEY());
                goBack();
            }
        },
    });

    const { isSubmitting, values } = form;

    return (
        <>
            <Form form={form}>
                <Modal.Body>
                    <Form.Row>
                        <Form.Field name="inspectionTypeId" label="Inspection Type">
                            <Select
                                options={inspectionTypes}
                                dataShape={{
                                    value: 'id',
                                    label: 'name',
                                }}
                            />
                        </Form.Field>
                    </Form.Row>
                    <Form.Row>
                        <Form.Field name="statusType" label="Status">
                            <Select options={statusTypes} />
                        </Form.Field>
                    </Form.Row>
                    <Form.Row>
                        <Form.Field name="assignee" label="Assignee">
                            <ProjectPersonSelector
                                projectNumber={projectNumber}
                                placeholder="Select Assignee..."
                            />
                        </Form.Field>
                    </Form.Row>
                    <Form.List
                        name="dueDates"
                        label="Forecasted Completion Dates"
                        render={(arrayHelpers) => (
                            <DueDatesFieldArray
                                values={values.dueDates}
                                arrayHelpers={arrayHelpers}
                            />
                        )}
                    />
                    <DateInPastWarning dates={values.dueDates} label="Forecasted Completion" />
                </Modal.Body>
                <ModalFooter submitText="Create" isSubmitting={isSubmitting} onCancel={goBack} />
            </Form>
        </>
    );
};
