import { ToastMessageType } from '@laingorourke/core-web-components';
import { v4 as uuidv4 } from 'uuid';

export const getToastMessage = (message: string, isSuccess: boolean | undefined) => {
    return {
        key: uuidv4(),
        title: isSuccess ? 'Success' : 'Error',
        type: isSuccess ? ToastMessageType.Success : ToastMessageType.Error,
        body: message,
        delay: 7000,
    };
};
