import {
    ColumnDefinition,
    Page,
    Placeholder,
    Skeleton,
    Table,
} from '@laingorourke/core-web-components';
import { LorImagesCdn } from 'consts';
import { get } from 'domain/services/client';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { getReportsUrlsConfiguration } from 'helpers/applicationConfig';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { InfoPanel, SecuredContent } from 'views/components';
import { ProjectParams } from '../Project';
import { ReportLink } from './ReportLink';
interface ReportSummary {
    displayName: string;
    url?: string;
    skipParams?: boolean;
}
export const Reports: React.FC = () => (
    <SecuredContent permissions={Permission.ProjectReportsRead}>
        <ReportsContent />
    </SecuredContent>
);

const ReportsContent: React.FC = () => {
    const { projectNumber } = useParams<ProjectParams>();
    const itemTypeId = useSelectedItemTypeId();
    const [projectItemsCount, setProjectItemsCount] = useState<number | undefined>();

    useEffect(() => {
        if (!!itemTypeId)
            get(`projects/${projectNumber}/itemtypes/${itemTypeId}/projectitems/count`).then(
                (count) => setProjectItemsCount(count)
            );
    }, [itemTypeId]);

    if (projectItemsCount === undefined) {
        return <Skeleton.Text />;
    }

    if (projectItemsCount === 0) {
        return (
            <Page.Section>
                <Placeholder
                    image={`${LorImagesCdn}/no_data.svg`}
                    title="No Reports"
                    description="Reports are not available as no Items have been created for this project."
                />
            </Page.Section>
        );
    }

    const reportsUrls = getReportsUrlsConfiguration();
    const reports: ReportSummary[] = [
        {
            url: reportsUrls?.dashboard,
            displayName: 'Dashboard',
        },
        {
            url: reportsUrls?.itemOwner,
            displayName: 'Item Owner',
        },
        {
            url: reportsUrls?.areaOwner,
            displayName: 'Area Owner',
        },
        {
            url: reportsUrls?.designer,
            displayName: 'Designer',
        },
        {
            //This url doesn't match the convention so, the filter with projectNumber is added here
            url: `${reportsUrls?.systemAccess}?filter=GateKeeperUserRoleSecurable/Securable in ('All project/securables', '${projectNumber}')`,
            displayName: 'System Access',
            skipParams: true,
        },
    ];

    const columns: ColumnDefinition<ReportSummary>[] = [
        {
            field: (d) => d.url,
            text: 'Comply Reports',
            renderer: (_: any, row) => (
                <ReportLink
                    displayName={row.displayName}
                    url={row.url}
                    projectNumber={projectNumber}
                    itemTypeId={itemTypeId}
                    skipParams={row.skipParams}
                />
            ),
        },
    ];

    return (
        <>
            <Table data={reports} columns={columns} keySelector={(d) => d.url} />
            <InfoPanel
                message="Please be advised reports are generated 3 times per day; generate is scheduled to start: AEDT 4 am, 11:00 am & 5:00 pm"
                showIcon
            />
        </>
    );
};
