import {
    CompareType,
    generatePath,
    Table,
    youAreOfflineFeatureNotAvailableMessage,
} from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import cn from 'classnames';
import { ProjectItem } from 'domain/models/api-models';
import { fetchAreasSummaries, useAreaSummaries } from 'domain/store/reducers/areas';
import {
    fetchProjectClassifications,
    useProjectClassifications,
} from 'domain/store/reducers/classifications';
import { useItemStatuses, useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import {
    fetchProjectItemNumberConfiguration,
    useProjectItemNumberConfiguration,
} from 'domain/store/reducers/projectItemNumberConfiguration';
import {
    fetchProjectControlMeasures,
    fetchProjectItems,
    useProjectId,
    useProjectItems,
} from 'domain/store/reducers/projects';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { getFeaturesConfiguration } from 'helpers/applicationConfig';
import { nameof } from 'helpers/nameOf';
import { useIsOffline } from 'hooks/useIsOffline';
import { useWithOwners } from 'hooks/useWithPerson';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import {
    getDefaultPaginationOptions,
    getDefaultSortedByColummKey,
    getFilterOptions,
    getItemTypeStatusColumn,
} from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { routes } from 'views/routes/Routes';
import { ProjectParams } from '../../Project';
import { editBlockedMessage, itemLockedDueToPermissionMessage } from '../details/messages';
import { getItemNotModifiableStatusMessage } from '../details/messagesHelper';
import { getNonEditableStatusesMessage } from '../details/statusesHelper';
import { BulkOperations } from './BulkOperations';
import { ActionButtons } from './components/ActionButtons';
import { WeatherEventsAcknowledgements } from './components/weatherEventsAcknowlegements/WeatherEventsAcknowledgements';
import { getAllItemsDisableddMessage, itemIsModifiable } from './helpers';
import styles from './List.module.scss';
import { ProjectItemTableRow } from './ProjectItemTableRow';
import { getTableColumns } from './tableColumns';

export const List: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();
    const itemTypeId = useSelectedItemTypeId();
    const weatherEnabled = getFeaturesConfiguration()?.weatherEnabled;

    const projectItemsData = useProjectItems();
    const projectItems = useWithOwners(projectItemsData);
    const areas = useAreaSummaries();
    const projectStatuses = useItemStatuses();
    const [overrideSelected, setOverrideSelected] = useState<ProjectItemTableRow[]>([]);
    const selectedIds = useMemo(() => overrideSelected.map(s => s.id), [overrideSelected]);
    const projectId = useProjectId(projectNumber);

    const isOffline = useIsOffline();
    const currentUserHasPermissionToManageItemsRead = useHasPermissions(
        Permission.ManageItemsRead,
        projectNumber
    );
    const currentUserHasPermissionToManageItemsWrite = useHasPermissions(
        Permission.ManageItemsWrite,
        projectNumber
    );

    const nonEditableStatusesMessage = getNonEditableStatusesMessage(projectStatuses);
    const data = useMemo(() => projectItems?.map((i): ProjectItemTableRow => ({
        ...i,
        status: projectStatuses?.find((s) => s.id === i.statusId)?.name,
    })), [projectItems, projectStatuses]);

    const classifications = useProjectClassifications(false);
    const classiticationsSortOrder = classifications && classifications.map((p) => p.id!);

    const classificationOptions = classifications
        ?.filter((c) => projectItems?.find((p) => p.classificationId === c.id))
        ?.map((c) => {
            return { value: c.id, label: c.name } as OptionTypeBase;
        });

    const statusSortOrder = projectStatuses && projectStatuses.map((p) => p.id!);

    const statusOptions = getFilterOptions(data, 'statusId', statusSortOrder)
        .map(option => ({ label: projectStatuses?.find(ps => ps.id === option.value)?.name, value: option.value }));

    const allStatusOptions = useMemo(() => {
        const nonEditableStatuses = projectStatuses?.filter(s => s.isEditable).map(s => s.id).join('_');
        if (nonEditableStatuses?.length!!) {
            return [{ value: nonEditableStatuses, label: 'Ongoing' }, ...statusOptions]
        }

        return statusOptions;
    }, [statusOptions]);
    const statusColumn = getItemTypeStatusColumn(projectStatuses, allStatusOptions, true, CompareType.Contains);

    const areaNameOptions = getFilterOptions(projectItems, 'area');
    const subAreaNameOptions = getFilterOptions(projectItems, 'subArea');

    const projectItemNumberConfiguration = useProjectItemNumberConfiguration();

    const dataLoaded =
        !!data &&
        classifications !== undefined &&
        areas !== null &&
        projectStatuses !== null &&
        !!projectItemNumberConfiguration;

    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Items found');

    useEffect(() => {
        if (!itemTypeId || !projectNumber) {
            return;
        }

        dispatch(fetchAreasSummaries({ itemTypeId, projectNumber }));
        dispatch(fetchProjectItems({ projectNumber, itemTypeId }));
        dispatch(fetchProjectClassifications({ itemTypeId: itemTypeId!, projectNumber }));
        dispatch(fetchProjectItemNumberConfiguration({ projectNumber, itemTypeId }));
        // get CM summaries to use them in StatusColumn
        dispatch(fetchProjectControlMeasures({ projectNumber, itemTypeId }));
        setOverrideSelected([]);
    }, [itemTypeId, projectNumber, dispatch]);

    const onRowClick = (projectItem: ProjectItem) =>
        currentUserHasPermissionToManageItemsRead
            ? projectItem.detailAccessLocked !== true && goToItemDetails(projectItem.id)
            : '';

    const hideItemDetailsColumn = !!data
        ? data?.filter((i) => i.detailAccessLocked)?.length === data?.length
        : true;

    const columns = getTableColumns(
        (projectItemId: string) => goToItemDetailsPreview(projectItemId),
        classiticationsSortOrder,
        classificationOptions,
        areaNameOptions,
        subAreaNameOptions,
        statusColumn,
        hideItemDetailsColumn,
        projectNumber,
        projectId
    );

    const goToItemDetails = (projectItemId: string) =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.items.routes!.itemDetails.path, {
                projectNumber,
                projectItemId,
                itemTypeCode,
            })
        );

    const goToItemDetailsPreview = (projectItemId: string) => {
        setOverrideSelected([]);
        return history.push(
            generatePath(routes.projects.routes!.project.routes!.items.routes!.preview.path, {
                projectNumber,
                projectItemId,
                itemTypeCode,
            })
        );
    };

    return (
        <>
            {children}
            {currentUserHasPermissionToManageItemsWrite && <ActionButtons areas={areas} />}
            <Table<ProjectItemTableRow>
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(columns, nameof<ProjectItemTableRow>('number'))}
                {...tableLoadingProps}
                data={dataLoaded ? data! : []}
                columns={columns}
                keySelector={(data) => data.id}
                clickable
                onRowClick={onRowClick}
                customRowClickable={(row) => !row.detailAccessLocked}
                rowClasses={(row) => cn(row.detailAccessLocked ? styles.defaultCursor : '')}
                keepSelection
                saveSelectedPageSize
                cacheKey="items"
                id="projectItemList"
                selectable={currentUserHasPermissionToManageItemsWrite}
                customRowSelectable={(row) => {
                    return {
                        selectable: !isOffline && itemIsModifiable(row),
                        noSelectableMessage: isOffline
                            ? youAreOfflineFeatureNotAvailableMessage
                            : row.myTasksOperationsBlocked
                                ? editBlockedMessage
                                : row.detailAccessLocked
                                    ? itemLockedDueToPermissionMessage
                                    : getItemNotModifiableStatusMessage(nonEditableStatusesMessage),
                    };
                }}
                onSelectionChange={setOverrideSelected}
                enableSelectInAllPagesOption
                overriddenSelected={overrideSelected}
                noSelectableRowsOnCurrentPageMessage={(fiteredData) => {
                    return getAllItemsDisableddMessage(
                        nonEditableStatusesMessage,
                        isOffline,
                        fiteredData
                    );
                }}
                showPageInfo={{ showAtTheTop: true }}
            />
            <BulkOperations
                selectedItems={selectedIds}
                onOperationCompleted={() => {
                    setOverrideSelected([]);
                }}
            />
            {weatherEnabled && <WeatherEventsAcknowledgements />}
        </>
    );
};
