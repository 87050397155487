import {
    emojis,
    emojiValidationMessage,
    getIntegerYup,
    invalidCharacters,
} from 'views/components/form/validators';
import * as Yup from 'yup';

const LeadDaysEntrySchema = Yup.object()
    .nullable()
    .shape({
        value: getIntegerYup('Lead Days', -731, 731).nullable(),
        comments: Yup.string()
            .nullable()
            .matches(emojis, emojiValidationMessage)
            .matches(invalidCharacters, 'Comments contains invalid characters')
            .max(500, 'Comments cannot be longer than 500 characters'),
    });

const ClassificationLeadDaysSchema = Yup.object().shape({
    classificationId: Yup.string().required('Classification is required'),
    projectLeadDays: LeadDaysEntrySchema,
    adminLeadDays: LeadDaysEntrySchema,
});

export const LeadDaysSchema = Yup.object().shape({
    controlMeasureId: Yup.string().required('Control Measure is required'),
    leadDays: Yup.array(ClassificationLeadDaysSchema),
});
