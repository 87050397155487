import { Form, Select, useForm } from '@laingorourke/core-web-components';
import {
    assignTeams,
    Team,
    useAllBuiltViewTeams,
} from 'domain/store/reducers/builtViewIntegration';
import { useCurrentUser } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { Modal } from 'react-bootstrap';
import { Permission } from 'security/Permission';
import { ModalFooter, SecuredContent } from 'views/components';
import * as Yup from 'yup';

interface AssignTeamsProps {
    itemTypeId: string;
    projectNumber: string;
    show: boolean;
    selectedTeams: Team[];
    onClose: (success: boolean) => void;
}

export const AssignTeams: React.FC<AssignTeamsProps> = ({
    itemTypeId,
    projectNumber,
    selectedTeams,
    onClose,
}) => {
    const dispatch = useAppDispatch();
    const allTeamsByUser = useAllBuiltViewTeams();
    const currentUser = useCurrentUser();

    const options = allTeamsByUser
        ?.concat(selectedTeams?.filter((st) => !allTeamsByUser.some((at) => at.id === st.id)) ?? [])
        ?.map((o) => {
            return { value: o.id, label: o.title };
        })
        ?.sort((a, b) => a.label!.localeCompare(b.label));

    const form = useForm({
        initialValues: {
            teamIds: selectedTeams?.map((st) => st.id) ?? [],
        },
        validationSchema: Yup.object().shape({
            teamIds: Yup.array(Yup.string()).nullable(),
        }),
        onSubmit: async (values) => {
            await dispatch(
                assignTeams({
                    teamIds: values.teamIds ?? [],
                    itemTypeId,
                    projectNumber,
                    email: currentUser?.emailAddress,
                })
            );

            onClose(true);
        },
    });

    const { isSubmitting } = form;

    return (
        <Modal show={true} onHide={() => onClose(false)} animation={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Update Teams</Modal.Title>
            </Modal.Header>
            <SecuredContent permissions={Permission.ManageItemsWrite}>
                <Form form={form}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Field name="teamIds" label="Teams">
                                <Select multi options={options} loading={!options} isClearable />
                            </Form.Field>
                        </Form.Row>
                    </Modal.Body>
                    <ModalFooter
                        submitText="Update"
                        isSubmitting={isSubmitting}
                        onCancel={() => onClose(false)}
                    />
                </Form>
            </SecuredContent>
        </Modal>
    );
};
