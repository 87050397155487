import { StatusType, useTasksTotalCount } from '@laingorourke/core-web-mytasksreactsdk';
import { ProjectControlMeasure, Status } from 'domain/models/api-models';
import React from 'react';
import { MissingIndicator } from 'views/components';
import { ProjectItemTableRow } from '../ProjectItemTableRow';

interface MissingDesignsIndicatorProps {
    projectItem: ProjectItemTableRow;
    projectControlMeasures: ProjectControlMeasure[];
    statuses: Status[];
}

export const MissingDesignsIndicator: React.FC<MissingDesignsIndicatorProps> = ({
    projectItem,
    projectControlMeasures,
    statuses,
}) => {
    // we are intereseted in statuses that have "Design" in the name
    const designStatuses = statuses.filter((c) => c.name!.indexOf('Design') > -1).map((c) => c.id);
    //...and we derive control measures based on the above statuses
    const designControlMeasuresIds = projectControlMeasures
        .filter(
            (c) => !!designStatuses.find((id) => id === c.itemStatusWhenControlMeasureCompleted)
        )
        .map((c) => c.id);
    const query = {
        filter: {
            and: [
                {
                    meta: {
                        any: { label: 'ProjectItemId', value: projectItem.id },
                    },
                },
                {
                    meta: {
                        any: { label: 'ControlMeasureId', value: { in: designControlMeasuresIds } },
                    },
                },
                {
                    or: [
                        { 'status/type': StatusType.ToDo },
                        { 'status/type': StatusType.InProgress },
                    ],
                },
            ],
        },
    };

    const count = useTasksTotalCount(query);

    return (
        <>
            {!!count?.data?.count && (
                <MissingIndicator
                    id={`missing-designs-${projectItem.id}`}
                    message="Design Control Measures have not been completed"
                />
            )}
        </>
    );
};
