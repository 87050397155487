import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { getQueryResult } from './graphQlClient';
import { ProjectItem } from './models';

const query = gql`
    query getProjectItems($search: String!) {
        projectItems(search: $search) {
            id
            name
            number
            project {
                name
                number
            }
            dateInService
            created
            itemType {
                id
            }
            classification {
                id
                name
            }
            category {
                name
            }
            status {
                name
            }
        }
    }
`;

export const useProjectItems = (search?: string) => {
    return useQuery(['projectItems', search], async () => {
        const result = await getQueryResult<{
            projectItems: ProjectItem[];
        }>(
            query,
            {
                search: search + '*', //need to consider this further, e.g. multiple words
            },
            (error) => {
                return error.response.status != 400;
            }
        );
        return result?.projectItems ?? [];
    });
};
