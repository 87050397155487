import { Table } from '@laingorourke/core-web-components';
import { useAsiteProjects, useFieldViewProjects } from 'domain/store/reducers/applicationProject';
import {
    fetchAsiteProjects,
    fetchFieldViewProjects,
} from 'domain/store/reducers/applicationProject/actions';
import { useAreas } from 'domain/store/reducers/areas';
import { fetchAreas } from 'domain/store/reducers/areas/actions';
import {
    useSelectedItemTypeId,
    useSelectedItemTypeOwnerLabel,
} from 'domain/store/reducers/itemType';
import {
    fetchProjectItemNumberConfiguration,
    useAreaCodeInItemNumber,
} from 'domain/store/reducers/projectItemNumberConfiguration';
import { useProjectId } from 'domain/store/reducers/projects';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { getApplicationProjectName } from 'helpers/applicationProjectHelper';
import { useWithOwners } from 'hooks/useWithPerson';
import React, { PropsWithChildren, useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import {
    getDefaultPaginationOptions,
    getDefaultSortedByColummKey,
    getFilterOptions,
} from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { routes } from 'views/routes/Routes';
import { ProjectParams } from '../Project';
import { AreaTableRow } from './AreaTableRow';
import AddArea from './components/AddAreaButtons';
import { getTableColumns } from './tableColumns';

export const Areas: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ManageAreasRead}>
        <AreasContent>{children}</AreasContent>
    </SecuredContent>
);

const AreasContent: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const params = useParams<ProjectParams>();
    const currentUserHasManageAreasWritePermission = useHasPermissions(
        Permission.ManageAreasWrite,
        params.projectNumber
    );
    const currentUserHasManageAreasDeletePermission = useHasPermissions(
        Permission.ManageAreasDelete,
        params.projectNumber
    );

    const areaCodeInItemNumber = useAreaCodeInItemNumber();
    const itemTypeId = useSelectedItemTypeId();
    const areasData = useAreas();
    let areas = useWithOwners(areasData) as AreaTableRow[];
    const asiteProjects = useAsiteProjects();
    const fieldViewProjects = useFieldViewProjects();
    const ownerLabel = useSelectedItemTypeOwnerLabel() ?? 'Owner';
    const projectId = useProjectId(params.projectNumber);

    areas = areas?.map((a) => ({
        ...a,
        areaCodeInItemNumber: areaCodeInItemNumber,
        showDetails: () => {
            history.push(
                generatePath(routes.projects.routes!.project.routes!.areas.routes!.details.path, {
                    ...params,
                    areaId: a.id,
                })
            );
        },
        fieldViewProjectName: getApplicationProjectName(a.fieldViewProjectId, fieldViewProjects),
        asiteWorskpaceName: getApplicationProjectName(a.asiteWorkspaceId, asiteProjects),
    }));

    const dataLoaded = !!areas;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Areas found');

    const areaNameOptions = getFilterOptions(areas, 'name');
    const subAreaNameOptions = getFilterOptions(areas, 'subAreaName');
    const columns = getTableColumns(
        areaNameOptions,
        subAreaNameOptions,
        currentUserHasManageAreasWritePermission,
        currentUserHasManageAreasDeletePermission,
        ownerLabel,
        projectId
    );

    useEffect(() => {
        dispatch(fetchAsiteProjects());
        dispatch(fetchFieldViewProjects());
    }, []);

    useEffect(() => {
        dispatch(fetchAreas({ projectNumber: params.projectNumber, itemTypeId }));
        dispatch(
            fetchProjectItemNumberConfiguration({ projectNumber: params.projectNumber, itemTypeId })
        );
    }, [params.projectNumber, itemTypeId, dispatch]);

    return (
        <>
            {currentUserHasManageAreasWritePermission && <AddArea />}
            {children}
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(columns)}
                {...tableLoadingProps}
                data={dataLoaded ? areas! : []}
                columns={columns}
                keySelector={(d) => d.id}
                clickable
                onRowClick={(row) => row.showDetails()}
                saveSelectedPageSize
                cacheKey="areas"
            />
        </>
    );
};
