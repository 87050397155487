import { generatePath } from '@laingorourke/core-web-components';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActionButtonsPanel, usePageActions } from 'views/components';
import { TooltipButton } from 'views/components/tooltipButton';
import { routes } from 'views/routes/Routes';
import { ProjectParams } from '../../../Project';

const AssignPermissionButton: React.FC = () => {
    const history = useHistory();
    const params = useParams<ProjectParams>();

    const goToAssignPermission = () =>
        history.push(
            generatePath(
                routes.projects.routes!.project.routes!.projectSetup.routes!.permissionManagement
                    .routes!.assignPermission.path,
                {
                    ...params,
                }
            )
        );

    usePageActions(
        <ActionButtonsPanel>
            <TooltipButton id="assign-permission" variant="primary" onClick={goToAssignPermission}>
                Assign Permission
            </TooltipButton>
        </ActionButtonsPanel>,
        [params.itemTypeCode]
    );

    return <></>;
};

export default AssignPermissionButton;
