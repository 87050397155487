import { createAsyncThunk } from '@reduxjs/toolkit';
import { BuiltViewIntegration, MediaResponse, TeamsByIdsResponse } from 'domain/models/api-models';
import { get, put } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { getCurrentUserHasProjectPermission } from '../../reducers/users';
import { addNotification } from '../notificationsReducer';
import { Team } from './reducer';

interface BaseParams {
    projectNumber: string;
    itemTypeId: string;
}

export const fetchBuiltViewProjectIntegration = createAsyncThunk<
    BuiltViewIntegration,
    BaseParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/builtViewIntegration/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/builtViewIntegration`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface FetchIntegrationByEmailParams extends BaseParams {
    email: string;
}

export const fetchAllBuiltViewTeamsByEmail = createAsyncThunk<
    Team[],
    FetchIntegrationByEmailParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/builtViewIntegration/teamsByEmail/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/builtViewIntegration/teams/${args.email}`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber && !!args.email,
    }
);

interface FetchBuiltViewMediaByItem extends BaseParams {
    itemNumber: string;
}

export const fetchBuiltViewMediaByItem = createAsyncThunk<
    MediaResponse[],
    FetchBuiltViewMediaByItem,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/builtViewIntegration/media/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/builtViewIntegration/media/${args.itemNumber}`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber && !!args.itemNumber,
    }
);

interface FetchIntegrationByIdsParams extends BaseParams {
    ids?: string[];
}

export const fetchAllBuiltViewTeamsByIds = createAsyncThunk<
    Team[],
    FetchIntegrationByIdsParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/builtViewIntegration/teamsByIds/get',
    async (args) => {
        const response = (await get<TeamsByIdsResponse[]>(
            `itemtypes/${args.itemTypeId}/projects/${
                args.projectNumber
            }/builtViewIntegration/teams?${args.ids!.map((id) => `ids=${id}`).join('&')}`
        ))!;
        return response
            .filter((t) => !t.error)
            .map((t) => {
                return { id: t.id, title: t.title } as Team;
            });
    },
    {
        condition: (args) =>
            !!args.itemTypeId && !!args.projectNumber && !!args.ids && args.ids.length > 0,
    }
);

export const activateBuiltViewIntegration = createAsyncThunk<
    void,
    BaseParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/builtViewIntegration/activate',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await put(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/builtViewIntegration/activate`
            );
            dispatch(
                addNotification({
                    message: 'BuiltView integration has been activated',
                    isSuccess: true,
                })
            );
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

export const deactivateBuiltViewIntegration = createAsyncThunk<
    void,
    BaseParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/builtViewIntegration/deactivate',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await put(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/builtViewIntegration/deactivate`
            );
            dispatch(
                addNotification({
                    message: 'BuiltView integration has been deactivated',
                    isSuccess: true,
                })
            );
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface AssignTeamsRequest extends BaseParams {
    teamIds: string[];
    email?: string;
}

export const assignTeams = createAsyncThunk<void, AssignTeamsRequest, { state: RootState }>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/builtViewIntegration/teams/patch',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await put(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/builtViewIntegration/teams`,
                args
            );
            const multipleTeamsAssigned = args.teamIds.length > 1;
            dispatch(
                addNotification({
                    message: `Item ${
                        multipleTeamsAssigned ? 'teams have' : 'team has'
                    } been assigned successfully`,
                    isSuccess: true,
                })
            );
        }, dispatch);
    },
    {
        condition: (args, { getState }) =>
            !!args.itemTypeId &&
            !!args.projectNumber &&
            !!args.email &&
            getCurrentUserHasProjectPermission(getState(), args.projectNumber),
    }
);
