import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { Button } from '@laingorourke/core-web-components';
import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import styles from './CopyText.module.scss';

interface CopyTextProps {
    text?: string;
}

const delay = 1500;

export const CopyText: React.FC<CopyTextProps> = ({ text }) => {
    const [copiedText, setCopiedText] = useState(false);

    useEffect(() => {
        let timer = setTimeout(() => {
            if (copiedText) setCopiedText(false);
        }, delay);

        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        return () => {
            clearTimeout(timer);
        };
    }, [copiedText]);

    return (
        <div className={styles.item}>
            <span>{text}</span>
            {text && (
                <Button
                    icon={{
                        icon: faCopy as any,
                        size: 'sm',
                    }}
                    variant="icon"
                    onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(text);
                        setCopiedText(true);
                    }}
                />
            )}
            {copiedText && <Badge className={styles.copiedText}>Copied!</Badge>}
        </div>
    );
};
