import { Navigation, Page, Skeleton } from '@laingorourke/core-web-components';
import {
    fetchProjectClassifications,
    useProjectClassifications,
} from 'domain/store/reducers/classifications';
import { useItemTypeCategories, useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import {
    fetchProjectComplianceTemplates,
    useProject,
    useProjectClassificationGuidelines,
} from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { PropsWithChildren, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { OnlineContentLoader, SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import styles from './ClassificationGuidelines.module.scss';
import { ClassificationGuidelinesCategory } from './ClassificationGuidelinesCategory';
import { ClassificationGuidelinesParams } from './ClassificationGuidelinesParams';

export const ClassificationGuidelines: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ProjectDetailsRead}>
        <ClassificationGuidelinesContent>{children}</ClassificationGuidelinesContent>
    </SecuredContent>
);

const ClassificationGuidelinesContent: React.FC<PropsWithChildren> = ({ children }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const params = useParams<ClassificationGuidelinesParams>();
    const projectNumber = params.projectNumber;
    const project = useProject(projectNumber);
    const classificationGuidelines = useProjectClassificationGuidelines();
    const itemTypeId = useSelectedItemTypeId();
    const classifications = useProjectClassifications();
    const categories = useItemTypeCategories();

    const dataLoaded = !!classifications && !!classificationGuidelines && !!categories && !!project;
    const projectRoutes = routes.projects.routes!.project.routes!;

    const goToFirstCategory = () =>
        history.push(
            generatePath(projectRoutes.classificationGuidelines.path, {
                ...params,
                categoryId: categories![0].id,
            })
        );

    useEffect(() => {
        dispatch(fetchProjectComplianceTemplates({ projectNumber, itemTypeId }));
        dispatch(fetchProjectClassifications({ projectNumber, itemTypeId }));
    }, [projectNumber, itemTypeId, dispatch]);

    useEffect(() => {
        if (dataLoaded && params.categoryId === undefined) goToFirstCategory();
    }, [dataLoaded]);

    return (
        <OnlineContentLoader loading={!dataLoaded}>
            {dataLoaded && (
                <Row>
                    <Col lg="2">
                        <Page.Section noPadding>
                            <Navigation
                                direction="vertical"
                                className={styles.navigation}
                                id="card">
                                {!!projectNumber &&
                                    categories?.map((c) => (
                                        <Navigation.Route
                                            key={c.id}
                                            route={projectRoutes.classificationGuidelines}
                                            params={{ ...params, categoryId: c.id }}>
                                            {c.name}
                                        </Navigation.Route>
                                    ))}
                                <Navigation.Route
                                    route={projectRoutes.classificationGuidelines!}
                                    params={{ ...params, categoryId: projectNumber }}>
                                    {!!project ? project.name : <Skeleton.Box height={23} />}
                                </Navigation.Route>
                            </Navigation>
                        </Page.Section>
                    </Col>
                    <Col lg="10">
                        {dataLoaded ? (
                            <ClassificationGuidelinesCategory
                                categoryId={params.categoryId ?? categories![0].id}
                            />
                        ) : (
                            <Skeleton.Text />
                        )}
                    </Col>
                </Row>
            )}
            {!dataLoaded && <Skeleton.Text />}
        </OnlineContentLoader>
    );
};
