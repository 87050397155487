import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    CreateIntegrationRequest,
    History,
    Integration,
    Integrations,
    IntegrationsMetadata,
    UpdateIntegrationRequest,
} from 'domain/models/api-models';
import { get, patch, post, remove } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

interface IntegrationParams {
    projectNumber?: string;
    itemTypeId: string;
    includeProjectApplicationIntegrations?: boolean;
}

const getBaseRoute = (params: IntegrationParams) =>
    !!params.projectNumber
        ? `itemtypes/${params.itemTypeId}/projects/${params.projectNumber}/integrations`
        : `itemtypes/${params.itemTypeId}/integrations`;

export const fetchIntegrations = createAsyncThunk<
    Integrations,
    IntegrationParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/integrations/get',
    async (args, { dispatch }) => {
        const baseRoute = getBaseRoute(args);
        return await runSafe(async () => {
            return await get(
                `${baseRoute}?includeProjectApplicationIntegrations=${
                    args.includeProjectApplicationIntegrations ?? false
                }`
            )!;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId,
    }
);

export const fetchIntegrationsMetadata = createAsyncThunk<
    IntegrationsMetadata,
    string,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/integrations/metadata/get',
    async (itemTypeId) => (await get(`itemtypes/${itemTypeId}/integrations/metadata`))!,
    {
        condition: (itemTypeId) => itemTypeId !== '',
    }
);

interface FetchIntegrationHistoryParams extends IntegrationParams {
    integrationId: string;
}

export const fetchIntegrationHistory = createAsyncThunk<
    History,
    FetchIntegrationHistoryParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/integrations/{integrationId}/history/get',
    async (args) => {
        let history = null;
        const baseRoute = getBaseRoute(args);
        try {
            (history = await get(`${baseRoute}/${args.integrationId}/history`))!;
        } catch (e: any) {
            // workaround: integration history is a unique page. If user doesn't have permissions
            // we get 403 and then return null, and based on that we figure that
            // user doesn't have permissions
            // we cannot get permissions earlier and just avoid call to the API with
            // the current architecuture
            if (e.status !== 403) {
                throw e;
            }
        } finally {
            return history;
        }
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.integrationId,
    }
);

interface CreateIntegration extends CreateIntegrationRequest, IntegrationParams {}

export const createIntegration = createAsyncThunk<
    Integration,
    CreateIntegration,
    { state: RootState }
>('itemtypes/{itemTypeId}/integrations/post', async (args, { dispatch }) => {
    const baseRoute = getBaseRoute(args);
    return await runSafe(async () => {
        const createdIntegration = await post(baseRoute, args);
        dispatch(
            addNotification({
                message: 'Integration has been created successfully',
                isSuccess: true,
            })
        );
        return createdIntegration;
    }, dispatch);
});

interface UpdateIntegration extends UpdateIntegrationRequest, IntegrationParams {
    id: string;
}

export const updateIntegration = createAsyncThunk<
    Integration,
    UpdateIntegration,
    { state: RootState }
>('itemtypes/{itemTypeId}/integrations/patch', async (args, { dispatch }) => {
    const baseRoute = getBaseRoute(args);

    return await runSafe(async () => {
        const updatedIntegration = await patch(`${baseRoute}/${args.id}`, args);

        dispatch(
            addNotification({
                message: 'Integration has been updated successfully',
                isSuccess: true,
            })
        );
        return updatedIntegration;
    }, dispatch);
});

export interface DeleteIntegrationParams {
    integrationId: string;
    projectNumber?: string;
}
export const deleteIntegration = createAsyncThunk<
    string,
    DeleteIntegrationParams,
    { state: RootState }
>('itemtypes/{itemTypeId}/integrations/{id}/delete', async (args, { getState, dispatch }) => {
    return await runSafe(async () => {
        const itemTypeId = getState().itemTypes.selectedItemType.id;
        const baseRoute = getBaseRoute({
            projectNumber: args.projectNumber,
            itemTypeId: itemTypeId,
        });
        await remove(`${baseRoute}/${args.integrationId}`);
        dispatch(
            addNotification({
                message: 'Integration has been deleted successfully',
                isSuccess: true,
            })
        );
        return args.integrationId;
    }, dispatch);
});

interface SyncIntegrationParams {
    integrationId: string;
    projectNumber?: string;
}
export const syncIntegration = createAsyncThunk<
    string,
    SyncIntegrationParams,
    { state: RootState }
>('itemtypes/{itemTypeId}/integrations/{id}/sync', async (args, { getState, dispatch }) => {
    return await runSafe(async () => {
        const itemTypeId = getState().itemTypes.selectedItemType.id;
        const baseRoute = getBaseRoute({
            projectNumber: args.projectNumber,
            itemTypeId: itemTypeId,
        });
        const result = await post(`${baseRoute}/${args.integrationId}/sync`);
        dispatch(
            addNotification({
                message: 'Status recalculation has been completed successfully',
                isSuccess: true,
            })
        );
        return result;
    }, dispatch);
});
