import { faTrashAlt } from '@fortawesome/free-regular-svg-icons/faTrashAlt';
import { youAreOfflineFeatureNotAvailableMessage } from '@laingorourke/core-web-components';
import { useIsOffline } from 'hooks/useIsOffline';
import React, { PropsWithChildren } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { ActionModalButton } from './ActionModalButton';
import { EditButton } from './EditButton';
import styles from './TableRowActionButtons.module.scss';

export const TableRowActionButtons: React.FC<
    PropsWithChildren<{
        editPath: string;
        entityId: string;
        hideEdit?: boolean;
        hideDelete?: boolean;
        skipDelete?: boolean;
        deleteBody?: string;
        deleteTitle?: string;
        isDeleteDisabled?: boolean;
        isEditDisabled?: boolean;
        onDelete?: () => Promise<void>;
        successfullyUpdated?: boolean | undefined;
        deleteDisabledMessage?: string;
        editDisabledMessage?: string;
        routeParams?: { [paramName: string]: string | number | boolean | undefined };
        prefix?: React.ReactNode;
    }>
> = ({
    editPath,
    entityId,
    hideEdit,
    hideDelete,
    skipDelete,
    deleteBody,
    isDeleteDisabled,
    isEditDisabled,
    onDelete,
    deleteTitle,
    deleteDisabledMessage,
    editDisabledMessage,
    successfullyUpdated,
    routeParams,
    prefix,
    children,
}) => {
    const history = useHistory();
    const goToEdit = () => history.push(generatePath(editPath, routeParams));
    const isOffline = useIsOffline();
    return (
        <div className={styles.buttonsContainer} onClick={(e) => e.stopPropagation()}>
            {prefix}
            <EditButton
                className={hideEdit ? styles.hidden : ''}
                key={`${entityId!}-edit`}
                message={isOffline ? youAreOfflineFeatureNotAvailableMessage : editDisabledMessage!}
                onClick={goToEdit!}
                disabled={isOffline || isEditDisabled}
            />
            {!skipDelete && (
                <ActionModalButton
                    className={hideDelete ? styles.hidden : ''}
                    key={`${entityId!}-delete`}
                    body={deleteBody!}
                    title={deleteTitle!}
                    message={
                        isOffline ? youAreOfflineFeatureNotAvailableMessage : deleteDisabledMessage!
                    }
                    successfullyUpdated={successfullyUpdated}
                    onInvokeAction={onDelete!}
                    disabled={isOffline || isDeleteDisabled}
                    okButtonText="Delete"
                    icon={faTrashAlt}
                />
            )}
            {children}
        </div>
    );
};
