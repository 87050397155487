import { WeatherEventType } from 'domain/models/api-models';
import { emojiValidationMessage, emojis, invalidCharacters } from 'views/components/form/validators';
import * as Yup from 'yup';

const requiredMessage = 'At least 1 category is required';
export const WeatherInspectionTypeSchema = Yup.object().shape({
    categoriesIds: Yup.array(Yup.string())
        .nullable()
        .min(1, requiredMessage)
        .required(requiredMessage),
    weatherEvent: Yup.string().required('Weather Event is required'),
    timePeriodInHours: Yup.number()
        .nullable()
        .when('weatherEvent', (weatherEvent: WeatherEventType, schema: any) => {
            if (weatherEvent === WeatherEventType.Rainfall) {
                return schema.required('Time Period is required');
            }
            return schema;
        }),
    limit: Yup.string().required('Limit is required'),
    value: Yup.number()
        .min(0.01)
        .max(400)
        .required('Value is required')
        .typeError(`Value must be a number between 0.01 and 400`),
    acknowledgmentMessage: Yup.string()
        .required('Acknowledgment Message is required')
        .matches(emojis, emojiValidationMessage)
        .matches(invalidCharacters, 'Acknowledgment Message contains invalid characters')
        .min(10, 'Acknowledgment Message cannot be shorter than 10 characters')
        .max(500, 'Acknowledgment Message cannot be longer than 500 characters'),
});
