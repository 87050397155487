import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { getQueryResult } from './graphQlClient';
import { Page, Person } from './models';

const query = gql`
    query getPeopleByIds($emails: [String!]!) {
        peopleByEmails(emails: $emails) {
            data {
                id
                displayName
                photo
                contact {
                    email
                }
            }
        }
    }
`;

export const usePeopleByEmails = (emails?: string[]) => {
    return useQuery(
        [emails, emails],
        async () => {
            const result = await getQueryResult<{
                peopleByEmails: Page<Person>;
            }>(query, {
                emails,
            });
            return result.peopleByEmails.data;
        },
        {
            enabled: !!emails?.length,
        }
    );
};
