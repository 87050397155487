import cn from 'classnames';
import React, { PropsWithChildren } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import styles from './ItemNumberPattern.module.scss';

interface TooltipProps {
    content: string;
    placement?: Placement;
    className: string;
}

export const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
    children,
    content,
    placement,
    className,
}) => {
    const tooltip = (
        <Popover className={cn(styles.popover, className)}>
            <Popover.Body className={styles.popover}>{content}</Popover.Body>
        </Popover>
    );
    return (
        <OverlayTrigger placement={placement} overlay={tooltip} delay={0}>
            <div>{children}</div>
        </OverlayTrigger>
    );
};

Tooltip.defaultProps = {
    placement: 'bottom',
};
