import { deleteWeatherInspectionType } from 'domain/store/reducers/weather';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableRowActionButtons } from 'views/components/table';
import { routes } from 'views/routes/Routes';
import { WeatherInspectionTypeTableRow } from '../WeatherInspectionTypeTableRow';

const weatherInspectionTypeDeletedMessage = 'This Weather Inspection Type has been deleted';

interface ActionButtonsProps {
    weatherInspectionType: WeatherInspectionTypeTableRow;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ weatherInspectionType }) => {
    const dispatch = useAppDispatch();
    const routeParams = useParams();
    const [successfullyUpdated, setSuccessfullyUpdated] = useState<boolean | undefined>(undefined);

    const onDelete = async () => {
        const result = await dispatch(deleteWeatherInspectionType(weatherInspectionType.id));
        if ((result as any).payload) setSuccessfullyUpdated(true);
    };

    return (
        <TableRowActionButtons
            editPath={
                routes.admin.routes!.weather.routes!.weatherInspectionTypes.path +
                `/edit/${weatherInspectionType.id}`
            }
            routeParams={routeParams}
            entityId={weatherInspectionType.id}
            deleteBody="Are you sure you want to remove this Weather Inspection Type?"
            onDelete={onDelete}
            deleteTitle="Delete Weather Inspection Type"
            deleteDisabledMessage={weatherInspectionTypeDeletedMessage}
            isDeleteDisabled={!weatherInspectionType.isActive}
            editDisabledMessage={weatherInspectionTypeDeletedMessage}
            isEditDisabled={!weatherInspectionType.isActive}
            successfullyUpdated={successfullyUpdated}
            hideEdit={!weatherInspectionType.canEdit}
            hideDelete={!weatherInspectionType.canDelete}
        />
    );
};

export default ActionButtons;
