import { Table } from '@laingorourke/core-web-components';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useProjects } from 'domain/store/reducers/projects';
import { fetchWeatherEvents, useWeatherEvents } from 'domain/store/reducers/weather';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getProjectDisplayName } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { ProjectParams } from 'views/routes/project';
import { WeatherEventTableRow } from './WeatherEventTableRow';
import styles from './WeatherEvents.module.scss';
import { WeatherWarning } from './WeatherWarning';
import { getTableColumns } from './tableColumns';

export const WeatherEvents: React.FC = () => {
    const dispatch = useAppDispatch();
    const weatherEvents = useWeatherEvents();
    const projects = useProjects();
    const itemTypeId = useSelectedItemTypeId();
    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();

    const weatherEventsTableData: WeatherEventTableRow[] = weatherEvents?.map((e) => ({
        ...e,
        projectName: getProjectDisplayName(e.projectNumber, projects),
        siteName:
            projects
                ?.find((p) => p.projectNumber === e.projectNumber)
                ?.sites?.find((s) => s.id.toLocaleLowerCase() === e.siteId.toLocaleLowerCase())
                ?.name ?? '',
    }));
    const dataLoaded = !!weatherEventsTableData;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Weather Events found');
    const columns = getTableColumns(!projectNumber);

    useEffect(() => {
        dispatch(fetchWeatherEvents({ itemTypeId, projectNumber }));
    }, [itemTypeId, dispatch]);

    return (
        <>
            <WeatherWarning />
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(
                    columns,
                    nameof<WeatherEventTableRow>('date'),
                    'desc'
                )}
                {...tableLoadingProps}
                data={dataLoaded ? weatherEventsTableData : []}
                keySelector={(d) => d.id}
                columns={columns}
                saveSelectedPageSize
                cacheKey="weather-alerts"
                rowClasses={() => styles.weatherTableRow}
                hover
            />
        </>
    );
};
