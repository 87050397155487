import {
    Checkbox,
    Form,
    Input,
    Select,
    TextArea,
    useForm,
    WarningPanel,
} from '@laingorourke/core-web-components';
import { InspectionType, ItemTypeCategory } from 'domain/models/api-models';
import { createInspectionType, updateInspectionType } from 'domain/store/reducers/inspectionType';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalFooter } from 'views/components';
import { InspectionTypeSchema } from './schemas';
import styles from './UpsertForm.module.scss';

interface UpsertInspectionTypeFormProps {
    isEditMode: boolean;
    inspectionType: InspectionType | undefined;
    categories: ItemTypeCategory[];
    goToInspectionTypes: () => void;
}

export const UpsertForm: React.FC<UpsertInspectionTypeFormProps> = ({
    isEditMode,
    inspectionType,
    categories,
    goToInspectionTypes,
}) => {
    const dispatch = useAppDispatch();
    const form = useForm({
        initialValues: {
            id: isEditMode ? inspectionType!.id! : '',
            name: isEditMode ? inspectionType!.name! : '',
            categoryIds: isEditMode ? inspectionType!.categoryIds ?? [] : [],
            description: isEditMode ? inspectionType!.description! : '',
            igmsLink: isEditMode ? inspectionType!.igmsLink! : '',
            evidenceRequired: isEditMode ? inspectionType?.evidenceRequired ?? true : true,
        },
        validationSchema: InspectionTypeSchema,
        onSubmit: async (values) => {
            let result;
            if (isEditMode) {
                result = await dispatch(updateInspectionType(values));
            } else {
                const { id, ...createValues } = values;
                result = await dispatch(createInspectionType(createValues));
            }
            if ((result as any).payload) goToInspectionTypes();
        },
    });

    const { isSubmitting } = form;

    return (
        <Form form={form}>
            <Modal.Body>
                <Form.Row>
                    <Form.Field name="categoryIds" label="Category">
                        <Select
                            className={styles.select}
                            options={categories.map((c) => ({ value: c.id, label: c.name }))}
                            multi
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="name" label="Name">
                        <Input />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="description" label="Description">
                        <TextArea />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="igmsLink" label="iGMS Link">
                        <Input />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="evidenceRequired" label="">
                        <Checkbox>Evidence is required</Checkbox>
                    </Form.Field>
                </Form.Row>
                <WarningPanel>
                    Check this checkbox enforces Evidence to be provided when the task is Completed
                </WarningPanel>
            </Modal.Body>
            <ModalFooter
                submitText={isEditMode ? 'Update' : 'Create'}
                isSubmitting={isSubmitting}
                onCancel={goToInspectionTypes}
            />
        </Form>
    );
};
