import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigation } from '@laingorourke/core-web-components';
import React from 'react';
import { routes } from '../Routes';
import styles from './ScanQrCodeButton.module.scss';
export const ScanQrCodeButton: React.FC<{ closeMenu: () => void }> = ({ closeMenu }) => {
    return (
        <Navigation onSelect={closeMenu}>
            <Navigation.Route route={routes.scanqrcode}>
                <FontAwesomeIcon className={styles.icon} icon={faQrcode} size="lg" />
                <span className={styles.name}>Scan QR Code</span>
            </Navigation.Route>
        </Navigation>
    );
};
