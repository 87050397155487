import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { getQueryResult } from './graphQlClient';
import { Project, Site } from './models';

const query = gql`
    query getProject($projectId: String!) {
        project(id: $projectId) {
            addresses {
                id
                name
                streetAddress
                postcode
                city
                state
            }
        }
    }
`;

export const useSites = (projectId: string | undefined) => {
    return useQuery(
        ['project', [projectId]],
        async () => {
            const result = await getQueryResult<{
                project: Project;
            }>(query, {
                projectId,
            });
            return result.project.addresses.map((a) => ({
                id: a.id,
                name: a.name,
                address: `${a.streetAddress}, ${a.postcode} ${a.city}, ${a.state}`,
            })) as Site[];
        },
        {
            enabled: !!projectId,
        }
    );
};
