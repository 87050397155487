import { Middleware } from '@reduxjs/toolkit';
import { errorOccurredMessage } from 'appSettings';
import { LogLevel, LogService } from 'domain/services/logService';
import { setError } from 'domain/store/reducers/applicationMessage';
import { loadingCompleted } from '../reducers/inProgress/reducer';

export const errorMiddleware: Middleware<{}, any> = (store) => (next) => (action) => {
    try {
        if (
            action &&
            action.type.indexOf('/rejected') !== -1 &&
            // if the request is aborted by Comply we don't want to display this error to the user
            !action.meta.aborted
        ) {
            store.dispatch(loadingCompleted());

            const service = new LogService();
            if (window.navigator.onLine) {
                store.dispatch(
                    setError({
                        isError: true,
                        message: action.error?.message,
                    })
                );

                service.logError(
                    `An error occurred while fetching '${action.type}': ${JSON.stringify(
                        action.error.message
                    )}`,
                    action.error?.stack,
                    getLogLevel(action.error?.stack)
                );
            }
        }

        return next(action);
    } catch (e: any) {
        store.dispatch(
            setError({
                isError: true,
                message: errorOccurredMessage,
            })
        );

        store.dispatch(loadingCompleted());

        const service = new LogService();
        service.logError(`An error occurred: ${e.message}`, e.stack);
        console.error(e);
        throw e;
    }
};

const getLogLevel = (errorStack?: string) => {
    if (errorStack?.includes('NotFoundException')) {
        return LogLevel.Warning;
    }
    return LogLevel.Error;
};
