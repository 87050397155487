import { ProjectItemNumberConfiguration } from 'domain/models/api-models';
import React from 'react';
import styles from './ItemNumberPattern.module.scss';
import { Tooltip } from './Tooltip';

interface SeparatorProps {
    configuration: ProjectItemNumberConfiguration;
}

export const Separator: React.FC<SeparatorProps> = ({ configuration }) => (
    <Tooltip content="Separator" className={styles.separator}>
        <span className={styles.separator}>{configuration.separator}</span>
    </Tooltip>
);
