import { ColumnDefinition, textFilter } from '@laingorourke/core-web-components';
import cn from 'classnames';
import { Classification, ProjectLeadDays, Status } from 'domain/models/api-models';
import { nameof } from 'helpers/nameOf';
import {
    defaultVerticalAlignment,
    EvidenceRequiredCell,
    igmsLinkFormatter,
    LeadDaysCell,
    LeadDaysTableHeader,
    ProjectDisplayName,
} from 'views/components';
import { getFilterOptions, getItemTypeStatusColumn } from 'views/components/table';
import styles from '../../../../components/table/Table.module.scss';
import ActionButtons from './components/ActionButtons';
import { LeadDayTableRow } from './LeadDayTableRow';
interface ProjectLeadDaysRow extends ProjectLeadDays {
    projectName: string;
}

const actionsFormatter = (cell: any, row: ProjectLeadDaysRow) => (
    <ActionButtons projectLeadDays={row} />
);

const actionsColumn: ColumnDefinition<LeadDayTableRow> = {
    field: (d) => d.controlMeasureId,
    text: '',
    renderer: actionsFormatter,
    headerClass: styles.minWitdh100,
};

export const getTableColumns = (
    showEdit: boolean,
    classifications?: Classification[],
    projectLeadDaysRow?: ProjectLeadDaysRow[],
    statuses?: Status[]
): ColumnDefinition<LeadDayTableRow>[] => {
    const statusSortOrder = statuses && statuses.map((p) => p.name!);
    const statusOptions = getFilterOptions(projectLeadDaysRow, 'status', statusSortOrder);
    const statusColumn = getItemTypeStatusColumn(statuses, statusOptions);
    return [
        {
            field: (d) => d.controlMeasureName,
            text: 'Control Measure',
            sortable: true,
            filter: textFilter(),
            headerClass: styles.preWrappedHeaderTitle,
            renderer: (_: any, row: ProjectLeadDaysRow) => (
                <EvidenceRequiredCell
                    id={row.controlMeasureId}
                    evidenceRequired={row.evidenceRequired}>
                    {row.controlMeasureName}
                </EvidenceRequiredCell>
            ),
        },
        {
            field: (d) => d.leadDays,
            cellClass: `${defaultVerticalAlignment} relative`,
            text: '',
            headerClass: styles.minWidth120,
            headerRenderer: () => <LeadDaysTableHeader classifications={classifications} />,
            renderer: (_: any, row: ProjectLeadDaysRow) => (
                <LeadDaysCell
                    leadDays={row.leadDays?.map((l) => ({
                        classificationId: l.classificationId,
                        leadDays: !!l.projectLeadDays
                            ? l.projectLeadDays.value
                            : l.adminLeadDays?.value,
                        comments: !!l.projectLeadDays
                            ? l.projectLeadDays.comments
                            : l.adminLeadDays?.comments,
                    }))}
                    classifications={classifications}
                />
            ),
        },
        {
            field: (d) => d.projectName,
            text: 'Project',
            sortable: true,
            headerClass: styles.minWidth150,
            filter: textFilter(),
            renderer: (_, row: LeadDayTableRow) => (
                <ProjectDisplayName projectNumber={row.projectNumber} />
            ),
        },
        {
            ...statusColumn,
            field: (d) => d.status,
            text: 'Status when Completed',
            headerClass: styles.noWrappedWitheSpace,
            key: nameof<LeadDayTableRow>('status'),
        },
        {
            field: (d) => d.updateInspectionScheduleStartDate,
            sortable: true,
            text: 'Update Inspection Schedule Start Date',
            headerClass: cn(styles.minWidth130, styles.preWrappedHeaderTitle),
            renderer: (_: any, row: ProjectLeadDaysRow) =>
                row.updateInspectionScheduleStartDate ? 'Yes' : 'No',
        },
        {
            field: (d) => d.igmsLink,
            text: 'iGMS Link',
            renderer: igmsLinkFormatter,
            headerClass: cn(styles.width90, styles.noWrappedWitheSpace),
        },
        {
            ...actionsColumn,
            hidden: !showEdit,
        },
    ];
};
