import { ColumnDefinition, textFilter } from '@laingorourke/core-web-components';
import { userSelectFilter } from '@laingorourke/core-web-components-personselector';
import cn from 'classnames';
import { nameof } from 'helpers/nameOf';
import { DateTableCell, PersonTableCell } from 'views/components';
import styles from './PastInspections.module.scss';
import { PastInspectionTableRow } from './PastInspectionTableRow';

export const getTableColumns = (
    userFilters: string[],
    projectNumber: string
): ColumnDefinition<PastInspectionTableRow>[] => [
    // This column has been added to store Task Id required to redirect user to MyTasks app
    {
        field: (d) => d.id,
        text: '',
        hidden: true,
    },
    {
        field: (d) => d.healthColor,
        text: '',
        headerClass: styles.healthHeader,
        cellStyle: (row) => ({
            ['--bs-table-accent-bg']: row.healthColor,
            padding: 0,
        }),
        renderer: (_: any, row: PastInspectionTableRow) => <></>,
    },
    {
        field: (d) => d.completedOn,
        text: 'Date',
        sortable: true,
        renderer: (cell: any, row: PastInspectionTableRow) => (
            <DateTableCell date={row.completedOn} />
        ),
        key: nameof<PastInspectionTableRow>('completedOn'),
    },
    {
        field: (d) => d.name,
        text: 'Task',
        sortable: true,
        filter: textFilter(),
        key: nameof<PastInspectionTableRow>('name'),
    },
    {
        field: (d) => d.status,
        text: 'Status',
        headerClass: cn(styles.noWrappedWitheSpace, styles.minWidth80),
    },
    {
        field: (d) => d.assignedUserId,
        text: 'Completed By',
        key: nameof<PastInspectionTableRow>('assignedUserId'),
        headerClass: `${styles.preWitheSpace} ${styles.minWidth200}`,
        renderer: (_: any, row: PastInspectionTableRow) => <PersonTableCell {...row.assignee} />,
        filter: userSelectFilter({
            contextId: 'user-select-filter',
            isMulti: true,
            placeholder: 'Select...',
        }),
    },
];
