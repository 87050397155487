import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { Button } from '@laingorourke/core-web-components';
import cn from 'classnames';
import React from 'react';
import { ButtonProps } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import styles from './ActionButton.module.scss';
import { ButtonOverlayTrigger } from './ButtonOverlayTrigger';

export interface ActionButtonProps extends ButtonProps {
    message?: string;
    buttonText?: string;
    //TODO: remove this key
    key: string;
    icon: IconLookup;
    placement?: Placement;
}

export const ActionButton: React.FC<ActionButtonProps> = (props) => {
    const { disabled, message, key, icon, className, placement, buttonText, ...rest } = props;

    const button = (
        <Button
            {...rest}
            variant={!!buttonText ? 'secondary' : 'icon'}
            className={cn(className, styles.button)}
            disabled={disabled}
            icon={{
                icon: icon as any,
                size: 'lg',
            }}
            style={disabled ? { pointerEvents: 'none' } : {}}>
            {buttonText}
        </Button>
    );

    return (
        <>
            {!disabled && button}
            {disabled && (
                <ButtonOverlayTrigger message={message} key={key} placement={placement}>
                    {button}
                </ButtonOverlayTrigger>
            )}
        </>
    );
};
