import {
    Button,
    Divider,
    Skeleton,
    Tour,
    useDevice,
    useTour,
} from '@laingorourke/core-web-components';
import { useItemTypes, useSelectedItemTypeCode } from 'domain/store/reducers/itemType';
import { useFavouriteProjects, useProjects } from 'domain/store/reducers/projects';
import { fetchActiveTourSteps, useActiveTourSteps } from 'domain/store/reducers/tourSteps';
import { useAppDispatch } from 'domain/store/rootStore';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { ContentEditor } from 'views/routes/admin/tour/components/ContentEditor';
import { routes } from 'views/routes/Routes';

const tooltipWidth = 500;

export const TourButton: React.FC = () => {
    // Tour is not supported on mobile as it would require a significant effort to make it look good and it's not worth it at this stage
    const isMobile = useDevice().isMobile;
    if (isMobile) {
        return <></>;
    }

    const dispatch = useAppDispatch();
    const tour = useTour();
    const { pathname } = useLocation();
    const projects = useProjects();
    const favouriteProjects = useFavouriteProjects();
    const itemTypeCode = useSelectedItemTypeCode();
    const itemTypes = useItemTypes();
    const match = matchPath(pathname, {
        path: routes.projects.routes!.project.path,
    });

    const routeParams = match?.params || {
        projectNumber: !!favouriteProjects?.length
            ? favouriteProjects[0].projectNumber
            : !!projects?.length
            ? projects[0].projectNumber
            : '',
        itemTypeCode: !!itemTypeCode ?? (!!itemTypes?.length ? itemTypes[0].code : ''),
    };
    const tourSteps = useActiveTourSteps();
    const dataLoaded = !!favouriteProjects && !!projects && !!itemTypes?.length && !!tourSteps;

    // @ts-ignore Ignore placement incompatibility
    const steps: TourStep[] = tourSteps
        ?.slice()
        ?.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
        ?.map((s) => ({
            target: s.target!,
            path: s.path!,
            title: s.title!,
            content: s.content!,
            disableBeacon: true,
            placement: s.placement ?? 'auto',
            routeParams,
        }));

    useEffect(() => {
        dispatch(fetchActiveTourSteps());
    }, []);

    return (
        <>
            {!!steps?.length && tour.run && dataLoaded && (
                <Tour
                    steps={steps.map((step) => ({ ...step, routeParams }))}
                    contentComponentRenderer={(content) => (
                        <ContentEditor
                            value={content}
                            isEditable={false}
                            placeholder={
                                <>
                                    <Skeleton.Text width={tooltipWidth} />
                                    <Skeleton.Text width={tooltipWidth} />
                                </>
                            }
                        />
                    )}
                />
            )}
            {!!steps?.length && (
                <>
                    <Button loading={!dataLoaded} onClick={() => tour.setRun(true)}>
                        Give me a tour!
                    </Button>

                    <Divider vertical={true} />
                </>
            )}
        </>
    );
};
