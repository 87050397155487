import { generatePath } from '@laingorourke/core-web-components';
import { AreaSummary } from 'domain/models/api-models';
import { useAreaCodeInItemNumber } from 'domain/store/reducers/projectItemNumberConfiguration';
import { useIsExternalUser } from 'domain/store/reducers/users';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActionButtonsPanel, usePageActions } from 'views/components';
import { TooltipButton } from 'views/components/tooltipButton';
import { ProjectParams } from 'views/routes/project/Project';
import { routes } from 'views/routes/Routes';
import styles from './ActionButtons.module.scss';

const creatingItemsDisabledMessage = 'Area/s must exist and contain an area code.';

export const ActionButtons: React.FC<{ areas: AreaSummary[] }> = ({ areas }) => {
    const history = useHistory();
    const isExternalUser = useIsExternalUser();
    const { projectNumber, itemTypeCode } = useParams<ProjectParams>();
    const areaCodeInItemNumber = useAreaCodeInItemNumber();

    const hasAreas = areas?.length > 0;
    const areasWithIncorrectCodeConfig = areas
        ?.filter((a) => !a.isAreaCodeConfigValid && !a.parentAreaId)
        .map((a) => a.name);
    const areasConfigInvalid = areasWithIncorrectCodeConfig?.length > 0;
    const creatingItemsDisabled = areaCodeInItemNumber && (areasConfigInvalid || !hasAreas);

    const goToNewProjectItem = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.items.routes!.newItem.path, {
                projectNumber,
                itemTypeCode,
            })
        );

    const goToUploadCsv = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.items.routes!.uploadCsv.path, {
                projectNumber,
                itemTypeCode,
            })
        );

    usePageActions(
        <ActionButtonsPanel>
            {!isExternalUser && (
                <TooltipButton
                    id="csv-upload-btn"
                    variant="secondary"
                    className={styles.uploadCsvBtn}
                    tooltipContent={creatingItemsDisabled ? creatingItemsDisabledMessage : ''}
                    disabled={creatingItemsDisabled}
                    onClick={goToUploadCsv}>
                    Upload CSV
                </TooltipButton>
            )}
            <TooltipButton
                id="new-project-item"
                onClick={goToNewProjectItem}
                disabled={!hasAreas || creatingItemsDisabled}
                tooltipContent={
                    creatingItemsDisabled
                        ? creatingItemsDisabledMessage
                        : `You haven't added any Areas yet."`
                }>
                New Item
            </TooltipButton>
        </ActionButtonsPanel>,
        [creatingItemsDisabled, hasAreas, itemTypeCode]
    );
    return <></>;
};
