import { Navigation, Page } from '@laingorourke/core-web-components';
import { getFeaturesConfiguration } from 'helpers/applicationConfig';
import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { AdminRouteParams } from '../AdminRouteParams';

export const Weather: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ManageWeatherSettingsRead} ignoreSecurable={true}>
        <WeatherContent>{children}</WeatherContent>
    </SecuredContent>
);

const WeatherContent: React.FC<PropsWithChildren> = ({ children }) => {
    const weatherEnabled = getFeaturesConfiguration()?.weatherEnabled;
    if (!weatherEnabled) return null;
    const params = useParams<AdminRouteParams>();
    const weatherRoutes = routes.admin.routes!.weather.routes!;

    return (
        <Row>
            <Col md="3" lg="2">
                <Page.Section noPadding>
                    <Navigation direction="vertical" id="weather-navigation">
                        <Navigation.Route
                            route={weatherRoutes.weatherInspectionTypes}
                            params={{ ...params }}>
                            Inspection Events
                        </Navigation.Route>
                        <Navigation.Route
                            route={weatherRoutes.weatherEvents}
                            params={{ ...params }}>
                            Site Events
                        </Navigation.Route>
                    </Navigation>
                </Page.Section>
            </Col>
            <Col md="9" lg="10">
                {children}
            </Col>
        </Row>
    );
};
