import { faBarsSort } from '@fortawesome/pro-regular-svg-icons';
import { Skeleton } from '@laingorourke/core-web-components';
import {
    FilterOptions,
    FilterType,
    ProjectTasksView,
    TaskColumn,
} from '@laingorourke/core-web-mytasks-components';
import { Task } from '@laingorourke/core-web-mytasksreactsdk';
import { ProjectItem, Status } from 'domain/models/api-models';
import {
    fetchProjectClassifications,
    useClassifications,
} from 'domain/store/reducers/classifications';
import {
    useItemStatuses,
    useSelectedItemTypeId,
    useSelectedItemTypeMyTasksWorkflowId,
} from 'domain/store/reducers/itemType';
import { fetchProjectItems, useProjectItems } from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ClassificationBadge, SecuredContent } from 'views/components';

export const Tasks: React.FC = () => (
    <SecuredContent permissions={Permission.ProjectDetailsRead}>
        <TasksContent />
    </SecuredContent>
);

const TasksContent: React.FC = () => {
    const { projectNumber } = useParams<{ projectNumber: string }>();
    const workflowId = useSelectedItemTypeMyTasksWorkflowId();
    const dispatch = useAppDispatch();
    const itemTypeId = useSelectedItemTypeId();

    const classifications = useClassifications();
    const items = useProjectItems();
    const statuses = useItemStatuses();

    const classificationFilter: FilterOptions = {
        key: 'classification',
        name: 'Classification',
        placeholder: 'Select Classifications',
        icon: faBarsSort,
        type: FilterType.MultiSelect,
        options: classifications.map((c) => ({ label: c.name, value: c.id })),
        clientFilter: (tasks: Task[], filterValue: string) => {
            const value = filterValue.toLowerCase();
            return tasks.filter((task) =>
                task.meta.find((m) => m.label === 'ClassificationId' && m.value === value)
            );
        },
        serverFilter: (value: string[]) => ({
            or: value.map((v) => ({
                meta: {
                    any: {
                        label: 'ClassificationId',
                        value: v.toString(),
                    },
                },
            })),
        }),
    };

    useEffect(() => {
        dispatch(fetchProjectClassifications({ itemTypeId: itemTypeId!, projectNumber }));
        dispatch(fetchProjectItems({ itemTypeId: itemTypeId!, projectNumber }));
    }, [itemTypeId, projectNumber, dispatch]);
    return (
        <>
            {workflowId && (
                <ProjectTasksView
                    workflowIds={[workflowId]}
                    projectNumber={projectNumber}
                    filtersConfig={(defaultFilters: Record<string, FilterOptions>) => {
                        const { title, status, assignee, due, health, tags } = defaultFilters;
                        return {
                            title,
                            status,
                            assignee,
                            due,
                            health,
                            classification: classificationFilter,
                            tags,
                        };
                    }}
                    columnsConfig={(defaultColumns) => {
                        // remove delegatee from defaults
                        defaultColumns.splice(4, 1);
                        // add item status column
                        defaultColumns.splice(6, 0, getStatusColumn(items, statuses));
                        // add classification column
                        defaultColumns.splice(7, 0, classificationColumn);
                        // remove phase column from defaults
                        defaultColumns.splice(9, 1);
                        return defaultColumns;
                    }}
                />
            )}
        </>
    );
};

const classificationColumn: TaskColumn = {
    text: 'Classification',
    field: (task) => task.id,
    renderer: (_, row: Task) => {
        return row.meta.some((m) => m.label === 'ClassificationId') ? (
            <ClassificationBadge id={row.meta.find((m) => m.label === 'ClassificationId')?.value} />
        ) : (
            ''
        );
    },
};

const getStatusColumn = (items?: ProjectItem[], statuses?: Status[]): TaskColumn => ({
    text: 'Item Status',
    field: (task) => task.id,
    renderer: (_, row: Task) => {
        const projectItemId = row.meta.find(
            (m) => m.label.toLocaleLowerCase() === 'projectitemid'
        )?.value;
        const item = items?.find((i) => i.id === projectItemId);
        const status = statuses?.find((s) => s.id === item?.statusId);
        return !!status?.name ? status?.name : <Skeleton.Box height={21} />;
    },
});

export default Tasks;
