import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ControlMeasure } from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import {
    createControlMeasure,
    deleteControlMeasure,
    fetchControlMeasures,
    updateControlMeasure,
} from './actions';

const initialState = {
    controlMeasures: undefined as unknown as ControlMeasure[],
};

const controlMeasuresSlice = createSlice({
    name: 'controlMeasures',
    initialState: initialState,
    reducers: {
        clearControlMeasures() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchControlMeasures.fulfilled.type,
            (state, action: PayloadAction<ControlMeasure[]>) => {
                state.controlMeasures = action.payload;
            }
        );
        builder.addCase(
            createControlMeasure.fulfilled.type,
            (state, action: PayloadAction<ControlMeasure>) => {
                if (action.payload === undefined) return;
                state.controlMeasures.push(action.payload);
            }
        );
        builder.addCase(
            updateControlMeasure.fulfilled.type,
            (state, action: PayloadAction<ControlMeasure>) => {
                if (action.payload === undefined) return;
                state.controlMeasures = state.controlMeasures.map((c) =>
                    c.id === action.payload.id ? action.payload : c
                );
            }
        );
        builder.addCase(
            deleteControlMeasure.fulfilled.type,
            (state, action: PayloadAction<string>) => {
                if (action.payload === undefined) return;
                state.controlMeasures = state.controlMeasures.filter(
                    (c) => c.id !== action.payload
                );
            }
        );
    },
});

export const useControlMeasures = () =>
    useSelector((state: RootState) => state.controlMeasures.controlMeasures);

export const useControlMeasure = (id: string) =>
    useSelector((state: RootState) =>
        state.controlMeasures.controlMeasures?.find((cm) => cm.id === id)
    );

export const { clearControlMeasures } = controlMeasuresSlice.actions;
export default controlMeasuresSlice.reducer;
