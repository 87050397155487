import { Table } from '@laingorourke/core-web-components';
import { Integrations } from 'domain/models/api-models';
import { useProjects } from 'domain/store/reducers/projects/reducer';
import { hasPermission, useCurrentUser } from 'domain/store/reducers/users/reducer';
import { getEnumKeyByEnumValue } from 'helpers/enumHelpers';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { getProjectDisplayName } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { SharedRouteParams } from 'views/routes/shared';
import { IntegrationType } from '../../IntegrationType';
import { IntegrationTableRow } from './IntegrationTableRow';
import { getTableColumns } from './tableColumns';

interface IntegrationTableProps {
    integrations: Integrations;
}

export const IntegrationTable: React.FC<IntegrationTableProps> = ({ integrations }) => {
    const projects = useProjects();
    const currentUser = useCurrentUser();
    const { projectNumber } = useParams<SharedRouteParams>();
    const isProjectMode = !!projectNumber;
    const dataLoaded = !!integrations?.integrationsList;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Integrations found');

    const integrationsTableData = useMemo(
        () =>
            integrations?.integrationsList?.map((i) => {
                const hasWritePermission = hasPermission(
                    currentUser,
                    Permission.ManageIntegrationSettingsWrite,
                    i.projectNumber
                );

                return {
                    ...i,
                    projectName: getProjectDisplayName(i.projectNumber, projects),
                    type: getEnumKeyByEnumValue(
                        IntegrationType,
                        i.controlMeasureId === null
                            ? IntegrationType.Inspection
                            : IntegrationType.ControlMeasure
                    ),
                    canEdit:
                        hasWritePermission && (!isProjectMode || projectNumber === i.projectNumber),
                    canDelete:
                        hasPermission(
                            currentUser,
                            Permission.ManageIntegrationSettingsDelete,
                            i.projectNumber
                        ) &&
                        (!isProjectMode || projectNumber === i.projectNumber),
                    canSync: hasWritePermission,
                } as IntegrationTableRow;
            }),
        [dataLoaded, projectNumber, integrations?.integrationsList]
    );

    const showSyncColumn = !!integrationsTableData?.find((c) => c.canSync);
    const showActions = !!integrationsTableData?.find((c) => c.canEdit || c.canDelete);

    const columns = getTableColumns(showSyncColumn, showActions);
    return (
        <>
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(columns)}
                {...tableLoadingProps}
                data={dataLoaded ? integrationsTableData! : []}
                columns={columns}
                keySelector={(d) => d.id}
                saveSelectedPageSize
                cacheKey="integrations"
                hover
            />
        </>
    );
};
