import {
    generatePath,
    Navigation,
    Page,
    UnauthorisedPage,
} from '@laingorourke/core-web-components';
import {
    fetchMetadata,
    setItemTypeCode,
    useItemTypes,
    useSelectedItemTypeCode,
    useSelectedItemTypeCodeIsSet,
    useSelectedItemTypeId,
} from 'domain/store/reducers/itemType';
import { useIsAdmin } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { getFeaturesConfiguration } from 'helpers/applicationConfig';
import React, { PropsWithChildren, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ItemTypeSelector, PageActions } from 'views/components';
import { routes } from '../Routes';
import { useIsAdminRootPage } from '../routesHooks';
import { AdminRouteParams } from './AdminRouteParams';

export const Admin: React.FC<PropsWithChildren> = (props) => {
    const dispatch = useAppDispatch();
    const params = useParams<AdminRouteParams>();
    const history = useHistory();
    const itemTypes = useItemTypes();
    const isSelectedItemTypeCodeIsSet = useSelectedItemTypeCodeIsSet();
    const selectedItemTypeCode = useSelectedItemTypeCode();
    const isAdminRootPage = useIsAdminRootPage();
    const isCurrentUserAdmin = useIsAdmin();
    const itemTypeId = useSelectedItemTypeId();
    const features = getFeaturesConfiguration();

    const adminRoutes = routes.admin.routes!;
    const adminParams = { itemTypeCode: selectedItemTypeCode! };

    useEffect(() => {
        if (itemTypes.length !== 0) {
            dispatch(setItemTypeCode(params.itemTypeCode));
        }
    }, [itemTypes, params.itemTypeCode, dispatch]);

    useEffect(() => {
        if (isSelectedItemTypeCodeIsSet && isAdminRootPage) {
            history.push(generatePath(routes.admin.path, adminParams));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemTypeCode]);
    useEffect(() => {
        if (itemTypeId) dispatch(fetchMetadata({ itemTypeId }));
    }, [itemTypeId, dispatch]);
    return isCurrentUserAdmin ? (
        <PageActions>
            {(content) => (
                <Page
                    header={
                        <Page.Header
                            title={'Administration'}
                            actions={[<ItemTypeSelector />, content]}>
                            {selectedItemTypeCode && (
                                <Navigation direction="horizontal">
                                    <Navigation.Route
                                        route={adminRoutes.complianceTemplates}
                                        params={adminParams}>
                                        Compliance Templates
                                    </Navigation.Route>
                                    <Navigation.Route
                                        route={adminRoutes.controlMeasures}
                                        params={adminParams}>
                                        Control Measures
                                    </Navigation.Route>
                                    <Navigation.Route
                                        route={adminRoutes.inspectionTypes}
                                        params={adminParams}>
                                        Inspections
                                    </Navigation.Route>
                                    <Navigation.Route
                                        route={adminRoutes.integrations}
                                        params={adminParams}>
                                        Integrations
                                    </Navigation.Route>
                                    <Navigation.Route
                                        route={adminRoutes.designers}
                                        params={adminParams}>
                                        Designers
                                    </Navigation.Route>
                                    <Navigation.Route
                                        route={adminRoutes.classifications}
                                        params={adminParams}>
                                        Classifications
                                    </Navigation.Route>
                                    <Navigation.Route
                                        route={adminRoutes.transfers}
                                        params={adminParams}>
                                        Transfers
                                    </Navigation.Route>
                                    {features?.weatherEnabled && (
                                        <Navigation.Route
                                            route={adminRoutes.weather}
                                            params={adminParams}>
                                            Weather
                                        </Navigation.Route>
                                    )}
                                    <Navigation.Route route={adminRoutes.tour} params={adminParams}>
                                        Tour
                                    </Navigation.Route>
                                </Navigation>
                            )}
                        </Page.Header>
                    }>
                    {props.children}
                </Page>
            )}
        </PageActions>
    ) : (
        <UnauthorisedPage />
    );
};
