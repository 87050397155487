import { generatePath } from '@laingorourke/core-web-components';
import { ProjectLeadDays } from 'domain/models/api-models';
import { useProjectClassifications } from 'domain/store/reducers/classifications';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useProjectControlMeasureLeadDays } from 'domain/store/reducers/projectLeadDays';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { LeadDaysRouteParams } from '../LeadDaysRouteParams';
import { UpdateForm } from './UpdateForm';

export const UpdateLeadDays: React.FC = () => {
    const { projectNumber, itemTypeCode, controlMeasureId } = useParams<LeadDaysRouteParams>();
    const history = useHistory();
    const goToProjectLeadDays = () =>
        history.push(
            generatePath(
                routes.projects.routes!.project.routes!.projectSetup.routes!.leadDays.path,
                {
                    projectNumber,
                    itemTypeCode,
                }
            )
        );
    const classifications = useProjectClassifications();
    const itemTypeId = useSelectedItemTypeId();
    const leadDays: ProjectLeadDays | undefined =
        useProjectControlMeasureLeadDays(controlMeasureId);

    return (
        <>
            {classifications && leadDays && (
                <Modal show={true} onHide={goToProjectLeadDays} animation={false} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Lead Days</Modal.Title>
                    </Modal.Header>
                    <SecuredContent permissions={Permission.ProjectLeadDaysWrite}>
                        <UpdateForm
                            projectNumber={projectNumber}
                            itemTypeId={itemTypeId}
                            leadDays={leadDays}
                            classifications={classifications}
                            goToProjectLeadDays={goToProjectLeadDays}
                            isProjectControlMeasure={!!leadDays.projectNumber}
                        />
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
