import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from 'domain/models/api-models';
import { get } from 'domain/services/client';
import { RootState } from 'domain/store/rootStore';
import { Permission } from 'security/Permission';

export const fetchCurrentUserDetails = createAsyncThunk<User, void, { state: RootState }>(
    'users/current',
    async () => (await get('users/current'))!
);

const getRequestKey = (permission: Permission) => `users/${permission}`;
const GetUsersWithPermission = async (projectNumber: string, permission: Permission) => {
    const path = `/users/${permission}/${projectNumber}`;
    return (await get(path))! as string[];
};

interface FetchUsersWithPermisionParams {
    projectNumber: string;
}

export const fetchOwnersIds = createAsyncThunk<
    string[],
    FetchUsersWithPermisionParams,
    { state: RootState }
>(
    getRequestKey(Permission.IncludeInOwnersListRead),
    async (args) => GetUsersWithPermission(args.projectNumber, Permission.IncludeInOwnersListRead)
);

export const fetchAreaOwnersIds = createAsyncThunk<
    string[],
    FetchUsersWithPermisionParams,
    { state: RootState }
>(
    getRequestKey(Permission.IncludeInAreaOwnersListRead),
    async (args) => GetUsersWithPermission(args.projectNumber, Permission.IncludeInAreaOwnersListRead)
);

export const fetchsUsersWithProjectWeatherAcknowledgementRead = createAsyncThunk<
    string[],
    FetchUsersWithPermisionParams,
    { state: RootState }
>(
    getRequestKey(Permission.ProjectWeatherAcknowledgementRead),
    async (args) => GetUsersWithPermission(args.projectNumber, Permission.ProjectWeatherAcknowledgementRead)
);
