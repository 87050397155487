import { faEdit } from '@fortawesome/free-regular-svg-icons/faEdit';
import {
    Page,
    Skeleton,
    youAreOfflineFeatureNotAvailableMessage,
} from '@laingorourke/core-web-components';
import { TASKKEY, useWorkflow } from '@laingorourke/core-web-mytasksreactsdk';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { fetchProjectClassifications } from 'domain/store/reducers/classifications';
import {
    fetchMetadata,
    fetchProjectStatuses,
    setItemTypeId,
    useItemStatuses,
    useSelectedItemTypeMyTasksWorkflowId,
} from 'domain/store/reducers/itemType';
import { clearItemDetails } from 'domain/store/reducers/projects';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { useIsOffline } from 'hooks/useIsOffline';
import { PropsWithChildren, default as React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ActionButton } from 'views/components/table/tableRowActionButtons/ActionButton';
import { UpsertItem } from '../../list';
import { Mode } from '../../list/components/Mode';
import { ProjectItemDetailsPage } from '../ProjectItemDetailsPage';
import { ProjectItemDetailsParams } from '../ProjectItemDetailsParams';
import { editBlockedMessage } from '../messages';
import { getNonEditableStatusesMessage } from '../statusesHelper';
import styles from './ItemInfo.module.scss';
import { ControlMeasures } from './controlMeasures/ControlMeasures';
import { ItemDetails } from './itemDetails';
import { PastInspections } from './pastInspections';
import { QRCode } from './qrCode';
import { UpcomingInspections } from './upcomingInspections';
import { useDetails } from './useDetails';

interface ItemInfoProps {
    showHeader?: boolean;
}

export const ItemInfo: React.FC<PropsWithChildren<ItemInfoProps>> = ({ children, showHeader }) => {
    const dispatch = useAppDispatch();
    const { projectItemId, projectNumber, itemTypeCode } = useParams<ProjectItemDetailsParams>();

    const workflowId = useSelectedItemTypeMyTasksWorkflowId();
    const workflow = useWorkflow(workflowId!);
    const workflowStatuses = workflow.data?.statuses?.sort((a, b) => a.order - b.order);
    const itemStatuses = useItemStatuses();
    const queryClient = useQueryClient();
    const isOffline = useIsOffline();
    const currentUserHasManageItemsWritePermission = useHasPermissions(
        Permission.ManageItemsWrite,
        projectNumber
    );

    const [mode, setMode] = useState<Mode | undefined>(undefined);

    const nonEditableStatusesMessage = getNonEditableStatusesMessage(itemStatuses);

    const itemDetails = useDetails(projectItemId, projectNumber);
    const itemTypeId = itemDetails?.itemTypeId;

    const canAddTask = itemDetails && itemDetails.canBeModified;
    const myTasksOperationsBlocked = !itemDetails || itemDetails?.myTasksOperationsBlocked;
    const readonly = !currentUserHasManageItemsWritePermission;

    const getTooltipContent = () => {
        if (isOffline) {
            return youAreOfflineFeatureNotAvailableMessage;
        }
        if (!itemDetails) {
            return 'Loading...';
        }
        if (!itemDetails.canBeModified)
            return `Item can not be edited as it is of status ${nonEditableStatusesMessage}`;
        if (itemDetails.myTasksOperationsBlocked) {
            return editBlockedMessage;
        }
        return '';
    };

    useEffect(() => {
        dispatch(setItemTypeId(itemTypeId));
        dispatch(fetchProjectStatuses({ itemTypeId }));
        dispatch(fetchMetadata({ itemTypeId }));
        dispatch(fetchProjectClassifications({ itemTypeId: itemTypeId!, projectNumber }));
    }, [itemTypeId, dispatch]);

    // invalidate query (and get tasks again when the page is opened again)
    useEffect(() => {
        return () => {
            queryClient.invalidateQueries(TASKKEY());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ProjectItemDetailsPage
            title={itemDetails?.name}
            showHeader={showHeader}
            leftSidebar={
                <>
                    {children}
                    {mode !== undefined && itemDetails && (
                        <UpsertItem
                            item={itemDetails}
                            itemTypeId={itemDetails.itemTypeId}
                            mode={mode}
                            goBack={() => setMode(undefined)}
                        />
                    )}
                    <Page.Section className={cn(styles.section)}>
                        <h3 className={styles.header}>
                            <span>Item Details</span>
                            {currentUserHasManageItemsWritePermission && (
                                <ActionButton
                                    key="popover-edit-disabled"
                                    disabled={
                                        isOffline ||
                                        !itemDetails ||
                                        !itemDetails.canBeModified ||
                                        itemDetails.myTasksOperationsBlocked
                                    }
                                    onClick={() => {
                                        dispatch(clearItemDetails());
                                        setMode(Mode.Edit);
                                    }}
                                    icon={faEdit}
                                    message={getTooltipContent()}
                                    placement="bottom"
                                />
                            )}
                            {/* Clone functionality is temporarily disabled: https://lorait.atlassian.net/browse/TW-514 */}
                            {/* <ActionButton
                                        key="popover-clone-disabled"
                                        disabled={!itemDetails.canBeCloned}
                                        onClick={() => setMode(Mode.Clone)}
                                        icon={faClone}
                                        message="Item can not be cloned as it is not the correct status."
                                        placement="bottom"
                                    /> */}
                        </h3>
                        <ItemDetails itemDetails={itemDetails} />
                    </Page.Section>
                    <Page.Section
                        className={cn(
                            styles.qrCodeContainer,
                            styles.section,
                            showHeader ? '' : 'mb-0'
                        )}>
                        <Skeleton.Box height={200} loading={!itemDetails || !itemDetails.name}>
                            <QRCode
                                itemTitle={itemDetails?.name!}
                                itemNumber={itemDetails?.number!}
                                projectNumber={projectNumber}
                                qrCodeAsBase64={itemDetails?.qrCodeAsBase64!}
                            />
                        </Skeleton.Box>
                    </Page.Section>
                </>
            }>
            <Page.Section className={styles.section}>
                <ControlMeasures
                    canModifyTasks={canAddTask}
                    myTasksOperationsBlocked={myTasksOperationsBlocked}
                    statuses={workflowStatuses}
                    nonEditableStatusesMessage={nonEditableStatusesMessage}
                    itemDetailsOwnerId={itemDetails?.ownerId}
                    readonly={readonly} />
            </Page.Section>
            <Page.Section className={styles.section}>
                <UpcomingInspections
                    myTasksOperationsBlocked={myTasksOperationsBlocked}
                    canAddInspection={canAddTask}
                    statuses={workflowStatuses}
                    nonEditableStatusesMessage={nonEditableStatusesMessage}
                    itemTypeCode={itemTypeCode!}
                    readonly={readonly}
                    schedulesAmended={itemDetails?.schedulesAmended}
                />
            </Page.Section>
            <Page.Section className={styles.section}>
                <h3 className={styles.header}>Past Inspections</h3>
                <PastInspections statuses={workflowStatuses} />
            </Page.Section>
        </ProjectItemDetailsPage>
    );
};

ItemInfo.defaultProps = {
    showHeader: true,
};
