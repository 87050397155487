import { AsyncThunkAction } from '@reduxjs/toolkit';
import { fetchProjectItemDetails, useItemDetails } from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import { useEffect, useState } from 'react';

export const useDetails = (projectItemId: string, projectNumber: string) => {
    const dispatch = useAppDispatch();
    const itemDetails = useItemDetails();
    const itemDetailsLoaded = !!itemDetails && itemDetails.id === projectItemId;

    const [fetchProjectItemDetailsPromise, setFetchProjectItemDetailsPromise] = useState<
        ReturnType<AsyncThunkAction<any, any, {}>> | undefined
    >();

    useEffect(() => {
        if (itemDetailsLoaded) return;

        if (fetchProjectItemDetailsPromise) fetchProjectItemDetailsPromise.abort();

        const promise = dispatch(fetchProjectItemDetails({ projectItemId, projectNumber }));
        setFetchProjectItemDetailsPromise(promise);
    }, [projectNumber, projectItemId, itemDetailsLoaded]);

    return itemDetails;
};
