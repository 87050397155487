import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    Classification,
    ComplianceTemplateData,
    CreateClassificationRequest,
    UpdateClassificationRequest,
} from 'domain/models/api-models';
import { get, patch, post, remove } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

export const fetchAllItemTypesClassifications = createAsyncThunk<
    Classification[],
    void,
    { state: RootState }
>('itemtypes/all/projects/all/classifications/all-itemtypes/get', async () => {
    return (await get(`itemtypes/all/projects/all/classifications/all-itemtypes`))!;
});

export const fetchAllProjectsClassifications = createAsyncThunk<
    Classification[],
    {
        itemTypeId: string;
    },
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/all/classifications/all-projects/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/all/classifications/all-projects`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId,
    }
);

interface FetchParams {
    projectNumber: string;
    itemTypeId: string;
}

export const fetchProjectClassifications = createAsyncThunk<
    Classification[],
    FetchParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/classifications/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/classifications`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

export const fetchAllowedBaseComplianceTemplates = createAsyncThunk<
    ComplianceTemplateData[],
    FetchParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/classifications/allowed-base-compliance-templates/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/classifications/allowed-base-compliance-templates`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface CreateClassification extends CreateClassificationRequest {
    itemTypeId: string;
    projectNumber: string;
}

export const createClassification = createAsyncThunk<
    Classification,
    CreateClassification,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/project/{projectNumber}/classifications/post',
    async (classification, { dispatch }) => {
        return await runSafe(async () => {
            const createdClassification = await post(
                `itemtypes/${classification.itemTypeId}/projects/${classification.projectNumber}/classifications`,
                classification
            );
            dispatch(
                addNotification({
                    message: 'Classification has been created successfully',
                    isSuccess: true,
                })
            );
            return createdClassification;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface UpdateProjectClassifications extends UpdateClassificationRequest {
    itemTypeId: string;
    projectNumber: string;
    id: string;
}

export const updateClassification = createAsyncThunk<
    Classification,
    UpdateProjectClassifications,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/classifications/patch',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const updatedProjectLeadDays = await patch(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/classifications/${args.id}`,
                args
            );

            dispatch(
                addNotification({
                    message: 'Classification has been updated successfully',
                    isSuccess: true,
                })
            );
            return updatedProjectLeadDays;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

export const reactivateClassification = createAsyncThunk<
    Classification,
    { id: string; itemTypeId: string; projectNumber: string },
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/classifications/patch/reactivate',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const updatedProjectLeadDays = await patch(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/classifications/${args.id}/reactivate`,
                args
            );

            dispatch(
                addNotification({
                    message: 'Classification has been activated successfully',
                    isSuccess: true,
                })
            );
            return updatedProjectLeadDays;
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

interface DeleteClassificationParams {
    itemTypeId: string;
    projectNumber: string;
    classificationId: string;
}

export const deleteClassification = createAsyncThunk<
    string,
    DeleteClassificationParams,
    { state: RootState }
>('itemtypes/{itemTypeId}/classifications/delete', async (args, { dispatch }) => {
    return await runSafe(async () => {
        await remove(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/classifications/${args.classificationId}`
        );
        dispatch(
            addNotification({
                message: 'Classification has been deleted successfully',
                isSuccess: true,
            })
        );
        return args.classificationId;
    }, dispatch);
});
