import { useToasts } from '@laingorourke/core-web-components';
import { removeNotification, useNotification } from 'domain/store/reducers/notificationsReducer';
import { useAppDispatch } from 'domain/store/rootStore';
import { getToastMessage } from 'helpers/notificationHelper';
import React, { PropsWithChildren, useEffect } from 'react';

export const Main: React.FC<PropsWithChildren> = (props) => {
    const dispatch = useAppDispatch();
    const notification = useNotification();
    const toasts = useToasts();
    useEffect(() => {
        if (notification.show) {
            toasts.newMessage(getToastMessage(notification.message, notification.isSuccess));
            dispatch(removeNotification());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification, dispatch]);

    return <>{props.children}</>;
};
