import { ButtonProps, generatePath } from '@laingorourke/core-web-components';
import { useHasPermissions } from 'domain/store/reducers/users';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ActionButtonsPanel, usePageActions } from 'views/components';
import { TooltipButton } from 'views/components/tooltipButton';
import { ProjectParams } from 'views/routes/project/Project';
import { routes } from 'views/routes/Routes';

interface NewClassificationButtonProps extends ButtonProps {
    disabledMessage: string;
}

export const NewClassificationButton: React.FC<NewClassificationButtonProps> = ({
    disabled,
    disabledMessage,
}) => {
    const routeParams = useParams<ProjectParams>();
    const history = useHistory();

    const currentUserHasAnyProjectClassificationsWritePermission = useHasPermissions(
        Permission.ProjectClassificationsWrite,
        undefined,
        true
    );

    const goToNewClassification = () =>
        history.push(
            generatePath(
                routes.projects.routes!.project.routes!.projectSetup.routes!.classifications.routes!
                    .newClassification.path,
                {
                    ...routeParams,
                }
            )
        );

    usePageActions(
        currentUserHasAnyProjectClassificationsWritePermission && (
            <ActionButtonsPanel>
                <TooltipButton
                    variant="primary"
                    onClick={goToNewClassification}
                    id="new-classification"
                    disabled={disabled}
                    tooltipContent={disabledMessage}>
                    New Classification
                </TooltipButton>
            </ActionButtonsPanel>
        ),
        [
            currentUserHasAnyProjectClassificationsWritePermission,
            routeParams.itemTypeCode,
            disabled,
            disabledMessage,
        ]
    );

    return <> </>;
};
