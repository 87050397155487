import { ColumnDefinition, selectFilter, textFilter } from '@laingorourke/core-web-components';
import { nameof } from 'helpers/nameOf';
import { TableCellActive } from 'views/components';
import { getFilterOptionsFromArray } from 'views/components/table';
import styles from '../../../../../components/table/Table.module.scss';
import { GroupTableRow, StatusOption } from './GroupTableRow';

export const getTableColumns = (): ColumnDefinition<GroupTableRow>[] => {
    const statusOptions = getFilterOptionsFromArray(Object.values(StatusOption));

    return [
        {
            field: (d) => d.name,
            text: 'Subcontractor',
            sortable: true,
            filter: textFilter(),
            key: nameof<GroupTableRow>('name'),
        },
        {
            field: (d) => d.member,
            text: 'Member',
            sortable: true,
            filter: textFilter(),
        },
        {
            field: (d) => d.jobTitle,
            text: 'Job Title',
            sortable: true,
            filter: textFilter(),
        },
        {
            field: (d) => d.role,
            text: 'Comply Role',
            sortable: true,
            filter: textFilter(),
        },
        {
            field: (d) => d.permission,
            text: 'Item Permission',
            sortable: false,
            filter: textFilter(),
        },
        {
            field: (d) => d.approvalRequired,
            headerClass: styles.minWidth150,
            text: 'Status',
            sortable: true,
            renderer: (value: any, row) =>
                row.approvalRequired === StatusOption.Pending ? (
                    <>{StatusOption.Pending}</>
                ) : (
                    <TableCellActive isActive={row.approvalRequired === StatusOption.Active} />
                ),
            filter: selectFilter({
                options: statusOptions,
            }),
        },
    ];
};
