import { faLockKeyhole, faUnlockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input, TextArea } from '@laingorourke/core-web-components';
import { Classification, ProjectClassificationLeadDays } from 'domain/models/api-models';
import React from 'react';
import { Button } from 'react-bootstrap';
import { TooltipWrapper } from '..';
import { ClassificationBadge } from '../classificationBadge/ClassificationBadge';
import styles from './LeadDaysFieldArray.module.scss';

interface LeadDaysFieldArrayProps {
    classifications: Classification[];
    leadDays: ProjectClassificationLeadDays[];
    isProjectControlMeasure: boolean;
}

export const LeadDaysFieldArray: React.FC<LeadDaysFieldArrayProps> = ({
    classifications,
    leadDays,
    isProjectControlMeasure,
}) => (
    <Form.List
        name="leadDays"
        label="Lead Days"
        render={(arrayHelpers) =>
            classifications.map((c) => {
                const index = leadDays.indexOf(leadDays.find((l) => l.classificationId === c.id)!);
                const classificationLeadDays = leadDays.find((l) => l.classificationId === c.id);
                const projectLeadDays = classificationLeadDays?.projectLeadDays;
                const adminLeadDays = classificationLeadDays?.adminLeadDays;
                return (
                    <React.Fragment key={c.id}>
                        <Form.Row className={styles.leadDaysRow}>
                            <ClassificationBadge id={c.id} className={styles.badge} />
                            {!!projectLeadDays && (
                                <Form.Field
                                    name={`leadDays[${index}].projectLeadDays.value`}
                                    className={styles.leadDaysField}>
                                    <Input />
                                </Form.Field>
                            )}
                            {!projectLeadDays && (
                                <Form.Field
                                    name={`leadDays[${index}].adminLeadDays.value`}
                                    disabled={!isProjectControlMeasure}
                                    className={styles.leadDaysField}>
                                    <Input />
                                </Form.Field>
                            )}
                            {!isProjectControlMeasure && (
                                <TooltipWrapper
                                    id={`leadDays[${index}]`}
                                    placement="top"
                                    tooltipContent={
                                        !!projectLeadDays
                                            ? `This project has configured its own Lead Days. The SME has set ${
                                                  adminLeadDays?.value ?? 'no value'
                                              } for all projects. To follow the SME settings, click the unlock icon.`
                                            : `This is following Lead Days for all projects as configured by the SME. To implement your own, click the lock icon.`
                                    }>
                                    <Button
                                        variant={!!projectLeadDays ? 'primary' : 'secondary'}
                                        onClick={() => {
                                            arrayHelpers.replace(index, {
                                                ...classificationLeadDays,
                                                projectLeadDays: !!projectLeadDays
                                                    ? undefined
                                                    : {
                                                          value: '',
                                                          comments: '',
                                                      },
                                            });
                                        }}
                                        className={styles.lockButton}>
                                        <FontAwesomeIcon
                                            icon={
                                                !!projectLeadDays ? faUnlockKeyhole : faLockKeyhole
                                            }
                                            size="lg"
                                        />
                                    </Button>
                                </TooltipWrapper>
                            )}
                        </Form.Row>
                        <Form.Row className={styles.leadDaysRow}>
                            {!!projectLeadDays && (
                                <Form.Field
                                    name={`leadDays[${index}].projectLeadDays.comments`}
                                    className={styles.comments}>
                                    <TextArea placeholder="Comments" />
                                </Form.Field>
                            )}
                            {!projectLeadDays && (
                                <Form.Field
                                    name={`leadDays[${index}].adminLeadDays.comments`}
                                    disabled={!isProjectControlMeasure}
                                    className={styles.comments}>
                                    <TextArea placeholder="Comments" />
                                </Form.Field>
                            )}
                        </Form.Row>
                    </React.Fragment>
                );
            })
        }
    />
);
