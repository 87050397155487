import { Table } from '@laingorourke/core-web-components';
import {
    clearAllProjectsClassifications,
    fetchAllProjectsClassifications,
    useAllProjectsClassifications,
} from 'domain/store/reducers/classifications';
import {
    clearControlMeasures,
    fetchControlMeasures,
    useControlMeasures,
} from 'domain/store/reducers/controlMeasure';
import {
    fetchProjectStatuses,
    useItemStatuses,
    useItemTypeMetadata,
    useSelectedItemTypeId,
} from 'domain/store/reducers/itemType';
import { useProjects } from 'domain/store/reducers/projects/reducer';
import { hasPermission, useCurrentUser } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { nameof } from 'helpers/nameOf';
import React, { PropsWithChildren, useEffect } from 'react';
import { Permission } from 'security/Permission';
import { SecuredContent, getProjectDisplayName } from 'views/components';
import { getDefaultPaginationOptions, getDefaultSortedByColummKey } from 'views/components/table';
import { useTableLoading } from 'views/components/table/hooks/useTableLoading';
import { ControlMeasureTableRow } from './ControlMeasureTableRow';
import { NewControlMeasureButton } from './components/NewControlMeasureButton';
import { getTableColumns } from './tableColumns';

export const ControlMeasures: React.FC<PropsWithChildren> = ({ children }) => (
    <SecuredContent permissions={Permission.ManageComplianceSettingsRead} ignoreSecurable={true}>
        <ControlMeasuresContent>{children}</ControlMeasuresContent>
    </SecuredContent>
);

const ControlMeasuresContent: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const currentUser = useCurrentUser();

    const controlMeasures = useControlMeasures();
    const itemTypeId = useSelectedItemTypeId();
    const metadata = useItemTypeMetadata();
    const projects = useProjects();
    const itemStatuses = useItemStatuses();
    const allClassifications = useAllProjectsClassifications();
    const classifications = allClassifications?.filter((c) => !c.projectNumber);

    const dataLoaded = !!controlMeasures && !!metadata && !!projects && !!classifications;
    const tableLoadingProps = useTableLoading(!dataLoaded, 'No Control Measures found');

    const controlMeasuresTableData: ControlMeasureTableRow[] | undefined =
        metadata &&
        controlMeasures?.map((c) => ({
            ...c,
            projectName: getProjectDisplayName(c.projectNumber, projects),
            canEdit: hasPermission(
                currentUser,
                Permission.ManageComplianceSettingsWrite,
                c.projectNumber
            ),
            canDelete: hasPermission(
                currentUser,
                Permission.ManageComplianceSettingsDelete,
                c.projectNumber
            ),
            status: itemStatuses?.find((s) => c.itemStatusWhenControlMeasureCompleted === s.id)
                ?.name,
            // we display only classifications / lead days not associated with any project
            leadDays: classifications
                ?.filter(
                    (classification) =>
                        !classification.projectNumber &&
                        !!c.leadDays?.find((l) => l.classificationId === classification.id)
                )
                .map(
                    (classification) =>
                        c.leadDays!.find((l) => l.classificationId === classification.id)!
                ),
        }));

    const columns = getTableColumns(
        !!controlMeasuresTableData?.find((c) => c.canEdit || c.canDelete),
        itemStatuses,
        controlMeasuresTableData,
        classifications ?? []
    );

    useEffect(() => {
        dispatch(fetchProjectStatuses({ itemTypeId: itemTypeId! }));
        dispatch(fetchControlMeasures());
        dispatch(fetchAllProjectsClassifications({ itemTypeId }));
        return () => {
            dispatch(clearControlMeasures());
            dispatch(clearAllProjectsClassifications());
        };
    }, [itemTypeId, dispatch]);

    return (
        <>
            <NewControlMeasureButton disabled={!dataLoaded} />
            {children}
            <Table
                {...getDefaultPaginationOptions()}
                {...getDefaultSortedByColummKey(columns, nameof<ControlMeasureTableRow>('status'))}
                {...tableLoadingProps}
                // this is far from ideal but this is really the only way to "enfore re-sort" once all categories are loaded
                // this is obviously not enforcing sort, but rerendering an entire component
                // Something like (table.current as any).sortContext.handleSort(columns[0]); cannot be used
                // as this just simulates "click" and doesn't consider sort order (asc / desc) and might lead to bugs
                // bootstrap react table doesn't support it in any other way
                key={itemStatuses?.length ?? 'none'}
                data={dataLoaded ? controlMeasuresTableData! : []}
                columns={columns}
                keySelector={(d) => d.id}
                saveSelectedPageSize
                cacheKey="control-measures"
                hover
            />
        </>
    );
};
