import { ColumnDefinition } from '@laingorourke/core-web-components';
import { DateTableCell } from 'views/components';
import { getPersonColumn } from 'views/components/table/personColumn';
import { ControlMeasureTableRow } from './ControlMeasureTableRow';
import styles from './ControlMeasures.module.scss';

export const getTableColumns = (
    statusesSortOrder: string[] | undefined
): ColumnDefinition<ControlMeasureTableRow>[] => [
        // This column has been added to store Task Id required to redirect user to MyTasks app
        {
            field: (d) => d.id,
            text: '',
            hidden: true,
        },
        {
            field: (d) => d.healthColor,
            text: '',
            headerClass: styles.healthHeader,
            cellStyle: (row) => ({
                ['--bs-table-accent-bg']: row.healthColor,
                padding: 0,
            }),
            renderer: (_: any, row: ControlMeasureTableRow) => <></>,
        },
        {
            field: (d) => d.name,
            text: 'Task',
            sortable: true,
        },
        {
            field: (d) => d.forecastedOn,
            text: 'Forecasted Completion',
            sortable: true,
            headerClass: styles.noWrappedWitheSpace,
            renderer: (_: any, row: ControlMeasureTableRow) => (
                <DateTableCell date={row.forecastedOn} />
            ),
        },
        {
            field: (d) => d.status,
            text: 'Status',
            sortable: true,
            headerClass: styles.noWrappedWitheSpace,
            customSort: (a, b, order) => {
                if (statusesSortOrder)
                    return order === 'asc'
                        ? statusesSortOrder.indexOf(a.status) - statusesSortOrder.indexOf(b.status)
                        : statusesSortOrder.indexOf(b.status) - statusesSortOrder.indexOf(a.status);
                return 1;
            },
        },
        {
            field: (d) => d.resolution,
            text: 'Reason',
            sortable: true,
        },
        {
            field: (d) => d.assignedUserId,
            text: 'Assignee',
            ...getPersonColumn('assignee', false, true),
        },
        {
            field: (d) => d.health,
            text: 'Health',
            sortable: true,
        },
    ];
