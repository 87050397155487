import { TableChangeEventHandler, useCachePageSizeState } from '@laingorourke/core-web-components';
import { Status, StatusType, useTasksSummaries } from '@laingorourke/core-web-mytasksreactsdk';
import { SortDirection } from '@laingorourke/core-web-types';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDefaultPaginationOptions } from 'views/components/table';
import { getTaskName } from '../../../../myTasksHelpers';
import { ProjectItemDetailsParams } from '../../ProjectItemDetailsParams';
import { TaskTable } from '../components';
import { getTableColumns } from './tableColumns';

const searchDelay = 500;

const cacheKey = 'past-inspections';

export const PastInspections: React.FC<{ statuses: Status[] | undefined }> = ({ statuses }) => {
    const { projectItemId, projectNumber } = useParams<ProjectItemDetailsParams>();
    const [nameFilter, setNameFilter] = useState('');
    const [assigneeFilter, setAssigneeFilter] = useState<string[]>([]);
    const [page, setPage] = useState(1);
    const defaultPaginationOptions = getDefaultPaginationOptions();
    const [sortColumnIndex, setSortColumnIndex] = useState(2);
    const [sortOrder, setSortOrder] = useState<SortDirection>('desc');
    const [timeoutValue, setTimeoutValue] = useState<NodeJS.Timeout>();
    const [isTableChanging, setIsTableChanging] = useState(false);

    const [sizePerPage, setSizePerPage] = useCachePageSizeState(
        cacheKey,
        defaultPaginationOptions.pageSize
    );

    const tableColumns = getTableColumns(assigneeFilter, projectNumber);

    const query = useMemo(() => {
        return !!tableColumns
            ? {
                filter: {
                    and: [
                        {
                            meta: {
                                any: { label: 'ProjectItemId', value: projectItemId },
                            },
                        },
                        {
                            meta: {
                                any: { label: 'InspectionTypeId' },
                            },
                        },
                        {
                            name: { contains: nameFilter },
                        },
                        {
                            ...(assigneeFilter &&
                                assigneeFilter.length > 0 && {
                                assignedUserId: { in: assigneeFilter },
                            }),
                        },
                        { 'status/type': StatusType.Completed },
                    ],
                },
                skip: (page - 1) * sizePerPage,
                top: sizePerPage,
                orderBy: `${tableColumns[sortColumnIndex].key} ${sortOrder}`,
            }
            : undefined;
    }, [page, sizePerPage, assigneeFilter, projectItemId, nameFilter, sortOrder]);

    const myTasks = useTasksSummaries(query);
    const data = statuses
        ? myTasks.data?.map((i) => ({
            id: i.id!,
            name: getTaskName(i.name),
            completedOn: i.completedOn ? new Date(i.completedOn) : null,
            assignedUserId: i.assignedUserId,
            status: i.status.name,
            health: i.health.toString()!,
            healthColor: i.healthColor,
        }))
        : undefined;

    const handleChange: TableChangeEventHandler = (e) => {
        setIsTableChanging(true);
        if (timeoutValue) {
            clearTimeout(timeoutValue);
        }

        const nameFilter = e.filters.find((f) => f.key === 'name');
        const assigneeFilter = e.filters.find((f) => f.key === 'assignedUserId');
        const timeout = setTimeout(() => {
            setNameFilter(nameFilter?.value ?? '');
            setAssigneeFilter(assigneeFilter?.value ?? '');
            setPage(e.page);
            setSizePerPage(e.pageSize);
            setSortColumnIndex(e.sortColumnIndex ?? 2);
            setSortOrder(e.sortDirection);
        }, searchDelay);
        setTimeoutValue(timeout);
        setIsTableChanging(false);
    };

    return (
        <TaskTable
            data={data}
            columns={tableColumns}
            onTableChange={handleChange}
            pagination={{
                currentPage: page,
                pageSize: sizePerPage,
                cacheKey: cacheKey,
                pageSizes: defaultPaginationOptions.pageSizes,
            }}
            isTableChanging={isTableChanging}
            isRemote
            sorted={{ sortColumnIndex: sortColumnIndex, sortDirection: sortOrder }}
            isFetching={myTasks.isFetching}
            noDataMessage="There are no completed Inspections for this item."
        />
    );
};
