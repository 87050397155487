import { ProjectItemNumberConfiguration } from 'domain/models/api-models';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ProjectParams } from 'views/routes/project/Project';
import styles from './ItemNumberPattern.module.scss';
import { Separator } from './Separator';
import { Tooltip } from './Tooltip';

interface ItemNumberPatternProps {
    configuration: ProjectItemNumberConfiguration;
}

export const ItemNumberPattern: React.FC<ItemNumberPatternProps> = ({ configuration }) => {
    const { projectNumber } = useParams<ProjectParams>();

    const areaCode = 'AAA';
    const zeros = '0'.repeat(configuration.maxItemNumber.toString().length - 1);
    const exampleNumber = `TW${zeros}1`;

    return (
        <>
            <h4>
                Creating items with the above configuration will apply numbers with the following
                pattern
            </h4>
            <div className={styles.example}>
                {!configuration.hideProjectNumber && (
                    <>
                        <Tooltip className={styles.projectNumber} content="Project Number">
                            <span className={styles.projectNumber}>{projectNumber}</span>
                        </Tooltip>
                        <Separator configuration={configuration} />
                    </>
                )}
                {configuration.areaCodeInItemNumber && (
                    <>
                        <Tooltip content="Area Code" className={styles['area-code']}>
                            <span className={styles['area-code']}>{areaCode}</span>
                        </Tooltip>
                        <Separator configuration={configuration} />
                    </>
                )}
                <Tooltip className={styles.number} content="Number">
                    <span className={styles.number}>{exampleNumber}</span>
                </Tooltip>
            </div>
        </>
    );
};
