import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Area, AreaSummary } from 'domain/models/api-models';
import { Person } from 'domain/services/dataService/models';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootStore';
import { fetchAreas, fetchAreasSummaries } from './actions';

const initialState = {
    areas: null as unknown as Area[],
    owners: null as unknown as Person[],
    summaries: null as unknown as AreaSummary[],
};

const areasSlice = createSlice({
    name: 'areas',
    initialState: initialState,
    reducers: {
        clearAreas() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAreas.fulfilled, (state, action: PayloadAction<Area[]>) => {
            state.areas = action.payload;
        });
        builder.addCase(
            fetchAreasSummaries.fulfilled,
            (state, action: PayloadAction<AreaSummary[]>) => {
                state.summaries = action.payload;
            }
        );
    },
});

export const useAreas = () => useSelector((state: RootState) => state.areas.areas);
export const useParentAreas = () =>
    useSelector((state: RootState) =>
        state.areas.areas === null
            ? null
            : state.areas.areas
                  .filter((a) => a.subAreaName === '')
                  .sort((a, b) => a.name!.localeCompare(b.name!))
    );
export const useSubAreas = () =>
    useSelector((state: RootState) =>
        state.areas.areas === null
            ? null
            : state.areas.areas
                  .filter((a) => a.subAreaName !== '')
                  .sort((a, b) => a.name!.localeCompare(b.subAreaName!))
    );
export const useParentAreasSummaries = () =>
    useSelector((state: RootState) =>
        state.areas.summaries === null
            ? null
            : state.areas.summaries
                  .filter((a) => !a.parentAreaId)
                  .sort((a, b) => a.name!.localeCompare(b.name!))
    );
export const useSubAreasSummaries = () =>
    useSelector((state: RootState) =>
        state.areas.summaries === null
            ? null
            : state.areas.summaries
                  .filter((a) => !!a.parentAreaId)
                  .sort((a, b) => a.name!.localeCompare(b.name!))
    );
export const useAreaSummaries = () => useSelector((state: RootState) => state.areas.summaries);
export const useArea = (id: string) =>
    useSelector((state: RootState) => state.areas.areas?.find((a) => a.id === id));

export const { clearAreas } = areasSlice.actions;
export default areasSlice.reducer;
