import { Select, SelectProps, useSelectSearch } from '@laingorourke/core-web-components';
import { Group } from 'domain/models/api-models';
import { useGroups } from 'domain/services/dataService/useGroups';
import { useEffect, useState } from 'react';

interface GroupSelectorProps
    extends Omit<
        SelectProps<string, true>,
        | 'value'
        | 'defaultValue'
        | 'ref'
        | 'multi'
        | 'beforeOnChange'
        | 'isDisabled'
        | 'isLoading'
        | 'components'
        | 'styles'
    > {
    selectedGroups?: Group[];
    projectNumber: string;
    groupSelected?: (selectedGroups: Group[]) => void;
}

interface OptionType {
    value: string;
    label: string;
}

const toOptionList = (groups?: Group[]) => {
    return (
        groups?.map((sg) => ({
            value: sg.id!,
            label: sg.name!,
        })) ?? ([] as OptionType[])
    );
};

const toGroupType = (options?: OptionType[]) => {
    return (
        options?.map((sg) => ({
            id: sg.value,
            name: sg.label,
        })) ?? ([] as Group[])
    );
};

export const GroupsSelector: React.FC<GroupSelectorProps> = ({
    selectedGroups,
    projectNumber,
    groupSelected,
    ...rest
}) => {
    const selectedToOptions = toOptionList(selectedGroups);
    const [search, setSearch] = useState<string>();
    const [selected, setSelected] = useState<OptionType[]>(selectedToOptions);
    const [updatingSelectedOptions, setUpdatingSelectedOptions] = useState<boolean>(false);
    const groupsQuery = useGroups(projectNumber, search);

    useEffect(() => {
        setUpdatingSelectedOptions(true);
        setSelected([
            ...selected,
            ...toOptionList(selectedGroups).filter(
                (s) => !selected?.some((sg) => sg.value === s.value)
            ),
        ]);
        setUpdatingSelectedOptions(false);
    }, [selectedGroups]);

    const selectProps = useSelectSearch({
        id: 'cacheGroupIds',
        onSearch: setSearch,
        selectedOptions: selected,
        options: [...toOptionList(groupsQuery.data), ...selected],
        cacheOptions: true,
    });
    return (
        <Select
            {...selectProps}
            {...rest}
            isLoading={groupsQuery.isFetching || updatingSelectedOptions}
            beforeOnChange={(groups) => {
                const selectedGroups = groups as unknown as OptionType[];
                setSelected(selectedGroups ?? []);
                groupSelected?.(toGroupType(selectedGroups) ?? []);
            }}
            disabled={updatingSelectedOptions}
            value={selected?.map((v) => v.value) ?? []}
            isMulti
        />
    );
};
