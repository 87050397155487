import { generatePath } from '@laingorourke/core-web-components';
import { Review } from 'domain/models/api-models';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useReview } from 'domain/store/reducers/reviews';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { ReviewRouteParams } from '../ReviewsRouteParams';
import { UpsertForm } from './UpsertForm';

export const UpsertReview: React.FC = () => {
    const { projectNumber, itemTypeCode, reviewId } = useParams<ReviewRouteParams>();
    const history = useHistory();
    const goToReview = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.reviews.path, {
                projectNumber,
                itemTypeCode,
            })
        );
    const itemTypeId = useSelectedItemTypeId();

    const isEditMode = reviewId !== undefined;
    const review: Review | undefined = useReview(reviewId);

    return (
        <Modal show={true} onHide={goToReview} animation={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? 'Edit' : 'New'} Review</Modal.Title>
            </Modal.Header>
            <SecuredContent permissions={Permission.ProjectGovernanceWrite}>
                <UpsertForm
                    isEditMode={isEditMode}
                    projectNumber={projectNumber}
                    itemTypeId={itemTypeId}
                    review={review}
                    goToReview={goToReview}
                />
            </SecuredContent>
        </Modal>
    );
};
