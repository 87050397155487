import React from 'react';
import { Link, TooltipWrapper } from 'views/components';

export const ReportLink: React.FC<{
    displayName: string;
    url?: string;
    projectNumber: string;
    itemTypeId: string;
    skipParams?: boolean;
}> = ({ displayName, url, projectNumber, itemTypeId, skipParams }) => {
    const reportUrl = skipParams ? url : `${url}?filter=Project/ProjectNumber eq '${projectNumber}' and ComplianceItemType/ComplianceItemTypeId eq '${itemTypeId}'`;
    return (
        <>
            {!!url && (
                <Link href={reportUrl} target="_blank">
                    {displayName}
                </Link>
            )}
            {!url && (
                <TooltipWrapper
                    id={`report-${displayName}`}
                    placement="right"
                    tooltipContent="This report is not available yet">
                    <span>{displayName}</span>
                </TooltipWrapper>
            )}
        </>
    );
};
