import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    Classification,
    ClassificationHub,
    ComplianceTemplateData
} from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import {
    createClassification,
    deleteClassification,
    fetchAllItemTypesClassifications,
    fetchAllowedBaseComplianceTemplates,
    fetchAllProjectsClassifications,
    fetchProjectClassifications,
    reactivateClassification,
    updateClassification,
} from './actions';

const initialState = {
    projectClassifications: undefined as unknown as Classification[],
    allProjectClassifications: undefined as unknown as Classification[],
    allowedBaseComplianceTemplates: undefined as unknown as ComplianceTemplateData[],
};

const classificationsSlice = createSlice({
    name: 'classifications',
    initialState: initialState,
    reducers: {
        clearProjectClassifications() {
            return initialState;
        },
        clearAllProjectsClassifications() {
            return initialState;
        },
        clearAllItemTypesClassifications() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchAllItemTypesClassifications.fulfilled.type,
            (state, action: PayloadAction<Classification[]>) => {
                state.allProjectClassifications = action.payload;
            }
        );
        builder.addCase(
            fetchAllProjectsClassifications.fulfilled.type,
            (state, action: PayloadAction<Classification[]>) => {
                state.allProjectClassifications = action.payload;
            }
        );
        builder.addCase(
            fetchProjectClassifications.fulfilled.type,
            (state, action: PayloadAction<Classification[]>) => {
                state.projectClassifications = action.payload;
            }
        );
        builder.addCase(
            fetchAllowedBaseComplianceTemplates.fulfilled.type,
            (state, action: PayloadAction<ComplianceTemplateData[]>) => {
                state.allowedBaseComplianceTemplates = action.payload;
            }
        );
        builder.addCase(
            createClassification.fulfilled.type,
            (state, action: PayloadAction<Classification>) => {
                if (action.payload === undefined) return;
                state.projectClassifications.push(action.payload);
            }
        );
        builder.addCase(
            updateClassification.fulfilled.type,
            (state, action: PayloadAction<Classification>) => {
                if (action.payload === undefined) return;
                state.projectClassifications = state.projectClassifications.map((c) =>
                    c.id === action.payload.id ? action.payload : c
                );
            }
        );
        builder.addCase(
            reactivateClassification.fulfilled.type,
            (state, action: PayloadAction<Classification>) => {
                if (action.payload === undefined) return;
                state.projectClassifications = state.projectClassifications.map((c) =>
                    c.id === action.payload.id ? action.payload : c
                );
            }
        );
        builder.addCase(
            deleteClassification.fulfilled.type,
            (state, action: PayloadAction<string>) => {
                if (action.payload === undefined) return;
                state.projectClassifications = state.projectClassifications.map((c) =>
                    c.id === action.payload ? { ...c, isActive: false } : c
                );
            }
        );
    },
});

const selectProjectClassifications = (state: RootState) => state.classifications.projectClassifications;
const selectAllProjectClassifications = (state: RootState) => state.classifications.allProjectClassifications;

const selectActiveProjectClassifications = createSelector(
    [selectProjectClassifications, (state, activeOnly) => activeOnly],
    (projectClassifications, activeOnly) => projectClassifications?.filter((c) => !activeOnly || c.isActive == activeOnly)
);

export const useProjectClassifications = (activeOnly = true) =>
    useSelector((state: RootState) => selectActiveProjectClassifications(state, activeOnly));

export const useAllProjectsClassifications = () => useSelector(selectAllProjectClassifications);

const sellectAllClassificationsSorted = createSelector(
    selectProjectClassifications,
    selectAllProjectClassifications,
    (projectClassifications, allProjectClassifications) =>
        [
            ...projectClassifications ?? [],
            ...allProjectClassifications ?? []
        ]
            .sort((classificationA, classificationB) => {
                const classificationHubs = Object.keys(ClassificationHub);
                return (
                    classificationHubs.indexOf(classificationA.hub!) -
                    classificationHubs.indexOf(classificationB.hub!) ||
                    classificationA.name!.localeCompare(classificationB.name!)
                );
            })
);

export const useClassifications = () => useSelector(sellectAllClassificationsSorted);

export const useClassification = (id: string) =>
    useSelector((state: RootState) =>
        state.classifications.projectClassifications?.find((c) => c.id === id)
    );

export const useAllowedBaseComplianceTemplates = () =>
    useSelector((state: RootState) => state.classifications.allowedBaseComplianceTemplates);

export const {
    clearProjectClassifications,
    clearAllProjectsClassifications,
    clearAllItemTypesClassifications,
} = classificationsSlice.actions;
export default classificationsSlice.reducer;
