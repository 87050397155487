import { HttpClient, HttpStatusCodes } from '@laingorourke/core-web-clientservices';
import { ApiVersionHeaderKey, ApiVersionStorageKey } from 'consts';
import semver from 'semver';
import { auth0Token } from './auth0Service';

export const firstHttpErrorCode = HttpStatusCodes.BadRequest;

const apiVersionCheck = (headers: Headers) => {
    const apiVersion = headers.get(ApiVersionHeaderKey);
    if (apiVersion === null) return;

    let currentVersion = window.localStorage.getItem(ApiVersionStorageKey);

    if (!currentVersion) {
        window.localStorage.setItem(ApiVersionStorageKey, apiVersion);
        return;
    }

    if (!semver.eq(currentVersion, apiVersion) && window.navigator.onLine) {
        window.localStorage.setItem(ApiVersionStorageKey, apiVersion);
        window.location.reload();
    }
};

const getClient = async () => {
    const token = await auth0Token.getAccessToken();
    return new HttpClient({
        headers: { Authorization: `Bearer ${token}` },
        onGet: apiVersionCheck,
    });
};

const getApiUrl = (url: string) => {
    if (url.indexOf('https://') === 0) return url;
    return url.indexOf('/') === 0 ? url : `/${url}`;
};
export const get = async <R = any>(url: string): Promise<R | null> =>
    (await getClient()).get<R>(getApiUrl(url)).catch(catchError);

export const getFromExternalApi = async <R = any>(
    baseUrl: string,
    path: string
): Promise<R | null> => {
    const token = await auth0Token.getAccessToken();
    const client = new HttpClient({
        baseUrl: baseUrl,
        headers: { Authorization: `Bearer ${token}` },
    });
    return client.get<R>(getApiUrl(path)).catch(catchError);
};

export const post = async <T = any, R = any>(url: string, data?: any): Promise<R | null> => {
    const response = (await getClient())
        .post<T, R>(getApiUrl(url), data)
        .catch<R>(async (error) => {
            // we don't catch errors from Log Controller to avoid loop
            if (error.status == 403) {
                throw new Error(notAuthorisedMessage);
            } else if (!error.ok && url.indexOf('logs/error') === -1) {
                const response = await error.json();
                let e = new Error(response.Title);
                error.stack = JSON.stringify(error.Detail);
                throw e;
            }
            return error;
        });
    return response!;
};

export const postFile = async <R = any>(url: string, file: File): Promise<R | null> =>
    (await getClient()).postFile<R>(getApiUrl(url), file).catch<R>(catchError);

export const put = async <T = any, R = any>(url: string, data?: any): Promise<R | null> =>
    (await getClient()).put<T, R>(getApiUrl(url), data).catch<R>(catchError);

export const patch = async <T = any, R = any>(url: string, data?: any): Promise<R | null> =>
    (await getClient()).patch<T, R>(getApiUrl(url), data).catch<R>(catchError);

export const postFilesAndData = async <T = any, R = any>(
    url: string,
    files: File[],
    data?: any
): Promise<R | null> => (await getClient()).postFilesAndData<T, R>(getApiUrl(url), files, data);

export const remove = async <R>(url: string): Promise<R | null> =>
    (await getClient()).delete<R>(getApiUrl(url)).catch<R>(catchError);

const catchError = async (error: any) => {
    if (error.status == 403) {
        throw new Error(notAuthorisedMessage);
    } else if (!error.ok) {
        const response = await error.json();
        let e = new Error(response.Title);
        e.stack = JSON.stringify(response.Detail);
        throw e;
    }
    return error;
};

export const notAuthorisedMessage = 'You are not authorised';
