import {
    AuthorisedContent,
    Drawer,
    generatePath,
    Skeleton,
    Timeline,
} from '@laingorourke/core-web-components';
import {
    clearIntegrationHistory,
    fetchIntegrationHistory,
    useIntegrationHistory,
} from 'domain/store/reducers/integrations';
import { useItemTypeId } from 'domain/store/reducers/itemType';
import { useAppDispatch } from 'domain/store/rootStore';
import { useEntityChangesWithAuthors } from 'hooks/useHistoryWithAuthors';
import { useIntegrationRoute } from 'hooks/useIntegrationRoute';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { OnlineContentLoader } from 'views/components';
import { IntegrationParams } from '..';
import styles from './IntegrationHistory.module.scss';

export const IntegrationHistory: React.FC = () => {
    const dispatch = useAppDispatch();
    const { integrationId, projectNumber, itemTypeCode } = useParams<IntegrationParams>();
    const history = useHistory();
    const itemTypeId = useItemTypeId(itemTypeCode);
    const isProjectMode = !!projectNumber;
    const integrationHistory = useIntegrationHistory();
    const integrationRoute = useIntegrationRoute();

    const goToIntegrations = () => {
        history.push(
            generatePath(integrationRoute.path, {
                itemTypeCode: itemTypeCode,
                ...(isProjectMode && { projectNumber }),
            })
        );
    };

    useEffect(() => {
        if (itemTypeId)
            dispatch(
                fetchIntegrationHistory({ integrationId: integrationId, itemTypeId, projectNumber })
            );

        return () => {
            dispatch(clearIntegrationHistory());
        };
    }, [itemTypeId, integrationId, dispatch]);

    const [entityChanges, loading] = useEntityChangesWithAuthors(integrationHistory);
    return (
        <Drawer
            push={true}
            closeOnClickAway={false}
            open={true}
            title={integrationHistory?.itemName}
            onChange={() => goToIntegrations()}
            className={styles.drawer}>
            <>
                {integrationHistory === undefined && <Skeleton.Text />}
                <AuthorisedContent authorised={integrationHistory !== null}>
                    <OnlineContentLoader loading={loading}>
                        <Timeline
                            entityChanges={entityChanges!}
                            loading={loading}
                            className={styles.timeline}
                        />
                    </OnlineContentLoader>
                </AuthorisedContent>
            </>
        </Drawer>
    );
};
