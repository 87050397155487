import { createAsyncThunk } from '@reduxjs/toolkit';
import { InspectionType } from 'domain/models/api-models';
import { get, patch, post, remove } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

export const fetchInspectionTypes = createAsyncThunk<InspectionType[], void, { state: RootState }>(
    'itemtypes/{itemTypeId}/inspectionTypes/get',
    async (_, { getState }) => {
        const { itemTypes } = getState();
        const itemTypeId = itemTypes.selectedItemType.id;
        return (await get(`itemtypes/${itemTypeId}/inspectionTypes`))!;
    },
    {
        condition: (_, { getState }) => getState().itemTypes.selectedItemType.id !== '',
    }
);

interface CreateInspectionType {
    name: string;
    description: string;
    igmsLink: string;
    categoryIds: string[];
}

export const createInspectionType = createAsyncThunk<
    InspectionType,
    CreateInspectionType,
    { state: RootState }
>('itemtypes/{itemTypeId}/inspectionTypes/post', async (inspectionType, { getState, dispatch }) => {
    return await runSafe(async () => {
        const itemTypeId = getState().itemTypes.selectedItemType.id;
        const createdInspectionType = await post(
            `itemtypes/${itemTypeId}/inspectiontypes`,
            inspectionType
        );
        dispatch(
            addNotification({
                message: 'Inspection has been created successfully',
                isSuccess: true,
            })
        );
        return createdInspectionType;
    }, dispatch);
});

interface UpdateInspectionType extends CreateInspectionType {
    id: string;
}

export const updateInspectionType = createAsyncThunk<
    InspectionType,
    UpdateInspectionType,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/inspectiontypes/patch',
    async (inspectionType, { getState, dispatch }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            const updatedInspectionType = await patch(
                `itemtypes/${itemTypeId}/inspectiontypes/${inspectionType.id}`,
                inspectionType
            );
            await dispatch(fetchInspectionTypes());
            dispatch(
                addNotification({
                    message: 'Inspection has been updated successfully',
                    isSuccess: true,
                })
            );
            return updatedInspectionType;
        }, dispatch);
    }
);

export const deleteInspectionType = createAsyncThunk<string, string, { state: RootState }>(
    'itemtypes/{itemTypeId}/inspectionTypes/delete',
    async (id, { getState, dispatch }) => {
        return await runSafe(async () => {
            const itemTypeId = getState().itemTypes.selectedItemType.id;
            await remove(`itemtypes/${itemTypeId}/inspectionTypes/${id}`);
            dispatch(
                addNotification({
                    message: 'Inspection has been deleted successfully',
                    isSuccess: true,
                })
            );
            return id;
        }, dispatch);
    }
);
