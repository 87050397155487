import { Form, useForm } from '@laingorourke/core-web-components';
import { Site } from 'domain/services/dataService/models';
import { loadingCompleted, loadingStarted } from 'domain/store/reducers/inProgress/reducer';
import { assignSite } from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Permission } from 'security/Permission';
import { ModalFooter, SecuredContent } from 'views/components';
import * as Yup from 'yup';
import { SelectedProjectItems } from '../components/SelectedProjectItem';
import { SiteSelect } from '../list/components/SiteSelect';

interface AssignSiteProps {
    itemTypeId: string;
    projectNumber: string;
    projectItemsIds: string[];
    show: boolean;
    sites: Site[];
    onClose: (success: boolean) => void;
}

export const AssignSite: React.FC<AssignSiteProps> = ({
    itemTypeId,
    projectNumber,
    projectItemsIds,
    sites,
    onClose,
}) => {
    const dispatch = useAppDispatch();

    const form = useForm({
        initialValues: {
            siteId: '',
        },
        validationSchema: Yup.object().shape({
            siteId: Yup.string().required('Site is required'),
        }),
        onSubmit: async (values) => {
            await dispatch(
                assignSite({
                    projectItemsIds,
                    siteId: values.siteId,
                    itemTypeId,
                    projectNumber,
                })
            );
            onClose(true);
        },
    });

    const { isSubmitting, values, setFieldValue } = form;

    const dataLoaded = !!sites;
    useEffect(() => {
        if (dataLoaded) dispatch(loadingCompleted());
        else dispatch(loadingStarted());
        return () => {
            dispatch(loadingCompleted());
        };
    }, [dataLoaded, dispatch]);

    return (
        <>
            {dataLoaded && !!sites?.length && (
                <Modal
                    show={true}
                    onHide={() => onClose(false)}
                    animation={false}
                    backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Assign Site</Modal.Title>
                    </Modal.Header>
                    <SecuredContent permissions={Permission.ManageItemsWrite}>
                        <Form form={form}>
                            <Modal.Body>
                                <Form.Row>
                                    <Form.Field name="siteId" label="Site">
                                        <SiteSelect
                                            sites={sites}
                                            selectedSiteId={values.siteId}
                                            onChange={(value) => setFieldValue('siteId', value)}
                                        />
                                    </Form.Field>
                                </Form.Row>
                                <SelectedProjectItems projectItemsIds={projectItemsIds} projectNumber={projectNumber} />
                            </Modal.Body>
                            <ModalFooter
                                submitText="Update"
                                isSubmitting={isSubmitting}
                                onCancel={() => onClose(false)}
                            />
                        </Form>
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
