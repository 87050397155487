import { PersonSelector } from '@laingorourke/core-web-components-personselector';
import { PersonSelectorProps } from '@laingorourke/core-web-components-personselector/lib/PersonSelectorProps';
import { useGroupMemberIdsFromCurrentUserGroups as useGroupMemberIdsFromCurrentExternalUser } from 'domain/services/dataService/useGroupMemberIdsFromCurrentExternalUser';
import { useProjectId } from 'domain/store/reducers/projects';
import { useIsExternalUser } from 'domain/store/reducers/users';
import { getDataServiceConfiguration } from 'helpers/applicationConfig';
import React from 'react';

interface ProjectPersonSelectorProps
    extends Omit<PersonSelectorProps, 'contextId' | 'searchOptions'> {
    projectNumber: string;
}

/**
 * @description - If the current user is internal, the select will search for people from the current
 * project with Comply permissions.
 *  - If the user is external, the select will search for people that are members of the groups
 * of the current user, that has permission to access the current project and has Comply permission
 */
export const ProjectPersonSelector: React.FC<ProjectPersonSelectorProps> = (props) => {
    const { projectNumber, ...rest } = props;
    const projectId = useProjectId(projectNumber);
    const systemId = getDataServiceConfiguration()?.systemId!;

    const isExternal = useIsExternalUser();

    //if the select is disabled, we don't want to trigger an unnecessary dataservice request
    const allMemberIdsFromExternalUserGroups = useGroupMemberIdsFromCurrentExternalUser(
        props.disabled
    );

    return (
        <PersonSelector
            contextId={projectNumber!}
            searchOptions={{
                projectPeopleSearchOptions: {
                    projectId: projectId!,
                    systemId: systemId,
                },
                ...(isExternal && { allowedPeopleIds: allMemberIdsFromExternalUserGroups.data }),
            }}
            {...rest}
        />
    );
};
