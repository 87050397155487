import { createAsyncThunk } from '@reduxjs/toolkit';
import { TourStep, UpdateTourStepRequest } from 'domain/models/api-models';
import { get, patch, remove } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

export const fetchTourSteps = createAsyncThunk<TourStep[], void, { state: RootState }>(
    'tourSteps/get',
    async () => {
        return (await get(`tourSteps`))!;
    }
);

export const fetchActiveTourSteps = createAsyncThunk<TourStep[], void, { state: RootState }>(
    'tourSteps/active/get',
    async () => {
        return (await get(`tourSteps/active`))!;
    }
);

interface UpdateTourStep extends UpdateTourStepRequest {
    id: string;
}

export const updateTourStep = createAsyncThunk<TourStep, UpdateTourStep, { state: RootState }>(
    'tourSteps/{tourStepId}/patch',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            const updatedTourStep = await patch(`tourSteps/${args.id}`, args);

            dispatch(
                addNotification({
                    message: 'Tour Step has been updated successfully',
                    isSuccess: true,
                })
            );
            return updatedTourStep;
        }, dispatch);
    }
);

interface ReactivateTourStep {
    id: string;
}

export const reactivateTourStep = createAsyncThunk<
    TourStep,
    ReactivateTourStep,
    { state: RootState }
>('tourSteps/{tourStepId}/reactivate/patch', async (args, { dispatch }) => {
    return await runSafe(async () => {
        const updatedTourStep = await patch(`tourSteps/${args.id}/reactivate`, args);

        dispatch(
            addNotification({
                message: 'Tour Step has been activated successfully',
                isSuccess: true,
            })
        );
        return updatedTourStep;
    }, dispatch);
});

interface DeleteParams {
    tourStepId: string;
}

export const deleteTourStep = createAsyncThunk<string, DeleteParams, { state: RootState }>(
    'tourSteps/delete/{tourStepId}',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await remove(`tourSteps/${args.tourStepId}`);
            dispatch(
                addNotification({
                    message: 'Tour Step has been deleted successfully',
                    isSuccess: true,
                })
            );
            return args.tourStepId;
        }, dispatch);
    },
    {
        condition: (args) => !!args.tourStepId,
    }
);
