import { Button, youAreOfflineFeatureNotAvailableMessage } from '@laingorourke/core-web-components';
import { Status, StatusType, useTasksSummaries } from '@laingorourke/core-web-mytasksreactsdk';
import cn from 'classnames';
import { useCurrentUser, useHasPermissions } from 'domain/store/reducers/users';
import { useIsOffline } from 'hooks/useIsOffline';
import { useItemInfoRoute } from 'hooks/useItemInfoRoute';
import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { AddButton, TooltipWrapper } from 'views/components';
import { getTaskName } from '../../../../myTasksHelpers';
import { ProjectItemDetailsParams } from '../../ProjectItemDetailsParams';
import { editBlockedMessage } from '../../messages';
import { TaskTable } from '../components';
import styles from './UpcomingInspection.module.scss';
import { UpcomingTaskTableRow } from './UpcomingTaskTableRow';
import { ChangeStatus } from './components/ChangeStatus';
import { getTableColumns } from './tableColumns';

const MyTasksOperationsBlockedMessage =
    'Unable to Add Inspection until background processing is complete.';

interface UpcomingInspections {
    statuses: Status[] | undefined;
    itemTypeCode: string;
    canAddInspection: boolean;
    nonEditableStatusesMessage: string;
    myTasksOperationsBlocked: boolean;
    readonly: boolean;
    schedulesAmended?: boolean;
}

export const UpcomingInspections: React.FC<UpcomingInspections> = ({
    statuses,
    itemTypeCode,
    canAddInspection,
    nonEditableStatusesMessage,
    myTasksOperationsBlocked,
    readonly,
    schedulesAmended
}) => {
    const history = useHistory();
    const { projectItemId, projectNumber } = useParams<ProjectItemDetailsParams>();
    const isOffline = useIsOffline();
    const [selectedTasks, setSelectedTasks] = useState<UpcomingTaskTableRow[]>([]);
    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const currentUser = useCurrentUser();

    const currentUserHasPermissionToManageItemsWrite = useHasPermissions(
        Permission.ManageInspectionsWrite,
        projectNumber
    );

    const query = {
        filter: {
            and: [
                {
                    meta: {
                        any: { label: 'ProjectItemId', value: projectItemId },
                    },
                },
                {
                    meta: {
                        any: { label: 'InspectionTypeId' },
                    },
                },
                {
                    or: [
                        { 'status/type': StatusType.ToDo },
                        { 'status/type': StatusType.InProgress },
                    ],
                },
            ],
        },
    };

    const myTasks = useTasksSummaries(query);
    const data: UpcomingTaskTableRow[] | undefined = statuses
        ? myTasks.data?.map((i) => ({
            id: i.id!,
            name: getTaskName(i.name),
            forecastedOn: !!i.forecastedOn ? new Date(i.forecastedOn.date) : undefined,
            status: i.status.name!,
            assignedUserId: i.assignedUserId,
            health: i.health.toString()!,
            healthColor: i.healthColor,
        }))
        : undefined;

    // This is to update the list of selected items if a task has been reassigned in mytasks after the user selected it in the table
    useEffect(() => {
        if (currentUserHasPermissionToManageItemsWrite) return;
        const newSelectedIds = selectedTasks.filter(s => data?.some(t => t.id === s.id && t.assignedUserId === currentUser.id)).map(s => s.id);
        setSelectedTasks(data?.filter(t => newSelectedIds.includes(t.id)) ?? []);
    }, [myTasks.data]);

    const anyTaskAssignedToCurrentUser = data?.some(t => t.assignedUserId === currentUser.id);
    const statusesSortOrder = statuses?.map((s) => s.name);
    const columns = getTableColumns(statusesSortOrder);

    const itemInfoRoute = useItemInfoRoute();
    const goToNewInspection = () => {
        history.push(
            generatePath(itemInfoRoute.routes!.newInspection.path, {
                projectNumber,
                projectItemId,
                itemTypeCode,
            })
        );
    };

    const nonEditableStatusMessage = `Inspection can not be added as the Item is of status ${nonEditableStatusesMessage}`;
    const getTooltipContent = () => {
        if (isOffline) {
            return youAreOfflineFeatureNotAvailableMessage;
        }
        if (!canAddInspection) return nonEditableStatusMessage;
        if (myTasksOperationsBlocked) {
            return MyTasksOperationsBlockedMessage;
        }
        return '';
    };

    const getWontDoTooltipContent = () => {
        if (isOffline) {
            return youAreOfflineFeatureNotAvailableMessage;
        }
        if (!selectedTasks.length) return 'Please select Tasks';
        if (myTasksOperationsBlocked) {
            return MyTasksOperationsBlockedMessage;
        }
        return '';
    };
    return (
        <>
            <div className={styles.headerContainer}>
                <h3 className={styles.header}><span>Upcoming Inspections</span>
                    {schedulesAmended && <Badge className={styles.badge}>Amended Inspection</Badge>}</h3>
                <div className={styles['buttons-container']}>
                    {(currentUserHasPermissionToManageItemsWrite || anyTaskAssignedToCurrentUser) && !readonly && (
                        <>
                            {!!data?.length && (
                                <ChangeStatus
                                    projectItemId={projectItemId}
                                    projectNumber={projectNumber}
                                    show={showChangeStatus}
                                    tasks={selectedTasks}
                                    onClose={() => {
                                        setShowChangeStatus(false);
                                        setSelectedTasks([]);
                                    }}
                                />
                            )}
                            <TooltipWrapper
                                id="wontdo"
                                tooltipContent={getWontDoTooltipContent()}
                                show={isOffline || myTasksOperationsBlocked || !selectedTasks.length}>
                                <Button
                                    className={cn(styles['wontdo-btn'], !readonly ? styles['margin-right'] : '')}
                                    variant="secondary"
                                    disabled={!selectedTasks.length}
                                    onClick={() => setShowChangeStatus(true)}>
                                    Will Not Occur
                                </Button>
                            </TooltipWrapper>
                        </>
                    )}
                    {!readonly && (
                        <TooltipWrapper
                            id="new-inspection"
                            tooltipContent={getTooltipContent()}
                            show={isOffline || myTasksOperationsBlocked || !canAddInspection}>
                            <AddButton
                                text="Add Inspection"
                                onClick={goToNewInspection}
                                disabled={isOffline || myTasksOperationsBlocked || !canAddInspection}
                                style={
                                    !canAddInspection || myTasksOperationsBlocked
                                        ? { pointerEvents: 'none' }
                                        : {}
                                }
                            />
                        </TooltipWrapper>
                    )}
                </div>
            </div>
            <TaskTable<UpcomingTaskTableRow>
                columns={columns}
                data={data}
                sorted={{ sortColumnIndex: 3, sortDirection: 'asc' }}
                noDataMessage="There are no scheduled Upcoming Inspections for this item."
                selectable={(currentUserHasPermissionToManageItemsWrite || anyTaskAssignedToCurrentUser) && !readonly}
                onSelectionChange={setSelectedTasks}
                overriddenSelected={selectedTasks}
                customRowSelectable={(row) => {
                    return {
                        selectable:
                            !isOffline &&
                            !readonly &&
                            (row.assignedUserId === currentUser.id ||
                                currentUserHasPermissionToManageItemsWrite),
                        noSelectableMessage: isOffline
                            ? youAreOfflineFeatureNotAvailableMessage
                            : readonly
                                ? editBlockedMessage
                                : row.assignedUserId !== currentUser.id
                                    ? 'You must be an assignee of this task'
                                    : '',
                    };
                }}
            />
        </>
    );
};
