import { generatePath } from '@laingorourke/core-web-components';
import { DesignerSummary, ProjectItemDetails } from 'domain/models/api-models';
import { useCurrentExternalUserGroups } from 'domain/services/dataService/useCurrentExternalUserGroups';
import { useSites } from 'domain/services/dataService/useSites';
import {
    fetchAreasSummaries,
    useParentAreasSummaries,
    useSubAreasSummaries,
} from 'domain/store/reducers/areas';
import { useProjectClassifications } from 'domain/store/reducers/classifications';
import { fetchDesignersSummaries } from 'domain/store/reducers/designers/actions';
import { useDesignersSummaries } from 'domain/store/reducers/designers/reducer';
import { loadingCompleted, loadingStarted } from 'domain/store/reducers/inProgress/reducer';
import {
    fetchProjectStatuses,
    useItemStatuses,
    useItemTypeMetadata,
    useSelectedItemTypeId,
} from 'domain/store/reducers/itemType';
import {
    fetchProjectItemNumberConfiguration,
    useAreaCodeInItemNumber,
} from 'domain/store/reducers/projectItemNumberConfiguration';
import { clearItemFormState, useProjectId } from 'domain/store/reducers/projects';
import { useIsExternalUser } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { routes } from 'views/routes/Routes';
import { ItemRouteParams } from '../../details/ItemRouteParams';
import { Mode } from './Mode';
import { UpsertForm } from './UpsertForm';

export const UpsertItem: React.FC<{
    mode: Mode;
    item?: ProjectItemDetails;
    itemTypeId?: string;
    goBack?: () => void;
}> = ({ mode, item, itemTypeId, goBack }) => {
    const dispatch = useAppDispatch();

    const { projectNumber, projectItemId, itemTypeCode } = useParams<ItemRouteParams>();
    const history = useHistory();
    const areas = useParentAreasSummaries();
    const subAreas = useSubAreasSummaries();
    const designers = useDesignersSummaries();
    const projectStatuses = useItemStatuses();
    const metadata = useItemTypeMetadata();
    const allProjectClassifications = useProjectClassifications(false);
    const classifications = allProjectClassifications?.filter(
        (c) => c.isActive || item?.classificationId === c.id
    );
    const selectedItemTypeId = useSelectedItemTypeId();
    itemTypeId = itemTypeId ?? selectedItemTypeId;

    const areaCodeInProjectNumber = useAreaCodeInItemNumber();
    const externalUserGroups = useCurrentExternalUserGroups();
    const isExternal = useIsExternalUser();

    const projectId = useProjectId(projectNumber);
    const sites = useSites(projectId);

    const dataLoaded =
        !!metadata &&
        !!projectStatuses &&
        !!designers &&
        !!areas &&
        !!subAreas &&
        !!classifications &&
        areaCodeInProjectNumber !== undefined &&
        (!isExternal || (isExternal && externalUserGroups.isFetched)) &&
        !!sites.data;

    useEffect(() => {
        dispatch(loadingStarted());
        dispatch(
            fetchDesignersSummaries({ projectNumber: projectNumber, itemTypeId: itemTypeId! })
        );
        dispatch(fetchAreasSummaries({ itemTypeId: itemTypeId!, projectNumber }));
        dispatch(fetchProjectStatuses({ itemTypeId: itemTypeId! }));
        dispatch(fetchProjectItemNumberConfiguration({ projectNumber, itemTypeId: itemTypeId! }));
        return () => {
            dispatch(clearItemFormState());
        };
    }, [projectNumber, itemTypeId, dispatch]);

    useEffect(() => {
        if (dataLoaded) dispatch(loadingCompleted());
    }, [dataLoaded, dispatch]);

    const designerSelectList = (designers: DesignerSummary[], item?: ProjectItemDetails) => {
        if (item === undefined) return designers;

        // Don't add an empty designer to the designer options
        if (!item.designerId) return designers;

        const selectedDesigner = {
            id: item.designerId,
            name: item.designerName,
        } as DesignerSummary;
        const selectedExists = designers.some((d) => d.id === selectedDesigner.id);
        if (!selectedExists) return [selectedDesigner, ...designers];

        return designers;
    };

    const goToItems = () =>
        goBack
            ? goBack()
            : history.push(
                  generatePath(routes.projects.routes!.project.routes!.items.path, {
                      projectNumber,
                      itemTypeCode,
                  })
              );

    return (
        <>
            {dataLoaded && (projectItemId === undefined || item !== undefined) && (
                <Modal show={true} onHide={goToItems} animation={false} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{mode} Item</Modal.Title>
                    </Modal.Header>
                    <SecuredContent permissions={Permission.ManageItemsWrite}>
                        <UpsertForm
                            projectNumber={projectNumber}
                            itemTypeCode={itemTypeCode}
                            mode={mode}
                            classifications={classifications}
                            categories={metadata?.categories!}
                            areas={areas!}
                            subAreas={subAreas!}
                            projectStatuses={projectStatuses!}
                            // Calling designerSelectList to show a selected disabled designer, if any, in the edit mode
                            designers={designerSelectList(designers, item)}
                            goToItems={goToItems}
                            item={item}
                            areaCodeInProjectNumber={areaCodeInProjectNumber}
                            externalUserGroups={externalUserGroups?.data}
                            isExternal={isExternal}
                            sites={sites.data}
                        />
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
