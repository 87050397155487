import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Designer, DesignerSummary, ProjectDesigner } from 'domain/models/api-models';
import { RootState } from 'domain/store/rootStore';
import { useSelector } from 'react-redux';
import {
    createDesigner,
    deleteDesigner,
    disableDesigner,
    enableDesigner,
    fetchDesigners,
    fetchDesignersSummaries,
    fetchProjectDesigners,
    reactivateDesigner,
    updateDesigner,
} from './actions';

const initialState = {
    designers: null as unknown as Designer[],
    projectDesigners: null as unknown as ProjectDesigner[],
    summaries: null as unknown as DesignerSummary[],
};

const designersSlice = createSlice({
    name: 'designers',
    initialState: initialState,
    reducers: {
        clearDesigners() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchDesigners.fulfilled.type,
            (state, action: PayloadAction<Designer[]>) => {
                state.designers = action.payload;
            }
        );

        builder.addCase(
            fetchDesignersSummaries.fulfilled.type,
            (state, action: PayloadAction<DesignerSummary[]>) => {
                state.summaries = action.payload = action.payload.sort((a, b) =>
                    // we want '<New Designer>' to be the last item on the list
                    b.name?.startsWith('<') ? -1 : a.name!.localeCompare(b.name!)
                );
                state.summaries = action.payload;
            }
        );

        builder.addCase(
            fetchProjectDesigners.fulfilled.type,
            (state, action: PayloadAction<ProjectDesigner[]>) => {
                state.projectDesigners = action.payload;
            }
        );

        builder.addCase(disableDesigner.fulfilled.type, (state, action: PayloadAction<string>) => {
            state.projectDesigners = state.projectDesigners.map((d) =>
                d.id === action.payload ? { ...d, isDisabled: true } : d
            );
        });

        builder.addCase(enableDesigner.fulfilled.type, (state, action: PayloadAction<string>) => {
            state.projectDesigners = state.projectDesigners.map((d) =>
                d.id === action.payload ? { ...d, isDisabled: false } : d
            );
        });

        builder.addCase(createDesigner.fulfilled.type, (state, action: PayloadAction<Designer>) => {
            if (action.payload === undefined) return;
            state.designers.push(action.payload);
        });

        builder.addCase(updateDesigner.fulfilled.type, (state, action: PayloadAction<Designer>) => {
            if (action.payload === undefined) return;
            state.designers = state.designers?.map((r) =>
                r.id === action.payload.id ? action.payload : r
            );
        });

        builder.addCase(deleteDesigner.fulfilled.type, (state, action: PayloadAction<string>) => {
            if (action.payload === undefined) return;
            state.designers = state.designers.map((c) =>
                c.id === action.payload ? { ...c, isActive: false } : c
            );
        });

        builder.addCase(
            reactivateDesigner.fulfilled.type,
            (state, action: PayloadAction<Designer>) => {
                if (action.payload === undefined) return;
                state.designers = state.designers.map((c) =>
                    c.id === action.payload.id ? action.payload : c
                );
            }
        );
    },
});

export const useProjectDesigners = () =>
    useSelector((state: RootState) => state.designers.projectDesigners);
export const useDesignersSummaries = () =>
    useSelector((state: RootState) => state.designers.summaries);
export const useDesigners = () => useSelector((state: RootState) => state.designers.designers);
export const useDesigner = (id: string) =>
    useSelector((state: RootState) => state.designers.designers?.find((d) => d.id === id));

export const { clearDesigners } = designersSlice.actions;
export default designersSlice.reducer;
