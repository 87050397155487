import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, post, remove } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from '../../../rootStore';
import { addNotification } from '../../notificationsReducer';

export const fetchFavouriteProjects = createAsyncThunk(
    'projects/favourites/get',
    async () => await get('projects/favourites')
);

interface Params {
    projectNumber: string;
    projectName: string;
}

export const addFavouriteProject = createAsyncThunk<string, Params, { state: RootState }>(
    'projects/favourites/post',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await post(`projects/favourites`, args.projectNumber);
            dispatch(
                addNotification({
                    message: `You have selected ${args.projectName} as a favourite.`,
                    isSuccess: true,
                })
            );
            return args.projectNumber;
        }, dispatch);
    }
);

export const removeFavouriteProject = createAsyncThunk<string, Params, { state: RootState }>(
    'projects/favourites/delete',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await remove(`projects/favourites/${args.projectNumber}`);
            dispatch(
                addNotification({
                    message: `${args.projectName} is no longer a favourite.`,
                    isSuccess: true,
                })
            );
            return args.projectNumber;
        }, dispatch);
    }
);
