import { CollapsibleCard } from '@laingorourke/core-web-components';
import { ClassificationHub } from 'domain/models/api-models';
import { useProjectClassifications } from 'domain/store/reducers/classifications';
import { useProject, useProjectClassificationGuidelines } from 'domain/store/reducers/projects';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ClassificationBadge, InfoPanel, Link } from 'views/components';
import { DaysControl } from 'views/components/form/daysControl/daysControl';
import styles from './ClassificationGuidelinesCategory.module.scss';

const IgmsLink: React.FC<{ label: string | undefined; href: string | undefined }> = ({
    label,
    href,
}) =>
    !!href?.length ? (
        <Link target="_blank" href={href}>
            {label}
        </Link>
    ) : (
        <>{label}</>
    );

interface ClassificationGuidelinesCategoryProps {
    categoryId: string;
}

export const ClassificationGuidelinesCategory: React.FC<ClassificationGuidelinesCategoryProps> = ({
    categoryId,
}) => {
    const classificationGuidelines = useProjectClassificationGuidelines();
    const classifications = useProjectClassifications();
    const project = useProject(categoryId);
    const complianceTemplateToDisplay = classificationGuidelines?.complianceTemplates
        ?.filter(
            (c) => (!project && c.categoryId === categoryId) || (c.categoryId === null && !!project)
        )
        .sort((a, b) => {
            const classificationHubs = Object.keys(ClassificationHub);
            const classificationA = classifications.find((c) => c.id === a.classificationId)!;
            const classificationB = classifications.find((c) => c.id === b.classificationId)!;
            return (
                (!!classificationA ? classificationHubs.indexOf(classificationA.hub!) : -1) -
                    (!!classificationB ? classificationHubs.indexOf(classificationB.hub!) : -1) ||
                (!!classificationB
                    ? classificationA?.name?.localeCompare(classificationB.name!) ?? -1
                    : -1)
            );
        });

    return (
        <div className={styles.container}>
            {classificationGuidelines && complianceTemplateToDisplay?.length === 0 && (
                <InfoPanel message="No Classification Guidelines" className={styles.infoPanel} />
            )}
            {complianceTemplateToDisplay?.map((c) => (
                <CollapsibleCard
                    key={c.id}
                    title={
                        classifications?.find(
                            (classification) => classification.id === c.classificationId
                        )?.name
                    }
                    collapsed={true}
                    prepend={<ClassificationBadge id={c.classificationId} />}>
                    <Container>
                        <Row className={styles.row}>
                            <Col xs={12} md={4}>
                                Examples
                            </Col>
                            <Col xs={12} md={8} className={styles.example}>
                                {c.example}
                            </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col xs={12} md={4}>
                                Inspections
                            </Col>
                            <Col xs={12} md={8}>
                                {c.inspections?.map((i) => (
                                    <DaysControl count={i.frequency} key={i.name}>
                                        <div className="form-control">
                                            <IgmsLink label={i.name} href={i.igmsLink} />
                                        </div>
                                    </DaysControl>
                                ))}
                                {c.inspections?.length === 0 && 'No Inspections'}
                            </Col>
                        </Row>
                        <Row className={styles.row}>
                            <Col xs={12} md={4}>
                                Control Measures
                            </Col>
                            <Col xs={12} md={8}>
                                {c.controlMeasures?.map((c) => (
                                    <DaysControl
                                        count={c.leadDays ?? 0}
                                        key={c.name}
                                        dayPreffix="Lead ">
                                        <div className="form-control">
                                            <IgmsLink label={c.name} href={c.igmsLink} />
                                        </div>
                                    </DaysControl>
                                ))}
                            </Col>
                        </Row>
                    </Container>
                </CollapsibleCard>
            ))}
        </div>
    );
};
