import { generatePath } from '@laingorourke/core-web-components';
import { Integration } from 'domain/models/api-models';
import {
    clearIntegrationsMetadata,
    fetchIntegrationsMetadata,
    useIntegration,
    useIntegrationsMetadata,
} from 'domain/store/reducers/integrations';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import { useAppDispatch } from 'domain/store/rootStore';
import { useIntegrationRoute } from 'hooks/useIntegrationRoute';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { SharedRouteParams } from '../..';
import { UpsertForm } from './UpsertForm';

export interface IntegrationParams extends SharedRouteParams {
    integrationId: string;
}

export const UpsertIntegration: React.FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { integrationId, itemTypeCode, projectNumber } = useParams<IntegrationParams>();
    const integration: Integration | undefined = useIntegration(integrationId);
    const metadata = useIntegrationsMetadata();
    const itemTypeId = useSelectedItemTypeId();
    const isProjectMode = !!projectNumber;
    const integrationRoute = useIntegrationRoute();

    const isEditMode = integrationId !== undefined;

    const goToIntegration = () => {
        history.push(
            generatePath(integrationRoute.path, {
                itemTypeCode,
                ...(isProjectMode && { projectNumber }),
            })
        );
    };

    useEffect(() => {
        dispatch(fetchIntegrationsMetadata(itemTypeId));
        return () => {
            dispatch(clearIntegrationsMetadata());
        };
    }, [dispatch, itemTypeId]);

    return (
        <>
            {(integrationId === undefined || integration !== undefined) && metadata && (
                <Modal show={true} onHide={goToIntegration} animation={false} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditMode ? 'Edit' : 'New'} Integration</Modal.Title>
                    </Modal.Header>
                    <SecuredContent
                        permissions={Permission.ManageIntegrationSettingsWrite}
                        projectNumber={integration?.projectNumber}
                        ignoreSecurable={!isEditMode}>
                        <UpsertForm
                            isEditMode={isEditMode}
                            integration={integration}
                            metadata={metadata}
                            itemTypeId={itemTypeId}
                            goToIntegrations={goToIntegration}
                            projectNumber={projectNumber}
                        />
                    </SecuredContent>
                </Modal>
            )}
        </>
    );
};
