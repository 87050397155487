import { Status, StatusType, useTasksSummaries } from '@laingorourke/core-web-mytasksreactsdk';
import { fetchProjectItemDetails } from 'domain/store/reducers/projects';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import { usePrevious } from 'hooks/usePrevious';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { getTaskName } from '../../../../myTasksHelpers';
import { ProjectItemDetailsParams } from '../../ProjectItemDetailsParams';
import { ControlMeasureAssignee, TaskTable } from '../components';
import { AddControlMeasureButton } from './AddControlMeasureButton';
import styles from './ControlMeasures.module.scss';
import { getTableColumns } from './tableColumns';

export const ControlMeasures: React.FC<{
    myTasksOperationsBlocked: boolean;
    statuses: Status[] | undefined;
    readonly: boolean;
    canModifyTasks: boolean;
    nonEditableStatusesMessage: string;
    itemDetailsOwnerId?: string;
}> = ({
    myTasksOperationsBlocked,
    statuses,
    readonly,
    canModifyTasks,
    nonEditableStatusesMessage,
    itemDetailsOwnerId
}) => {
        const dispatch = useAppDispatch();
        const { projectItemId, projectNumber } = useParams<ProjectItemDetailsParams>();

        const currentUserHasManageItemsWritePermission = useHasPermissions(
            Permission.ManageItemsWrite,
            projectNumber
        );

        const query = {
            filter: {
                and: [
                    {
                        meta: {
                            any: { label: 'ProjectItemId', value: projectItemId },
                        },
                    },
                    {
                        meta: {
                            any: { label: 'ControlMeasureId' },
                        },
                    },
                ],
            },
        };
        const myTasks = useTasksSummaries(query);
        const data = statuses
            ? myTasks.data?.map((i) => ({
                id: i.id!,
                name: getTaskName(i.name),
                forecastedOn: !!i.forecastedOn?.date ? new Date(i.forecastedOn.date) : undefined,
                status: i.status.name!,
                resolution: i.resolution,
                assignedUserId: i.assignedUserId,
                health: i.health.toString()!,
                healthColor: i.healthColor,
            }))
            : undefined;

        const statusesSortOrder = statuses?.map((s) => s.name!);
        const columns = getTableColumns(statusesSortOrder);

        // If a task is completed, project item status could have been recalculated in the background
        // As we don't have any socket-based solution, this is the easiest way of handling this
        // When completed tasks number changes we refetch project item details
        const numberOfCompletedTasks = useMemo(
            () => myTasks?.data?.filter((t) => t.status.type === StatusType.Completed).length,
            [myTasks.data]
        );

        const previewsNumberOfCompletedTasks = usePrevious(numberOfCompletedTasks, projectItemId);
        // This is to refresh the item status when CM is Completed.
        // Compeltion of a CM might trigger the change of item status. This happens in one of Comply Azure functions, we don't have any sockets / SignalR setup to refresh item details when it happens.
        // We assume that this is refreshed within 5 sec and refetch item details, if it's not refreshed within 5 sec, it's not a big deal.
        useEffect(() => {
            const refetchDelay = 5000;
            let timer = setTimeout(() => {
                // Compare the previous number of completed items to the new number. If they are different the item status could have been changed and we refetch the data.
                if (
                    (numberOfCompletedTasks ?? 0) != (previewsNumberOfCompletedTasks ?? 0) &&
                    // don't refesh when initialy there is no tasks loaded yet
                    previewsNumberOfCompletedTasks !== undefined
                ) {
                    dispatch(fetchProjectItemDetails({ projectItemId, projectNumber }));
                }
            }, refetchDelay);

            // this will clear Timeout
            // when component unmount like in willComponentUnmount
            return () => {
                clearTimeout(timer);
            };
        }, [numberOfCompletedTasks]);

        return (
            <>
                <div className={styles.headerContainer}>
                    <h3 className={styles.header}>Control Measures</h3>
                    <div className={styles['buttons-container']}>
                        {currentUserHasManageItemsWritePermission && (
                            <ControlMeasureAssignee
                                projectNumber={projectNumber}
                                projectItemId={projectItemId}
                                myTasksOperationsBlocked={myTasksOperationsBlocked
                                }
                                nonEditableStatusesMessage={nonEditableStatusesMessage}
                                readonly={readonly}
                                canUpdateControlMeasures={canModifyTasks}
                                currentOwnerId={itemDetailsOwnerId}
                                className={!readonly ? styles['margin-right'] : ''}
                            />
                        )}
                        {!readonly &&
                            <AddControlMeasureButton
                                myTasksOperationsBlocked={myTasksOperationsBlocked}
                                canAddControlMeasure={canModifyTasks}
                                nonEditableStatusesMessage={nonEditableStatusesMessage} />}
                    </div>
                </div >
                <TaskTable
                    columns={columns}
                    data={data}
                    noDataMessage="No Control Measures found"
                    sorted={{ sortColumnIndex: 3, sortDirection: 'asc' }}
                />
            </>
        );
    };
