import cn from 'classnames';
import React from 'react';
import styles from './TableCellActive.module.scss';

export const TableCellActive: React.FC<{ isActive: boolean; className?: string }> = ({
    isActive,
    className,
}) => {
    return (
        <span className={cn({ [styles.active]: isActive }, className)}>
            {isActive ? 'Active' : 'Inactive'}
        </span>
    );
};
