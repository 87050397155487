import { generatePath } from '@laingorourke/core-web-components';
import { useSelectedItemTypeId } from 'domain/store/reducers/itemType';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { ProjectParams } from 'views/routes/project/Project';
import { routes } from 'views/routes/Routes';

export const AssignPermission: React.FC = () => {
    const params = useParams<ProjectParams>();
    const history = useHistory();
    const goToPermissionManagement = () =>
        history.push(
            generatePath(
                routes.projects.routes!.project.routes!.projectSetup.routes!.permissionManagement
                    .path,
                {
                    ...params,
                }
            )
        );

    // TODO: remove if you don't need it
    const itemTypeId = useSelectedItemTypeId();

    return (
        <Modal show={true} onHide={goToPermissionManagement} animation={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Assign Permission</Modal.Title>
            </Modal.Header>
            <SecuredContent permissions={Permission.ProjectPermissionManagementWrite}>
                TODO
                {/* <CreateForm
                  TODO: for Dean, see how we do it, take reviews -> components  -> upsertreview.tsx as an example, it's one of the easiest forms
                /> */}
            </SecuredContent>
        </Modal>
    );
};
