import { Form, HorizontalSelect, Input, Select, TextArea, WarningPanel, useForm } from '@laingorourke/core-web-components';
import {
    ItemTypeCategory,
    WeatherEventType,
    WeatherInspectionType,
} from 'domain/models/api-models';
import { useHasPermissions } from 'domain/store/reducers/users';
import {
    createWeatherInspectionType,
    updateWeatherInspectionType,
} from 'domain/store/reducers/weather';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Permission } from 'security/Permission';
import { ModalFooter, ProjectSelector } from 'views/components';
import { Limit } from '../../Limit';
import { Unit } from '../../Unit';
import styles from './UpsertForm.module.scss';
import { WeatherInspectionTypeSchema } from './schema';

interface UpsertWeatherInspectionTypeFormProps {
    isEditMode: boolean;
    weatherInspectionType: WeatherInspectionType | undefined;
    categories: ItemTypeCategory[];
    goToWeatherInspectionTypes: () => void;
}

export const UpsertForm: React.FC<UpsertWeatherInspectionTypeFormProps> = ({
    isEditMode,
    weatherInspectionType,
    categories,
    goToWeatherInspectionTypes,
}) => {
    const userHasPermissionsToAllProjects = useHasPermissions(
        Permission.ManageWeatherSettingsWrite,
        ''
    );
    const dispatch = useAppDispatch();
    const form = useForm({
        initialValues: {
            id: weatherInspectionType?.id,
            allProjects: !userHasPermissionsToAllProjects
                ? false
                : isEditMode
                    ? weatherInspectionType?.projectNumber === null
                    : true,
            projectNumber: isEditMode ? weatherInspectionType!.projectNumber! : undefined,
            categoriesIds: isEditMode ? weatherInspectionType!.categoriesIds ?? [] : [],
            weatherEvent: isEditMode ? weatherInspectionType!.weatherEvent! : undefined,
            timePeriodInHours: isEditMode
                ? weatherInspectionType!.timePeriodInHours ?? null
                : undefined,
            limit: isEditMode ? weatherInspectionType!.limit! : undefined,
            value: isEditMode ? weatherInspectionType!.value! : undefined,
            acknowledgmentMessage: isEditMode ? weatherInspectionType?.acknowledgmentMessage : undefined
        },
        validationSchema: WeatherInspectionTypeSchema,
        onSubmit: async (values) => {
            const { id, weatherEvent, limit, value, timePeriodInHours, ...valuesToSubmit } = values;
            const request = {
                ...valuesToSubmit,
                weatherEvent: weatherEvent!,
                limit: limit!,
                value: value!,
                timePeriodInHours:
                    weatherEvent === WeatherEventType.Rainfall ? timePeriodInHours! : undefined,
            };
            const result = isEditMode
                ? await dispatch(updateWeatherInspectionType({ id: id!, ...request }))
                : await dispatch(createWeatherInspectionType(request));
            if ((result as any).payload) goToWeatherInspectionTypes();
        },
    });

    const { isSubmitting, values, setFieldValue } = form;
    const recordedValuePlaceholder = '{RecordedValue}';
    return (
        <Form form={form}>
            <Modal.Body>
                <ProjectSelector
                    permission={Permission.ManageWeatherSettingsWrite}
                    isEditMode={isEditMode}
                    allProjects={values.allProjects}
                    setFieldValue={setFieldValue}
                    isProjectMode={false}
                />
                <WarningPanel className={styles.warning}>
                    Use the placeholder <b>{recordedValuePlaceholder}</b> to include the actual recorded rainfall in your message
                </WarningPanel>
                <Form.Row>
                    <Form.Field name="acknowledgmentMessage" label="Acknowledgment Message">
                        <TextArea />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="categoriesIds" label="Category">
                        <Select
                            className={styles.select}
                            options={categories.map((c) => ({ value: c.id, label: c.name }))}
                            multi
                        />
                    </Form.Field>
                </Form.Row>
                <Form.Row>
                    <Form.Field name="weatherEvent" label="Type">
                        <HorizontalSelect
                            name="type"
                            options={Object.keys(WeatherEventType).map((type: string) => ({
                                label: WeatherEventType[type as keyof typeof WeatherEventType],
                                value: type,
                            }))}
                        />
                    </Form.Field>
                </Form.Row>
                {values.weatherEvent === WeatherEventType.Rainfall && (
                    <Form.Row>
                        <Form.Field name="timePeriodInHours" label="Time Period">
                            <Select options={[{ value: 24, label: '24 h' }]} />
                        </Form.Field>
                    </Form.Row>
                )}
                <Form.Row>
                    <Form.Field name="limit" label="Limit">
                        <HorizontalSelect
                            name="type"
                            options={Object.keys(Limit).map((type: string) => ({
                                label: Limit[type as keyof typeof Limit],
                                value: type,
                            }))}
                        />
                    </Form.Field>
                    <Form.Row>
                        <Form.Field name="value" label="Value">
                            <Input
                                append={
                                    !!values.weatherEvent
                                        ? values.weatherEvent === WeatherEventType.Rainfall
                                            ? Unit.mm
                                            : Unit.kmh
                                        : ''
                                }
                            />
                        </Form.Field>
                    </Form.Row>
                </Form.Row>
            </Modal.Body>
            <ModalFooter
                submitText={isEditMode ? 'Update' : 'Create'}
                isSubmitting={isSubmitting}
                onCancel={goToWeatherInspectionTypes}
            />
        </Form>
    );
};
