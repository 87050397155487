import { ColumnDefinition, selectFilter, textFilter } from '@laingorourke/core-web-components';
import { OptionTypeBase } from '@laingorourke/core-web-components/dist/Form/SelectProps';
import { ItemTypeCategory, Limit } from 'domain/models/api-models';
import { nameof } from 'helpers/nameOf';
import { ProjectDisplayName } from 'views/components';
import { TableCellActive } from 'views/components/table';
import { getCategoriesColumn } from 'views/components/table/categoriesColumn';
import { getFilterOptionsFromArray } from 'views/components/table/filters';
import styles from '../../../../components/table/Table.module.scss';
import { Unit } from '../Unit';
import { WeatherEventType } from '../WeatherEvent';
import { WeatherInspectionTypeTableRow } from './WeatherInspectionTypeTableRow';
import ActionButtons from './components/ActionButtons';

const actionsFormatter = (_: any, row: WeatherInspectionTypeTableRow) => (
    <ActionButtons weatherInspectionType={row} />
);

const actionsColumn: ColumnDefinition<WeatherInspectionTypeTableRow> = {
    field: (d) => d.id,
    text: '',
    renderer: actionsFormatter,
    headerClass: styles.minWidth100,
};

export const getTableColumns = (
    inspectionTypesTableData: WeatherInspectionTypeTableRow[] | undefined,
    categoryOptions: OptionTypeBase[],
    categories: ItemTypeCategory[] | undefined,
    showActions: boolean
): ColumnDefinition<WeatherInspectionTypeTableRow>[] => [
        getCategoriesColumn(inspectionTypesTableData, categoryOptions, categories, 'categoriesIds'),
        {
            field: (d) => d.projectName,
            key: nameof<WeatherInspectionTypeTableRow>('projectName'),
            text: 'Project',
            sortable: true,
            filter: textFilter(),
            renderer: (_, row: WeatherInspectionTypeTableRow) => (
                <ProjectDisplayName projectNumber={row.projectNumber} />
            ),
        },
        {
            field: (d) => d.acknowledgmentMessage,
            text: 'Acknowledgement Message'
        },
        {
            field: (d) => d.weatherEvent,
            text: 'Type',
            sortable: true,
            filter: selectFilter({
                options: getFilterOptionsFromArray(Object.values(WeatherEventType)),
            }),
            renderer: (_: any, row: WeatherInspectionTypeTableRow) =>
                WeatherEventType[row.weatherEvent as keyof typeof WeatherEventType],
        },
        {
            field: (d) => d.timePeriodInHours,
            text: 'Time period',
            sortable: true,
            headerClass: styles.noWrappedWitheSpace,
            renderer: (_: any, row: WeatherInspectionTypeTableRow) =>
                !!row.timePeriodInHours ? `over ${row.timePeriodInHours} h` : '',
        },
        {
            field: (d) => d.value,
            text: 'Measure',
            sortable: true,
            headerClass: styles.noWrappedWitheSpace,
            renderer: (_: any, row: WeatherInspectionTypeTableRow) =>
                `${row.limit === Limit.GreaterThan ? 'more' : 'less'} than ${row.value} ${Unit[row.unit]}`,
        },
        {
            field: (d) => d.isActive,
            text: 'Status',
            sortable: true,
            renderer: (_: any, row: WeatherInspectionTypeTableRow) => (
                <TableCellActive isActive={row.isActive} />
            ),
        },
        {
            ...actionsColumn,
            hidden: !showActions,
        },
    ];
