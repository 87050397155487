import {
    emojis,
    emojiValidationMessage,
    getIntegerYup,
    invalidCharacters,
} from 'views/components/form/validators';
import * as Yup from 'yup';

const itemNumberPrefixValidationError = 'Item Number Prefix requires up to 4 capital letters or digits';

export const getAreaSchema = (isSubArea: boolean, areaCodeInItemNumber: boolean) =>
    Yup.object().shape({
        name: Yup.string()
            .matches(emojis, emojiValidationMessage)
            .matches(
                invalidCharacters,
                `${isSubArea ? 'Sub ' : ''}Area contains invalid characters`
            )
            .max(100, `${isSubArea ? 'Sub ' : ''}Area cannot be longer than 100 characters`)
            .required(`${isSubArea ? 'Sub ' : ''}Area is required`),
        description: Yup.string()
            .matches(emojis, emojiValidationMessage)
            .matches(invalidCharacters, 'Description contains invalid characters')
            .max(250, 'Description cannot be longer than 250 characters'),
        ownerId: Yup.string()
            .nullable()
            .when('isSubArea', (isSubArea: boolean, schema: any) => {
                if (!isSubArea) {
                    return schema.required('Owner is required');
                }
                return schema;
            }),
        isSubArea: Yup.boolean().required(),
        parentAreaId: Yup.string().when('isSubArea', (isSubArea: boolean, schema: any) => {
            if (isSubArea) {
                return schema.required('Area is required');
            }
            return schema;
        }),
        fieldViewProjectId: getIntegerYup('Field View Project', 0, 999999).nullable(),
        asiteWorkspaceId: getIntegerYup('Asite Workspace', 60000, 5000000).nullable(),
        inheritFieldViewProjectId: Yup.boolean().nullable(),
        inheritAsiteWorkspaceId: Yup.boolean().nullable(),
        itemNumberPrefix: Yup.string()
            .nullable()
            .when('isSubArea', (isSubArea: boolean, schema: any) => {
                if (!isSubArea && areaCodeInItemNumber) {
                    return schema
                        .required('Item Number Prefix is required')
                        .matches(emojis, emojiValidationMessage)
                        .matches(/^[A-Z0-9]*$/, itemNumberPrefixValidationError)
                        .max(4, 'Item Number Prefix cannot be longer than 4 characters');
                }
                return schema;
            }),
        itemNumberCommencement: getIntegerYup('Item Number Commencement', 1, 900).when(
            'isSubArea',
            (isSubArea: boolean, schema: any) => {
                if (!isSubArea && areaCodeInItemNumber) {
                    return schema
                        .required('Item Number Commencement is required')
                        .matches(emojis, emojiValidationMessage)
                        .nullable();
                }
                return schema;
            }
        ),
    });
