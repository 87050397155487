import { Navigation, Page } from '@laingorourke/core-web-components';
import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { routes } from 'views/routes/Routes';
import { ProjectParams } from '../Project';

export const Weather: React.FC<PropsWithChildren> = ({ children }) => {
    const params = useParams<ProjectParams>();
    const weatherRoutes = routes.projects.routes!.project.routes!.weather.routes!;

    return (
        <Row>
            <Col md="3" lg="2">
                <Page.Section noPadding>
                    <Navigation direction="vertical" id="weather-navigation">
                        <Navigation.Route
                            route={weatherRoutes.weatherEventsItems}
                            params={{ ...params }}>
                            Items
                        </Navigation.Route>
                        <Navigation.Route
                            route={weatherRoutes.weatherEvents}
                            params={{ ...params }}>
                            Site Events
                        </Navigation.Route>
                    </Navigation>
                </Page.Section>
            </Col>
            <Col md="9" lg="10">
                {children}
            </Col>
        </Row>
    );
};
