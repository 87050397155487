import { generatePath, Navigation, Page, Skeleton } from '@laingorourke/core-web-components';
import cn from 'classnames';
import { clearBuiltViewItemMedia } from 'domain/store/reducers/builtViewIntegration';
import {
    clearItemDetails,
    useItemDetailsIsLocked,
    useProject,
} from 'domain/store/reducers/projects';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useAppDispatch } from 'domain/store/rootStore';
import React, { PropsWithChildren, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { SecuredContent } from 'views/components';
import { ProjectItemSecuredContent } from 'views/components/securedContent/ProjectItemSecureContent';
import { routes } from 'views/routes/Routes';
import styles from './ProjectItemDetailsPage.module.scss';
import { ProjectItemDetailsParams } from './ProjectItemDetailsParams';

interface ProjectItemDetailsPageProps {
    title: string | undefined;
    leftSidebar?: React.ReactNode;
    actionButtons?: React.ReactNode;
    showHeader?: boolean;
}
export const ProjectItemDetailsPage: React.FC<PropsWithChildren<ProjectItemDetailsPageProps>> = ({
    children,
    title,
    leftSidebar,
    actionButtons,
    showHeader,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const routeParams = useParams<ProjectItemDetailsParams>();

    const project = useProject(routeParams.projectNumber);
    const currentUserHasPermissions = useHasPermissions(
        Permission.ManageItemsRead,
        routeParams.projectNumber
    );

    const itemDetailsIsLocked = useItemDetailsIsLocked();

    const goToItems = () =>
        history.push(
            generatePath(routes.projects.routes!.project.routes!.items.path, { ...routeParams })
        );

    useEffect(() => {
        return () => {
            dispatch(clearItemDetails());
            dispatch(clearBuiltViewItemMedia());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page
            className={cn(styles.page, showHeader ? '' : styles['full-page'])}
            leftSidebar={currentUserHasPermissions && !itemDetailsIsLocked && leftSidebar}
            header={
                showHeader ? (
                    <Page.Header
                        title={title}
                        loading={title === undefined}
                        unauthorised={!currentUserHasPermissions}
                        className={styles.pageHeader}
                        actions={[actionButtons]}
                        goBack={goToItems}>
                        <Skeleton.Box height={23} width={200} loading={!project}>
                            <h4 className={styles.projectName}>{project?.name}</h4>
                        </Skeleton.Box>
                        <Navigation direction="horizontal">
                            <Navigation.Route
                                route={
                                    routes.projects.routes!.project.routes?.items.routes
                                        ?.itemDetails!
                                }
                                params={{ ...routeParams }}>
                                Info
                            </Navigation.Route>
                            <Navigation.Route
                                route={
                                    routes.projects.routes!.project.routes?.items.routes
                                        ?.builtViewMedia!
                                }
                                params={{ ...routeParams }}>
                                BuiltView
                            </Navigation.Route>
                            <Navigation.Route
                                route={
                                    routes.projects.routes!.project.routes?.items?.routes
                                        ?.schedules!
                                }
                                params={{ ...routeParams }}>
                                Inspection Schedules
                            </Navigation.Route>
                            <Navigation.Route
                                route={
                                    routes.projects.routes!.project.routes?.items.routes?.groups!
                                }
                                params={{ ...routeParams }}>
                                Subcontractors
                            </Navigation.Route>
                            <Navigation.Route
                                route={
                                    routes.projects.routes!.project.routes?.items?.routes?.history!
                                }
                                params={{ ...routeParams }}>
                                History
                            </Navigation.Route>
                        </Navigation>
                    </Page.Header>
                ) : (
                    <></>
                )
            }>
            <ProjectItemSecuredContent>
                <SecuredContent
                    permissions={[Permission.ProjectDetailsRead, Permission.ManageItemsRead]}>
                    {children}
                </SecuredContent>
            </ProjectItemSecuredContent>
        </Page>
    );
};
ProjectItemDetailsPage.defaultProps = {
    showHeader: true,
};
