import { Status } from 'domain/models/api-models';

export const getNonEditableStatuses = (itemStatuses: Status[] | undefined) =>
    itemStatuses?.filter((s) => !s.isEditable).map((s) => s.name!);

export const getNonEditableStatusesMessage = (itemStatuses: Status[] | undefined) => {
    if (!itemStatuses) {
        return '';
    }

    const statusesNames = getNonEditableStatuses(itemStatuses);
    if (statusesNames === undefined || statusesNames?.length <= 2) {
        return statusesNames?.join(' or ') ?? '';
    }

    const withoutLast = statusesNames?.slice(0, statusesNames.length - 1);
    return `${withoutLast?.join(', ')} or ${statusesNames![statusesNames!.length - 1]}`;
};
