import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { isGuid } from 'helpers/guidHelper';
import { getQueryResult } from './graphQlClient';
import { Group, Page } from './models';

const query = gql`
    query getGroupsByIds($ids: [ID!]!, $roleWithSystemIdPermissions: ID!) {
        groupsByIds(ids: $ids) {
            data {
                id
                name
                members {
                    data {
                        id
                        displayName
                        jobTitle
                        roleAssignments(roleWithSystemIdPermissions: $roleWithSystemIdPermissions) {
                            data {
                                role {
                                    id
                                    name
                                    isActive
                                    permissions {
                                        identifier
                                        type
                                    }
                                    securables {
                                        id
                                        typeId
                                        users {
                                            userId
                                            approvalRequired
                                            isActive
                                        }
                                    }
                                }
                                securables {
                                    id
                                    typeId
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const useGroupsByIdsWithMembersAndPermissions = (
    ids?: string[],
    roleWithSystemIdPermissions?: string
) => {
    ids = ids?.filter((id) => isGuid(id));
    return useQuery(
        [ids, ids, roleWithSystemIdPermissions],
        async () => {
            const result = await getQueryResult<{
                groupsByIds: Page<Group>;
            }>(query, {
                ids,
                roleWithSystemIdPermissions,
            });
            return result.groupsByIds.data;
        },
        {
            enabled: !!ids?.length,
        }
    );
};
