import { Form, useForm } from '@laingorourke/core-web-components';
import { assignGroups } from 'domain/store/reducers/projects';
import { useAppDispatch } from 'domain/store/rootStore';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Permission } from 'security/Permission';
import { ModalFooter, SecuredContent } from 'views/components';
import { GroupsSelector } from 'views/components/groupSelector';
import * as Yup from 'yup';
import { SelectedProjectItems } from '../components/SelectedProjectItem';

interface AssignOwnerProps {
    itemTypeId: string;
    projectNumber: string;
    projectItemsIds: string[];
    show: boolean;
    onClose: (success: boolean) => void;
}

const requiredMessage = 'At least 1 subcontractor is required';

export const AssignSubcontractor: React.FC<AssignOwnerProps> = ({
    itemTypeId,
    projectNumber,
    projectItemsIds,
    onClose,
}) => {
    const dispatch = useAppDispatch();

    const form = useForm({
        initialValues: {
            groupIds: [],
        },
        validationSchema: Yup.object().shape({
            groupIds: Yup.array(Yup.string())
                .nullable()
                .min(1, requiredMessage)
                .required(requiredMessage),
        }),
        onSubmit: async (values) => {
            await dispatch(
                assignGroups({
                    projectItemsIds,
                    groupIds: values.groupIds,
                    itemTypeId,
                    projectNumber,
                })
            );

            onClose(true);
        },
    });

    const { isSubmitting } = form;

    return (
        <Modal show={true} onHide={() => onClose(false)} animation={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Assign Subcontractors</Modal.Title>
            </Modal.Header>
            <SecuredContent permissions={Permission.ManageItemsWrite}>
                <Form form={form}>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Field name="groupIds" label="Subcontractors">
                                <GroupsSelector projectNumber={projectNumber} />
                            </Form.Field>
                        </Form.Row>
                        <SelectedProjectItems projectItemsIds={projectItemsIds} projectNumber={projectNumber} />
                    </Modal.Body>
                    <ModalFooter
                        submitText="Update"
                        isSubmitting={isSubmitting}
                        onCancel={() => onClose(false)}
                    />
                </Form>
            </SecuredContent>
        </Modal>
    );
};
