import { Button, ButtonProps, generatePath } from '@laingorourke/core-web-components';
import { useHasPermissions } from 'domain/store/reducers/users';
import { useIntegrationRoute } from 'hooks/useIntegrationRoute';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Permission } from 'security/Permission';
import { ActionButtonsPanel, usePageActions } from 'views/components';
import { SharedRouteParams } from '../..';

interface NewIntegrationButtonProps extends ButtonProps {}

export const NewIntegrationButton: React.FC<NewIntegrationButtonProps> = (props) => {
    const { itemTypeCode, projectNumber } = useParams<SharedRouteParams>();
    const history = useHistory();
    const isProjectMode = !!projectNumber;
    const integrationRoute = useIntegrationRoute();

    const currentUserHasAnyIntegrationWritePermission = useHasPermissions(
        Permission.ManageIntegrationSettingsWrite,
        projectNumber,
        !isProjectMode
    );

    const goToNewIntegrations = () => {
        history.push(
            generatePath(integrationRoute.routes!.newIntegration.path, {
                itemTypeCode,
                ...(isProjectMode && { projectNumber }),
            })
        );
    };

    usePageActions(
        currentUserHasAnyIntegrationWritePermission && (
            <ActionButtonsPanel>
                <Button variant="primary" onClick={goToNewIntegrations} {...props}>
                    New Integration
                </Button>
            </ActionButtonsPanel>
        ),
        [itemTypeCode, currentUserHasAnyIntegrationWritePermission]
    );

    return <> </>;
};
