import cn from 'classnames';
import React, { HTMLProps } from 'react';
import styles from './ApplicationLogo.module.scss';

interface ApplicationLogoProps extends HTMLProps<HTMLImageElement> {}

export const ApplicationLogo: React.FC<ApplicationLogoProps> = (props) => {
    const { className, ...rest } = props;
    return <img className={cn(styles.applicationLogo, className)} {...rest} />;
};
