import { useSchedule } from '@laingorourke/core-web-mytasksreactsdk';
import React from 'react';
import { CreateForm } from './CreateForm';

interface EditScheduleProps {
    scheduleId: string;
    inspectionTypes: { id: string; name: string }[];
    dateInService: Date;
    projectNumber: string;
    projectItemId: string;
    isEditMode: boolean;
    goBack: () => void;
}

export const EditSchedule: React.FC<EditScheduleProps> = ({
    scheduleId,
    inspectionTypes,
    dateInService,
    projectNumber,
    projectItemId,
    isEditMode,
    goBack,
}) => {
    const schedule = useSchedule(scheduleId);

    return !schedule.isLoading ? (
        <CreateForm
            projectNumber={projectNumber}
            projectItemId={projectItemId}
            dateInService={dateInService}
            inspectionTypes={inspectionTypes}
            goBack={goBack}
            isEditMode={isEditMode}
            schedule={schedule.data}
        />
    ) : (
        <> </>
    );
};
