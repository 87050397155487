import { createAsyncThunk } from '@reduxjs/toolkit';
import { P6Integration } from 'domain/models/api-models';
import { get, put } from 'domain/services/client';
import { runSafe } from 'domain/store/actions/errorHandling';
import { RootState } from 'domain/store/rootStore';
import { addNotification } from '../notificationsReducer';

interface BaseParams {
    projectNumber: string;
    itemTypeId: string;
}

export const fetchP6ProjectIntegration = createAsyncThunk<
    P6Integration,
    BaseParams,
    { state: RootState }
>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/p6Integrations/get',
    async (args) => {
        return (await get(
            `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/p6Integrations`
        ))!;
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

export const activateP6Integration = createAsyncThunk<void, BaseParams, { state: RootState }>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/p6Integrations/activate',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await put(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/p6Integrations/activate`
            );
            dispatch(
                addNotification({
                    message: 'P6 integration has been activated',
                    isSuccess: true,
                })
            );
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);

export const deactivateP6Integration = createAsyncThunk<void, BaseParams, { state: RootState }>(
    'itemtypes/{itemTypeId}/projects/{projectNumber}/p6Integrations/deactivate',
    async (args, { dispatch }) => {
        return await runSafe(async () => {
            await put(
                `itemtypes/${args.itemTypeId}/projects/${args.projectNumber}/p6Integrations/deactivate`
            );
            dispatch(
                addNotification({
                    message: 'P6 integration has been deactivated',
                    isSuccess: true,
                })
            );
        }, dispatch);
    },
    {
        condition: (args) => !!args.itemTypeId && !!args.projectNumber,
    }
);
